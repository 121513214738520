/**
 * @author Aditya Sodhani 
 */

// This library creates a 3D viewing platform on the DIV provided using the data provided.


// import * as THREE from 'three';
import * as THREE from './three.module';
import * as dat from './dat.gui.module';

import { TweenMax, gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all";

// (function(global, factory) {
//         typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
//             typeof define === 'function' && define.amd ? define(['exports'], factory) :
//             (global = global || self, factory(global.BHS3D = {}));
//     }(this, function(exports) {
'use strict';


// Version 0.0.0
// Version 1.0.0
// 1st conversion from html.erb to js

// Version 1.0.1
// Fixes issues with Gyration and Pumplogs;

// Version 1.0.2
// Gyration turned off initially from now
// Reduced the number of vertices for each geometry to make it go faster
// Fixed issues with exagerrating receivers

// Version 1.0.3
// Added GUI part for saving figures to Figures tab for automatic report generation
// Added functionality to mark events as representative events!


var VERSION = '1.0.3';
var parentContainer = null;
// Setting all required variables!
// Data Related
var plot_id = -1;
var project_id = -1;
var project_name = "";
var plot_title = "";

var drag = false;

var COLORMAP = [
    [0.0, 0xDA211F],
    [0.006369, 0xDA221F],
    [0.009554, 0xDA241F],
    [0.012739, 0xDB2520],
    [0.015924, 0xDB2620],
    [0.019108, 0xDC2821],
    [0.022293, 0xDC2921],
    [0.025478, 0xDC2B21],
    [0.028662, 0xDC2C21],
    [0.031847, 0xDD2E22],
    [0.035032, 0xDD2F22],
    [0.038217, 0xDE3123],
    [0.041401, 0xDE3223],
    [0.044586, 0xDE3423],
    [0.047771, 0xDE3523],
    [0.050955, 0xDF3624],
    [0.054140, 0xDF3824],
    [0.057325, 0xDF3925],
    [0.060510, 0xDF3A25],
    [0.063694, 0xE03C25],
    [0.066879, 0xE03D25],
    [0.070064, 0xE03F26],
    [0.073248, 0xE14026],
    [0.076433, 0xE14227],
    [0.079618, 0xE14327],
    [0.082803, 0xE24527],
    [0.085987, 0xE24627],
    [0.089172, 0xE24828],
    [0.092357, 0xE24928],
    [0.095541, 0xE34A29],
    [0.098726, 0xE34C29],
    [0.101911, 0xE44D29],
    [0.105096, 0xE44E29],
    [0.108280, 0xE4502A],
    [0.111465, 0xE4512A],
    [0.114650, 0xE5532B],
    [0.117834, 0xE5542B],
    [0.121019, 0xE6562B],
    [0.124204, 0xE6572B],
    [0.127389, 0xE6592C],
    [0.130573, 0xE65A2C],
    [0.133758, 0xE75C2C],
    [0.136943, 0xE75D2D],
    [0.140127, 0xE85E2D],
    [0.143312, 0xE8602D],
    [0.146497, 0xE8612E],
    [0.149682, 0xE8622E],
    [0.152866, 0xE9642F],
    [0.156051, 0xE9652F],
    [0.159236, 0xE9672F],
    [0.162420, 0xEA682F],
    [0.165605, 0xEA6A30],
    [0.168790, 0xEA6B30],
    [0.171975, 0xEB6D30],
    [0.175159, 0xEB6E31],
    [0.178344, 0xEB7031],
    [0.181529, 0xEB7131],
    [0.184713, 0xEC7232],
    [0.187898, 0xEC7432],
    [0.191083, 0xED7532],
    [0.194268, 0xED7632],
    [0.197452, 0xED7833],
    [0.200637, 0xED7933],
    [0.203822, 0xEE7B34],
    [0.207006, 0xEE7C34],
    [0.210191, 0xEE7E34],
    [0.213376, 0xEF7F35],
    [0.216561, 0xEF8135],
    [0.219745, 0xEF8235],
    [0.222930, 0xF08336],
    [0.226115, 0xF08536],
    [0.229299, 0xF08636],
    [0.232484, 0xF08836],
    [0.235669, 0xF18937],
    [0.238854, 0xF18A37],
    [0.242038, 0xF28C38],
    [0.245223, 0xF28D38],
    [0.248408, 0xF28F38],
    [0.251592, 0xF29038],
    [0.254777, 0xF29138],
    [0.257962, 0xF29138],
    [0.261146, 0xF29338],
    [0.264331, 0xF29338],
    [0.267516, 0xF29438],
    [0.270701, 0xF29538],
    [0.273885, 0xF29638],
    [0.277070, 0xF29738],
    [0.280255, 0xF29838],
    [0.283439, 0xF29838],
    [0.286624, 0xF29938],
    [0.289809, 0xF29A38],
    [0.292994, 0xF29B38],
    [0.296178, 0xF29C38],
    [0.299363, 0xF29D38],
    [0.302548, 0xF29E38],
    [0.305732, 0xF29F38],
    [0.308917, 0xF29F38],
    [0.312102, 0xF2A038],
    [0.315287, 0xF2A138],
    [0.318471, 0xF2A238],
    [0.321656, 0xF2A338],
    [0.324841, 0xF2A438],
    [0.328025, 0xF2A438],
    [0.331210, 0xF2A638],
    [0.334395, 0xF2A638],
    [0.337580, 0xF2A738],
    [0.340764, 0xF2A838],
    [0.343949, 0xF2A938],
    [0.347134, 0xF2AA38],
    [0.350318, 0xF2AB38],
    [0.353503, 0xF2AB38],
    [0.356688, 0xF2AC38],
    [0.359873, 0xF2AD38],
    [0.363057, 0xF2AE38],
    [0.366242, 0xF2AF38],
    [0.369427, 0xF2B038],
    [0.372611, 0xF2B038],
    [0.375796, 0xF2B238],
    [0.378981, 0xF2B238],
    [0.382166, 0xF2B338],
    [0.385350, 0xF2B438],
    [0.388535, 0xF2B538],
    [0.391720, 0xF2B638],
    [0.394904, 0xF2B738],
    [0.398089, 0xF2B738],
    [0.401274, 0xF2B838],
    [0.404459, 0xF2B938],
    [0.407643, 0xF2BA38],
    [0.410828, 0xF2BB38],
    [0.414013, 0xF2BC38],
    [0.417197, 0xF2BD38],
    [0.420382, 0xF2BE38],
    [0.423567, 0xF2BE38],
    [0.426752, 0xF2BF38],
    [0.429936, 0xF2C038],
    [0.433121, 0xF2C138],
    [0.436306, 0xF2C238],
    [0.439490, 0xF2C338],
    [0.442675, 0xF2C338],
    [0.445860, 0xF2C438],
    [0.449045, 0xF2C538],
    [0.452229, 0xF2C638],
    [0.455414, 0xF2C738],
    [0.458599, 0xF2C838],
    [0.461783, 0xF2C838],
    [0.464968, 0xF2CA38],
    [0.468153, 0xF2CA38],
    [0.471338, 0xF2CB38],
    [0.474522, 0xF2CC38],
    [0.477707, 0xF2CD38],
    [0.480892, 0xF2CE38],
    [0.484076, 0xF2CF38],
    [0.487261, 0xF2CF38],
    [0.490446, 0xF2D138],
    [0.493631, 0xF2D138],
    [0.496815, 0xF2D238],
    [0.500000, 0xF2D338],
    [0.503185, 0xF0D33A],
    [0.506369, 0xEDD23B],
    [0.509554, 0xEAD13D],
    [0.512739, 0xE7D03E],
    [0.515924, 0xE5CF40],
    [0.519108, 0xE1CE42],
    [0.522293, 0xDFCD43],
    [0.525478, 0xDCCC45],
    [0.528662, 0xD9CB47],
    [0.531847, 0xD6CA48],
    [0.535032, 0xD3C94A],
    [0.538217, 0xD0C84B],
    [0.541401, 0xCDC74D],
    [0.544586, 0xCAC64F],
    [0.547771, 0xC7C551],
    [0.550955, 0xC4C452],
    [0.554140, 0xC1C354],
    [0.557325, 0xBEC255],
    [0.560510, 0xBCC257],
    [0.563694, 0xB8C059],
    [0.566879, 0xB6C05A],
    [0.570064, 0xB2BF5C],
    [0.573248, 0xB0BE5E],
    [0.576433, 0xADBD5F],
    [0.579618, 0xAABC61],
    [0.582803, 0xA7BB62],
    [0.585987, 0xA4BA64],
    [0.589172, 0xA1B966],
    [0.592357, 0x9EB868],
    [0.595541, 0x9BB769],
    [0.598726, 0x98B66B],
    [0.601911, 0x95B56C],
    [0.605096, 0x92B46E],
    [0.608280, 0x8FB370],
    [0.611465, 0x8DB271],
    [0.614650, 0x89B173],
    [0.617834, 0x87B175],
    [0.621019, 0x83AF76],
    [0.624204, 0x81AF78],
    [0.627389, 0x7EAD79],
    [0.630573, 0x7BAD7B],
    [0.633758, 0x78AC7D],
    [0.636943, 0x75AB7F],
    [0.640127, 0x72AA80],
    [0.643312, 0x6FA982],
    [0.646497, 0x6CA883],
    [0.649682, 0x69A785],
    [0.652866, 0x66A686],
    [0.656051, 0x63A588],
    [0.659236, 0x60A48A],
    [0.662420, 0x5EA38C],
    [0.665605, 0x5AA28D],
    [0.668790, 0x58A18F],
    [0.671975, 0x55A090],
    [0.675159, 0x529F92],
    [0.678344, 0x4F9E94],
    [0.681529, 0x4C9E96],
    [0.684713, 0x499C97],
    [0.687898, 0x469C99],
    [0.691083, 0x439A9A],
    [0.694268, 0x409A9C],
    [0.697452, 0x3D999E],
    [0.700637, 0x3A989F],
    [0.703822, 0x3797A1],
    [0.707006, 0x3596A3],
    [0.710191, 0x3195A4],
    [0.713376, 0x2F94A6],
    [0.716561, 0x2B93A7],
    [0.719745, 0x2992A9],
    [0.722930, 0x2691AB],
    [0.726115, 0x2390AD],
    [0.729299, 0x208FAE],
    [0.732484, 0x1D8EB0],
    [0.735669, 0x1A8DB1],
    [0.738854, 0x178DB3],
    [0.742038, 0x148BB5],
    [0.745223, 0x118BB7],
    [0.748408, 0xE89B8],
    [0.751592, 0xB89BA],
    [0.754777, 0xA87BA],
    [0.757962, 0xA87B9],
    [0.761146, 0xA85B8],
    [0.764331, 0xB84B8],
    [0.767516, 0xA83B7],
    [0.770701, 0xB82B6],
    [0.773885, 0xA81B6],
    [0.777070, 0xB80B5],
    [0.780255, 0xA7FB4],
    [0.783439, 0xB7EB4],
    [0.786624, 0xA7DB3],
    [0.789809, 0xB7CB2],
    [0.792994, 0xA7BB1],
    [0.796178, 0xB7AB1],
    [0.799363, 0xB78B0],
    [0.802548, 0xB78B0],
    [0.805732, 0xB76AF],
    [0.808917, 0xB75AE],
    [0.812102, 0xB74AD],
    [0.815287, 0xB73AD],
    [0.818471, 0xB72AC],
    [0.821656, 0xB71AB],
    [0.824841, 0xB70AA],
    [0.828025, 0xB6FAA],
    [0.831210, 0xB6EA9],
    [0.834395, 0xB6DA8],
    [0.837580, 0xB6CA8],
    [0.840764, 0xB6BA7],
    [0.843949, 0xB69A6],
    [0.847134, 0xB68A6],
    [0.850318, 0xB67A5],
    [0.853503, 0xB66A4],
    [0.856688, 0xB65A3],
    [0.859873, 0xB64A3],
    [0.863057, 0xB63A2],
    [0.866242, 0xB62A2],
    [0.869427, 0xB61A1],
    [0.872611, 0xB60A0],
    [0.875796, 0xB5F9F],
    [0.878981, 0xB5E9F],
    [0.882166, 0xB5C9E],
    [0.885350, 0xB5C9D],
    [0.888535, 0xB5A9D],
    [0.891720, 0xC5A9C],
    [0.894904, 0xB589B],
    [0.898089, 0xC579B],
    [0.901274, 0xB569A],
    [0.904459, 0xC5599],
    [0.907643, 0xB5498],
    [0.910828, 0xC5398],
    [0.914013, 0xB5297],
    [0.917197, 0xC5196],
    [0.920382, 0xB5095],
    [0.923567, 0xC4F95],
    [0.926752, 0xC4D94],
    [0.929936, 0xC4D94],
    [0.933121, 0xC4B93],
    [0.936306, 0xC4A92],
    [0.939490, 0xC4991],
    [0.942675, 0xC4891],
    [0.945860, 0xC4790],
    [0.949045, 0xC468F],
    [0.952229, 0xC458F],
    [0.955414, 0xC448E],
    [0.958599, 0xC438D],
    [0.961783, 0xC428D],
    [0.964968, 0xC408C],
    [0.968153, 0xC408B],
    [0.971338, 0xC3E8A],
    [0.974522, 0xC3E8A],
    [0.977707, 0xC3C89],
    [0.980892, 0xC3B89],
    [0.984076, 0xC3A88],
    [0.987261, 0xC3987],
    [0.990446, 0xC3886],
    [0.993631, 0xC3786],
    [0.996815, 0xC3685],
    [1.000000, 0xC3584]
];

var STRAIN_JET_COLORMAP = [
    [0.000000, '#000C77'],
    [0.005650, '#000F78'],
    [0.011299, '#00117A'],
    [0.016949, '#00127C'],
    [0.022599, '#00147E'],
    [0.028249, '#00157F'],
    [0.033898, '#001781'],
    [0.039548, '#001982'],
    [0.045198, '#001B85'],
    [0.050847, '#001D86'],
    [0.056497, '#001F88'],
    [0.062147, '#002189'],
    [0.067797, '#00238C'],
    [0.073446, '#00258D'],
    [0.079096, '#00288F'],
    [0.084746, '#002990'],
    [0.090395, '#002C92'],
    [0.096045, '#002D94'],
    [0.101695, '#003096'],
    [0.107345, '#003197'],
    [0.112994, '#003599'],
    [0.118644, '#00379C'],
    [0.124294, '#003B9E'],
    [0.129944, '#003D9F'],
    [0.135593, '#0042A2'],
    [0.141243, '#0045A3'],
    [0.146893, '#004AA6'],
    [0.152542, '#004EA7'],
    [0.158192, '#0052AB'],
    [0.163842, '#0056AC'],
    [0.169492, '#005AAE'],
    [0.175141, '#005EB0'],
    [0.180791, '#0063B3'],
    [0.186441, '#0066B4'],
    [0.192090, '#006BB7'],
    [0.197740, '#006EB8'],
    [0.203390, '#0073BC'],
    [0.209040, '#0077BD'],
    [0.214689, '#007CBF'],
    [0.220339, '#0080C1'],
    [0.225989, '#0086C4'],
    [0.231638, '#008AC6'],
    [0.237288, '#008EC8'],
    [0.242938, '#0092C9'],
    [0.248588, '#0097CD'],
    [0.254237, '#009CCE'],
    [0.259887, '#00A1D2'],
    [0.265537, '#00A4D3'],
    [0.271186, '#00A9D5'],
    [0.276836, '#00AED7'],
    [0.282486, '#00B3DA'],
    [0.288136, '#00B8DB'],
    [0.293785, '#00BDDF'],
    [0.299435, '#00C0E0'],
    [0.305085, '#00C6E3'],
    [0.310734, '#00CAE5'],
    [0.316384, '#00D0E7'],
    [0.322034, '#00D4EA'],
    [0.327684, '#00DAEC'],
    [0.333333, '#00DEEE'],
    [0.338983, '#00E3F1'],
    [0.344633, '#00E8F2'],
    [0.350282, '#00EDF6'],
    [0.355932, '#00F2F7'],
    [0.361582, '#00F8FA'],
    [0.367232, '#00FDFC'],
    [0.372881, '#00FFFF'],
    [0.378531, '#00FFF8'],
    [0.384181, '#00FFF3'],
    [0.389831, '#00FFEC'],
    [0.395480, '#00FFE7'],
    [0.401130, '#00FFDF'],
    [0.406780, '#00FFDA'],
    [0.412429, '#00FFD4'],
    [0.418079, '#00FFCF'],
    [0.423729, '#13FFC9'],
    [0.429379, '#1FFFC4'],
    [0.435028, '#28FFBD'],
    [0.440678, '#30FFB8'],
    [0.446328, '#37FFB2'],
    [0.451977, '#3EFFAD'],
    [0.457627, '#45FFA7'],
    [0.463277, '#4CFFA2'],
    [0.468927, '#51FF9C'],
    [0.474576, '#58FF97'],
    [0.480226, '#5EFF92'],
    [0.485876, '#65FF8D'],
    [0.491525, '#6AFF88'],
    [0.497175, '#72FF83'],
    [0.502825, '#78FF7E'],
    [0.508475, '#7EFF79'],
    [0.514124, '#84FF74'],
    [0.519774, '#8BFF70'],
    [0.525424, '#90FF6C'],
    [0.531073, '#97FF68'],
    [0.536723, '#9DFF63'],
    [0.542373, '#A3FF60'],
    [0.548023, '#A9FF5C'],
    [0.553672, '#B0FF58'],
    [0.559322, '#B6FF55'],
    [0.564972, '#BCFF52'],
    [0.570621, '#C2FF4F'],
    [0.576271, '#C9FF4C'],
    [0.581921, '#CFFF49'],
    [0.587571, '#D6FF47'],
    [0.593220, '#DDFF45'],
    [0.598870, '#E4FF43'],
    [0.604520, '#EAFE42'],
    [0.610169, '#F1FE41'],
    [0.615819, '#F7FE40'],
    [0.621469, '#FEFE3F'],
    [0.627119, '#FFFE3E'],
    [0.632768, '#FFF83D'],
    [0.638418, '#FFF13B'],
    [0.644068, '#FFEA3A'],
    [0.649718, '#FFE338'],
    [0.655367, '#FFDD37'],
    [0.661017, '#FFD735'],
    [0.666667, '#FFD134'],
    [0.672316, '#FFC932'],
    [0.677966, '#FFC331'],
    [0.683616, '#FFBD30'],
    [0.689266, '#FFB72E'],
    [0.694915, '#FFB02D'],
    [0.700565, '#FFAB2C'],
    [0.706215, '#FFA32A'],
    [0.711864, '#FF9E29'],
    [0.717514, '#FF9727'],
    [0.723164, '#FF9226'],
    [0.728814, '#FF8B25'],
    [0.734463, '#FF8624'],
    [0.740113, '#FF7E22'],
    [0.745763, '#FF7921'],
    [0.751412, '#FF7320'],
    [0.757062, '#FF6D1F'],
    [0.762712, '#FF671D'],
    [0.768362, '#FF621C'],
    [0.774011, '#FF5C1B'],
    [0.779661, '#FF551A'],
    [0.785311, '#FF4F19'],
    [0.790960, '#FF4A18'],
    [0.796610, '#FF4417'],
    [0.802260, '#FF3F16'],
    [0.807910, '#FF3815'],
    [0.813559, '#FF3214'],
    [0.819209, '#FF2C13'],
    [0.824859, '#FF2613'],
    [0.830508, '#FF2012'],
    [0.836158, '#FF1A11'],
    [0.841808, '#FF1111'],
    [0.847458, '#FF0010'],
    [0.853107, '#FF000F'],
    [0.858757, '#FF000F'],
    [0.864407, '#FF000F'],
    [0.870056, '#FF000F'],
    [0.875706, '#FF000E'],
    [0.881356, '#FF000E'],
    [0.887006, '#F8000D'],
    [0.892655, '#F2000D'],
    [0.898305, '#EB000C'],
    [0.903955, '#E5000B'],
    [0.909605, '#E0000B'],
    [0.915254, '#DA000A'],
    [0.920904, '#D3000A'],
    [0.926554, '#CD0009'],
    [0.932203, '#C70009'],
    [0.937853, '#C10008'],
    [0.943503, '#BA0007'],
    [0.949153, '#B50007'],
    [0.954802, '#AE0007'],
    [0.960452, '#A80006'],
    [0.966102, '#A20006'],
    [0.971751, '#9D0005'],
    [0.977401, '#970005'],
    [0.983051, '#920005'],
    [0.988701, '#8B0004'],
    [0.994350, '#860004'],
    [1.000000, '#800004'],
]

var STRAIN_SEISMIC_COLORMAP = [
    [0.000000, '#000031'],
    [0.014663, '#000054'],
    [0.029326, '#000060'],
    [0.043988, '#00006B'],
    [0.058651, '#000076'],
    [0.073314, '#00007E'],
    [0.087977, '#00008A'],
    [0.102639, '#000095'],
    [0.117302, '#0000A0'],
    [0.131965, '#0000A8'],
    [0.146628, '#0000B1'],
    [0.161290, '#0000BF'],
    [0.175953, '#0000C7'],
    [0.190616, '#0000D5'],
    [0.205279, '#0000DE'],
    [0.219941, '#0000EC'],
    [0.234604, '#0000F4'],
    [0.249267, '#0000FC'],
    [0.263930, '#0C0CFF'],
    [0.278592, '#1C1CFF'],
    [0.293255, '#2828FF'],
    [0.307918, '#3D3DFF'],
    [0.322581, '#4D4DFF'],
    [0.337243, '#5D5DFF'],
    [0.351906, '#6969FF'],
    [0.366569, '#7575FF'],
    [0.381232, '#8585FF'],
    [0.395894, '#9595FF'],
    [0.410557, '#A5A5FF'],
    [0.425220, '#B5B5FF'],
    [0.439883, '#C4C4FF'],
    [0.454545, '#D4D4FF'],
    [0.469208, '#E4E4FF'],
    [0.483871, '#E8E8FF'],
    [0.498534, '#FDFDFF'],
    [0.513196, '#FFF0F0'],
    [0.527859, '#FFE1E1'],
    [0.542522, '#FFD1D1'],
    [0.557185, '#FFC1C1'],
    [0.571848, '#FFB1B1'],
    [0.586510, '#FFA5A5'],
    [0.601173, '#FF9999'],
    [0.615836, '#FF8989'],
    [0.630499, '#FF7979'],
    [0.645161, '#FF6969'],
    [0.659824, '#FF5959'],
    [0.674487, '#FF4949'],
    [0.689150, '#FF3838'],
    [0.703812, '#FF2828'],
    [0.718475, '#FF2424'],
    [0.733138, '#FF1111'],
    [0.747801, '#FF0101'],
    [0.762463, '#F70000'],
    [0.777126, '#EF0000'],
    [0.791789, '#E70000'],
    [0.806452, '#DF0000'],
    [0.821114, '#D70000'],
    [0.835777, '#D30000'],
    [0.850440, '#CB0000'],
    [0.865103, '#C30000'],
    [0.879765, '#BB0000'],
    [0.894428, '#B30000'],
    [0.909091, '#AB0000'],
    [0.923754, '#A30000'],
    [0.938416, '#9B0000'],
    [0.953079, '#970000'],
    [0.967742, '#910000'],
    [0.982405, '#870000'],
    [1, '#7F0000']
]


var MTI_COLORMAP = [
    [0, 0x3070BA],
    [29.999 / 360, 0x3070BA],
    [30 / 360, 0x51AC5A],
    [59.999999 / 360, 0x51AC5A],
    [60 / 360, 0xFFFD55],
    [89.9999999 / 360, 0xFFFD55],
    [90 / 360, 0xE19875],
    [119.9999999 / 360, 0xE19875],
    [120 / 360, 0xEA3323],
    [149.999999 / 360, 0xEA3323],
    [150 / 360, 0xDE8AA3],
    [179.99999 / 360, 0xDE8AA3],
    [180 / 360, 0x3070BA],
    [209.99999 / 360, 0x3070BA],
    [210 / 360, 0x51AC5A],
    [239.999999 / 360, 0x51AC5A],
    [240 / 360, 0xFFFD55],
    [269.9999999 / 360, 0xFFFD55],
    [270 / 360, 0xE19875],
    [299.9999999 / 360, 0xE19875],
    [300 / 360, 0xEA3323],
    [329.999999 / 360, 0xEA3323],
    [330 / 360, 0xDE8AA3],
    [1, 0xDE8AA3],
];

var MODELLING_MODE = false;
var admin = false;
var event_datasets = [];
var perf_models = [];

var well_info = [];
var well_stage_info = [];
var event_figure_types = [];
var perf_figure_types = [];
var srv_figure_types = [];
var sceneView = [];

var hide_event_datasets = [],
    hide_designs = [];


var measurementAlert = null,
    measurementAlertDiv = null;
var xVal1, yVal1, zVal1, xVal2, yVal2, zVal2;
var custom_views;
var eventSizeVariationSlider;
var eventColorSlider;
var eventCounterRender = 0,
    visibleEvents = 0;
var stCounterRender = 0,
    visibleST = 0;
var sampleCounter = 0;
var sceneWidthSettings, sceneHeightSettings
var LEGEND_TEXT = [],
    LEGEND_COLOR = [];
var eventErrorOptions;


var pickDelay = 0;
var size = 0;
var trtWell = [];
var mtrWell = [];
var addWell = [];
var rec = [];
var wellLog = [];
var fracture = [];
var perf = [];
var events = [];
var eventCounter = 0;
var formationTops = [];
var formationOpacity = 0.7;
var formationTopsPoints = [];
var faults = [];
var faultOpacity = 0.7;
var sourceTensor = [];
var momentTensor = [];
var sortedEvents = [];
var revealData = [];
var wellLabel = [];
var pumpLogs = [];
var gyration = [];
var pumpLogsDrawnTime = [];
var eventMap = {};
var bk_x_min = NaN;
var x_min = NaN;
var bk_x_max = NaN;
var x_max = NaN;
var x_div = NaN;

var bk_y_min = NaN;
var y_min = NaN;
var bk_y_max = NaN;
var y_max = NaN;
var y_div = NaN;

var bk_z_min = NaN;
var z_min = NaN;
var bk_z_max = NaN;
var z_max = NaN;
var z_div = NaN;

var take_snapshot_button = [];
var EXAGGERATION_X = 1;
var EXAGGERATION_Y = 1;
var EXAGGERATION_Z = 1;
var SCENE_ROWS = 1;
var SCENE_COLS = 1;
var SNAPSHOT_FACTOR = 1.5;
var COMPASS_FACTOR = 1.5;
var topView = 0;
var sideView = 0;
var positions = [];
var point = THREE.Vector3();
var CAMERA_FAR = 0;
var DOWNSAMPLE = 0.05;
var PUMPLOGS_PRESENT = false;
var PUMPLOG_SAMPLES = 5000;
var PUMPLOG_SUBPLOTS = 1;
var pumpLogRenderCounter = 0;
var STRAIN_PRESENT = false;

// Source Tensor Timelapse
var ST_TIMELAPSE = false;
var ST_TIMELAPSE_PAUSE = false;
var ST_TIMELAPSE_CURRENT_STAGE = false;
var ST_TIMELAPSE_MODE = false;

// Events
var MISFIT_MIN = 0;
var MISFIT_MAX = 0;
var MAGNITUDE_MIN = 0;
var MAGNITUDE_MAX = 0;
var EAST_ERROR_MIN = 0;
var EAST_ERROR_MAX = 0;
var NORTH_ERROR_MIN = 0;
var NORTH_ERROR_MAX = 0;
var DEPTH_ERROR_MIN = 0;
var DEPTH_ERROR_MAX = 0;
var SOURCE_CONDITION_MIN = 0;
var SOURCE_CONDITION_MAX = 0;
var MOMENT_CONDITION_MIN = 0;
var MOMENT_CONDITION_MAX = 0;
var EVENT_SIZE = 15;
var eventTimeMin = 0;
var eventTimeMax = 0;

// Event Timelapse
var ET_TIMELAPSE = false;
var ET_TIMELAPSE_PAUSE = false;
var ET_TIMELAPSE_CURRENT_STAGE = false;
var ET_TIMELAPSE_MODE = false;

var wellModelObjects = [];
var noModels = 0;
var strainDataUrl = "";
var strainData = null;
var filteredStrainTime = null;
var volumeDataUrl = [];
var volumeData = [];
var volumeDataName = [];
var volumePointCloud = [];
var volumeGeometryDrawn = [];
var volumeDataBounds = [];
var volumeDataGUIControl = [];
var volumeMin = [];
var volumeMax = [];
var volumeLut = [];
var volOptions = [];
var volTypeFolder = [];


var FIBER_DATA_PRESENT = false;
var FIBER_DATA_INDEX = 0;

var FIBER_DATA = [];
var fiberData = [];
var FIBER_MIN = [];
var FIBER_MAX = [];
var FIBER_LUT = [];
var FIBER_MIN_MD = [];
var FIBER_MAX_MD = [];
var FIBER_DATA_GUI = {};
var fibRec = [];
var fibRecCount, fibDataCount;
var FIBER_TIMELAPSE_START = false;
var FIBER_TIMELAPSE_STOP = false;
var fibRecGeomPicking = [];
var fibRecGeomDrawn = [];
var fibRecMaterial = [];
var fibDataGeomDrawn = [];
var fibDataGeomPicking = [];
var fibDataMaterial = [];
var fibRecMesh = [];
var fibDataMesh = [];

// Scene & GUI Related
var scene = new THREE.Scene();
var stats = null;
var renderer = THREE.WebGLRenderer({
    antialias: true,
    logarithmicDepthBuffer: false,
});
var compassRenderer = null;
var container = null;
var pickingScene = new THREE.Scene();
var pickingTexture = new THREE.Scene();
var pickingData = [];
var axisHelper = new THREE.Group();
var compassContainer = null;
var compassScene = new THREE.Scene();
var COMPASS_WIDTH = 125;
var COMPASS_HEIGHT = 125; //parentContainer.width() /15;

var xText = null,
    yText = null,
    zText = null;
var gui = null;


var mouse = new THREE.Vector2();
var pickingID = 1;

var gridGeometry = new THREE.BufferGeometry();
var gridMaterial = null;
var gridMesh = null;
var gridXScale = 1;
var gridYScale = 1;
var gridZScale = 1;
var gridSize = 250;
var gridSizeDropdown = null;

var mtWellGeomDrawn = [];
var mtWellGeomPicking = [];
var mtWellMaterial = null;
var mtWellMesh = [];
var mtWellMeshPick = [];

var tmWellGeomDrawn = [];
var tmWellGeomPicking = [];
var tmWellMaterial = null;
var tmWellMesh = [];
var tmWellMeshPick = [];
var tmWellMaterialPick = null;
var tmWellColor;

var addWellGeomDrawn = [];
var addWellGeomPicking = [];
var addWellMaterial = null;
var addWellMesh = [];
var addWellMeshPick = [];

var wellMaterial;

var wellLabelGeom = [];
var wellLabelMaterial = null;
var wellLabelMesh = [];
var wellLabelFolder = null;
var wellLabelOptions = {};
var wellLabelController = [];

var perfGeomDrawn = null;
var perfGeomPicking = null;
var perfMaterial = null;
var perfMaterialPick = null;
var perfMesh = null;
var perfMeshPick = null;

var perfCheckBoxes = [
    []
];
var perfControllers = [
    []
];
var perfCount, offsets, iColors, iScale, eventTempColor;
var perfMatArraySize;

var recGeomDrawn = null;
var recGeomPicking = null;
var recMaterial = null;
var recMaterialPick = null;
var receiverCheckBox = null;
var recMesh = null;
var recMeshPick = null;
var fractureGeomDrawn = null;
var fractureGeomPicking = null;
var fractureMaterial = null;
var fractureMaterialPick = null;
var fractureCheckBox = null;
var fractureMesh = null;
var fractureMeshPick = null;
var fracMatArraySize = null;
var fracMatrixArray = null;

var wellLogGeomDrawn = [];
var wellLogGeomPicking = [];
var wellLogMaterial = null;
var wellLogMaterialPick = null;
var wellLogCheckBox = null;
var wellLogMesh = [];
var wellLogMeshPick = [];
var wellLogControllers = [
    []
];

var revealDataMaterial = null;
var revealDataMaterialPick = null;
var revealDataGeomDrawn = null;
var revealDataGeomPicking = null;
var revealDataCheckBox = null;
var revealDataMesh = null;
var revealDataMeshPick = null;

var eventMaterial = null;
var eventMaterialPick = null;
var eventGeomDrawn = null;
var eventGeomPicking = null;
var eventMesh = null;
var eventMeshPick = null;
var eventTime = [];

var centerOffsetX, centerOffsetY, centerOffsetZ;
var wellOffsetX, wellOffsetY, wellOffsetZ;

var eventSize = 15;
var eventCheckBoxes = [
    []
];
var eventControllers = [
    []
];
var eastErrorMaterial = null;
var eastErrorGeom = null;
var eastErrorLine = null;
var northErrorMaterial = null;
var northErrorGeom = null;
var northErrorLine = null;
var depthErrorMaterial = null;
var depthErrorGeom = null;
var depthErrorLine = null;
var azimuthErrorMaterial = null;
var azimuthErrorGeom = null;
var azimuthErrorLine = null;
var rayTracingMaterial = null;
var rayTracingGeom = null;
var rayTracingLine = null;

var gyrationMaterial = null;
var gyrationMaterialPick = null;
var gyrationGeomDrawn = null;
var gyrationGeomPicking = null;
var gyrationMesh = null;
var gyrationMeshPick = null;

var momentTensorMaterial = null;
var momentTensorMaterialPick = null;
var momentTensorGeomDrawn = [];
var momentTensorGeomPicking = null;
var momentTensorMesh = [];
var momentTensorMeshPick = null;
var momentTensorSize = 15;
var momentTensorCheckBoxes = [
    []
];
var momentTensorControllers = [
    []
];
var momentTensorWells = null;
var momentTensorStages = null;
var momentTensorStagesCheckBoxes = null;
var momentTensorDisplayAttributes = null;
var momentTensorFilterAttributes = null;

var sourceTensorMaterial = null;
var sourceTensorMaterialPick = null;
var sourceTensorGeomDrawn = [];
var sourceTensorGeomPicking = null;
var sourceTensorMesh = [];
var sourceTensorMeshPick = null;
var sourceTensorSize = 15;
var sourceTensorCheckBoxes = [
    []
];
var sourceTensorControllers = [
    []
];
var sourceTensorWells = null;
var sourceTensorStages = null;
var sourceTensorStagesCheckBoxes = null;
var sourceTensorDisplayAttributes = null;
var sourceTensorFilterAttributes = null;

var formationTopPointGeomDrawn = [];
var formationTopPointGeomPicking = [];
var formationTopPointMaterial = null;
var formationTopPointMaterialPick = null;
var formationTopPointMesh = null;
var formationTopPointPick = null;
var formationTopGeomDrawn = [];
var formationTopGeomPicking = [];
var formationTopMaterial = null;
var formationTopMaterialPick = null;
var formationTopMesh = null;
var formationTopPick = null;
var formTopCheckBoxes = [];
var formTopColormaps = [];
var formTopControllers = [
    []
];
var formationTopsFolder = null;

var formLabel = [];
var formLabelGeom = [];
var formLabelMaterial = null;
var formLabelMesh = [];
var formLabelFolder = null;
var formLabelController = [];

var faultGeomDrawn = [];
var faultGeomPicking = [];
var faultCheckBoxes = [];

// custom global variables
var projector = null;
var sprite1 = null;
var canvas1 = null;
var context1 = null;
var texture1 = null;

var measurementMode = false;
var measurementAlert = "";
var measurementAlertDiv = "";

var editMode = false;
var editEventsFolder = null;
var editEventsFolderValues = {}



var timelineMode = false;
var clickCounter = 0;
var timeCounter = 0;

var arrowHelper = null,
    arrowHelper2 = null,
    arrowHelper3 = null;

var rotation_matrix = [
    [1, 0, 0],
    [0, 1, 0],
    [0, 0, 1]
];

var frac_designs = [];

var local_easting = 0,
    local_northing = 0,
    local_tvdss = 0;

var currentStages = [],
    currentStagesPerfIndex = [];

var dasEvents = [];

function initialize() {


    arrowHelper = null;
    arrowHelper2 = null;
    arrowHelper3 = null;
    MODELLING_MODE = false
    admin = false;
    event_datasets = [];
    perf_models = [];
    well_info = [];
    well_stage_info = [];
    event_figure_types = [];
    perf_figure_types = [];
    srv_figure_types = [];
    eventSizeVariationSlider = null;
    eventColorSlider = null;
    eventCounterRender = 0;
    visibleEvents = 0;
    stCounterRender = 0;
    visibleST = 0;
    sceneWidthSettings = null;
    sceneHeightSettings = null;
    LEGEND_TEXT = [];
    LEGEND_COLOR = [];
    eventErrorOptions = null;
    sampleCounter = 0;
    timeCounter = 0;
    size = 0;
    trtWell = [];
    mtrWell = [];
    addWell = [];
    rec = [];
    wellLog = [];
    fracture = [];
    perf = [];
    events = [];
    eventCounter = 0;
    formationTops = [];
    formationTopsPoints = [];
    sourceTensor = [];
    momentTensor = [];
    sortedEvents = [];
    revealData = [];
    wellLabel = [];
    pumpLogs = [];
    gyration = [];
    pumpLogsDrawnTime = [];
    eventMap = {};
    bk_x_min = NaN;
    x_min = NaN;
    bk_x_max = NaN;
    x_max = NaN;
    bk_y_min = NaN;
    y_min = NaN;
    bk_y_max = NaN;
    y_max = NaN;
    bk_z_min = NaN;
    z_min = NaN;
    bk_z_max = NaN;
    z_max = NaN;
    stats = null;
    take_snapshot_button = [];
    EXAGGERATION_X = 1;
    EXAGGERATION_Y = 1;
    EXAGGERATION_Z = 1;
    SCENE_ROWS = 1;
    SCENE_COLS = 1;
    SNAPSHOT_FACTOR = 1.5;
    COMPASS_FACTOR = 1.5;
    topView = 0;
    sideView = 0;
    positions = [];
    point = THREE.Vector3();
    CAMERA_FAR = 0;
    DOWNSAMPLE = 0.05;
    PUMPLOGS_PRESENT = false;
    PUMPLOG_SAMPLES = 5000;
    pumpLogRenderCounter = 0;
    STRAIN_PRESENT = false;

    // Source Tensor Timelapse
    ST_TIMELAPSE = false;
    ST_TIMELAPSE_PAUSE = false;
    ST_TIMELAPSE_CURRENT_STAGE = false;
    ST_TIMELAPSE_MODE = false;

    // Events
    MISFIT_MIN = 0;
    MISFIT_MAX = 0;
    MAGNITUDE_MIN = 0;
    MAGNITUDE_MAX = 0;
    EAST_ERROR_MIN = 0;
    EAST_ERROR_MAX = 0;
    NORTH_ERROR_MIN = 0;
    NORTH_ERROR_MAX = 0;
    DEPTH_ERROR_MIN = 0;
    DEPTH_ERROR_MAX = 0;
    SOURCE_CONDITION_MIN = 0;
    SOURCE_CONDITION_MAX = 0;
    MOMENT_CONDITION_MIN = 0;
    MOMENT_CONDITION_MAX = 0;
    EVENT_SIZE = 15;
    eventTimeMin = 0;
    eventTimeMax = 0;

    // Event Timelapse
    ET_TIMELAPSE = false;
    ET_TIMELAPSE_PAUSE = false;
    ET_TIMELAPSE_CURRENT_STAGE = false;
    ET_TIMELAPSE_MODE = false;

    wellModelObjects = [];
    noModels = 0;
    strainDataUrl = "";
    strainData = null;
    filteredStrainTime = null;
    volumeDataUrl = [];
    volumeData = [];
    volumeDataName = [];
    volumePointCloud = [];
    volumeGeometryDrawn = [];
    volumeDataBounds = [];
    volumeDataGUIControl = [];
    volumeMin = [];
    volumeMax = [];
    volumeLut = [];
    volOptions = [];
    volTypeFolder = [];


    FIBER_DATA_PRESENT = false;
    FIBER_DATA_INDEX = 0;

    FIBER_DATA = [];
    fiberData = [];
    FIBER_MIN = [];
    FIBER_MAX = [];
    FIBER_LUT = [];
    FIBER_MIN_MD = [];
    FIBER_MAX_MD = [];
    FIBER_DATA_GUI = {};
    fibRec = [];
    FIBER_TIMELAPSE_START = false;
    FIBER_TIMELAPSE_STOP = false;
    fibRecGeomPicking = [];
    fibRecGeomDrawn = [];
    fibRecMaterial = [];
    fibDataGeomDrawn = [];
    fibDataGeomPicking = [];
    fibDataMaterial = [];
    fibRecMesh = [];
    fibDataMesh = [];
    fibRecCount = null;
    fibDataCount = null;


    // Scene & GUI Related
    scene = new THREE.Scene();
    renderer = THREE.WebGLRenderer({
        antialias: true,
        logarithmicDepthBuffer: false,
    });
    container = null;
    pickingScene = new THREE.Scene();
    pickingTexture = new THREE.Scene();
    pickingData = [];
    axisHelper = new THREE.Group();
    compassContainer = null;
    compassScene = new THREE.Scene();
    COMPASS_WIDTH = 125;
    COMPASS_HEIGHT = 125; //parentContainer.width() /15;
    gui = null;
    xText = null, yText = null, zText = null;
    mouse = new THREE.Vector2();
    pickingID = 1;

    gridGeometry = new THREE.BufferGeometry();
    gridMaterial = null;
    gridMesh = null;
    gridXScale = 1;
    gridYScale = 1;
    gridZScale = 1;
    gridSize = 250;
    gridSizeDropdown = null;

    mtWellGeomDrawn = [];
    mtWellGeomPicking = [];
    mtWellMaterial = null;
    mtWellMesh = [];
    mtWellMeshPick = [];

    tmWellGeomDrawn = [];
    tmWellGeomPicking = [];
    tmWellMaterial = null;
    tmWellMesh = [];
    tmWellMeshPick = [];

    addWellGeomDrawn = [];
    addWellGeomPicking = [];
    addWellMaterial = null;
    addWellMesh = [];
    addWellMeshPick = [];

    wellLabelGeom = [];
    wellLabelMaterial = null;
    wellLabelMesh = [];
    wellLabelFolder = null;
    wellLabelOptions = {};
    wellLabelController = [];

    perfGeomDrawn = null;
    perfGeomPicking = null;
    perfMaterial = null;
    perfMaterialPick = null;
    perfMesh = null;
    perfMeshPick = null;

    perfCheckBoxes = [
        []
    ];
    perfControllers = [
        []
    ];

    recGeomDrawn = null;
    recGeomPicking = null;
    recMaterial = null;
    recMaterialPick = null;
    receiverCheckBox = null;
    recMesh = null;
    recMeshPick = null;
    fractureGeomDrawn = null;
    fractureGeomPicking = null;
    fractureMaterial = null;
    fractureMaterialPick = null;
    fractureCheckBox = null;
    fractureMesh = null;
    fractureMeshPick = null;
    fracMatArraySize = null;
    fracMatrixArray = null;

    wellLogGeomDrawn = [];
    wellLogGeomPicking = [];
    wellLogMaterial = null;
    wellLogMaterialPick = null;
    wellLogCheckBox = null;
    wellLogMesh = [];
    wellLogMeshPick = [];
    wellLogControllers = [
        []
    ];

    revealDataMaterial = null;
    revealDataMaterialPick = null;
    revealDataGeomDrawn = null;
    revealDataGeomPicking = null;
    revealDataCheckBox = null;
    revealDataMesh = null;
    revealDataMeshPick = null;

    eventMaterial = null;
    eventMaterialPick = null;
    eventGeomDrawn = null;
    eventGeomPicking = null;
    eventMesh = null;
    eventMeshPick = null;
    eventTime = [];
    eventSize = 15;
    eventCheckBoxes = [
        []
    ];
    eventControllers = [
        []
    ];
    eastErrorMaterial = null;
    eastErrorGeom = null;
    eastErrorLine = null;
    northErrorMaterial = null;
    northErrorGeom = null;
    northErrorLine = null;
    depthErrorMaterial = null;
    depthErrorGeom = null;
    depthErrorLine = null;
    azimuthErrorMaterial = null;
    azimuthErrorGeom = null;
    azimuthErrorLine = null;
    rayTracingMaterial = null;
    rayTracingGeom = null;
    rayTracingLine = null;

    gyrationMaterial = null;
    gyrationMaterialPick = null;
    gyrationGeomDrawn = null;
    gyrationGeomPicking = null;
    gyrationMesh = null;
    gyrationMeshPick = null;

    momentTensorMaterial = null;
    momentTensorMaterialPick = null;
    momentTensorGeomDrawn = [];
    momentTensorGeomPicking = null;
    momentTensorMesh = [];
    momentTensorMeshPick = null;
    momentTensorSize = 15;
    momentTensorCheckBoxes = [
        []
    ];
    momentTensorControllers = [
        []
    ];
    momentTensorWells = null;
    momentTensorStages = null;
    momentTensorStagesCheckBoxes = null;
    momentTensorDisplayAttributes = null;
    momentTensorFilterAttributes = null;

    sourceTensorMaterial = null;
    sourceTensorMaterialPick = null;
    sourceTensorGeomDrawn = [];
    sourceTensorGeomPicking = null;
    sourceTensorMesh = [];
    sourceTensorMeshPick = null;
    sourceTensorSize = 15;
    sourceTensorCheckBoxes = [
        []
    ];
    sourceTensorControllers = [
        []
    ];
    sourceTensorWells = null;
    sourceTensorStages = null;
    sourceTensorStagesCheckBoxes = null;
    sourceTensorDisplayAttributes = null;
    sourceTensorFilterAttributes = null;

    formationTopPointGeomDrawn = [];
    formationTopPointGeomPicking = [];
    formationTopPointMaterial = null;
    formationTopPointMaterialPick = null;
    formationTopPointMesh = null;
    formationTopPointPick = null;
    formationTopGeomDrawn = [];
    formationTopGeomPicking = [];
    formationTopMaterial = null;
    formationTopMaterialPick = null;
    formationTopMesh = null;
    formationTopPick = null;
    formTopCheckBoxes = [];
    formTopColormaps = [];
    formTopControllers = [
        []
    ];
    formationTopsFolder = null;

    faultGeomDrawn = [];
    faultGeomPicking = [];
    faults = [];
    faultOpacity = 0.7;
    faultCheckBoxes = [];

    formLabel = [];
    formLabelGeom = [];
    formLabelMaterial = null;
    formLabelMesh = [];
    formLabelFolder = null;
    formLabelController = [];

    // custom global variables
    projector = null;
    sprite1 = null;
    canvas1 = null;
    context1 = null;
    texture1 = null;

    measurementMode = false;
    measurementAlert = "";
    measurementAlertDiv = "";

    editMode = false;
    editEventsFolder = null;
    editEventsFolderValues = {}

    timelineMode = false;
    clickCounter = 0;
    xVal1 = 0, yVal1 = 0, zVal1 = 0, xVal2 = 0, yVal2 = 0, zVal2 = 0;


    rotation_matrix = [
        [1, 0, 0],
        [0, 1, 0],
        [0, 0, 1]
    ];

    local_easting = 0, local_northing = 0, local_tvdss = 0;

    frac_designs = [];

    currentStages = [], currentStagesPerfIndex = [];
    dasEvents = [];
}

function convertToArray(data, key) {
    let arrData = [];
    for (var i = 0; i < data.length; i++) {
        var element = (data[i][key]);
        if (((data[i][key] != '')) && typeof data[i][key] !== "undefined" && (data[i][key] != "#VALUE!"))
            arrData.push(element);

    }
    return arrData;
}

function setData(data) {

    plot_id = data.plot.id; //-1;
    plot_title = data.plot.title; //-1;
    project_id = data.project.id; //-1;
    project_name = data.project.name; //-1;



    if (data['modelling'] && data['modelling'] != null) {
        MODELLING_MODE = true;
    }

    if (MODELLING_MODE) {
        project_name = "";
        plot_title = "";
    }

    if (data['admin'] && data['admin'] != null) {
        admin = data['admin']
    }

    if (data['well_stage_info'] && data['well_stage_info'] != null) {
        well_stage_info = data['well_stage_info']
    }

    if (data['well_info'] && data['well_info'] != null) {
        well_info = data['well_info']
    }

    if (data['event_datasets'] && data['event_datasets'] != null) {
        event_datasets = data['event_datasets']
    }


    if (data['perf_models'] && data['perf_models'] != null) {
        perf_models = data['perf_models']
    }

    if (data['event_figure_types'] && data['event_figure_types'] != null) {
        event_figure_types = data['event_figure_types']
    }

    if (data['perf_figure_types'] && data['perf_figure_types'] != null) {
        perf_figure_types = data['perf_figure_types']
    }

    if (data['srv_figure_types'] && data['srv_figure_types'] != null) {
        srv_figure_types = data['srv_figure_types']
    }

    if (data['attributes'] && data['attributes']['mag_min'] != null) {
        localStorage.setItem("mag-min-3d-plot" + project_id, data['attributes']['mag_min']);
    }

    if (data['attributes'] && data['attributes']['mag_max'] != null) {
        localStorage.setItem("mag-max-3d-plot" + project_id, data['attributes']['mag_max']);
    }

    if (data['attributes'] && data['attributes']['rotation_matrix'] && data['attributes']['rotation_matrix'] != null) {
        rotation_matrix = data['attributes']['rotation_matrix'];
    }

    if (data['attributes'] && data['attributes']['local_easting'] && data['attributes']['local_easting'] != null) {
        local_easting = data['attributes']['local_easting'];
    }

    if (data['attributes'] && data['attributes']['local_northing'] && data['attributes']['local_northing'] != null) {
        local_northing = data['attributes']['local_northing'];
    }

    if (data['attributes'] && data['attributes']['local_tvdss'] && data['attributes']['local_tvdss'] != null) {
        local_tvdss = data['attributes']['local_tvdss'];
    }

    if (data['frac_designs'] && data['frac_designs'].length > 0) {
        frac_designs = data['frac_designs'];
    }


    if (data['attributes'] && data['attributes']['default_display'] && data['attributes']['default_display']['design_hidden']) {
        hide_designs = data['attributes']['default_display']['design_hidden'];
    }

    if (data['attributes'] && data['attributes']['default_display'] && data['attributes']['default_display']['event_dataset_hidden']) {
        hide_event_datasets = data['attributes']['default_display']['event_dataset_hidden'];
    }

}

function addAnnotation(type, data) {


    if (type == "Fiber Data") {

        // console.log("Update fiber data", data);

        FIBER_MAX = data['fiber_max'];
        FIBER_MIN = data['fiber_min'];

        fibDataMaterial = new THREE.MeshLambertMaterial({
            map: new THREE.TextureLoader().load('https://borehole-seismic-biz.s3.amazonaws.com/texture.jpg?t=2123213012'),
            vertexColors: THREE.VertexColors,
            side: THREE.DoubleSide
        });

        fibDataMaterial.onBeforeCompile = function(shader) {
            shader.vertexShader =
                `
                #define LAMBERT
                // instanced
                attribute vec3 iOffset;
                attribute vec3 iColor;
                attribute vec3 iScale;
                varying vec3 vLightFront;
                varying vec3 vIndirectFront;
                attribute vec4 aInstanceMatrix0;
                attribute vec4 aInstanceMatrix1;
                attribute vec4 aInstanceMatrix2;
                attribute vec4 aInstanceMatrix3;
    
                #ifdef DOUBLE_SIDED
                    varying vec3 vLightBack;
                    varying vec3 vIndirectBack;
                #endif
                #include <common>
                #include <uv_pars_vertex>
                #include <uv2_pars_vertex>
                #include <envmap_pars_vertex>
                #include <bsdfs>
                #include <lights_pars_begin>
                #include <color_pars_vertex>
                #include <fog_pars_vertex>
                #include <morphtarget_pars_vertex>
                #include <skinning_pars_vertex>
                #include <shadowmap_pars_vertex>
                #include <logdepthbuf_pars_vertex>
                #include <clipping_planes_pars_vertex>
                void main() {
                    #include <uv_vertex>
                    #include <uv2_vertex>
                    #include <color_vertex>
                    #ifdef USE_COLOR
                        vColor.xyz = iColor.xyz;
                    #endif
                    #include <beginnormal_vertex>
                    #include <morphnormal_vertex>
                    #include <skinbase_vertex>
                    #include <skinnormal_vertex>
                    #include <defaultnormal_vertex>
                    #include <begin_vertex>
    
                    mat4 aInstanceMatrix = mat4(
                        aInstanceMatrix0,
                        aInstanceMatrix1,
                        aInstanceMatrix2,
                        aInstanceMatrix3
                    );
                    transformed = (aInstanceMatrix * vec4( position , 1. )).xyz;
                    transformed *= iScale;
                    //vec3 positionEye = ( modelViewMatrix * aInstanceMatrix * vec4( position, 1.0 ) ).xyz;
                    //gl_Position = projectionMatrix * vec4( positionEye, 1.0 );
    
                    #include <morphtarget_vertex>
                    #include <skinning_vertex>
                    #include <project_vertex>
                    #include <logdepthbuf_vertex>
                    #include <clipping_planes_vertex>
                    #include <worldpos_vertex>
                    #include <envmap_vertex>
    
    
                    vec3 diffuse = vec3( 1.0 );
                    GeometricContext geometry;
                    geometry.position = mvPosition.xyz;
                    geometry.normal = normalize( transformedNormal );
                    geometry.viewDir = normalize( -mvPosition.xyz );
                    GeometricContext backGeometry;
                    backGeometry.position = geometry.position;
                    backGeometry.normal = -geometry.normal;
                    backGeometry.viewDir = geometry.viewDir;
                    vLightFront = vec3( 0.0 );
                    vIndirectFront = vec3( 0.0 );
                    #ifdef DOUBLE_SIDED
                        vLightBack = vec3( 0.0 );
                        vIndirectBack = vec3( 0.0 );
                    #endif
                    IncidentLight directLight;
                    float dotNL;
                    vec3 directLightColor_Diffuse;
                    // #if NUM_POINT_LIGHTS > 0
                    // 	#pragma unroll_loop
                    // 	for ( int i = 0; i < NUM_POINT_LIGHTS; i ++ ) {
                    // 		getPointDirectLightIrradiance( pointLights[ i ], geometry, directLight );
                    // 		dotNL = dot( geometry.normal, directLight.direction );
                    // 		directLightColor_Diffuse = 0.5 * PI * directLight.color;
                    // 		vLightFront += saturate( dotNL ) * directLightColor_Diffuse;
                    // 		#ifdef DOUBLE_SIDED
                    // 			vLightBack += saturate( -dotNL ) * directLightColor_Diffuse;
                    // 		#endif
                    // 	}
                    // #endif
                    // #if NUM_SPOT_LIGHTS > 0
                    // 	#pragma unroll_loop
                    // 	for ( int i = 0; i < NUM_SPOT_LIGHTS; i ++ ) {
                    // 		getSpotDirectLightIrradiance( spotLights[ i ], geometry, directLight );
                    // 		dotNL = dot( geometry.normal, directLight.direction );
                    // 		directLightColor_Diffuse = PI * directLight.color;
                    // 		vLightFront += saturate( dotNL ) * directLightColor_Diffuse;
                    // 		#ifdef DOUBLE_SIDED
                    // 			vLightBack += saturate( -dotNL ) * directLightColor_Diffuse;
                    // 		#endif
                    // 	}
                    // #endif
                    // #if NUM_DIR_LIGHTS > 0
                    // 	#pragma unroll_loop
                    // 	for ( int i = 0; i < NUM_DIR_LIGHTS; i ++ ) {
                    // 		getDirectionalDirectLightIrradiance( directionalLights[ i ], geometry, directLight );
                    // 		dotNL = dot( geometry.normal, directLight.direction );
                    // 		directLightColor_Diffuse = PI * directLight.color;
                    // 		vLightFront += saturate( dotNL ) * directLightColor_Diffuse;
                    // 		#ifdef DOUBLE_SIDED
                    // 			vLightBack += saturate( -dotNL ) * directLightColor_Diffuse;
                    // 		#endif
                    // 	}
                    // #endif
                    #if NUM_HEMI_LIGHTS > 0
                        #pragma unroll_loop
                        for ( int i = 0; i < NUM_HEMI_LIGHTS; i ++ ) {
                            vIndirectFront += getHemisphereLightIrradiance( hemisphereLights[ i ], geometry );
                            #ifdef DOUBLE_SIDED
                                vIndirectBack += getHemisphereLightIrradiance( hemisphereLights[ i ], backGeometry );
                            #endif
                        }
                    #endif
    
    
                    vLightFront += vec3( 1.0 );
                    #ifdef DOUBLE_SIDED
                        vLightBack += vec3( 1.0 );
                    #endif
    
                    #include <shadowmap_vertex>
                    #include <fog_vertex>
    
                }
                `;

            var materialShader =
                `
                    varying float visible;
                    uniform vec3 diffuse;
                    uniform vec3 emissive;
                    uniform float opacity;
                    varying vec3 vLightFront;
                    varying vec3 vIndirectFront;
                    #ifdef DOUBLE_SIDED
                        varying vec3 vLightBack;
                        varying vec3 vIndirectBack;
                    #endif
                    #include <common>
                    #include <packing>
                    #include <dithering_pars_fragment>
                    #include <color_pars_fragment>
                    #include <uv_pars_fragment>
                    #include <uv2_pars_fragment>
                    #include <map_pars_fragment>
                    #include <alphamap_pars_fragment>
                    #include <aomap_pars_fragment>
                    #include <lightmap_pars_fragment>
                    #include <emissivemap_pars_fragment>
                    #include <envmap_common_pars_fragment>
                    #include <envmap_pars_fragment>
                    #include <bsdfs>
                    #include <lights_pars_begin>
                    #include <fog_pars_fragment>
                    #include <shadowmap_pars_fragment>
                    #include <shadowmask_pars_fragment>
                    #include <specularmap_pars_fragment>
                    #include <logdepthbuf_pars_fragment>
                    #include <clipping_planes_pars_fragment>
                    void main() {
                        if (visible == 0.0) discard;
                        #include <clipping_planes_fragment>
                        vec4 diffuseColor = vec4( diffuse, opacity );
                        ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
                        vec3 totalEmissiveRadiance = emissive;
                        #include <logdepthbuf_fragment>
                        #include <map_fragment>
                        #include <color_fragment>
                        #include <alphamap_fragment>
                        #include <alphatest_fragment>
                        #include <specularmap_fragment>
                        #include <emissivemap_fragment>
                        reflectedLight.indirectDiffuse = getAmbientLightIrradiance( ambientLightColor );
                        #ifdef DOUBLE_SIDED
                            reflectedLight.indirectDiffuse += ( gl_FrontFacing ) ? vIndirectFront : vIndirectBack;
                        #else
                            reflectedLight.indirectDiffuse += vIndirectFront;
                        #endif
                        #include <lightmap_fragment>
                        reflectedLight.indirectDiffuse *= BRDF_Diffuse_Lambert( diffuseColor.rgb );
                        #ifdef DOUBLE_SIDED
                            reflectedLight.directDiffuse = ( gl_FrontFacing ) ? vLightFront : vLightBack;
                        #else
                            reflectedLight.directDiffuse = vLightFront;
                        #endif
                        reflectedLight.directDiffuse *= BRDF_Diffuse_Lambert( diffuseColor.rgb ) * getShadowMask();
                        #include <aomap_fragment>
                        vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + totalEmissiveRadiance;
                        #include <envmap_fragment>
                        gl_FragColor = vec4( outgoingLight, diffuseColor.a );
                        #include <tonemapping_fragment>
                        #include <encodings_fragment>
                        #include <fog_fragment>
                        #include <premultiplied_alpha_fragment>
                        #include <dithering_fragment>
                    }
                    `



        };

        fibDataCount = data.easting.length;

        var offsets = [],
            iColors = [],
            iScale = [];

        ///////////////////// TRYING ROTATION ////////////////////

        var fibDataMatArraySize = fibDataCount * 4
        var fibDataMatrixArray = [
            new Float32Array(fibDataMatArraySize),
            new Float32Array(fibDataMatArraySize),
            new Float32Array(fibDataMatArraySize),
            new Float32Array(fibDataMatArraySize),
        ];

        /////////////////////////////////////////////////////////

        FIBER_LUT = new Lut();
        FIBER_LUT.addColorMap("Jet", STRAIN_JET_COLORMAP);
        FIBER_LUT.addColorMap("Seismic", STRAIN_SEISMIC_COLORMAP);

        if (data['colormap'] == "Jet") {
            FIBER_LUT.setColorMap("Jet", STRAIN_JET_COLORMAP.length);
        } else {
            FIBER_LUT.setColorMap("Seismic", STRAIN_SEISMIC_COLORMAP.length);
        }

        FIBER_LUT.setMin(FIBER_MIN);
        FIBER_LUT.setMax(FIBER_MAX);

        for (var i = 0; i < fibDataCount; i++) {

            offsets.push(
                (DOWNSAMPLE * Number(data.northing[i]) - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                (DOWNSAMPLE * Number(data.tvdss[i]) - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                (DOWNSAMPLE * Number(data.easting[i]) - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
            );

            iScale.push(1, 1, 1);

            var fibDataMatrix = new THREE.Matrix4();

            var fibDataPosition = new THREE.Vector3(
                (DOWNSAMPLE * Number(data.northing[i]) - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                (DOWNSAMPLE * Number(data.tvdss[i]) - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                (DOWNSAMPLE * Number(data.easting[i]) - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
            );

            if (i < fibDataCount - 1) {

                var fibDataQuaternion = new THREE.Quaternion();

                var fibDataPositionNew = new THREE.Vector3(
                    (DOWNSAMPLE * Number(data.northing[i + 1]) - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    (DOWNSAMPLE * Number(data.tvdss[i + 1]) - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    (DOWNSAMPLE * Number(data.easting[i + 1]) - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                );

                var dir1 = new THREE.Vector3();
                dir1.subVectors(fibDataPositionNew, fibDataPosition);

                var dir2 = new THREE.Vector3();
                dir2.subVectors(fibDataPosition, fibDataPositionNew);

                dir2.cross(dir1);
                // dir2.setZ(dir2.z * Math.PI / 2);

                var fibRotation = new THREE.Euler();
                fibRotation.x = Math.PI / 2;
                // fibRotation.z = Math.PI / 2;
                // fibRotation.y = Math.PI / 2;

                var mx = new THREE.Matrix4().lookAt(dir2, new THREE.Vector3(0, 1, 0).applyEuler(fibRotation), new THREE.Vector3(0, 1, 0).applyEuler(fibRotation));
                var fibDataQuaternion = new THREE.Quaternion().setFromRotationMatrix(mx);

                // var fibDataQuaternion = new THREE.Quaternion().setFromRotationMatrix(new THREE.Matrix4().lookAt(fibDataPositionNew, new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0)));

                // fibDataQuaternion = 
                // fibDataQuaternion.dot(new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 0, 1), Math.PI / 2));

            }

            // var fibDataQuaternion = new THREE.Quaternion();
            // // fibDataQuaternion.copy(sceneView[0].oCamera.quaternion);
            // fibDataQuaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI / 2);

            // var fibRotation = new THREE.Euler();
            // fibRotation.z = Math.PI / 2;
            // var fibDataQuaternion = new THREE.Quaternion();
            // fibDataQuaternion.setFromEuler(fibRotation, false);

            var eventTempColor = FIBER_LUT.getColor(data.data[i]); //new THREE.Color('rgba(255, 255, 255, 1.0)');
            iColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);

            var maxCoef = Math.max(Math.abs(FIBER_MAX), Math.abs(FIBER_MIN));

            var coef = 1;
            // if (Math.abs(data.data[i]) < maxCoef) {
            // coef = 1;
            coef = (data.data[i]) / maxCoef;

            if (coef < -1) {
                coef = -1;
            }

            if (coef > 1) {
                coef = 1;
            }


            // }
            // else {
            //     coef = Math.abs(fiberData[j].data[0][i]) / maxCoef;
            // }

            // fibDataScale = new THREE.Vector3(0.3 + 0.7*coef, 1, 1);
            var fibDataScale = new THREE.Vector3((0.75 + 0.5 * coef), 1, (0.75 + 0.5 * coef));

            // fibDataMatrix.compose(fibDataPosition, fibDataQuaternion, fibDataScale);
            // fibDataMatrix.compose(fibDataPosition, sceneView[0].oCamera.quaternion, fibDataScale);
            fibDataMatrix.compose(fibDataPosition,
                fibDataQuaternion,
                fibDataScale);

            for (var r = 0; r < 4; r++) {
                for (var c = 0; c < 4; c++) {
                    fibDataMatrixArray[r][i * 4 + c] = fibDataMatrix.elements[r * 4 + c];
                }
            }
        }


        var fdMesh = scene.getObjectByName("Fiber Data Mesh");
        // scene.remove(fdMesh);

        if (fdMesh == null) {

            var boxGeometry = new THREE.CylinderBufferGeometry(2, 2, 1);
            boxGeometry.frustumCulled = false;
            fibDataGeomDrawn = new THREE.InstancedBufferGeometry();
            fibDataGeomDrawn.dynamic = true;
            fibDataGeomDrawn.instanceCount = fibDataCount;
            fibDataGeomDrawn.index = boxGeometry.index;
            fibDataGeomDrawn.attributes = boxGeometry.attributes;
            fibDataGeomDrawn.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
            fibDataGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
            fibDataGeomDrawn.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
            for (let i = 0; i < fibDataMatrixArray.length; i++) {
                fibDataGeomDrawn.setAttribute(
                    `aInstanceMatrix${i}`,
                    new THREE.InstancedBufferAttribute(fibDataMatrixArray[i], 4)
                );
            }

            fibDataMesh = new THREE.Mesh(fibDataGeomDrawn, fibDataMaterial);
            fibDataMesh.frustumCulled = false;
            fibDataMesh.name = "Fiber Data Mesh";
            scene.add(fibDataMesh);

        } else {

            fibDataGeomDrawn.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
            fibDataGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
            fibDataGeomDrawn.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
            for (let i = 0; i < fibDataMatrixArray.length; i++) {
                fibDataGeomDrawn.setAttribute(
                    `aInstanceMatrix${i}`,
                    new THREE.InstancedBufferAttribute(fibDataMatrixArray[i], 4)
                );
            }

        }




    }

    if (type == "Text") {

        //console.log("Text data", data);

        var fdMesh = scene.getObjectByName("annot-label-" + data.id);
        if (fdMesh != null) {
            scene.remove(fdMesh);
        }

        var annotLabelMaterial = [
            new THREE.MeshLambertMaterial({ color: 0xffffff, flatShading: true }), // front
            new THREE.MeshPhongMaterial({ color: 0xffffff }) // side
        ];

        new THREE.FontLoader().load('https://borehole-seismic-portal.s3.amazonaws.com/helvetiker_bold.typeface.json', function(font) {

            var annotTextGeo = new THREE.TextGeometry(data.data, { font: font, size: 4, height: 2, });
            annotTextGeo.computeBoundingBox();
            annotTextGeo.computeVertexNormals();

            var centerOffsetX = -0.5 * (annotTextGeo.boundingBox.max.x - annotTextGeo.boundingBox.min.x);
            var centerOffsetY = -0.5 * (annotTextGeo.boundingBox.max.y - annotTextGeo.boundingBox.min.y);
            var centerOffsetZ = -0.5 * (annotTextGeo.boundingBox.max.z - annotTextGeo.boundingBox.min.z);

            var annotLabelGeom = new THREE.BufferGeometry().fromGeometry(annotTextGeo);
            annotLabelGeom.center();
            var annotLabelMesh = new THREE.Mesh(annotLabelGeom, annotLabelMaterial);


            annotLabelMesh.position.x = DOWNSAMPLE * centerOffsetX + (DOWNSAMPLE * Number(data.northing) - bk_x_min - 0.5 * (bk_x_max - bk_x_min)); // + wellOffsetX;
            annotLabelMesh.position.y = DOWNSAMPLE * centerOffsetY + (DOWNSAMPLE * Number(data.tvdss) - bk_y_min - 0.5 * (bk_y_max - bk_y_min)) // + wellOffsetY; 
            annotLabelMesh.position.z = DOWNSAMPLE * centerOffsetZ + (DOWNSAMPLE * Number(data.easting) - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
            annotLabelMesh.name = "annot-label-" + data.id;

            annotLabelMesh.renderOrder = 999;
            annotLabelMesh.onBeforeRender = function(renderer) { renderer.clearDepth(); };

            scene.add(annotLabelMesh);

            dasEvents.push(annotLabelMesh.name);

        });

    }

    if (type == "Text Shape") {

        //console.log("Text data", data);

        var fdMesh = scene.getObjectByName("annot-label-shape-" + data.id);
        if (fdMesh != null) {
            scene.remove(fdMesh);
        }

        var annotLabelShapeMaterial = new THREE.MeshLambertMaterial({
            map: new THREE.TextureLoader().load('https://borehole-seismic-biz.s3.amazonaws.com/receiver.jpg'),
            combine: THREE.MultiplyOperation,
            reflectivity: 0.8,
            color: 0x00ff00,
            vertexColors: THREE.VertexColors,
            fog: true,
            transparent: false,
        });

        var annotLabelShapeGeometry = new THREE.CylinderGeometry(1, 1, 8);

        var annotLabelShapeMesh = new THREE.Mesh(annotLabelShapeGeometry, annotLabelShapeMaterial);
        annotLabelShapeMesh.frustumCulled = false;

        annotLabelShapeMesh.position.x = (DOWNSAMPLE * Number(data.northing) - bk_x_min - 0.5 * (bk_x_max - bk_x_min)); // + wellOffsetX;
        annotLabelShapeMesh.position.y = (DOWNSAMPLE * Number(data.tvdss) - bk_y_min - 0.5 * (bk_y_max - bk_y_min)); // + wellOffsetY; 
        annotLabelShapeMesh.position.z = (DOWNSAMPLE * Number(data.easting) - bk_z_min - 0.5 * (bk_z_max - bk_z_min));
        annotLabelShapeMesh.name = "annot-label-shape-" + data.id;

        scene.add(annotLabelShapeMesh);

    }

    if (type == "Signal Line Static") {

        var fdMesh = scene.getObjectByName(type + data.id);
        if (fdMesh != null) {
            scene.remove(fdMesh);
        }
        let stagePerfs = data["stage_name"].map(stage_name => perf.filter((perforation) => perforation['name'] == stage_name))
        var distanceHtml = "";
        if (stagePerfs.length > 0) {

            stagePerfs.map(e => e[''])
            var dasLinePoints = [];
            stagePerfs.map(eee => {
                let x_mean = eee.map(e => e['x']).mean()
                let y_mean = eee.map(e => e['y']).mean()
                let z_mean = eee.map(e => e['z']).mean()

                dasLinePoints.push(
                    (DOWNSAMPLE * x_mean - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    (DOWNSAMPLE * y_mean - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    (DOWNSAMPLE * z_mean - bk_z_min - 0.5 * (bk_z_max - bk_z_min)),
                );
                dasLinePoints.push(
                    (DOWNSAMPLE * data['northing'] - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    (DOWNSAMPLE * data['tvdss'] - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    (DOWNSAMPLE * data['easting'] - bk_z_min - 0.5 * (bk_z_max - bk_z_min)),
                );
                let stagDiffX = (data['easting'] - z_mean);
                let stagDiffY = (data['northing'] - x_mean);
                let stagDiffZ = (data['tvdss'] - y_mean);
                let stageName
                if (eee.length > 0) {
                    stageName = eee[0].name
                }
                let hoverAzimuth = Math.atan2(stagDiffX, stagDiffY) * 180 / Math.PI;
                hoverAzimuth = 180 + hoverAzimuth;
                if (hoverAzimuth > 180) {
                    hoverAzimuth = hoverAzimuth - 180;
                }
            })


            var dasLineGeom = new LineSegmentsGeometry().setPositions(dasLinePoints);
            var dasLineMat = new LineMaterial({ color: 0xffffff, linewidth: 2 });
            dasLineMat.resolution.set(parentContainer.width(), parentContainer.height()); // important, for now
            var dasLineMesh = new LineSegments2(dasLineGeom, dasLineMat);
            dasLineMesh.name = "Signal Line Static" + data.id;
            dasLineMesh.frustumCulled = false;
            scene.add(dasLineMesh);

            document.getElementById("azimuthValue").innerHTML = distanceHtml
            document.getElementById("azimuthData").style.display = "block";

        }

    }



    if (type == "Signal Line") {

        var fdMesh = scene.getObjectByName(type);
        if (fdMesh != null) {
            scene.remove(fdMesh);
        }
        let stagePerfs = data["stage_name"].map(stage_name => perf.filter((perforation) => perforation['name'] == stage_name))

        if (stagePerfs.length > 0) {

            stagePerfs.map(e => e[''])
            var dasLinePoints = [];
            var distanceHtml = ""
            var lineDistancesMeasures = []
            stagePerfs.map(eee => {
                let x_mean = eee.map(e => e['x']).mean()
                let y_mean = eee.map(e => e['y']).mean()
                let z_mean = eee.map(e => e['z']).mean()

                dasLinePoints.push(
                    (DOWNSAMPLE * x_mean - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    (DOWNSAMPLE * y_mean - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    (DOWNSAMPLE * z_mean - bk_z_min - 0.5 * (bk_z_max - bk_z_min)),
                );
                dasLinePoints.push(
                    (DOWNSAMPLE * data['northing'] - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    (DOWNSAMPLE * data['tvdss'] - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    (DOWNSAMPLE * data['easting'] - bk_z_min - 0.5 * (bk_z_max - bk_z_min)),
                );
                let stagDiffX = (data['easting'] - z_mean);
                let stagDiffY = (data['northing'] - x_mean);
                let stagDiffZ = (data['tvdss'] - y_mean);
                let stageName
                if (eee.length > 0) {
                    stageName = eee[0].name
                }
                let lineDistance = Math.pow(Math.pow(stagDiffX, 2) + Math.pow(stagDiffY, 2) + Math.pow(stagDiffZ, 2), 0.5);
                let hoverAzimuth = Math.atan2(stagDiffX, stagDiffY) * 180 / Math.PI;
                hoverAzimuth = 180 + hoverAzimuth;
                if (hoverAzimuth > 180) {
                    hoverAzimuth = hoverAzimuth - 180;
                }
                distanceHtml += stageName + " - Dist:" + lineDistance.toFixed() + " .ft" + " Azm:" + hoverAzimuth.toFixed() + "<br />"
            })


            var dasLineGeom = new LineSegmentsGeometry().setPositions(dasLinePoints);
            var dasLineMat = new LineMaterial({ color: 0xffffff, linewidth: 2 });
            dasLineMat.resolution.set(parentContainer.width(), parentContainer.height()); // important, for now
            var dasLineMesh = new LineSegments2(dasLineGeom, dasLineMat);
            dasLineMesh.name = "Signal Line";
            dasLineMesh.frustumCulled = false;
            scene.add(dasLineMesh);

            // let diffX = (data['easting'] - stagePerfs.map(e => e['z']).mean());
            // let diffY = (data['northing'] - stagePerfs.map(e => e['x']).mean());
            // let diffZ = (data['tvdss'] - stagePerfs.map(e => e['y']).mean());

            // let distance = Math.pow(Math.pow(diffX, 2) + Math.pow(diffY, 2) + Math.pow(diffZ, 2), 0.5);

            // let hoverAzimuth = Math.atan2(diffX, diffY) * 180 / Math.PI;

            // if (diffX > 0 && diffY < 0) {
            //     hoverAzimuth = 90 + Math.abs(hoverAzimuth);
            // }

            // if (diffX < 0 && diffY < 0) {
            //     hoverAzimuth = 180 + Math.abs(hoverAzimuth);
            // }

            // if (diffX < 0 && diffY > 0) {
            //     hoverAzimuth = 270 + Math.abs(hoverAzimuth);
            // }
            // let azimuthValue = "Azimuth: " + Math.round(hoverAzimuth * 10) / 10 + "<br>Distance: " + Math.round(distance * 10) / 10 + " ft.";
            document.getElementById("azimuthValue").innerHTML = distanceHtml
                // document.getElementById("azimuthValue").innerHTML = "Azimuth: " + Math.round(hoverAzimuth * 10) / 10 + "<br>Distance: " + Math.round(distance * 10) / 10 + " ft.";
            document.getElementById("azimuthData").style.display = "block";

        }

    }
    if (false && scene != null) {
        var selectedObject = scene.getObjectByName("Annotation");
        scene.remove(selectedObject);
        var geometry = new THREE.CubeGeometry(10, 10, 10);
        var material = new THREE.MeshBasicMaterial({ color: COLORMAP[Math.floor(100 * Math.random())][1] });
        var mesh = new THREE.Mesh(geometry, material);
        mesh.position.set(
            (DOWNSAMPLE * x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
            (DOWNSAMPLE * y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
            (DOWNSAMPLE * z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
        );
        mesh.name = "Annotation";
        //scene is global
        scene.add(mesh);
    }
}

function removeAnnotation(objectName = "") {

    var fdMesh = scene.getObjectByName(objectName);
    scene.remove(fdMesh);

    if (dasEvents.indexOf(objectName) > -1) {
        dasEvents.splice(dasEvents.indexOf(objectName), 1);
    }

    if (objectName == "Signal Line") {
        try {
            document.getElementById("azimuthData").style.display = "none";
            document.getElementById("azimuthValue").innerHTML = "";
        } catch (e) {

        }
    }


    // if (type == "Fiber Data") {
    // var fdMesh = scene.getObjectByName("Fiber Data Mesh");
    // scene.remove(fdMesh);
    // }

    // if (scene != null) {
    //     var selectedObject = scene.getObjectByName("Annotation");
    //     scene.remove(selectedObject);
    // }
}

function seismicViewer(data, divId, reload = false) {
    console.log("Seismic viewer divID -", divId);
    console.log("Reloading -", reload);
    initialize();
    if ($('#' + divId).length == 0) {
        alert("Container div " + divId + " doesn't exist on page;");
        return;
    }
    setData(data);
    parentContainer = $('#' + divId);
    if (!reload) {
        setUI();
    }
    updateStatus("Reading Data");
    readData(data, divId);
}

var i, j, n, k;

function setUI() {
    parentContainer.html(`
            <canvas id="canvas"></canvas>
            <div id="container1"><div id="subcontainer1"><div id="eventSelector"></div></div></div>
            <div id="container2"><div id="subcontainer2"></div></div>
            <div id="container3"><div id="subcontainer3"></div></div>
            <div id="container4"><div id="subcontainer4"></div></div>
            <div id="uiContainer">
                <div id="legendTitle">
                    <p id="legendTitleText"></p>
                </div>
                <div id="legendData">
                    <div id="legendLabel">
                        <p id="legendLabelText"></p>
                    </div>
                    <div id="legendLegend"></div>
                </div>
            </div>
            <div class="moveGUI"></div>
                <div id="hoverData">
                <span id="hoverText"></span>
                <div id="measurementData2"><br>
                    <div id="measurementText">
                    </div>
                </div>
                <div id="histogramAnalysis">
                </div>
                <div id="srv">
                    <div id="srvCalculation"></div>
                    <div id="srvAzimuth"></div>
                </div>
            </div>
            <div id="bottom-container">
               
                
                <div id="compass">
                </div>

                <div id="strainPlots" >
                </div>

                <div id="fiberDataPlots" >
                </div>

                <div id="pumplogs">
                </div>

                

                <div id="azimuthData">
                    <span id="azimuthValue"></span>
                </div>

            </div>

            <div class="modal fade" id="fmTopHistModal" tabindex="-1" role="dialog" aria-labelledby="fmTopLabel">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 class="modal-title" id="fmTopLabel"></h4>
                    </div>
                    <div class="modal-body" id="fmTopHist">
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-dark" data-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>

            <div class="lds-ellipsis" id="hourGlass">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            </div>

            <div class="statusText">
            <p id="loadingStatus" style="color: white;"></p>
            </div>

            <div id="tooltip"></div>

            <script type="x-shader/x-vertex" id="grid-vertexshader">
            varying vec3 vNormal;
            void main() {
                vNormal = normal;
                vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
                gl_Position = projectionMatrix * mvPosition;
            }
            </script>
            
            <script type="x-shader/x-fragment" id="grid-fragmentshader">
                uniform vec3 color;
                varying vec3 vNormal;
                void main() {
                    gl_FragColor = vec4( color, 1.0);
                }
            </script>
            
            <script type="x-shader/x-vertex" id="well-vertexshader">
                varying vec3 vNormal;
                void main() {
                    vNormal = normal;
                    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
                    gl_Position = projectionMatrix * mvPosition;
                }
            </script>
            
            <script type="x-shader/x-fragment" id="well-fragmentshader">
                uniform vec3 color;
                varying vec3 vNormal;
                void main() {
                    gl_FragColor = vec4( color, 1.0);
                }
            </script>
            
            <script type="x-shader/x-vertex" id="vertexshaderLine">
                attribute vec3 customColor;
                attribute float inputAlpha;
                varying vec3 vColor;
                varying float vAlpha;
                void main() {
                    vColor = customColor;
                    vAlpha = inputAlpha;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                }
            </script>
            
            <script type="x-shader/x-fragment" id="fragmentshaderLine">
                varying vec3 vColor;
                varying float vAlpha;
                void main() {
                    if ( vAlpha < 0.1 ){
                        discard;
                    }
                    gl_FragColor = vec4(vColor, 1.0);
                }
            </script>
            
            
            <script id="vertInstanced" type="x-shader/x-vertex">
                precision highp float;
                uniform mat4 modelViewMatrix;
                uniform mat4 projectionMatrix;
                attribute vec3 iOffset;
                attribute vec3 iColor;
                varying vec3 vColor;
            
                void main()	{
                    vColor = iColor;
                    vec3 positionEye = ( modelViewMatrix * vec4( iOffset, 1.0 ) ).xyz;
                    gl_Position = projectionMatrix * vec4( positionEye, 1.0 );
                    gl_Size = 10.0;
                }
            </script>
            
            <script id="fragInstanced" type="x-shader/x-fragment">
                #extension GL_OES_standard_derivatives : enable
                precision highp float;
                varying vec3 vColor;
                void main()	{
                    gl_FragColor = vec4( vColor, 1.0 );
                }
            </script>
            
            <script type='x-shader/x-vertex' id='fm-vertexshader'>
                attribute vec3 colorAttr;
                varying vec3 vColorVelocity;
                attribute float vSize;
                attribute float opacity;
                varying float self_opacity;
                void main() {
                    self_opacity = opacity;
                    vColorVelocity = colorAttr;
                    vec4 mvPosition1 = modelViewMatrix * vec4( position, 1.0 );
                    gl_PointSize = vSize; //2.0;
                    gl_Position = projectionMatrix * mvPosition1;
                }
            </script>
            
            <script type='x-shader/x-fragment' id='fm-fragmentshader'>
                varying vec3 vColorVelocity;
                varying float self_opacity;
                void main() {
                    gl_FragColor = vec4(vColorVelocity, self_opacity);
                }
            </script>

            <script type='x-shader/x-vertex' id='fault-vertexshader'>
                attribute vec3 colorAttr;
                varying vec3 vColorVelocity;
                attribute float opacity;
                varying float self_opacity;
                attribute float vSize;
                void main() {
                    vColorVelocity = colorAttr;
                    self_opacity = opacity;
                    vec4 mvPosition1 = modelViewMatrix * vec4( position, 1.0 );
                    gl_PointSize = vSize; //2.0;
                    gl_Position = projectionMatrix * mvPosition1;
                }
            </script>
            
            <script type='x-shader/x-fragment' id='fault-fragmentshader'>
                varying vec3 vColorVelocity;
                varying float self_opacity;
                void main() {
                    gl_FragColor = vec4(vColorVelocity, self_opacity);
                }
            </script>

            <script type='x-shader/x-fragment' id='viewingPlane-fragmentshader'>
                varying vec3 vColorVelocity;
                vec3 colorA = vec3(1.0, 0.0, 0.0);
                vec3 colorB = vec3(0.0, 0.0, 1.0);
                void main() {
                    // vec3 color = vec3(0.0);
                    // color = mix(colorA, colorB, 1.0 - vColorVelocity);
                    gl_FragColor = vec4(vColorVelocity, 0.7);
                }
            </script>
                        

            
            <script type='x-shader/x-vertex' id='formation-plane-vertexshader'>
                attribute vec3 colorAttr;
                varying vec3 vColorVelocity;
                attribute float opacity;
                varying float self_opacity;
                attribute float vSize;
                void main() {
                    vColorVelocity = colorAttr;
                    self_opacity = opacity;
                    vec4 mvPosition1 = modelViewMatrix * vec4( position, 1.0 );
                    gl_PointSize = vSize; //2.0;
                    gl_Position = projectionMatrix * mvPosition1;
                }
            </script>
            
            <script type='x-shader/x-fragment' id='formation-plane-fragmentshader'>
                varying vec3 vColorVelocity;
                varying float self_opacity;
                void main() {
                    gl_FragColor = vec4(vColorVelocity, self_opacity);
                }
            </script>

        `);

};


function updateStatus(info) {
    setTimeout(function() {
        document.getElementById('loadingStatus').innerHTML += "<br>" + info;
    }, 0);
};


function readData(data, divId) {
    console.log(data);

    // Assigning data from DB;
    // Wells
    for (let n = 0; n < data.wells.length; n++) {
        if (data.wells[n].well_type == 'Treatment') {
            trtWell.push(data.wells[n]);
            var data1 = JSON.parse(JSON.stringify(data.wells[n])); // data.slice();
            if (data.wells[n].alias && data.wells[n].alias.length > 0) {
                data1.name = data.wells[n].alias;
            }
            wellLabel.push(data1);
        }

        if (data.wells[n].well_type == 'Monitor') {
            mtrWell.push(data.wells[n]);
            var data1 = JSON.parse(JSON.stringify(data.wells[n])); // data.slice();
            if (data.wells[n].alias && data.wells[n].alias.length > 0) {
                data1.name = data.wells[n].alias;
            }
            wellLabel.push(data1);
        }

        if (data.wells[n].well_type == 'Additional') {
            addWell.push(data.wells[n]);
            var data1 = JSON.parse(JSON.stringify(data.wells[n])); // data.slice();
            if (data.wells[n].alias && data.wells[n].alias.length > 0) {
                data1.name = data.wells[n].alias;
            }
            wellLabel.push(data1);
        }

    }

    // Receivers
    rec = JSON.parse(JSON.stringify(data.receivers)); //rec.concat(data.rec);

    // Perf
    perf = JSON.parse(JSON.stringify(data.perforations)); //rec.concat(data.rec);
    for (let p = 0; p < perf.length; p++) {
        perf[p].color = getColor(perf[p].color);
    }

    // Formation Tops
    formationTops = formationTops.concat(data.formations);
    formLabel = formLabel.concat(data.formations);

    formationTopsPoints = formationTopsPoints.concat(data.horizons);

    // Events
    events = JSON.parse(JSON.stringify(data.events)); //rec.concat(data.rec);
    for (let p = 0; p < events.length; p++) {
        events[p].color = getColor(events[p].color);
    }

    let catalogues = data.catalogues;
    let counter = 0;

    faults = JSON.parse(JSON.stringify(data.faults));

    for (let n = 0; n < catalogues.length; n++) {

        if (catalogues[n].ctg_type == 'Strain') {
            STRAIN_PRESENT = true;
            strainDataUrl = catalogues[n].file_url;
            counter = counter + 1;
        } else if (catalogues[n].ctg_type == 'Volume') {
            volumeDataUrl.push(catalogues[n].file_url);
            volumeDataName.push(catalogues[n].ctg_name);
            counter = counter + 1;
        } else if (catalogues[n].ctg_type == 'Fiber Data') {

            FIBER_DATA_PRESENT = true;
            let m = FIBER_DATA.map(e => e.name).indexOf(catalogues[n].ctg_name);
            if (m == -1) {
                FIBER_DATA.push({
                    name: catalogues[n].ctg_name,
                    url: catalogues[n].file_url,
                })
            } else {
                FIBER_DATA[m].url = catalogues[n].file_url;
            }
            counter = counter + 1;
        } else {

            Papa.parse(catalogues[n].file_url, {

                header: true,
                download: true,
                skipEmptyLines: true,
                complete: function(results) {
                    let csvData = results.data;
                    if (catalogues[n].ctg_type == 'Pumplogs') {

                        if (csvData.length > 0) {
                            console.log('CSVDATA@@@@', csvData);
                            var data = {};
                            PUMPLOG_SUBPLOTS = ~~(Object.keys(csvData[0]).length / 5)
                            data['time'] = convertToArray(csvData, 'Time');


                            for (var i = 1; i < PUMPLOG_SUBPLOTS + 1; i++) {
                                var suffix = (i > 1) ? i : '';
                                console.log(suffix)
                                console.log('Pressure@@@@', convertToArray(csvData, 'Pressure' + suffix));
                                data['pressure' + suffix] = convertToArray(csvData, 'Pressure' + suffix);
                                data['rate' + suffix] = convertToArray(csvData, 'Rate' + suffix);
                                data['density' + suffix] = convertToArray(csvData, 'Density' + suffix);
                                data['stage' + suffix] = convertToArray(csvData, 'Stage' + suffix);
                                data['well' + suffix] = convertToArray(csvData, 'Well' + suffix);

                            }
                            console.log('Data@@', data)

                            // To add data for suffix 1:
                            // data.pressure1.push(10);
                            // data.rate1.push(5);
                            // data.density1.push(2);
                            // data.time1.push('2023-03-07');

                        }
                        for (var i = 0; i < data.time.length; i += 1) {
                            var logData = { time: new Date(data.time[i]) };
                            for (var j = 1; j < PUMPLOG_SUBPLOTS + 1; j += 1) {
                                var suffix = (j > 1) ? j : '';

                                logData['pressure' + suffix] = data['pressure' + suffix][i];
                                logData['rate' + suffix] = data['rate' + suffix][i];
                                logData['density' + suffix] = data['density' + suffix][i];
                                logData['stage' + suffix] = data['stage' + suffix][i];
                                logData['well' + suffix] = data['well' + suffix][i];
                            }
                            pumpLogs.push(logData);
                        }
                        // console.log('pumplog', pumpLogs)

                        // var time = convertToArray(csvData, 'Time');
                        // var pressure = convertToArray(csvData, 'Pressure');
                        // var rate = convertToArray(csvData, 'Rate');
                        // var density = convertToArray(csvData, 'Density');
                        // var stage = convertToArray(csvData, 'Stage');
                        // var well = convertToArray(csvData, 'Well');
                        // var pressure2 = convertToArray(csvData, 'Pressure2');
                        // var rate2 = convertToArray(csvData, 'Rate2');
                        // var density2 = convertToArray(csvData, 'Density2');
                        // var stage2 = convertToArray(csvData, 'Stage2');
                        // var well2 = convertToArray(csvData, 'Well2');

                        // for (var i = 0; i < time.length; i += 1) { // 0; i ++ ){ //
                        //     pumpLogs.push({
                        //         time: new Date(time[i]),
                        //         pressure: pressure[i],
                        //         rate: rate[i],
                        //         density: density[i],
                        //         stage: stage[i],
                        //         well: well[i],
                        //         pressure2: pressure2[i],
                        //         rate2: rate2[i],
                        //         density2: density2[i],
                        //         stage2: stage2[i],
                        //         well2: well2[i],


                        //     });
                        // }

                        try {
                            if (pumpLogs.length > 0) {
                                pumpLogs.sort(function(a, b) {
                                    return new Date(a.time) - new Date(b.time);
                                });
                                PUMPLOGS_PRESENT = true;
                            }
                        } catch (e) {
                            console.log('Error in sorting pumplogs by time!');
                            console.log('Error Trace - ', e);
                            updateStatus("Error. Check console for logs.");
                        }


                    }

                    if (catalogues[n].ctg_type == 'Gyration') {

                        var time = convertToArray(csvData, 'Time');
                        var stage = convertToArray(csvData, 'Stage');
                        var well = convertToArray(csvData, 'Well');

                        var easting = convertToArray(csvData, 'Easting');
                        var northing = convertToArray(csvData, 'Northing');
                        var tvdss = convertToArray(csvData, 'TVDSS');

                        var xl = convertToArray(csvData, 'Major Length');
                        var x1 = convertToArray(csvData, 'Major North');
                        var x2 = convertToArray(csvData, 'Major Down');
                        var x3 = convertToArray(csvData, 'Major East');

                        var yl = convertToArray(csvData, 'Middle Length');
                        var y1 = convertToArray(csvData, 'Middle North');
                        var y2 = convertToArray(csvData, 'Middle Down');
                        var y3 = convertToArray(csvData, 'Middle East');

                        var zl = convertToArray(csvData, 'Minor Length');
                        var z1 = convertToArray(csvData, 'Minor North');
                        var z2 = convertToArray(csvData, 'Minor Down');
                        var z3 = convertToArray(csvData, 'Minor East');

                        var stg_time = convertToArray(csvData, 'Stage Time');
                        var total_stage_time = convertToArray(csvData, 'Total Stage Time');

                        for (var i = 0; i < time.length; i += 1) { // 0; i ++ ){ //

                            if (i != -1) {

                                gyration.push({
                                    time: Date.parse(time[i]),
                                    stage: stage[i],
                                    well: well[i],
                                    x: northing[i],
                                    y: tvdss[i],
                                    z: easting[i],
                                    xl: xl[i],
                                    x1: x1[i],
                                    x2: x2[i],
                                    x3: x3[i],
                                    yl: yl[i],
                                    y1: y1[i],
                                    y2: y2[i],
                                    y3: y3[i],
                                    zl: zl[i],
                                    z1: z1[i],
                                    z2: z2[i],
                                    z3: z3[i],
                                    color: stg_time[i] / total_stage_time[i],
                                });
                            }

                        }

                        try {
                            if (gyration.length > 0) {
                                gyration.sort(function(a, b) {
                                    return (a.time) - (b.time);
                                });
                            }
                        } catch (e) {
                            console.log('Error in sorting gyration by time!');
                            console.log('Error Trace - ', e);
                            updateStatus("Error. Check console for logs.");
                        }


                    }

                    if (catalogues[n].ctg_type == 'Reveal Data') {

                        let group = convertToArray(csvData, 'Group');
                        x = convertToArray(csvData, 'Northing');
                        z = convertToArray(csvData, 'Easting');
                        y = convertToArray(csvData, 'TVDSS');

                        for (var i = 0; i < group.length; i++) {

                            if (x[i] == "" || y[i] == "" || z[i] == "" || group[i] == "")
                                continue;

                            revealData.push({
                                group: group[i],
                                x: x[i],
                                y: y[i],
                                z: z[i],
                            });
                        }

                    }

                    if (catalogues[n].ctg_type == 'Formation Tops') {

                        var names = [];
                        for (var i = 0; i < csvData.length; i++) {
                            var element = (csvData[i]['Name']);
                            if (element != '') {
                                names.push(element);
                            }
                        }
                        var depth = convertToArray(csvData, 'Depth');
                        for (var i = 0; i < depth.length; i++) {
                            formationTops.push({
                                id: -1,
                                name: names[i],
                                depth: depth[i],
                                reference_x: 0,
                                reference_y: 0,
                                azimuth: 0,
                                inclination: 0
                            });
                        }
                    }

                    // X, Y, Z is Easting, Northing, TVDSS here - it should be Easting, TVDSS, Northing;
                    if (catalogues[n].ctg_type == 'Source Tensor') {

                        var x = convertToArray(csvData, 'Northing');
                        var y = convertToArray(csvData, 'Easting');
                        var z = convertToArray(csvData, 'TVDSS');

                        if (csvData.length > 0 && csvData[0].hasOwnProperty("Event Time")) {
                            var sourceEventTime = convertToArray(csvData, 'Event Time');
                        } else {
                            var sourceEventTime = [];
                        }

                        var condition = convertToArray(csvData, 'Condition');
                        var confidence = convertToArray(csvData, 'Confidence');
                        var misfit = convertToArray(csvData, 'Misfit');

                        var strike = convertToArray(csvData, 'Strike');
                        var dip = convertToArray(csvData, 'Dip');
                        var slip = convertToArray(csvData, 'Slip');

                        var iso = convertToArray(csvData, 'ISO');
                        var clvd = convertToArray(csvData, 'CLVD');
                        var dc = convertToArray(csvData, 'DC');

                        var fractureArea = [];

                        if (csvData.length > 1 && csvData[0].hasOwnProperty("Fracture Area")) {
                            fractureArea = convertToArray(csvData, 'Fracture Area');
                        }

                        if (csvData.length > 1 && csvData[0].hasOwnProperty("Brune source area")) {
                            fractureArea = convertToArray(csvData, 'Brune source area');
                        }

                        for (var i = 0; i < fractureArea.length; i++) {
                            fractureArea[i] = 3.28084 * 3.28084 * Number(fractureArea[i]);
                        }

                        var dataset = [];

                        for (var i = 0; i < csvData.length; i++) {

                            var element = (csvData[i]['Dataset']);
                            if (element == null || element.length == 0) {
                                dataset.push("Master");
                            } else {
                                dataset.push(element);
                            }
                        }

                        for (var i = 0; i < slip.length; i++) {

                            if (slip[i] > 180 || slip[i] < -180) {

                                var tempSlip = slip[i] % 360;

                                if (tempSlip > 180) {
                                    slip[i] = tempSlip - 180;
                                } else {
                                    slip[i] = tempSlip;
                                }
                            }

                        }

                        var colorPam = [];

                        for (var i = 0; i < csvData.length; i++) {
                            var colorStr = "rgb(";
                            colorStr += Math.round(Math.abs(255 * iso[i] / 100)).toString() + ",";
                            colorStr += Math.round(Math.abs(255 * clvd[i] / 100)).toString() + ",";
                            colorStr += Math.round(Math.abs(255 * dc[i] / 100)).toString() + ")";
                            colorPam.push(colorStr);
                        }

                        var stages = [];
                        for (var i = 0; i < csvData.length; i++) {
                            var element = (csvData[i]['Stage']);
                            if (element != '') {
                                if (isNaN(Number(element))) {
                                    stages.push(element);
                                } else {
                                    stages.push(Number(element));
                                }
                            }
                        }

                        var wells = [];
                        for (var i = 0; i < csvData.length; i++) {
                            var element = (csvData[i]['Well']);
                            if (element == null || element.length == 0) {
                                wells.push("Well");
                            } else {
                                wells.push(element);
                            }
                        }

                        for (var i = 0; i < x.length; i++) {
                            if (x[i] == "" || y[i] == "" || z[i] == "" || stages[i] == "")
                                continue;

                            var name = 'ST_' + wells[i] + '_' + stages[i];
                            var data = {
                                index: i,
                                name: name, //'ST_' + wells[i] + '_' +  isNaN(Number(stages[i]))? stages[i]: Number(stages[i]) ,
                                well: wells[i],
                                dataset: dataset[i],
                                x: x[i],
                                z: y[i],
                                y: z[i],
                                color: colorPam[i],
                                condition: condition[i],
                                confidence: confidence[i],
                                misfit: misfit[i],
                                strike: Number(strike[i]),
                                dip: Number(dip[i]),
                                slip: Number(slip[i]),
                            };

                            if (fractureArea.length > 0 && !isNaN(fractureArea[i])) {
                                data['fractureArea'] = fractureArea[i];
                            }

                            if (sourceEventTime.length > 0) {
                                data['time'] = Date.parse(sourceEventTime[i]);
                            }

                            sourceTensor.push(data);
                        }


                        try {
                            if (sourceTensor.length > 0 && sourceTensor[0].hasOwnProperty("time")) {
                                sourceTensor.sort(function(a, b) {
                                    return new Date(a.time) - new Date(b.time);
                                });
                            }
                        } catch (e) {
                            console.log('Error in sorting events by time!');
                            console.log('Trace - ', e);
                            updateStatus("Error. Check console for logs.");
                        }

                    }

                    // X, Y, Z is Easting, Northing, TVDSS here - it should be Easting, TVDSS, Northing;
                    if (catalogues[n].ctg_type == 'Moment Tensor') {

                        x = convertToArray(csvData, 'Northing');
                        y = convertToArray(csvData, 'Easting');
                        z = convertToArray(csvData, 'TVDSS');

                        condition = convertToArray(csvData, 'Condition');
                        confidence = convertToArray(csvData, 'Confidence');
                        misfit = convertToArray(csvData, 'Misfit');

                        strike = convertToArray(csvData, 'Strike');
                        dip = convertToArray(csvData, 'Dip');
                        slip = convertToArray(csvData, 'Slip');

                        for (var i = 0; i < slip.length; i++) {

                            if (slip[i] > 180 || slip[i] < -180) {

                                tempSlip = slip[i] % 360;

                                if (tempSlip > 180) {
                                    slip[i] = tempSlip - 180;
                                } else {
                                    slip[i] = tempSlip;
                                }
                            }

                        }

                        iso = convertToArray(csvData, 'ISO');
                        clvd = convertToArray(csvData, 'CLVD');
                        dc = convertToArray(csvData, 'DC');

                        colorPam = [];

                        for (var i = 0; i < csvData.length; i++) {

                            colorStr = "rgb(";

                            colorStr += Math.round(Math.abs(255 * iso[i] / 100)).toString() + ",";
                            colorStr += Math.round(Math.abs(255 * clvd[i] / 100)).toString() + ",";
                            colorStr += Math.round(Math.abs(255 * dc[i] / 100)).toString() + ")";

                            colorPam.push(colorStr);
                        }

                        stages = [];
                        for (var i = 0; i < csvData.length; i++) {
                            var element = (csvData[i]['Stage']);
                            if (element != '') {
                                stages.push(element);
                            }
                        }

                        wells = [];
                        for (var i = 0; i < csvData.length; i++) {
                            var element = (csvData[i]['Well']);
                            if (element == null || element.length == 0) {
                                wells.push("Well");
                            } else {
                                wells.push(element);
                            }
                        }


                        dataset = [];
                        for (var i = 0; i < csvData.length; i++) {

                            element = (csvData[i]['Dataset']);
                            if (element == null || element.length == 0) {
                                dataset.push("Master");
                            } else {
                                dataset.push(element);
                            }
                        }


                        for (var i = 0; i < x.length; i++) {
                            if (x[i] == "" || y[i] == "" || z[i] == "" || stages[i] == "")
                                continue;

                            data = {
                                index: i,
                                name: 'MT_' + wells[i] + '_' + stages[i],
                                well: wells[i],
                                dataset: dataset[i],
                                x: x[i],
                                z: y[i],
                                y: z[i],
                                color: colorPam[i],
                                condition: condition[i],
                                confidence: confidence[i],
                                misfit: misfit[i],
                                strike: strike[i],
                                dip: dip[i],
                                slip: slip[i],
                            };

                            momentTensor.push(data);

                        }

                    }

                    if (catalogues[n].ctg_type == 'Treatment Well') {
                        // Due to some weird reason x is fine, y = z and z = y; some issue in javascript
                        data = {
                            name: catalogues[n].ctg_name,
                            hoverinfo: catalogues[n].ctg_name,
                            x: convertToArray(csvData, 'Northing'),
                            z: convertToArray(csvData, 'Easting'),
                            y: convertToArray(csvData, 'TVDSS'),
                            legendName: catalogues[n].ctg_name,
                            color: 'rgb(255, 08, 0)', //getColor(plotColor),
                        };
                        trtWell.push(data);

                        data1 = JSON.parse(JSON.stringify(data)); // data.slice();
                        if (catalogues[n].alias_name && catalogues[n].alias_name.length > 0) {
                            data1.name = catalogues[n].alias_name;
                        }

                        wellLabel.push(data1);
                    }

                    if (catalogues[n].ctg_type == 'Monitor Well') {
                        // Due to some weird reason x is fine, y = z and z = y; some issue in javascript
                        data = {
                            name: catalogues[n].ctg_name,
                            hoverinfo: catalogues[n].ctg_name,
                            x: convertToArray(csvData, 'Northing'),
                            z: convertToArray(csvData, 'Easting'),
                            y: convertToArray(csvData, 'TVDSS'),
                            legendName: catalogues[n].ctg_name,
                            color: 'rgb(47, 141, 255)', //getColor(plotColor),
                        };
                        mtrWell.push(data);

                        data1 = JSON.parse(JSON.stringify(data)); // data.slice();
                        if (catalogues[n].alias_name && catalogues[n].alias_name.length > 0) {
                            data1.name = catalogues[n].alias_name;
                        }
                        wellLabel.push(data1);
                    }

                    if (catalogues[n].ctg_type == 'Additional Well') {
                        // Due to some weird reason x is fine, y = z and z = y; some issue in javascript
                        data = {
                            name: catalogues[n].ctg_name,
                            hoverinfo: catalogues[n].ctg_name,
                            x: convertToArray(csvData, 'Northing'),
                            z: convertToArray(csvData, 'Easting'),
                            y: convertToArray(csvData, 'TVDSS'),
                            legendName: catalogues[n].ctg_name,
                            color: 'rgb(255, 0, 0)',
                        };
                        addWell.push(data);

                        data1 = JSON.parse(JSON.stringify(data)); // data.slice();
                        if (catalogues[n].alias_name && catalogues[n].alias_name.length > 0) {
                            data1.name = catalogues[n].alias_name;
                        }

                        wellLabel.push(data1);
                    }

                    if (catalogues[n].ctg_type == 'Perf') {
                        // Due to some weird reason x is fine, y = z and z = y; some issue in javascript
                        x = convertToArray(csvData, 'Northing');
                        z = convertToArray(csvData, 'Easting');
                        y = convertToArray(csvData, 'TVDSS');
                        stages = [];
                        dataset = [];
                        wells = [];
                        fileName = [];

                        for (var i = 0; i < csvData.length; i++) {

                            element = csvData[i]['File Name'];
                            if (element == null || element.length == 0) {
                                fileName.push("");
                            } else {
                                fileName.push(element);
                            }


                            element = (csvData[i]['Stage']);
                            if (element != '') {
                                stages.push(element);
                            }

                            element = (csvData[i]['Dataset']);
                            if (element && element != '') {
                                dataset.push(element);
                            } else {
                                dataset.push("Master");
                            }

                            element = (csvData[i]['Well']);
                            if (!element || element.length == 0) {
                                wells.push("Well");
                            } else {
                                wells.push(element);
                            }
                        }

                        colorPam = convertToArray(csvData, 'Misc');

                        for (var i = 0; i < x.length; i++) {
                            if (x[i] == "" || y[i] == "" || z[i] == "" || stages[i] == "")
                                continue;

                            // Due to some weird reason x is fine, y = z and z = y; some issue in javascript
                            data = {
                                name: wells[i] + " " + stages[i],
                                x: x[i],
                                y: y[i],
                                z: z[i],
                                well: wells[i],
                                stage: stages[i],
                                //color: colorPam[i], //getColor(colorPam[i]),
                                color: getColor(colorPam[i]),
                                dataset: dataset[i],
                                fileName: fileName[i]
                            };

                            perf.push(data);

                        }

                    }

                    if (catalogues[n].ctg_type == 'Well Logs') {

                        var wellLogData = [];

                        var x = convertToArray(csvData, 'Northing');
                        var z = convertToArray(csvData, 'Easting');
                        var y = convertToArray(csvData, 'TVDSS');
                        var stages = convertToArray(csvData, 'Stage');
                        var value = convertToArray(csvData, 'Value');

                        for (var i = 0; i < x.length; i++) {
                            var wellLogName = catalogues[n].ctg_name;

                            var stage = '';
                            if (stages.length > 0) {
                                stage = 'Stage ' + stages[i].toString();
                            }

                            var color = 'rgba(255, 0, 0, 1.0)';
                            if (stages.length > 0) {
                                color = getColor(Number(stages[i]));
                            }

                            var wellLogValue = 0;
                            if (value.length > 0) {
                                wellLogValue = value[i];
                            }

                            if (x[i] == "" || y[i] == "" || z[i] == "")
                                continue;

                            var azimuth = 0;
                            var dip = 0;

                            // Due to some weird reason x is fine, y = z and z = y; some issue in javascript
                            var data = {
                                name: wellLogName,
                                x: x[i],
                                y: y[i],
                                z: z[i],
                                value: wellLogValue,
                                showlegend: false,
                                legendgroup: catalogues[n].ctg_name,
                                color: color,
                                stage: stage,
                                azimuth: azimuth,
                                dip: dip,
                            };

                            wellLogData.push(data);
                        }

                        if (wellLogData.length > 0) {
                            wellLog.push(wellLogData);
                        }

                    }

                    if (catalogues[n].ctg_type == 'Fracture') {

                        var x = convertToArray(csvData, 'Northing');
                        var z = convertToArray(csvData, 'Easting');
                        var y = convertToArray(csvData, 'TVDSS');
                        var stages = convertToArray(csvData, 'Stage');
                        var azimuth = convertToArray(csvData, 'Azimuth');
                        var dip = convertToArray(csvData, 'Dip');

                        for (var i = 0; i < x.length; i++) {
                            var recName = '';
                            if (stages.length > 0) {
                                recName = stages[i];
                            }

                            var fracAzi = 0;
                            if (azimuth.length > 0) {
                                fracAzi = azimuth[i];
                            }

                            var fracDip = 0;
                            if (dip.length > 0) {
                                fracDip = dip[i];
                            }

                            if (x[i] == "" || y[i] == "" || z[i] == "")
                                continue;

                            // Due to some weird reason x is fine, y = z and z = y; some issue in javascript
                            var data = {
                                name: recName,
                                x: x[i],
                                y: y[i],
                                z: z[i],
                                azimuth: fracAzi,
                                dip: fracDip,
                                showlegend: false,
                                legendgroup: catalogues[n].ctg_name,
                                color: 'rgba(255, 255, 255, 1.0)', //getColor(plotColor),
                            };

                            fracture.push(data);
                        }

                    }

                    if (catalogues[n].ctg_type == 'Receiver') {

                        x = convertToArray(csvData, 'Northing');
                        z = convertToArray(csvData, 'Easting');
                        y = convertToArray(csvData, 'TVDSS');
                        stages = convertToArray(csvData, 'Stage');
                        colorPam = convertToArray(csvData, 'Misc');

                        for (var i = 0; i < x.length; i++) {
                            recName = '';
                            if (stages.length > 0) {
                                recName = stages[i];
                            }

                            if (x[i] == "" || y[i] == "" || z[i] == "")
                                continue;

                            // Due to some weird reason x is fine, y = z and z = y; some issue in javascript
                            data = {
                                design: "Master Design",
                                name: recName,
                                x: x[i],
                                y: y[i],
                                z: z[i],
                                color: 'rgba(255, 255, 255, 1.0)', //getColor(plotColor),
                            };
                            rec.push(data);
                        }

                    }

                    if (catalogues[n].ctg_type == 'Pointwise Formation Tops') {

                        // Due to some weird reason x is fine, y = z and z = y; some issue in javascript
                        data = {
                            name: catalogues[n].ctg_name,
                            hoverinfo: catalogues[n].ctg_name,
                            x: convertToArray(csvData, 'Northing'),
                            z: convertToArray(csvData, 'Easting'),
                            y: convertToArray(csvData, 'TVDSS'),
                            legendName: catalogues[n].ctg_name,
                            formColor: convertToArray(csvData, 'Color'),
                            color: 'rgb(255, 0, 0)',
                        };
                        formationTopsPoints.push(data);

                    }

                    if (catalogues[n].ctg_type == 'Event') {

                        var x = convertToArray(csvData, 'Northing');
                        var y = convertToArray(csvData, 'Easting');
                        var z = convertToArray(csvData, 'TVDSS');
                        var colorPam = convertToArray(csvData, 'Misc');
                        var fractureArea = [],
                            width = [],
                            height = [],
                            length = [],
                            azimuth = [],
                            eventMisfit = [],
                            eventMagnitude = [],
                            eventTimes = [],
                            wells = [],
                            stages = [],
                            eventFormation = [];
                        var noArrays = [],
                            noWells = [];
                        var eastError = [],
                            northError = [],
                            depthError = [];
                        var dataset = [];
                        var fileName = [];
                        var azimuthError = [],
                            azimuthErrorEast1 = [],
                            azimuthErrorNorth1 = [],
                            azimuthErrorTVDSS1 = [],
                            azimuthErrorEast2 = [],
                            azimuthErrorNorth2 = [],
                            azimuthErrorTVDSS2 = [];



                        for (var i = 0; i < csvData.length; i++) {

                            element = csvData[i]['File Name'];
                            if (element == null || element.length == 0) {
                                fileName.push("");
                            } else {
                                fileName.push(element);
                            }

                            element = (csvData[i]['Dataset']);
                            if (element == null || element.length == 0) {
                                dataset.push("Master");
                            } else {
                                dataset.push(element);
                            }

                            noWells.push(Number(csvData[i]['No of Wells']));

                            noArrays.push(Number(csvData[i]['No of Arrays']));

                            stages.push(Number(csvData[i]['Stage']));

                            element = (csvData[i]['Well']);
                            if (!element || element == null || element.length == 0) {
                                wells.push("Well");
                            } else {
                                wells.push(element);
                            }

                            element = (csvData[i]['Event Time']);
                            if (element && element != '') {
                                eventTimes.push(element);
                            }

                            element = Number(csvData[i]['Misfit']);
                            if (!isNaN(element)) {
                                eventMisfit.push(element);
                            }


                            element = Number(csvData[i]['Brune source area']);
                            if (!isNaN(element)) {
                                fractureArea.push(element);
                            }

                            element = (csvData[i]['Formation']);
                            if (element != '') {
                                eventFormation.push(element);
                            }

                            element = Number(csvData[i]['Magnitude']);
                            if (!isNaN(element)) {
                                eventMagnitude.push(element);
                            }

                            width.push(Number(csvData[i]['Width']));
                            length.push(Number(csvData[i]['Length']));
                            height.push(Number(csvData[i]['Height']));

                            azimuth.push(Number(csvData[i]['Azimuth']));

                            element = Number(csvData[i]['Easting Error']);
                            if (!isNaN(Number(element))) {
                                eastError.push(element);
                            }


                            element = Number(csvData[i]['Northing Error']);
                            if (!isNaN(Number(element))) {
                                northError.push(element);
                            }

                            element = Number(csvData[i]['Depth Error']);
                            if (!isNaN(Number(element))) {
                                depthError.push(element);
                            }

                            if (csvData[i].hasOwnProperty('Azimuth Error')) {
                                element = Number(csvData[i]['Azimuth Error']);
                                azimuthError.push(element);
                            }

                            if (csvData[i].hasOwnProperty('Azimuth Error East1')) {
                                element = Number(csvData[i]['Azimuth Error East1']);
                                azimuthErrorEast1.push(element);
                            }

                            if (csvData[i].hasOwnProperty('Azimuth Error East2')) {
                                element = Number(csvData[i]['Azimuth Error East2']);
                                azimuthErrorEast2.push(element);
                            }

                            if (csvData[i].hasOwnProperty('Azimuth Error North1')) {
                                element = Number(csvData[i]['Azimuth Error North1']);
                                azimuthErrorNorth1.push(element);
                            }

                            if (csvData[i].hasOwnProperty('Azimuth Error North2')) {
                                element = Number(csvData[i]['Azimuth Error North2']);
                                azimuthErrorNorth2.push(element);
                            }

                            if (csvData[i].hasOwnProperty('Azimuth Error TVDSS1')) {
                                element = Number(csvData[i]['Azimuth Error TVDSS1']);
                                azimuthErrorTVDSS1.push(element);
                            }

                            if (csvData[i].hasOwnProperty('Azimuth Error TVDSS2')) {
                                element = Number(csvData[i]['Azimuth Error TVDSS2']);
                                azimuthErrorTVDSS2.push(element);
                            }


                        }


                        for (var i = 0; i < x.length; i++) {
                            if (x[i] == "" || y[i] == "" || z[i] == "" || stages[i] == "")
                                continue;

                            data = {
                                index: eventCounter,
                                name: wells[i] + " " + stages[i],
                                fileName: fileName[i],
                                well: wells[i],
                                stage: stages[i],
                                x: x[i],
                                z: y[i],
                                y: z[i],
                                width: width[i],
                                height: height[i],
                                length: length[i],
                                azimuth: azimuth[i],
                                noArrays: noArrays[i],
                                color: getColor(colorPam[i]),
                                noWells: noWells[i],
                                dataset: dataset[i],
                            };


                            if (eventTimes.length > 0) {
                                data.time = Date.parse(eventTimes[i]);
                            }

                            if (eventMisfit.length > 0) {
                                data.misfit = eventMisfit[i];
                            }

                            if (eventMagnitude.length > 0) {
                                data.magnitude = eventMagnitude[i];
                            }

                            if (eventFormation.length > 0) {
                                data.formation = eventFormation[i];
                            }

                            if (eastError.length > 0) {
                                data.eastError = eastError[i];
                            }

                            if (northError.length > 0) {
                                data.northError = northError[i];
                            }

                            if (depthError.length > 0) {
                                data.depthError = depthError[i];
                            }

                            if (fractureArea.length > 0) {
                                data.fractureArea = fractureArea[i];
                            }

                            if (azimuthError.length > 0) {
                                data.azimuthError = azimuthError[i];
                                data.azimuthErrorEast1 = azimuthErrorEast1[i];
                                data.azimuthErrorNorth1 = azimuthErrorNorth1[i];
                                data.azimuthErrorTVDSS1 = azimuthErrorTVDSS1[i];
                                data.azimuthErrorEast2 = azimuthErrorEast2[i];
                                data.azimuthErrorNorth2 = azimuthErrorNorth2[i];
                                data.azimuthErrorTVDSS2 = azimuthErrorTVDSS2[i];
                            };

                            events.push(data);
                            eventCounter = eventCounter + 1;

                        }

                        if (eventTimes.length > 0) {
                            events.sort(function(a, b) {
                                return new Date(a.time) - new Date(b.time);
                            });
                        }

                        // console.log("Events", events);

                    }

                    counter = counter + 1;

                    if (counter == catalogues.length) {
                        prePlotting(divId);
                    }
                }
            });

        }

    }

    if (catalogues.length == 0 || catalogues.filter(e => e.ctg_type != "Strain" && e.ctg_type != "Volume" && e.ctg_type != "Fiber Data").length == 0) {
        prePlotting(divId);
    }

}

function prePlotting(divId) {

    if (trtWell.length == 0) {
        alert("Cannot create plot without a treatment well.");
        $("#hourGlass").hide(250);
        setTimeout(function() {
            $('#loadingStatus').fadeOut('slow');
        }, 500); // 
        return;
    }

    // Calculate Boundaries
    x_min = [trtWell, mtrWell].flat().map(e => e.x).flat().min();
    x_max = [trtWell, mtrWell].flat().map(e => e.x).flat().max();

    y_min = [trtWell, mtrWell].flat().map(e => e.y).flat().min();
    y_max = [trtWell, mtrWell].flat().map(e => e.y).flat().max();

    z_min = [trtWell, mtrWell].flat().map(e => e.z).flat().min();
    z_max = [trtWell, mtrWell].flat().map(e => e.z).flat().max();

    var xdiff = Math.abs(x_max - x_min);
    var zdiff = Math.abs(z_max - z_min);
    var ydiff = Math.abs(y_max - y_min); //*0.5;



    var maxDiff = Math.max(xdiff, zdiff);

    if (maxDiff < 2500) {
        maxDiff = 2500;
    }

    if (ydiff < 2500) {
        ydiff = 2500;
    }


    x_min = x_min - maxDiff;
    z_min = z_min - maxDiff;
    // if (y_min > 0)
    // y_min = y_min + ydiff;
    // else
    y_min = y_min - ydiff;

    x_max = x_max + maxDiff;
    z_max = z_max + maxDiff;
    y_max = y_max + ydiff;

    var CAMERA_FAR = 100 * Math.max(maxDiff, ydiff);

    new THREE.FontLoader().load('https://borehole-seismic-portal.s3.amazonaws.com/helvetiker_bold.typeface.json', function(font) {

        updateStatus("Plotting data");

        try {

            plot3D(font, divId);
            console.log("scene", scene);

            scene.traverse(function(child) {
                if (child.type == "Mesh") {
                    child.castShadow = true;
                    child.receiveShadow = true;
                }
            });

            animate();
            // $("#container").hide();	
            // $("#container").show();	
            $("#hourGlass").hide(250);
            setTimeout(function() {
                $('#loadingStatus').fadeOut('slow');
            }, 500); // <-- time in milliseconds
        } catch (e) {
            $("#hourGlass").hide(250);
            updateStatus("Error. Check console for logs.");
            console.log(e);
        }

    });

}


function plot3D(font, divId) {

    // RENDERER
    if (Detector.webgl)
        renderer = new THREE.WebGLRenderer({
            antialias: true,
            logarithmicDepthBuffer: false,
        });
    else
        renderer = new THREE.CanvasRenderer();


    // 
    renderer.setSize(parentContainer.width(), parentContainer.height(), false);
    container = document.getElementById(divId);
    container.appendChild(renderer.domElement);

    // SCENE
    scene = new THREE.Scene();
    scene.background = new THREE.Color(0x141414).convertSRGBToLinear();

    // var ambientLight = new THREE.AmbientLight(0x404040, 0.4);
    // scene.add(ambientLight);
    // scene.add(new THREE.HemisphereLight(0x888888, 0x888888));

    scene.add(new THREE.AmbientLight(0xffffff, 1));
    scene.add(new THREE.HemisphereLight(0xffffff, 0xAAAAAA, 1));

    pickingScene = new THREE.Scene();
    pickingTexture = new THREE.WebGLRenderTarget(parentContainer.width(), parentContainer.height());
    pickingTexture.texture.minFilter = THREE.NearestFilter;
    pickingTexture.texture.generateMipmaps = false;

    // CAMERA
    var SCREEN_WIDTH = parentContainer.width(),
        SCREEN_HEIGHT = parentContainer.height();
    var VIEW_ANGLE = 45,
        ASPECT = SCREEN_WIDTH / SCREEN_HEIGHT,
        NEAR = 0.50,
        FAR = 1000;

    // STATS
    stats = new Stats();
    stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
    stats.domElement.style.position = 'fixed';
    stats.domElement.style.height = '50px';
    stats.domElement.style.top = '10px';
    stats.domElement.style.right = '10px';

    if (pumpLogs.length > 0) {
        document.getElementById('pumplogs').style.display = 'block';
    }

    stats.domElement.style.left = (window.innerWidth - 100).toString() + 'px';
    container.appendChild(stats.domElement);

    if (pumpLogs.length > 0) {
        var PUMPLOG_SUBPLOTS = ~~(Object.keys(pumpLogs[0]).length / 5);
        document.getElementById('pumplogs').style.height = (PUMPLOG_SUBPLOTS * 160).toString();
        document.getElementById('compass').style.bottom = ((PUMPLOG_SUBPLOTS + 1) * 160).toString();
    } else {
        document.getElementById('compass').style.bottom = '40px';
    }

    //////////////////////
    ////// GRID CALC /////
    //////////////////////

    bk_x_min = DOWNSAMPLE * x_min;
    bk_x_max = DOWNSAMPLE * x_max;
    bk_y_min = DOWNSAMPLE * y_min;
    bk_y_max = DOWNSAMPLE * y_max;
    bk_z_min = DOWNSAMPLE * z_min;
    bk_z_max = DOWNSAMPLE * z_max;

    x_min = -1 * (bk_x_max - bk_x_min);
    x_max = 1 * (bk_x_max - bk_x_min);
    x_div = DOWNSAMPLE * 500;
    // y corresponds to depth, god knows why
    y_min = -0.5 * (bk_y_max - bk_y_min);
    y_max = 2 * (bk_y_max - bk_y_min);
    y_div = DOWNSAMPLE * 500;

    z_min = -1 * (bk_z_max - bk_z_min);
    z_max = 1 * (bk_z_max - bk_z_min);
    z_div = DOWNSAMPLE * 500;


    gridGeometry = new THREE.BufferGeometry();
    gridMaterial = new THREE.MeshBasicMaterial({
        map: new THREE.TextureLoader().load('https://borehole-seismic-biz.s3.amazonaws.com/receiver.jpg'),
        color: new THREE.Color(0x9c9b9b).convertSRGBToLinear()
    });
    updateGridGeometry();
    gridMesh = new THREE.Line(gridGeometry, gridMaterial);

    scene.add(gridMesh);

    console.log("Plotted Grid");
    updateStatus("Plotted Grid");

    //////////////////////
    ////// GRID END  /////
    //////////////////////

    initializeGUI();

    plotMonitorWells();

    plotTreatmentWells();

    plotAdditionalWells();

    plotWellLabels(font);

    /////////////////////////////////
    ///// PERFORATION 	    START ///
    /////////////////////////////////

    plotPerforations();

    /////////////////////////////////
    ///// PERFORATION WELLS DONE ///
    /////////////////////////////////

    plotReceivers();


    /////////////////////////////////
    ///// WELL LOG	 	    START ///
    /////////////////////////////////

    plotWellLogs();

    /////////////////////////////////
    ///// FRACTURE 			START ///
    /////////////////////////////////

    plotFaults();


    plotFractures();


    /////////////////////////////////
    //////   REVEAL DATA START //////
    /////////////////////////////////

    plotRevealData();



    /////////////////////////////////
    ///// EVENT		 	    START ///
    /////////////////////////////////

    if (events.length > 0) {

        // events.sort((b,a) => { return a.y - b.y; });
        // var eventTexture = new THREE.TextureLoader().load('https://borehole-seismic-biz.s3.amazonaws.com/receiver.jpg');
        // eventTexture.wrapS = THREE.MirroredRepeatWrapping;
        // eventTexture.wrapT = THREE.MirroredRepeatWrapping;
        // eventTexture.repeat.set(2, 1);


        eventMaterial = new THREE.MeshLambertMaterial({
            // map: eventTexture,
            map: new THREE.TextureLoader().load('https://borehole-seismic-biz.s3.amazonaws.com/texture.jpg?t=2123213012'),
            vertexColors: THREE.VertexColors,
            // emissive: 0x666666,
            // emissiveIntensity: 0.3,
            side: THREE.DoubleSide
                // side: THREE.DoubleSide
        });

        eventMaterial.onBeforeCompile = function(shader) {
            shader.vertexShader =
                `
				#define LAMBERT
				// instanced
				attribute vec3 iOffset;
				attribute vec3 iColor;
				attribute vec3 iScale;
                attribute vec3 iSize;
				attribute vec3 iSizeVariation;
				attribute vec3 iMisfitFilter;
				attribute vec3 iMagnitudeFilter;
				attribute vec3 timelapse;
				attribute vec3 iErrorFilter;
				attribute vec3 iVisible;
				attribute vec3 iWellPickedFilter;
				varying float visible;
				
				varying vec3 vLightFront;
				varying vec3 vIndirectFront;
				#ifdef DOUBLE_SIDED
					varying vec3 vLightBack;
					varying vec3 vIndirectBack;
				#endif
				#include <common>
				#include <uv_pars_vertex>
				#include <uv2_pars_vertex>
				#include <envmap_pars_vertex>
				#include <bsdfs>
				#include <lights_pars_begin>
				#include <color_pars_vertex>
				#include <fog_pars_vertex>
				#include <morphtarget_pars_vertex>
				#include <skinning_pars_vertex>
				#include <shadowmap_pars_vertex>
				#include <logdepthbuf_pars_vertex>
				#include <clipping_planes_pars_vertex>
				void main() {
					#include <uv_vertex>
					#include <uv2_vertex>
					#include <color_vertex>
					#ifdef USE_COLOR
						vColor.xyz = iColor.xyz;
					#endif
					#include <beginnormal_vertex>
					#include <morphnormal_vertex>
					#include <skinbase_vertex>
					#include <skinnormal_vertex>
					#include <defaultnormal_vertex>
					#include <begin_vertex>
					// position instanced

                    transformed *= iScale;
					transformed *= iSize;
					transformed *= iSizeVariation;
					transformed *= timelapse;
					transformed *= iMisfitFilter;
					transformed *= iMagnitudeFilter;
					transformed *= iVisible;

					transformed *= iWellPickedFilter;
                    transformed *= iErrorFilter[0];
                    transformed *= iErrorFilter[1];
                    transformed *= iErrorFilter[2];


                    visible = iVisible[0]*iScale[0]*timelapse[0]*iMisfitFilter[0]*iWellPickedFilter[0]*iMagnitudeFilter[0]*iErrorFilter[0]*iErrorFilter[1]*iErrorFilter[2];
					transformed = transformed + iOffset;
					#include <morphtarget_vertex>
					#include <skinning_vertex>
					#include <project_vertex>
					#include <logdepthbuf_vertex>
					#include <clipping_planes_vertex>
					#include <worldpos_vertex>
					#include <envmap_vertex>

                    vec3 diffuse = vec3( 1.0 );
					GeometricContext geometry;
					geometry.position = mvPosition.xyz;
					geometry.normal = normalize( transformedNormal );
					geometry.viewDir = normalize( -mvPosition.xyz );
					GeometricContext backGeometry;
					backGeometry.position = geometry.position;
					backGeometry.normal = -geometry.normal;
					backGeometry.viewDir = geometry.viewDir;
					vLightFront = vec3( 0.0 );
					vIndirectFront = vec3( 0.0 );
					#ifdef DOUBLE_SIDED
						vLightBack = vec3( 0.0 );
						vIndirectBack = vec3( 0.0 );
					#endif
					IncidentLight directLight;
					float dotNL;
					vec3 directLightColor_Diffuse;

                    
					#if NUM_HEMI_LIGHTS > 0
						#pragma unroll_loop
						for ( int i = 0; i < NUM_HEMI_LIGHTS; i ++ ) {
							vIndirectFront += getHemisphereLightIrradiance( hemisphereLights[ i ], geometry );
							#ifdef DOUBLE_SIDED
								vIndirectBack += getHemisphereLightIrradiance( hemisphereLights[ i ], geometry );
							#endif
						}
					#endif


					vLightFront += vec3( 1 );
					#ifdef DOUBLE_SIDED
						vLightBack += vec3( 1 );
					#endif


					#include <shadowmap_vertex>
					#include <fog_vertex>
				}
				`;

            var materialShader =
                `
                varying float visible;
                uniform vec3 diffuse;
                uniform vec3 emissive;
                uniform float opacity;
                varying vec3 vLightFront;
                varying vec3 vIndirectFront;
                #ifdef DOUBLE_SIDED
                    varying vec3 vLightBack;
                    varying vec3 vIndirectBack;
                #endif
                #include <common>
                #include <packing>
                #include <dithering_pars_fragment>
                #include <color_pars_fragment>
                #include <uv_pars_fragment>
                #include <uv2_pars_fragment>
                #include <map_pars_fragment>
                #include <alphamap_pars_fragment>
                #include <aomap_pars_fragment>
                #include <lightmap_pars_fragment>
                #include <emissivemap_pars_fragment>
                #include <envmap_common_pars_fragment>
                #include <envmap_pars_fragment>
                #include <bsdfs>
                #include <lights_pars_begin>
                #include <fog_pars_fragment>
                #include <shadowmap_pars_fragment>
                #include <shadowmask_pars_fragment>
                #include <specularmap_pars_fragment>
                #include <logdepthbuf_pars_fragment>
                #include <clipping_planes_pars_fragment>
                void main() {
                    if (visible == 0.0) discard;
                    #include <clipping_planes_fragment>
                    vec4 diffuseColor = vec4( diffuse, opacity );
                    ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
                    vec3 totalEmissiveRadiance = emissive;
                    #include <logdepthbuf_fragment>
                    #include <map_fragment>
                    #include <color_fragment>
                    #include <alphamap_fragment>
                    #include <alphatest_fragment>
                    #include <specularmap_fragment>
                    #include <emissivemap_fragment>
                    reflectedLight.indirectDiffuse = getAmbientLightIrradiance( ambientLightColor );
                    #ifdef DOUBLE_SIDED
                        reflectedLight.indirectDiffuse += ( gl_FrontFacing ) ? vIndirectFront : vIndirectBack;
                    #else
                        reflectedLight.indirectDiffuse += vIndirectFront;
                    #endif
                    #include <lightmap_fragment>
                    reflectedLight.indirectDiffuse *= BRDF_Diffuse_Lambert( diffuseColor.rgb );
                    #ifdef DOUBLE_SIDED
                        reflectedLight.directDiffuse = ( gl_FrontFacing ) ? vLightFront : vLightBack;
                    #else
                        reflectedLight.directDiffuse = vLightFront;
                    #endif
                    reflectedLight.directDiffuse *= BRDF_Diffuse_Lambert( diffuseColor.rgb ) * getShadowMask();
                    #include <aomap_fragment>
                    vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + totalEmissiveRadiance;
                    #include <envmap_fragment>
                    gl_FragColor = vec4( outgoingLight, diffuseColor.a );
                    #include <tonemapping_fragment>
                    #include <encodings_fragment>
                    #include <fog_fragment>
                    #include <premultiplied_alpha_fragment>
                    #include <dithering_fragment>
                }
                `
        };


        var eventCount = events.length;

        var offsets = [],
            iColors = [],
            iScale = [],
            iSize = [],
            timelapse = [];

        for (var i = 0; i < eventCount; i++) {
            offsets.push((DOWNSAMPLE * events[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                (DOWNSAMPLE * events[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                (DOWNSAMPLE * events[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));
            var eventTempColor = new THREE.Color(events[i].color).convertSRGBToLinear();
            iColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);

            iScale.push(1, 1, 1);
            iSize.push(1, 1, 1);
            timelapse.push(1, 1, 1);
        }
        console.log("@@@event colors", iColors)

        if (eventCount > 500 * 100) {
            var boxGeometry = new THREE.SphereBufferGeometry(1, 5, 3);
        } else {
            boxGeometry = new THREE.SphereBufferGeometry(1);
        }

        boxGeometry.frustumCulled = false;
        eventGeomDrawn = new THREE.InstancedBufferGeometry();
        eventGeomDrawn.dynamic = true;
        eventGeomDrawn.instanceCount = eventCount;
        eventGeomDrawn.index = boxGeometry.index;
        eventGeomDrawn.attributes = boxGeometry.attributes;

        eventGeomDrawn.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
        eventGeomDrawn.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
        eventGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
        eventGeomDrawn.setAttribute('iVisible', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
        eventGeomDrawn.setAttribute('iMisfitFilter', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
        eventGeomDrawn.setAttribute('iMagnitudeFilter', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
        eventGeomDrawn.setAttribute('iErrorFilter', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

        eventGeomDrawn.setAttribute('iSize', new THREE.InstancedBufferAttribute(new Float32Array(iSize), 3));
        eventGeomDrawn.setAttribute('iSizeVariation', new THREE.InstancedBufferAttribute(new Float32Array(iSize), 3));
        eventGeomDrawn.setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timelapse), 3));
        eventGeomDrawn.setAttribute('iWellPickedFilter', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));


        var offsets = [],
            iColors = [],
            iScale = [];

        for (var i = 0; i < eventCount; i++) {

            offsets.push((DOWNSAMPLE * events[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                (DOWNSAMPLE * events[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                (DOWNSAMPLE * events[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));

            eventTempColor = new THREE.Color(events[i].color).convertSRGBToLinear();
            eventTempColor.setHex(pickingID);
            iColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);

            iScale.push(1, 1, 1);

            pickingData[pickingID] = {
                name: "<b>Event from " + events[i].well.toString() + " Stage " + events[i].stage.toString() + "</b>" + "<br>Easting: " + Math.round(events[i].z).toString() + " ft<br>Northing: " +
                    Math.round(events[i].x).toString() + " ft<br>Depth (Subsea): " + Math.round(events[i].y).toString() + " ft",
                position: new THREE.Vector3(Math.round(events[i].x), Math.round(events[i].z), Math.round(events[i].y)),
                eventIndex: i
            };

            if (events[i].hasOwnProperty("id")) {
                pickingData[pickingID].event_result_id = events[i].id;
            }

            if (events[i].hasOwnProperty("fileName")) {
                pickingData[pickingID].fileName = events[i].fileName;
            }

            if (events[i].noArrays != 0 && events[i].noArrays) {
                pickingData[pickingID].name = pickingData[pickingID].name + "<br>Arrays Picked: " + events[i].noArrays.toString();
            }


            if (!isNaN(events[i].width) && !isNaN(events[i].length) && !isNaN(events[i].height)) {
                pickingData[pickingID].name = pickingData[pickingID].name + "<br><b>Event Location Stats (in ft):</b><br>" +
                    "Width: " + Math.round(events[i].width).toString() +
                    ", Length: " + Math.round(events[i].length).toString() +
                    ", Height: " + Math.round(events[i].height).toString();
            }

            if (events[i].hasOwnProperty("misfit") && events[i].misfit) {
                pickingData[pickingID].name = pickingData[pickingID].name + "<br>" +
                    "Misfit: " + (Math.round(10 * events[i].misfit) / 10).toString() + " ms";
            }

            if (events[i].hasOwnProperty("magnitude") && !isNaN(events[i].magnitude)) {
                pickingData[pickingID].name = pickingData[pickingID].name + "<br>" +
                    "Magnitude: " + (Math.round(100 * events[i].magnitude) / 100).toString();
            }

            if (events[i].hasOwnProperty("formation") && events[i].formation) {
                pickingData[pickingID].name = pickingData[pickingID].name + "<br>" +
                    "Formation: " + events[i].formation;

            }

            if (events[i].hasOwnProperty("eastError") && events[i].eastError) {
                pickingData[pickingID].name = pickingData[pickingID].name + "<br>" +
                    "East Error: " + (Math.round(100 * events[i].eastError) / 100).toString() + " ft";

            }

            if (events[i].hasOwnProperty("northError") && events[i].northError) {
                pickingData[pickingID].name = pickingData[pickingID].name + "<br>" +
                    "North Error: " + (Math.round(100 * events[i].northError) / 100).toString() + " ft";

            }

            if (events[i].hasOwnProperty("depthError") && events[i].depthError) {
                pickingData[pickingID].name = pickingData[pickingID].name + "<br>" +
                    "Depth Error: " + (Math.round(100 * events[i].depthError) / 100).toString() + " ft";
            }

            if (events[i].hasOwnProperty("azimuthError") && events[i].azimuthError) {
                pickingData[pickingID].name = pickingData[pickingID].name + "<br>" +
                    "Azimuth Error: " + (Math.round(10 * events[i].azimuthError) / 10).toString();
            }


            pickingID = pickingID + 1;
        }

        var eventMesh = new THREE.Mesh(eventGeomDrawn, eventMaterial);
        eventMesh.frustumCulled = false;
        scene.add(eventMesh);

        var boxGeometry = new THREE.SphereBufferGeometry(1);
        boxGeometry.frustumCulled = false;
        eventGeomPicking = new THREE.InstancedBufferGeometry();
        eventGeomPicking.instanceCount = eventCount;
        eventGeomPicking.dynamic = true;
        eventGeomPicking.index = boxGeometry.index;
        eventGeomPicking.attributes = boxGeometry.attributes;
        eventGeomPicking.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
        eventGeomPicking.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
        eventGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
        eventGeomPicking.setAttribute('iVisible', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

        eventGeomPicking.setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
        eventGeomPicking.setAttribute('iMisfitFilter', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
        eventGeomPicking.setAttribute('iMagnitudeFilter', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
        eventGeomPicking.setAttribute('iErrorFilter', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

        eventGeomPicking.setAttribute('iWellPickedFilter', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
        eventGeomPicking.setAttribute('iSizeVariation', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
        eventGeomPicking.setAttribute('iSize', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

        var vertexShader = [
            "precision highp float;",
            "",
            "uniform mat4 modelViewMatrix;",
            "uniform mat4 projectionMatrix;",
            "",
            "attribute vec3 position;",
            "attribute vec3 iOffset;",
            "attribute vec3 iColor;",
            "attribute vec3 iScale;",
            "attribute vec3 iVisible;",
            "attribute vec3 timelapse;",


            "attribute vec3 iSize;",
            "attribute vec3 iSizeVariation;",

            "attribute vec3 iMisfitFilter;",
            "attribute vec3 iMagnitudeFilter;",
            "attribute vec3 iErrorFilter;",
            "attribute vec3 iWellPickedFilter;",

            "varying vec3 scale;",
            "varying vec3 visible;",
            "varying vec3 vColor;",
            "varying vec3 timelapseA;",

            "varying vec3 Size;",

            "varying vec3 misfitFilter;",
            "varying vec3 MagnitudeFilter;",
            "varying vec3 ErrorFilter;",

            "varying vec3 WellPickedFilter;",

            "",
            "void main() {",
            "",
            "	gl_Position = projectionMatrix * modelViewMatrix * vec4( iOffset + position, 1.0 );",
            "   scale = iScale;",
            "   timelapseA = timelapse;",
            "   misfitFilter = iMisfitFilter;",
            "	vColor = iColor;",
            "	visible = iVisible;",

            "	ErrorFilter = iErrorFilter;",
            "	WellPickedFilter = iWellPickedFilter;",
            "	MagnitudeFilter = iMagnitudeFilter;",

            "",
            "}"
        ].join("\n");
        var fragmentShader = [
            "precision highp float;",
            "varying vec3 vColor;",
            "varying vec3 timelapseA;",
            "varying vec3 scale;",
            "varying vec3 visible;",

            "varying vec3 misfitFilter;",
            "varying vec3 MagnitudeFilter;",

            "varying vec3 WellPickedFilter;",
            "varying vec3 ErrorFilter;",


            "",
            "void main() {",
            "",
            "if (misfitFilter == vec3(0.0,0.0,0.0)) discard;",
            "if (MagnitudeFilter == vec3(0.0,0.0,0.0)) discard;",
            "if (timelapseA == vec3(0.0,0.0,0.0)) discard;",
            "if (scale == vec3(0.0,0.0,0.0)) discard;",
            "if (visible == vec3(0.0,0.0,0.0)) discard;",

            "if (ErrorFilter[0] == 0.0) discard;",
            "if (ErrorFilter[1] == 0.0) discard;",
            "if (ErrorFilter[2] == 0.0) discard;",
            "if (WellPickedFilter == vec3(0.0,0.0,0.0)) discard;",
            "	gl_FragColor = vec4(vColor, 1.0);",
            "",
            "}"
        ].join("\n");

        var eventMaterialPick = new THREE.RawShaderMaterial({
            uniforms: {},
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            side: THREE.DoubleSide,
            transparent: false
        });

        eventMeshPick = new THREE.Mesh(eventGeomPicking, eventMaterialPick);
        eventMeshPick.frustumCulled = false;
        pickingScene.add(eventMeshPick);

        console.log("Plotted Events");
        updateStatus("Plotted Events");


        rayTracingMaterial = new THREE.LineBasicMaterial({
            color: 0xffffff,
            linewidth: 1,
        });
        rayTracingGeom = new THREE.BufferGeometry();
        rayTracingGeom.setAttribute('position', new THREE.BufferAttribute(new Float32Array(0), 3));
        rayTracingLine = new THREE.LineSegments(rayTracingGeom, rayTracingMaterial);
        rayTracingLine.frustumCulled = false;
        scene.add(rayTracingLine);

    }


    /////////////////////////////////
    ///// EVENT		 	     DONE ///
    /////////////////////////////////


    /////////////////////////////////
    ///////// GYRATION	START ///////
    /////////////////////////////////

    if (gyration.length > 0) {

        var gyrationMaterial = new THREE.MeshBasicMaterial({
            combine: THREE.MultiplyOperation,
            reflectivity: 0.8,
            vertexColors: THREE.VertexColors,
            fog: true,
            transparent: true,
            opacity: 0.5
        });

        gyrationMaterial.transparent = true

        gyrationMaterial.onBeforeCompile = function(shader) {
            shader.vertexShader =
                `
				#define LAMBERT
				// instanced
				attribute vec3 iColor;
				attribute vec3 iFilter;
				attribute vec3 iShow;
				attribute vec3 iMode;
				attribute vec3 iTimelapse;
				varying vec3 vLightFront;
				varying vec3 vIndirectFront;
				attribute vec4 aInstanceMatrix0;
				attribute vec4 aInstanceMatrix1;
				attribute vec4 aInstanceMatrix2;
				attribute vec4 aInstanceMatrix3;


				#ifdef DOUBLE_SIDED
					varying vec3 vLightBack;
					varying vec3 vIndirectBack;
				#endif
				#include <common>
				#include <uv_pars_vertex>
				#include <uv2_pars_vertex>
				#include <envmap_pars_vertex>
				#include <bsdfs>
				#include <lights_pars_begin>
				#include <color_pars_vertex>
				#include <fog_pars_vertex>
				#include <morphtarget_pars_vertex>
				#include <skinning_pars_vertex>
				#include <shadowmap_pars_vertex>
				#include <logdepthbuf_pars_vertex>
				#include <clipping_planes_pars_vertex>
				void main() {
					#include <uv_vertex>
					#include <uv2_vertex>
					#include <color_vertex>
					#ifdef USE_COLOR
						vColor.xyz = iColor.xyz;
					#endif
					#include <beginnormal_vertex>
					#include <morphnormal_vertex>
					#include <skinbase_vertex>
					#include <skinnormal_vertex>
					#include <defaultnormal_vertex>
					#include <begin_vertex>
					mat4 aInstanceMatrix = mat4(
						aInstanceMatrix0,
						aInstanceMatrix1,
						aInstanceMatrix2,
						aInstanceMatrix3
					);
					transformed = (aInstanceMatrix * vec4( position , 1. )).xyz;
					transformed *= iTimelapse;
					transformed *= iFilter;
					transformed *= iShow;
					transformed *= iMode;

					#include <morphtarget_vertex>
					#include <skinning_vertex>
					#include <project_vertex>
					#include <logdepthbuf_vertex>
					#include <clipping_planes_vertex>
					#include <worldpos_vertex>
					#include <envmap_vertex>
					#include <lights_lambert_vertex>
					#include <shadowmap_vertex>
					#include <fog_vertex>
				}
				`;


            var materialShader = shader;
        };

        var gyrationCount = gyration.length;



        // Get the colors & offsets in an array first;

        var iFilter = [],
            iColors = [],
            iShow = [],
            iTimelapse = [];
        var iMode = [];
        var gyrationMatArraySize = 4 * gyrationCount;
        var gyrationMatrixArray = [
            new Float32Array(gyrationMatArraySize),
            new Float32Array(gyrationMatArraySize),
            new Float32Array(gyrationMatArraySize),
            new Float32Array(gyrationMatArraySize),
        ];

        var lut = new Lut();
        lut.setColorMap('rainbow');

        for (var i = 0; i < gyrationCount; i++) {

            var gyrationMatrix = new THREE.Matrix4();
            //gyrationTempColor = new THREE.Color( 0xff0000 );
            //iColors.push(gyrationTempColor.r, gyrationTempColor.g, gyrationTempColor.b);

            lut.setMax(1);
            lut.setMin(0);
            var colorGyration = lut.getColor(gyration[i].color);

            iColors.push(colorGyration.r, colorGyration.g, colorGyration.b);

            // Turned off Initially
            iShow.push(0, 0, 0);

            iMode.push(1, 1, 1);

            var gyrationPosition = new THREE.Vector3(
                DOWNSAMPLE * gyration[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min),
                DOWNSAMPLE * gyration[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min),
                DOWNSAMPLE * gyration[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min),
            );

            var gyrationScale = new THREE.Vector3(DOWNSAMPLE * gyration[i].xl, DOWNSAMPLE * gyration[i].zl, DOWNSAMPLE * gyration[i].yl);
            var gyrationQuaternion = new THREE.Quaternion();

            // 1 = north, 2 = down, 3 = east;

            var a1 = new THREE.Vector3(-1, 0, 0);
            var a2 = new THREE.Vector3(0, -1, 0);
            var a3 = new THREE.Vector3(0, 0, -1);

            var e1 = new THREE.Vector3(gyration[i].x1, -gyration[i].x2, gyration[i].x3);
            var e2 = new THREE.Vector3(gyration[i].y1, -gyration[i].y2, gyration[i].y3);
            var e3 = new THREE.Vector3(gyration[i].z1, -gyration[i].z2, gyration[i].z3);

            // <%# console.log('Angles - ', e1.angleTo(a1), '; ', e2.angleTo(a2), '; ', e3.angleTo(a3)); %>

            var gyrationRotation = new THREE.Euler(e1.angleTo(a1), e2.angleTo(a2), e3.angleTo(a3), 'XYZ');
            var gyrationQuaternion = new THREE.Quaternion();
            gyrationQuaternion.setFromEuler(gyrationRotation);
            gyrationMatrix.compose(gyrationPosition, gyrationQuaternion, gyrationScale);

            for (var r = 0; r < 4; r++) {
                for (var c = 0; c < 4; c++) {
                    gyrationMatrixArray[r][4 * i + c] = gyrationMatrix.elements[r * 4 + c];
                }
            }
            iFilter.push(1, 1, 1);
            iTimelapse.push(1, 1, 1);

        }

        boxGeometry = new THREE.SphereBufferGeometry(1, 32, 32);

        boxGeometry.frustumCulled = false;
        gyrationGeomDrawn = new THREE.InstancedBufferGeometry();
        gyrationGeomDrawn.instanceCount = gyrationCount;
        gyrationGeomDrawn.index = boxGeometry.index;
        gyrationGeomDrawn.attributes = boxGeometry.attributes;
        gyrationGeomDrawn.setAttribute('iFilter', new THREE.InstancedBufferAttribute(new Float32Array(iFilter), 3));
        gyrationGeomDrawn.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
        gyrationGeomDrawn.setAttribute('iTimelapse', new THREE.InstancedBufferAttribute(new Float32Array(iTimelapse), 3));
        gyrationGeomDrawn.setAttribute('iShow', new THREE.InstancedBufferAttribute(new Float32Array(iShow), 3));
        gyrationGeomDrawn.setAttribute('iMode', new THREE.InstancedBufferAttribute(new Float32Array(iMode), 3));

        for (var i = 0; i < gyrationMatrixArray.length; i++) {
            gyrationGeomDrawn.setAttribute(
                `aInstanceMatrix${i}`,
                new THREE.InstancedBufferAttribute(gyrationMatrixArray[i], 4)
            );
        }

        var gyrationMesh = new THREE.Mesh(gyrationGeomDrawn, gyrationMaterial);
        gyrationMesh.frustumCulled = false;
        scene.add(gyrationMesh);

        updateStatus("Plotted Gyration");


    }





    /////////////////////////////////
    // MODIFIED MOMENT TENSOR 	/////
    /////////////////////////////////

    if (momentTensor.length > 0) {


        momentTensorMaterial = new THREE.MeshLambertMaterial({
            // map : new THREE.TextureLoader().load( 'https://borehole-seismic-biz.s3.amazonaws.com/receiver.jpg' ),
            // map : new THREE.TextureLoader().load( 'https://borehole-seismic-biz.s3.amazonaws.com/receiver.jpg' ),
            vertexColors: THREE.VertexColors,
            emissive: 0x2a2a2a,
            // emissiveIntensity: 1,
            side: THREE.DoubleSide
                // side: THREE.DoubleSide
        });

        momentTensorMaterial.onBeforeCompile = function(shader) {
            shader.vertexShader =
                `
				#define LAMBERT
				// instanced
				attribute vec3 iOffset;
				attribute vec3 iColor;
				attribute vec3 iScale;
				attribute vec3 iConfidenceFilter;
				attribute vec3 iMisfitFilter;

				varying vec3 vLightFront;
				varying vec3 vIndirectFront;
				attribute vec4 aInstanceMatrix0;
				attribute vec4 aInstanceMatrix1;
				attribute vec4 aInstanceMatrix2;
				attribute vec4 aInstanceMatrix3;


				#ifdef DOUBLE_SIDED
					varying vec3 vLightBack;
					varying vec3 vIndirectBack;
				#endif
				#include <common>
				#include <uv_pars_vertex>
				#include <uv2_pars_vertex>
				#include <envmap_pars_vertex>
				#include <bsdfs>
				#include <lights_pars_begin>
				#include <color_pars_vertex>
				#include <fog_pars_vertex>
				#include <morphtarget_pars_vertex>
				#include <skinning_pars_vertex>
				#include <shadowmap_pars_vertex>
				#include <logdepthbuf_pars_vertex>
				#include <clipping_planes_pars_vertex>
				void main() {
					#include <uv_vertex>
					#include <uv2_vertex>
					#include <color_vertex>
					#ifdef USE_COLOR
						vColor.xyz = iColor.xyz;
					#endif
					#include <beginnormal_vertex>
					#include <morphnormal_vertex>
					#include <skinbase_vertex>
					#include <skinnormal_vertex>
					#include <defaultnormal_vertex>
					#include <begin_vertex>
					// position instanced
					// transformed *= iScale;
					// transformed = transformed + iOffset;

					mat4 aInstanceMatrix = mat4(
						aInstanceMatrix0,
						aInstanceMatrix1,
						aInstanceMatrix2,
						aInstanceMatrix3
					);
					transformed = (aInstanceMatrix * vec4( position , 1. )).xyz;
					transformed *= iScale;
					transformed *= iMisfitFilter;
					transformed *= iConfidenceFilter;

					//vec3 positionEye = ( modelViewMatrix * aInstanceMatrix * vec4( position, 1.0 ) ).xyz;
					//gl_Position = projectionMatrix * vec4( positionEye, 1.0 );

					#include <morphtarget_vertex>
					#include <skinning_vertex>
					#include <project_vertex>
					#include <logdepthbuf_vertex>
					#include <clipping_planes_vertex>
					#include <worldpos_vertex>
					#include <envmap_vertex>
					#include <lights_lambert_vertex>
					// #include <shadowmap_vertex>
					// #include <fog_vertex>
				}
				`;


            var materialShader = shader;
        };

        var momentTensorCount = momentTensor.length;

        var vertexShader = [
            "precision highp float;",
            "",
            "uniform mat4 modelViewMatrix;",
            "uniform mat4 projectionMatrix;",
            "",
            "attribute vec3 position;",
            "attribute vec3 iOffset;",
            "attribute vec3 iColor;",
            "varying vec3 vColor;",

            "attribute vec3 iScale;",
            "attribute vec3 iMisfitFilter;",
            "attribute vec3 iConfidenceFilter;",

            "varying vec3 Scale;",
            "varying vec3 MisfitFilter;",
            "varying vec3 ConfidenceFilter;",

            "",
            "void main() {",
            "",
            "	gl_Position = projectionMatrix * modelViewMatrix * vec4( position + iOffset, 1.0 );",
            "	vColor = iColor;",
            "	Scale = iScale;",
            "	MisfitFilter = iMisfitFilter;",
            "	ConfidenceFilter = iConfidenceFilter;",
            "",
            "}"
        ].join("\n");

        var fragmentShader = [
            "precision highp float;",
            "varying vec3 vColor;",
            "varying vec3 Scale;",
            "varying vec3 MisfitFilter;",
            "varying vec3 ConfidenceFilter;",
            "",
            "void main() {",
            "",
            "	if (Scale == vec3(0.0,0.0,0.0)) discard;",
            "	if (MisfitFilter == vec3(0.0,0.0,0.0)) discard;",
            "	if (ConfidenceFilter == vec3(0.0,0.0,0.0)) discard;",
            "	gl_FragColor = vec4(vColor, 1.0);",
            "",
            "}"
        ].join("\n");

        var momentTensorMaterialPick = new THREE.RawShaderMaterial({
            uniforms: {},
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            side: THREE.DoubleSide,
            transparent: false
        });

        var e1 = new THREE.Vector3(1, 0, 0);
        var e2 = new THREE.Vector3(0, 1, 0);
        var e3 = new THREE.Vector3(0, 0, 1);

        // Get the colors & offsets in an array first;

        var offsets = [],
            iScale = [],
            iFilter = [];

        for (var i = 0; i < momentTensorCount; i++) {
            offsets.push((DOWNSAMPLE * momentTensor[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                (DOWNSAMPLE * momentTensor[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                (DOWNSAMPLE * momentTensor[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));
            iScale.push(0, 0, 0);
            iFilter.push(1, 1, 1);
        }

        for (var count = 0; count < 4; count++) {

            var iColors = [];

            momentTensorMatArraySize = 4 * momentTensorCount;
            momentTensorMatrixArray = [
                new Float32Array(momentTensorMatArraySize),
                new Float32Array(momentTensorMatArraySize),
                new Float32Array(momentTensorMatArraySize),
                new Float32Array(momentTensorMatArraySize),
            ];

            for (var i = 0; i < momentTensorCount; i++) {

                momentTensorMatrix = new THREE.Matrix4();
                momentTensorTempColor = new THREE.Color(momentTensor[i].color).convertSRGBToLinear();

                if (count == 1 || count == 3) {
                    momentTensorTempColor = new THREE.Color(0xffffff).convertSRGBToLinear();
                }

                iColors.push(momentTensorTempColor.r, momentTensorTempColor.g, momentTensorTempColor.b);

                momentTensorPosition = new THREE.Vector3(
                    (DOWNSAMPLE * momentTensor[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    (DOWNSAMPLE * momentTensor[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    (DOWNSAMPLE * momentTensor[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                );

                momentTensorScale = new THREE.Vector3(1, 1, 1);
                momentTensorQuaternion = new THREE.Quaternion();

                q0 = new THREE.Quaternion();
                q0.setFromAxisAngle(e1, -90 * Math.PI / 180);
                momentTensorQuaternion.multiply(q0);

                // Define rotation into strike direction
                q1 = new THREE.Quaternion();
                q1.setFromAxisAngle(e3, (-momentTensor[i].strike) * Math.PI / 180);

                //rotate original coordinate into strike direction to get new coordinate system
                e1a = e1.clone();
                e1a.applyQuaternion(q1);
                e2a = e2.clone();
                e2a.applyQuaternion(q1);
                e3a = e3.clone();
                e3a.applyQuaternion(q1);
                q2 = new THREE.Quaternion();
                e2a.normalize();
                q2.setFromAxisAngle(e2a, -momentTensor[i].dip * Math.PI / 180);

                var e1b = e1a.clone();
                e1b.applyQuaternion(q2);
                var e2b = e2a.clone();
                e2b.applyQuaternion(q2);
                var e3b = e3a.clone();
                e3b.applyQuaternion(q2);

                var q3 = new THREE.Quaternion();
                var e1bxe2b_plane = new THREE.Vector3().crossVectors(e1b, e2b);
                e1bxe2b_plane.normalize();

                q3.setFromAxisAngle(e1bxe2b_plane, momentTensor[i].slip * Math.PI / 180);

                var e1c = e1b.clone();
                e1c.applyQuaternion(q3);
                var e2c = e2b.clone();
                e2c.applyQuaternion(q3);
                var e3c = e3b.clone();
                e3c.applyQuaternion(q3);

                momentTensorQuaternion.multiply(q3);
                momentTensorQuaternion.multiply(q2);
                momentTensorQuaternion.multiply(q1);

                momentTensorMatrix.compose(momentTensorPosition, momentTensorQuaternion, momentTensorScale);

                for (r = 0; r < 4; r++) {
                    for (c = 0; c < 4; c++) {
                        momentTensorMatrixArray[r][4 * i + c] = momentTensorMatrix.elements[r * 4 + c];
                    }
                }
            }

            if (count == 0) {
                boxGeometry = new THREE.SphereBufferGeometry(1, 8, 8, 0, Math.PI / 2);
            }
            if (count == 1) {
                boxGeometry = new THREE.SphereBufferGeometry(1, 8, 8, -Math.PI / 2, Math.PI / 2);
            }
            if (count == 2) {
                boxGeometry = new THREE.SphereBufferGeometry(1, 8, 8, Math.PI, Math.PI / 2);
            }
            if (count == 3) {
                boxGeometry = new THREE.SphereBufferGeometry(1, 8, 8, Math.PI / 2, Math.PI / 2);
            }

            boxGeometry.frustumCulled = false;
            momentTensorGeomDrawn[count] = new THREE.InstancedBufferGeometry();
            momentTensorGeomDrawn[count].instanceCount = momentTensorCount;
            momentTensorGeomDrawn[count].index = boxGeometry.index;
            momentTensorGeomDrawn[count].attributes = boxGeometry.attributes;
            momentTensorGeomDrawn[count].setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
            momentTensorGeomDrawn[count].setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
            momentTensorGeomDrawn[count].setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
            momentTensorGeomDrawn[count].setAttribute('iConfidenceFilter', new THREE.InstancedBufferAttribute(new Float32Array(iFilter), 3));
            momentTensorGeomDrawn[count].setAttribute('iMisfitFilter', new THREE.InstancedBufferAttribute(new Float32Array(iFilter), 3));

            for (let i = 0; i < momentTensorMatrixArray.length; i++) {
                momentTensorGeomDrawn[count].setAttribute(
                    `aInstanceMatrix${i}`,
                    new THREE.InstancedBufferAttribute(momentTensorMatrixArray[i], 4)
                );
            }

            momentTensorMesh[count] = new THREE.Mesh(momentTensorGeomDrawn[count], momentTensorMaterial);
            momentTensorMesh[count].frustumCulled = false;
            scene.add(momentTensorMesh[count]);

        }

        offsets = [], iColors = [], iScale = [];

        for (var i = 0; i < momentTensorCount; i++) {

            offsets.push((DOWNSAMPLE * momentTensor[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                (DOWNSAMPLE * momentTensor[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                (DOWNSAMPLE * momentTensor[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));

            eventTempColor = new THREE.Color(0xeeeeee).convertSRGBToLinear();
            eventTempColor.setHex(pickingID);
            iColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);

            pickingData[pickingID] = {
                name: "<b>Moment Tensor</b> - " + momentTensor[i].name.toString() +
                    "<br>Easting: " + Math.round(momentTensor[i].x).toString() +
                    " ft<br>Northing: " + Math.round(momentTensor[i].z).toString() +
                    " ft<br>Depth (Subsea): " + Math.round(momentTensor[i].y).toString() + " ft" +
                    "<br>Confidence: " + Math.round(momentTensor[i].confidence * 100) / 100 +
                    "<br>Condition No.: " + Math.round(momentTensor[i].condition * 100) / 100 +
                    "<br>Misfit.: " + Math.round(momentTensor[i].misfit * 100) / 100,
                position: new THREE.Vector3(Math.round(momentTensor[i].x), Math.round(momentTensor[i].z), Math.round(momentTensor[i].y))



            };
            pickingID = pickingID + 1;

            iScale.push(1, 1, 1);

        }

        boxGeometry = new THREE.SphereBufferGeometry(1, 3, 2);
        momentTensorGeomPicking = new THREE.InstancedBufferGeometry();
        momentTensorGeomPicking.instanceCount = momentTensorCount;;
        momentTensorGeomPicking.index = boxGeometry.index;
        momentTensorGeomPicking.attributes = boxGeometry.attributes;
        momentTensorGeomPicking.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
        momentTensorGeomPicking.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
        momentTensorGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
        momentTensorGeomPicking.setAttribute('iConfidenceFilter', new THREE.InstancedBufferAttribute(new Float32Array(iFilter), 3));
        momentTensorGeomPicking.setAttribute('iMisfitFilter', new THREE.InstancedBufferAttribute(new Float32Array(iFilter), 3));

        momentTensorMeshPick = new THREE.Mesh(momentTensorGeomPicking, momentTensorMaterialPick);
        momentTensorMeshPick.frustumCulled = false;
        pickingScene.add(momentTensorMeshPick);

        updateStatus("Plotted Moment Tensors");

    }

    /////////////////////////////////
    // MODIFIED MOMENT TENSOR END ///
    /////////////////////////////////


    /////////////////////////////////
    // MODIFIED SOURCE TENSOR 	/////
    /////////////////////////////////

    if (sourceTensor.length > 0) {


        sourceTensorMaterial = new THREE.MeshLambertMaterial({
            map: new THREE.TextureLoader().load('https://borehole-seismic-biz.s3.amazonaws.com/texture.jpg'),
            // map : new THREE.TextureLoader().load( 'https://borehole-seismic-biz.s3.amazonaws.com/receiver.jpg' ),
            vertexColors: THREE.VertexColors,
            emissive: 0x2a2a2a,
            // emissiveIntensity: 1,
            side: THREE.DoubleSide
                // side: THREE.DoubleSide
        });


        sourceTensorMaterial.onBeforeCompile = function(shader) {
            shader.vertexShader =
                `
				#define LAMBERT
				// instanced
				attribute vec3 iOffset;
				attribute vec3 iColor;
				attribute vec3 iScale;
				attribute vec3 iConfidenceFilter;
				attribute vec3 iMisfitFilter;
				attribute vec3 iSizeVariation;
				attribute vec3 timelapse;

				varying vec3 vLightFront;
				varying vec3 vIndirectFront;
				attribute vec4 aInstanceMatrix0;
				attribute vec4 aInstanceMatrix1;
				attribute vec4 aInstanceMatrix2;
				attribute vec4 aInstanceMatrix3;


				#ifdef DOUBLE_SIDED
					varying vec3 vLightBack;
					varying vec3 vIndirectBack;
				#endif
				#include <common>
				#include <uv_pars_vertex>
				#include <uv2_pars_vertex>
				#include <envmap_pars_vertex>
				#include <bsdfs>
				#include <lights_pars_begin>
				#include <color_pars_vertex>
				#include <fog_pars_vertex>
				#include <morphtarget_pars_vertex>
				#include <skinning_pars_vertex>
				#include <shadowmap_pars_vertex>
				#include <logdepthbuf_pars_vertex>
				#include <clipping_planes_pars_vertex>
				void main() {
					#include <uv_vertex>
					#include <uv2_vertex>
					#include <color_vertex>
					#ifdef USE_COLOR
						vColor.xyz = iColor.xyz;
					#endif
					#include <beginnormal_vertex>
					#include <morphnormal_vertex>
					#include <skinbase_vertex>
					#include <skinnormal_vertex>
					#include <defaultnormal_vertex>
					#include <begin_vertex>
					// position instanced
					// transformed *= iScale;
					// transformed = transformed + iOffset;

					mat4 aInstanceMatrix = mat4(
						aInstanceMatrix0,
						aInstanceMatrix1,
						aInstanceMatrix2,
						aInstanceMatrix3
					);
					transformed = (aInstanceMatrix * vec4( iSizeVariation*position , 1. )).xyz;
					transformed *= timelapse;
					transformed *= iScale;
					transformed *= iMisfitFilter;
					transformed *= iConfidenceFilter;

					//vec3 positionEye = ( modelViewMatrix * aInstanceMatrix * vec4( position, 1.0 ) ).xyz;
					//gl_Position = projectionMatrix * vec4( positionEye, 1.0 );

					#include <morphtarget_vertex>
					#include <skinning_vertex>
					#include <project_vertex>
					#include <logdepthbuf_vertex>
					#include <clipping_planes_vertex>
					#include <worldpos_vertex>
					#include <lights_lambert_vertex>

					vLightFront = vec3( 1.5 );
					#ifdef DOUBLE_SIDED
						vLightBack = vec3( 1.5 );
					#endif

					#include <shadowmap_vertex>
					// #include <fog_vertex>
				}
				`;


            var materialShader = shader;
        };


        var sourceTensorCount = sourceTensor.length;

        var vertexShader = [
            "precision highp float;",
            "",
            "uniform mat4 modelViewMatrix;",
            "uniform mat4 projectionMatrix;",
            "",
            "attribute vec3 position;",
            "attribute vec3 iOffset;",
            "attribute vec3 iColor;",

            "attribute vec3 iScale;",
            "attribute vec3 iMisfitFilter;",
            "attribute vec3 iConfidenceFilter;",
            "attribute vec3 iSizeVariation;",
            "attribute vec3 timelapse;",

            "varying vec3 Scale;",
            "varying vec3 MisfitFilter;",
            "varying vec3 ConfidenceFilter;",
            "varying vec3 itimelapse;",

            "varying vec3 vColor;",
            "",
            "void main() {",
            "",
            "	gl_Position = projectionMatrix * modelViewMatrix * vec4( iSizeVariation*position + iOffset, 1.0 );",
            "	vColor = iColor;",
            "	Scale = iScale;",
            "	MisfitFilter = iMisfitFilter;",
            "	itimelapse = timelapse;",
            "	ConfidenceFilter = iConfidenceFilter;",
            "",
            "}"
        ].join("\n");

        var fragmentShader = [
            "precision highp float;",
            "varying vec3 vColor;",
            "varying vec3 Scale;",
            "varying vec3 MisfitFilter;",
            "varying vec3 ConfidenceFilter;",
            "varying vec3 itimelapse;",
            "",
            "void main() {",
            "",
            "	if (itimelapse == vec3(0.0,0.0,0.0)) discard;",
            "	if (Scale == vec3(0.0,0.0,0.0)) discard;",
            "	if (MisfitFilter == vec3(0.0,0.0,0.0)) discard;",
            "	if (ConfidenceFilter == vec3(0.0,0.0,0.0)) discard;",
            "	gl_FragColor = vec4(vColor, 1.0);",
            "",
            "}"
        ].join("\n");

        var sourceTensorMaterialPick = new THREE.RawShaderMaterial({
            uniforms: {},
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            side: THREE.DoubleSide,
            transparent: false
        });

        var e1 = new THREE.Vector3(1, 0, 0);
        var e2 = new THREE.Vector3(0, 1, 0);
        var e3 = new THREE.Vector3(0, 0, 1);

        // Get the colors & offsets in an array first;

        var offsets = [],
            iScale = [];
        var iFilter = [];

        for (var i = 0; i < sourceTensorCount; i++) {
            offsets.push((DOWNSAMPLE * sourceTensor[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                (DOWNSAMPLE * sourceTensor[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                (DOWNSAMPLE * sourceTensor[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));
            iScale.push(0, 0, 0);
            iFilter.push(1, 1, 1);
        }

        for (var count = 0; count < 4; count++) {

            var iColors = [];

            var sourceTensorMatArraySize = 4 * sourceTensorCount;
            var sourceTensorMatrixArray = [
                new Float32Array(sourceTensorMatArraySize),
                new Float32Array(sourceTensorMatArraySize),
                new Float32Array(sourceTensorMatArraySize),
                new Float32Array(sourceTensorMatArraySize),
            ];

            for (var i = 0; i < sourceTensorCount; i++) {

                var sourceTensorMatrix = new THREE.Matrix4();
                var sourceTensorTempColor = new THREE.Color(sourceTensor[i].color).convertSRGBToLinear();

                if (count == 1 || count == 3) {
                    sourceTensorTempColor = new THREE.Color(0xffffff).convertSRGBToLinear();
                }

                iColors.push(sourceTensorTempColor.r, sourceTensorTempColor.g, sourceTensorTempColor.b);

                var sourceTensorPosition = new THREE.Vector3(
                    (DOWNSAMPLE * sourceTensor[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    (DOWNSAMPLE * sourceTensor[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    (DOWNSAMPLE * sourceTensor[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                );

                var sourceTensorScale = new THREE.Vector3(1, 1, 1);
                var sourceTensorQuaternion = new THREE.Quaternion();

                var q0 = new THREE.Quaternion();
                q0.setFromAxisAngle(e1, -90 * Math.PI / 180);
                sourceTensorQuaternion.multiply(q0);

                // Define rotation into strike direction
                var q1 = new THREE.Quaternion();
                q1.setFromAxisAngle(e3, (-sourceTensor[i].strike) * Math.PI / 180);

                //rotate original coordinate into strike direction to get new coordinate system
                var e1a = e1.clone();
                e1a.applyQuaternion(q1);
                var e2a = e2.clone();
                e2a.applyQuaternion(q1);
                var e3a = e3.clone();
                e3a.applyQuaternion(q1);
                var q2 = new THREE.Quaternion();
                e2a.normalize();
                q2.setFromAxisAngle(e1a, -sourceTensor[i].dip * Math.PI / 180);

                var e1b = e1a.clone();
                e1b.applyQuaternion(q2);
                var e2b = e2a.clone();
                e2b.applyQuaternion(q2);
                var e3b = e3a.clone();
                e3b.applyQuaternion(q2);

                var q3 = new THREE.Quaternion();
                var e1bxe2b_plane = new THREE.Vector3().crossVectors(e1b, e2b);
                e1bxe2b_plane.normalize();

                q3.setFromAxisAngle(e1bxe2b_plane, sourceTensor[i].slip * Math.PI / 180);

                var e1c = e1b.clone();
                e1c.applyQuaternion(q3);
                var e2c = e2b.clone();
                e2c.applyQuaternion(q3);
                var e3c = e3b.clone();
                e3c.applyQuaternion(q3);

                sourceTensorQuaternion.multiply(q3);
                sourceTensorQuaternion.multiply(q2);
                sourceTensorQuaternion.multiply(q1);

                sourceTensorMatrix.compose(sourceTensorPosition, sourceTensorQuaternion, sourceTensorScale);

                for (var r = 0; r < 4; r++) {
                    for (var c = 0; c < 4; c++) {
                        sourceTensorMatrixArray[r][4 * i + c] = sourceTensorMatrix.elements[r * 4 + c];
                    }
                }
            }

            if (count == 0) {
                var boxGeometry = new THREE.SphereBufferGeometry(1, 8, 8, 0, Math.PI / 2);
            }
            if (count == 1) {
                var boxGeometry = new THREE.SphereBufferGeometry(1, 8, 8, -Math.PI / 2, Math.PI / 2);
            }
            if (count == 2) {
                var boxGeometry = new THREE.SphereBufferGeometry(1, 8, 8, Math.PI, Math.PI / 2);
            }
            if (count == 3) {
                var boxGeometry = new THREE.SphereBufferGeometry(1, 8, 8, Math.PI / 2, Math.PI / 2);
            }

            boxGeometry.frustumCulled = false;
            sourceTensorGeomDrawn[count] = new THREE.InstancedBufferGeometry();
            sourceTensorGeomDrawn[count].instanceCount = sourceTensorCount;
            sourceTensorGeomDrawn[count].index = boxGeometry.index;
            sourceTensorGeomDrawn[count].attributes = boxGeometry.attributes;
            sourceTensorGeomDrawn[count].setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
            sourceTensorGeomDrawn[count].setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
            sourceTensorGeomDrawn[count].setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
            sourceTensorGeomDrawn[count].setAttribute('iConfidenceFilter', new THREE.InstancedBufferAttribute(new Float32Array(iFilter), 3));
            sourceTensorGeomDrawn[count].setAttribute('iMisfitFilter', new THREE.InstancedBufferAttribute(new Float32Array(iFilter), 3));
            sourceTensorGeomDrawn[count].setAttribute('iSizeVariation', new THREE.InstancedBufferAttribute(new Float32Array(iFilter), 3));
            sourceTensorGeomDrawn[count].setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(iFilter), 3));


            for (let i = 0; i < sourceTensorMatrixArray.length; i++) {
                sourceTensorGeomDrawn[count].setAttribute(
                    `aInstanceMatrix${i}`,
                    new THREE.InstancedBufferAttribute(sourceTensorMatrixArray[i], 4)
                );
            }

            sourceTensorMesh[count] = new THREE.Mesh(sourceTensorGeomDrawn[count], sourceTensorMaterial);
            sourceTensorMesh[count].frustumCulled = false;
            scene.add(sourceTensorMesh[count]);

        }

        var offsets = [],
            iColors = [],
            iScale = [];

        for (var i = 0; i < sourceTensorCount; i++) {

            offsets.push((DOWNSAMPLE * sourceTensor[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                (DOWNSAMPLE * sourceTensor[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                (DOWNSAMPLE * sourceTensor[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));

            eventTempColor = new THREE.Color(0xeeeeee).convertSRGBToLinear();
            eventTempColor.setHex(pickingID);
            iColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);

            pickingData[pickingID] = {
                name: "<b>Source Tensor</b> - " + sourceTensor[i].name.toString() +
                    "<br>Easting: " + Math.round(sourceTensor[i].x).toString() +
                    " ft<br>Northing: " + Math.round(sourceTensor[i].z).toString() +
                    " ft<br>Depth (Subsea): " + Math.round(sourceTensor[i].y).toString() + " ft" +
                    "<br>Confidence: " + Math.round(sourceTensor[i].confidence * 100) / 100 +
                    "<br>Condition No.: " + Math.round(sourceTensor[i].condition * 100) / 100 +
                    "<br>Strike: " + Math.round(sourceTensor[i].strike * 100) / 100 +
                    "<br>Dip: " + Math.round(sourceTensor[i].dip * 100) / 100 +
                    "<br>Slip: " + Math.round(sourceTensor[i].slip * 100) / 100 +
                    "<br>Misfit.: " + Math.round(sourceTensor[i].misfit * 100) / 100,
                position: new THREE.Vector3((sourceTensor[i].x), (sourceTensor[i].z), (sourceTensor[i].y)),
                azimuth: Math.round(sourceTensor[i].strike * 100) / 100,
            };

            if (sourceTensor[i].hasOwnProperty("fractureArea")) {
                pickingData[pickingID].name = pickingData[pickingID].name +
                    "<br>Fracture Area: " + Math.round(sourceTensor[i].fractureArea) + " sq ft";
            }

            pickingID = pickingID + 1;

            iScale.push(0, 0, 0);

        }

        var boxGeometry = new THREE.SphereBufferGeometry(1, 3, 2);
        sourceTensorGeomPicking = new THREE.InstancedBufferGeometry();
        sourceTensorGeomPicking.instanceCount = sourceTensorCount;;
        sourceTensorGeomPicking.index = boxGeometry.index;
        sourceTensorGeomPicking.attributes = boxGeometry.attributes;
        sourceTensorGeomPicking.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
        sourceTensorGeomPicking.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
        sourceTensorGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
        sourceTensorGeomPicking.setAttribute('iConfidenceFilter', new THREE.InstancedBufferAttribute(new Float32Array(iFilter), 3));
        sourceTensorGeomPicking.setAttribute('iMisfitFilter', new THREE.InstancedBufferAttribute(new Float32Array(iFilter), 3));
        sourceTensorGeomPicking.setAttribute('iSizeVariation', new THREE.InstancedBufferAttribute(new Float32Array(iFilter), 3));
        sourceTensorGeomPicking.setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(iFilter), 3));


        sourceTensorMeshPick = new THREE.Mesh(sourceTensorGeomPicking, sourceTensorMaterialPick);
        sourceTensorMeshPick.frustumCulled = false;
        pickingScene.add(sourceTensorMeshPick);

        updateStatus("Plotted Source Tensors");


    }

    /////////////////////////////////
    // MODIFIED SOURCE TENSOR END ///
    /////////////////////////////////


    /////////////////////////////////
    ///// FORMATION TOPS START //////
    /////////////////////////////////

    // console.log("formationTops", formationTops);
    //assign different positions to the points
    for (var i = 0; i < formationTops.length; i++) {

        var formationTop = formationTops[i];

        var formationLut = new Lut();

        var vertices = new Float32Array(4 * 3);
        var size = new Float32Array(4);
        var opacity = new Float32Array(4);

        // Z is Easting
        var ref_z = DOWNSAMPLE * formationTop['reference_x'] - bk_z_min - 0.5 * (bk_z_max - bk_z_min);

        // X is Northing
        var ref_x = DOWNSAMPLE * formationTop['reference_y'] - bk_x_min - 0.5 * (bk_x_max - bk_x_min);

        var points = [];
        var colorAttr = new Float32Array(4 * 3);
        for (var k = 0; k < 4; k++) {

            size[k] = 3.0;
            opacity[k] = formationOpacity;

            if (k == 0) {
                vertices[3 * k] = x_min;
                vertices[3 * k + 1] = getRelativeDepth(DOWNSAMPLE * formationTops[i].depth - bk_y_min - 0.5 * (bk_y_max - bk_y_min), ref_z, z_min, ref_x, x_min, formationTop.azimuth, formationTop.inclination);
                vertices[3 * k + 2] = z_min;
            }

            if (k == 1) {
                vertices[3 * k] = x_max;
                // vertices[3 * k + 1] = DOWNSAMPLE * formationTops[i].depth - bk_y_min - 0.5 * (bk_y_max - bk_y_min) - 0.5;
                vertices[3 * k + 1] = getRelativeDepth(DOWNSAMPLE * formationTops[i].depth - bk_y_min - 0.5 * (bk_y_max - bk_y_min), ref_z, z_min, ref_x, x_max, formationTop.azimuth, formationTop.inclination);
                vertices[3 * k + 2] = z_min;
            }

            if (k == 2) {
                vertices[3 * k] = x_min;
                // vertices[3 * k + 1] = DOWNSAMPLE * formationTops[i].depth - bk_y_min - 0.5 * (bk_y_max - bk_y_min) + 0.5;
                vertices[3 * k + 1] = getRelativeDepth(DOWNSAMPLE * formationTops[i].depth - bk_y_min - 0.5 * (bk_y_max - bk_y_min), ref_z, z_max, ref_x, x_min, formationTop.azimuth, formationTop.inclination);
                vertices[3 * k + 2] = z_max;
            }

            if (k == 3) {
                vertices[3 * k] = x_max;
                // vertices[3 * k + 1] = DOWNSAMPLE * formationTops[i].depth - bk_y_min - 0.5 * (bk_y_max - bk_y_min) + 0.5;
                vertices[3 * k + 1] = getRelativeDepth(DOWNSAMPLE * formationTops[i].depth - bk_y_min - 0.5 * (bk_y_max - bk_y_min), ref_z, z_max, ref_x, x_max, formationTop.azimuth, formationTop.inclination);
                vertices[3 * k + 2] = z_max;
            }

            points.push(new THREE.Vector3(vertices[3 * k], vertices[3 * k + 1], vertices[3 * k + 2]));

            var formColor = new THREE.Color(getColor(i)).convertSRGBToLinear();
            colorAttr[3 * k] = (formColor.r);
            colorAttr[3 * k + 1] = (formColor.g);
            colorAttr[3 * k + 2] = (formColor.b);

        }


        var shaderMaterial = new THREE.ShaderMaterial({
            transparent: true,
            side: THREE.DoubleSide,
            vertexShader: document.getElementById('formation-plane-vertexshader').textContent,
            fragmentShader: document.getElementById('formation-plane-fragmentshader').textContent,
        });



        var geomDelaunator = new THREE.BufferGeometry().setFromPoints(points);
        geomDelaunator.dynamic = true;

        // triangulate x, z
        // console.log("Points:", points);
        var indexDelaunay = Delaunator.from(
            points.map(v => {
                return [v.z, v.x];
            })
        );

        var meshIndex = []; // delaunay index => three.js index
        for (let a = 0; a < indexDelaunay.triangles.length; a++) {
            meshIndex.push(indexDelaunay.triangles[a]);
        }
        geomDelaunator.setIndex(meshIndex); // add three.js index to the existing geometry
        geomDelaunator.computeVertexNormals();
        geomDelaunator.computeBoundingBox();
        geomDelaunator.dynamic = true;
        geomDelaunator.setAttribute('colorAttr', new THREE.BufferAttribute(colorAttr, 3));
        geomDelaunator.setAttribute('vSize', new THREE.BufferAttribute(size, 1));
        geomDelaunator.setAttribute('opacity', new THREE.BufferAttribute(opacity, 1));


        formationTopGeomPicking[i] = new THREE.Mesh(
            geomDelaunator, // re-use the existing geometry
            shaderMaterial
        );
        formationTopGeomPicking[i].frustumCulled = false;
        formationTopGeomPicking[i].name = formationTop.name;
        formationTopGeomPicking[i].visible = false;
        scene.add(formationTopGeomPicking[i]);

        // Formation top lines!
        var curvePointsArray = [];
        curvePointsArray.push(x_min);
        curvePointsArray.push(getRelativeDepth(DOWNSAMPLE * formationTops[i].depth - bk_y_min - 0.5 * (bk_y_max - bk_y_min), ref_z, ref_z, ref_x, x_min, formationTop.azimuth, formationTop.inclination));
        curvePointsArray.push(0);

        curvePointsArray.push(x_max);
        curvePointsArray.push(getRelativeDepth(DOWNSAMPLE * formationTops[i].depth - bk_y_min - 0.5 * (bk_y_max - bk_y_min), ref_z, ref_z, ref_x, x_max, formationTop.azimuth, formationTop.inclination));
        curvePointsArray.push(0);

        var azimuth = 0; //dir2.angleTo(dir1);
        if (trtWell.length > 0) {
            var vector1 = new THREE.Vector3(trtWell[0].x[trtWell[0].x.length - 5], 0, trtWell[0].z[trtWell[0].z.length - 5]);
            var vector2 = new THREE.Vector3(trtWell[0].x[trtWell[0].x.length - 10], 0, trtWell[0].z[trtWell[0].z.length - 10]);
            var dir1 = new THREE.Vector3();

            if (trtWell[0].x[trtWell[0].x.length - 5] < trtWell[0].x[trtWell[0].x.length - 10]) {
                dir1.subVectors(vector2, vector1)
                var dir2 = new THREE.Vector3(1, 0, 0);
                azimuth = dir2.angleTo(dir1);
            } else {
                dir1.subVectors(vector1, vector2)
                var dir2 = new THREE.Vector3(1, 0, 0);
                azimuth = dir2.angleTo(dir1);
            }


        }

        console.log("Azimuth", azimuth);

        var tempGeom = new LineSegmentsGeometry().setPositions(curvePointsArray);
        var tempMaterial = new LineMaterial({ color: formColor.getStyle(), linewidth: 1 });
        tempMaterial.resolution.set(parentContainer.width(), parentContainer.height()); // important, for now
        var tempMesh = new LineSegments2(tempGeom, tempMaterial);
        tempMesh.name = formationTop.name + "Line - Side View";
        tempMesh.visible = false;
        tempMesh.rotateOnAxis(new THREE.Vector3(0, 1, 0), azimuth);
        scene.add(tempMesh);

        // Formation top lines!
        var curvePointsArray = [];
        curvePointsArray.push(0);
        curvePointsArray.push(getRelativeDepth(DOWNSAMPLE * formationTops[i].depth - bk_y_min - 0.5 * (bk_y_max - bk_y_min), ref_z, z_min, ref_x, ref_x, formationTop.azimuth, formationTop.inclination));
        curvePointsArray.push(z_min);
        curvePointsArray.push(0);
        curvePointsArray.push(getRelativeDepth(DOWNSAMPLE * formationTops[i].depth - bk_y_min - 0.5 * (bk_y_max - bk_y_min), ref_z, z_max, ref_x, ref_x, formationTop.azimuth, formationTop.inclination));
        curvePointsArray.push(z_max);
        var tempGeom = new LineSegmentsGeometry().setPositions(curvePointsArray);
        var tempMaterial = new LineMaterial({ color: formColor.getStyle(), linewidth: 1 });
        tempMaterial.resolution.set(parentContainer.width(), parentContainer.height()); // important, for now
        var tempMesh = new LineSegments2(tempGeom, tempMaterial);
        tempMesh.name = formationTop.name + "Line - Barrel View";
        tempMesh.visible = false;
        tempMesh.rotateOnAxis(new THREE.Vector3(0, 1, 0), azimuth);
        scene.add(tempMesh);



    }

    /////////////////////////////////
    ///// FORMATION TOPS END   //////
    /////////////////////////////////

    /////////////////////////////////
    // FORMATION TOPS POINTS START //
    /////////////////////////////////

    //assign different positions to the points
    for (j = 0; j < formationTopsPoints.length; j++) {

        var formationTop = formationTopsPoints[j];
        var formationLut = new Lut();
        var vertices = new Float32Array(formationTop.x.length * 3);
        var size = new Float32Array(formationTop.x.length);
        var opacity = new Float32Array(formationTop.y.length);

        var points = [];

        for (k = 0; k < formationTop.x.length; k++) {
            size[k] = 2.0;
            opacity[k] = formationOpacity;
            vertices[3 * k] = (DOWNSAMPLE * formationTop.x[k] - bk_x_min - 0.5 * (bk_x_max - bk_x_min));
            vertices[3 * k + 1] = (DOWNSAMPLE * formationTop.y[k] - bk_y_min - 0.5 * (bk_y_max - bk_y_min));
            vertices[3 * k + 2] = (DOWNSAMPLE * formationTop.z[k] - bk_z_min - 0.5 * (bk_z_max - bk_z_min));

            points.push(new THREE.Vector3(vertices[3 * k], vertices[3 * k + 1], vertices[3 * k + 2]));
        }

        if (formationTop.formColor.length > 0) {

            var colorMin = Infinity; //formationTop.formColor[0];
            var colorMax = -Infinity; //formationTop.formColor[0];

            for (var i = 0; i < formationTop.formColor.length; i++) {
                if (!isNaN(formationTop.formColor[i])) {
                    colorMin = Math.min(colorMin, formationTop.formColor[i]);
                    colorMax = Math.max(colorMax, formationTop.formColor[i]);
                }
            }

            var colorAttr = new Float32Array(formationTop.formColor.length * 3);
            for (var i = 0; i < formationTop.formColor.length; i++) {
                var coef = (formationTop.formColor[i] - colorMin) / (colorMax - colorMin);
                if (isNaN(coef)) {
                    var formColor = formationLut.getColor(0);
                } else {
                    var formColor = formationLut.getColor(coef);
                }
                colorAttr[3 * i] = (formColor.r);
                colorAttr[3 * i + 1] = (formColor.g);
                colorAttr[3 * i + 2] = (formColor.b);
            }

        } else {

            var depthMin = Infinity; //formationTop.y[0];
            var depthMax = -Infinity; //formationTop.y[0];

            for (var i = 0; i < formationTop.y.length; i++) {
                if (!isNaN(formationTop.formColor[i])) {
                    depthMin = Math.min(depthMin, formationTop.y[i]);
                    depthMax = Math.max(depthMax, formationTop.y[i]);
                }
            }
            depthMin = 0.999999999 * depthMin;
            depthMax = 1.0001 * depthMax;

            var colorAttr = new Float32Array(formationTop.y.length * 3);
            for (var i = 0; i < formationTop.y.length; i++) {
                // colorAttr[i] = (formationTop.y[i] - depthMin)/(depthMax - depthMin);
                var coef = (formationTop.formColor[i] - depthMin) / (depthMax - depthMin);
                if (isNaN(coef)) {
                    var formColor = formationLut.getColor(0);
                } else {
                    var formColor = formationLut.getColor(coef);
                }
                colorAttr[3 * i] = (formColor.r);
                colorAttr[3 * i + 1] = (formColor.g);
                colorAttr[3 * i + 2] = (formColor.b);
            }

        }


        var shaderMaterial = new THREE.ShaderMaterial({
            transparent: true,
            side: THREE.DoubleSide,
            vertexShader: document.getElementById('fm-vertexshader').textContent,
            fragmentShader: document.getElementById('fm-fragmentshader').textContent,
        });


        if (true) {

            var geomDelaunator = new THREE.BufferGeometry().setFromPoints(points);
            geomDelaunator.dynamic = true;

            // triangulate x, z
            var indexDelaunay = Delaunator.from(
                points.map(v => {
                    return [v.x, v.z];
                })
            );

            var meshIndex = []; // delaunay index => three.js index
            for (let a = 0; a < indexDelaunay.triangles.length; a++) {
                meshIndex.push(indexDelaunay.triangles[a]);
            }
            geomDelaunator.setIndex(meshIndex); // add three.js index to the existing geometry
            geomDelaunator.computeVertexNormals();
            geomDelaunator.computeBoundingBox();
            geomDelaunator.setAttribute('colorAttr', new THREE.BufferAttribute(colorAttr, 3));
            geomDelaunator.setAttribute('vSize', new THREE.BufferAttribute(size, 1));
            geomDelaunator.setAttribute('opacity', new THREE.BufferAttribute(opacity, 1));

            geomDelaunator.dynamic = true;

            formationTopPointGeomDrawn[j] = new THREE.Mesh(
                geomDelaunator, // re-use the existing geometry
                shaderMaterial
            );
            formationTopPointGeomDrawn[j].frustumCulled = false;
            formationTopPointGeomDrawn[j].name = formationTop.name;
            formationTopPointGeomDrawn[j].visible = false;
            scene.add(formationTopPointGeomDrawn[j]);
        }

    }

    if (formationTops.length > 0 || formationTopsPoints.length > 0) {
        updateStatus("Plotted Formation Tops/Horizons.");
    }

    /////////////////////////////////
    /// FORMATION TOPS POINTS END ///
    /////////////////////////////////



    //////////////////////////////////////
    /////// FORMATION LABEL START ////////
    //////////////////////////////////////

    formLabelMaterial = [
        new THREE.MeshLambertMaterial({ color: 0xffffff, flatShading: true }), // front
        new THREE.MeshPhongMaterial({ color: 0xffffff }) // side
    ];

    for (var j = 0; j < formLabel.length; j++) {

        var textGeo = new THREE.TextGeometry(formLabel[j].name, { font: font, size: 4, height: 2, });
        textGeo.computeBoundingBox();
        textGeo.computeVertexNormals();

        var centerOffsetX = -0.5 * (textGeo.boundingBox.max.x - textGeo.boundingBox.min.x);
        var centerOffsetY = -1 * (textGeo.boundingBox.max.y - textGeo.boundingBox.min.y);
        var centerOffsetZ = -0.5 * (textGeo.boundingBox.max.z - textGeo.boundingBox.min.z);


        var formationTop = formationTops[j];
        var ref_y = DOWNSAMPLE * formationTop.depth - bk_y_min - 0.5 * (bk_y_max - bk_y_min);

        formLabelGeom[j] = new THREE.BufferGeometry().fromGeometry(textGeo);
        formLabelGeom[j].center();
        formLabelMesh[j] = new THREE.Mesh(formLabelGeom[j], formLabelMaterial);

        formLabelMesh[j].position.x = 0;
        formLabelMesh[j].position.y = ref_y + centerOffsetY;
        formLabelMesh[j].position.z = 0;

        formLabelMesh[j].name = "Form Label" + formLabel[j].name;
        formLabelMesh[j].visible = false;

        formLabelMesh[j].renderOrder = 999;
        formLabelMesh[j].onBeforeRender = function(renderer) { renderer.clearDepth(); };

        scene.add(formLabelMesh[j]);

    }

    if (formLabel.length > 0) {
        console.log("Plotted Formation Labels");
        updateStatus("Plotted Formation Labels");
    }

    //////////////////////////////////////
    //////// FORMATION LABEL END /////////
    //////////////////////////////////////

    // when the mouse moves, call the given function
    // document.addEventListener( 'mousemove', onDocumentMouseMove, false );
    // renderer.domElement.addEventListener( 'mousemove', onMouseMove );
    // renderer.domElement.addEventListener( 'click', onMouseClick );

    for (var n = 1; n <= 4; n++) {
        var subDiv = document.getElementById("container" + n.toString());
        subDiv.addEventListener('mousemove', onMouseMove);
        subDiv.addEventListener('click', onMouseClick);
    }

    /////////////////////////////////
    /////////// PUMPLOGS ////////////
    /////////////////////////////////

    if (pumpLogs.length > 0) {
        console.log('pumplogs', pumpLogs)
        PUMPLOG_SUBPLOTS = ~~(Object.keys(pumpLogs[0]).length / 5);


        // create empty object
        // for (var i = 1; i < 3 + 1; i = i + 1) {
        //     var suffix = 1;
        //     if (i > 1) {
        //         suffix = i;
        //     }
        //     console.log(eval('var pressure' + suffix + '= [];'));
        //     eval('var' + ' rate' + suffix + '= [];');
        //     eval('var' + ' density' + suffix + '= [];');
        //     eval('var' + ' time' + suffix + '= [];');
        //     console.log(rate + suffix)
        // }
        // console.log(rate2)

        //let user = ["user1", "user2", "user3", "user4", "user5"];
        // creating variable same as username and storing userid into that.
        // for (let user_id = 0; user_id < user.length; user_id++) {
        //     eval('var ' + user[user_id] + '= ' + user_id + ';');
        // }
        // console.log('user check', user1);

        // density = [],
        //     rate = [],
        //     time = [],
        //     pressure2 = [],
        //     density2 = [],
        //     rate2 = [],
        //     timeData = [];
        // console.log(suffix)
        // console.log(rate1)
        // console.log(pressure1)
        //}
        sampleCounter = Math.ceil(pumpLogs.length / PUMPLOG_SAMPLES);
        timeCounter = 0;
        var data = {
            time: [],
        };

        for (var j = 1; j <= PUMPLOG_SUBPLOTS; j++) {
            var suffix = (j > 1) ? j : '';
            data['pressure' + suffix] = [];
            data['rate' + suffix] = [];
            data['density' + suffix] = [];
            //data['well' + suffix] = [];
            data['stage' + suffix] = [];

        }
        for (var i = 0; i < pumpLogs.length; i = i + sampleCounter) {
            pumpLogsDrawnTime.push(new Date(pumpLogs[i].time));
            data['time'].push(new Date(pumpLogs[i].time));
            for (var j = 1; j <= PUMPLOG_SUBPLOTS; j++) {
                var suffix = (j > 1) ? j : ''; // use empty string for suffix 1
                // data['pressure' + suffix][i] = pumpLogs[i]['pressure' + suffix];
                // data['density' + suffix][i] = pumpLogs[i]['density' + suffix];
                // data['rate' + suffix][i] = pumpLogs[i]['rate' + suffix];
                data['pressure' + suffix].push(pumpLogs[i]['pressure' + suffix]);
                data['density' + suffix].push(pumpLogs[i]['density' + suffix]);
                data['rate' + suffix].push(pumpLogs[i]['rate' + suffix]);
                //data['well' + suffix].push(pumpLogs[i]['stage' + suffix]);
                data['stage' + suffix].push(pumpLogs[i]['well' + suffix] + ' Stage ' + pumpLogs[i]['stage' + suffix]);
            }
        }

        // console.log('Data5668', data);

        let time = [];
        let timeStep = (data['time'][data['time'].length - 1] - data['time'][0]) / PUMPLOG_SAMPLES;

        // Generate times with 5-minute intervals
        for (let x = data['time'][0].getTime(); x <= data['time'][data['time'].length - 1].getTime(); x += timeStep) {
            time.push(new Date(x));
        }

        // console.log('time', time);

        // for (var i = 0; i < pumpLogs.length; i = i + sampleCounter) {
        //     pumpLogsDrawnTime.push(new Date(pumpLogs[i].time));

        //     // time.push(new Date(pumpLogs[i].time).getTime());
        //     // timeCounter ++;
        //     //time.push(pumpLogs[i].time);
        //     data['time' + suffix] = pumpLogs[i].time;
        //     // timeData.push(new Date(pumpLogs[i].time).toLocaleString());
        //     for (var j = 1; j < PUMPLOG_SUBPLOTS + 1 + 1; j++) {
        //         var suffix = (j > 1) ? j : ''; // use empty string for suffix 1
        //         data['pressure' + suffix] = pumpLogs[i].pressure + suffix;
        //         data['rate' + suffix] = pumpLogs[i].density + suffix;
        //         data['density' + suffix] = pumpLogs[i].rate + suffix;

        //     }
        //     console.log(data)
        // pressure.push(pumpLogs[i].pressure);
        // density.push(pumpLogs[i].density);
        // rate.push(pumpLogs[i].rate);
        // // timeData.push(new Date(pumpLogs[i].time).toLocaleString());
        // pressure2.push(pumpLogs[i].pressure2);
        // density2.push(pumpLogs[i].density2);
        // rate2.push(pumpLogs[i].rate2);


        // Rounding off to 60 seconds;
        var coeff = 1000 * 60; // * (1/6);
        let pumpLogData = []
        eventTime = events.map(e => new Date(Math.round(e.time / coeff) * coeff).getTime());
        for (var j = 1; j <= PUMPLOG_SUBPLOTS; j++) {
            var suffix = (j > 1) ? j : '';
            pumpLogData.push({
                name: 'Pressure',
                type: "scattergl",
                mode: "line",
                marker: {
                    color: '#1f77b4',
                    line: {
                        width: 1,
                        color: '#1f77b4'
                    }
                },
                x: time,
                y: data['pressure' + suffix],
                // text: data['Stage' + suffix],
                xaxis: 'x' + suffix,
                yaxis: 'y' + suffix,
                text: data['stage' + suffix],
                // hoverinfo: "x+y",
                hovertemplate: '<b>%{x|%Y/%m/%d %I:%M %p}</b><br>%{text}<br>' + 'Pressure: %{y}<extra></extra>'

            }, {
                name: 'Density',
                type: "scattergl",
                mode: "line",
                marker: {
                    color: '#ff7f0e',
                    line: {
                        width: 1,
                        color: '#ff7f0e'
                    }
                },
                x: time,
                y: data['density' + suffix],
                yaxis: 'y' + (j + PUMPLOG_SUBPLOTS),
                xaxis: 'x' + suffix,
                hovertemplate: 'Density: %{y}<extra></extra>',
            }, {
                name: 'Rate',
                type: "scattergl",
                mode: "line",
                marker: {
                    color: '#2CA02C',
                    line: {
                        width: 1,
                        color: '#2CA02C'
                    }
                },
                x: time,
                y: data['rate' + suffix],
                yaxis: 'y' + (j + 2 * PUMPLOG_SUBPLOTS),
                xaxis: 'x' + suffix,
                hovertemplate: 'Rate: %{y}<extra></extra>',
            }, {

                name: "Events",
                type: "histogram",
                yaxis: 'y' + (j + 3 * PUMPLOG_SUBPLOTS),
                xaxis: 'x' + suffix,
                x: eventTime,
                autobinx: false,
                xbins: {
                    size: 60000,
                },
                marker: {
                    color: "#ff0000",
                    line: {
                        color: "#ff0000",
                        // width: 10,
                    },
                },
            });
        }
        // console.log(pumpLogData)

        // hovertemplate: '<b>Time</b>: %{x}' +
        // 		'<br><b>No Events:</b>: %{y}<br>',
        // hoverinfo: "x+y",
        // },
        // {
        //     name: 'Pressure2',
        //     type: "scattergl",
        //     mode: "line",
        //     marker: {
        //         color: '#1f77b4',
        //         line: {
        //             width: 1,
        //             color: '#1f77b4'
        //         }
        //     },
        //     x: time,
        //     y: pressure2,
        //     xaxis: 'x2',
        //     yaxis: 'y2',
        //     hoverinfo: "x+y",
        // },
        // {
        //     name: 'Density2',
        //     type: "scattergl",
        //     mode: "line",
        //     marker: {
        //         color: '#ff7f0e',
        //         line: {
        //             width: 1,
        //             color: '#ff7f0e'
        //         }
        //     },
        //     x: time,
        //     y: density2,
        //     yaxis: 'y4',
        //     xaxis: 'x2',
        //     hoverinfo: "x+y",
        // },
        // {
        //     name: 'Rate2',
        //     type: "scattergl",
        //     mode: "line",
        //     marker: {
        //         color: '#2CA02C',
        //         line: {
        //             width: 1,
        //             color: '#2CA02C'
        //         }
        //     },
        //     x: time,
        //     y: rate2,
        //     yaxis: 'y6',
        //     xaxis: 'x2',
        //     hoverinfo: "x+y",
        // } //,
        // {
        //     name: "Events",
        //     type: "histogram",
        //     yaxis: 'y8',
        //     xaxis: 'x2',
        //     x: eventTime,
        //     autobinx: false,
        //     xbins: {
        //         size: 60000,
        //     },
        //     marker: {
        //         color: "#ff0000",
        //         line: {
        //             color: "#ff0000",
        //             // width: 10,
        //         },
        //     },
        //     // hovertemplate: '<b>Time</b>: %{x}' +
        //     // 		'<br><b>No Events:</b>: %{y}<br>',
        //     hoverinfo: "x+y",
        // }



        var pumpLogLayout = {
            grid: {
                rows: PUMPLOG_SUBPLOTS,
                columns: 1,
                pattern: 'independent',
                roworder: 'bottom to top'
            },
            shapes: [
                // 1st highlight during Feb 4 - Feb 6
                {
                    type: 'line',
                    // x-reference is assigned to the x-values
                    xref: 'x',
                    // y-reference is assigned to the plot paper [0,1]
                    yref: 'y',
                    x0: time[0],
                    y0: 0,
                    x1: time[0],
                    y1: 1.1 * data['pressure'].max(),
                    fillcolor: '#ffffff',
                    line: {
                        width: 0,
                        color: '#ffffff',
                    }
                },
            ],
            autosize: true,
            width: parentContainer.width() - 22.5,
            height: PUMPLOG_SUBPLOTS * 160,
            start_cell: 'bottom-left',
            vertical_spacing: 0.05,
            margin: {
                l: 10,
                r: 10,
                b: 27,
                t: 20,
                pad: 0
            },
            xaxis: {
                //title: 'Pumplogs - Time',
                color: '#fff',
                tickcolor: '#ffffff',
                zeroline: true,
                mirror: true,
                linecolor: '#fff',
                gridcolor: '#777',
                // hoverinfo:"x",					
                ticks: "",
                showticklabels: true,
                tickformat: '%B %d %Y',
                // hoverformat: "%Y/%m/%d %I:%M %p",
                // hovertemplate: '<b>Time</b>: %{x}' +
                //     '<br><b>Well</b>: %{y}<br>' +
                //     '<b><b>Stage: </b>%{z}</b>',
            },
            //title: 'Pumplogs',
            titlefont: {
                // family: 'Courier New, monospace',
                size: 14,
                color: '#ffffff'
            },
            tickfont: {
                color: '#ffffff'
            },
            showlegend: false,
            paper_bgcolor: 'rgba(0,0,0,0)',
            plot_bgcolor: 'rgba(0,0,0,0)'
        }


        for (var j = 1; j <= PUMPLOG_SUBPLOTS; j++) {
            var suffix = (j > 1) ? j : '';
            if (j > 1) {
                pumpLogLayout['xaxis' + suffix] = {
                    color: '#fff',
                    tickcolor: '#ffffff',
                    zeroline: true,
                    mirror: true,
                    linecolor: '#fff',
                    gridcolor: '#777',
                    // hoverinfo:"x",					
                    ticks: "",
                    showticklabels: false,
                    hoverformat: "%Y/%m/%d %I:%M %p",
                    // hovertemplate: '<b>Time</b>: %{x}' +
                    //     '<br><b>Well</b>: %{y}<br>' +
                    //     '<b><b>Stage: </b>%{z}</b>',
                }
            }
            // xaxis2: {
            //     color: '#fff',
            //     tickcolor: '#ffffff',
            //     zeroline: true,
            //     mirror: true,
            //     linecolor: '#fff',
            //     gridcolor: '#777',
            //     // hoverinfo:"x",					
            //     ticks: "",
            //     showticklabels: false,
            //     hoverformat: "%Y/%m/%d %I:%M %p"
            // },
            pumpLogLayout['yaxis' + suffix] = {
                linecolor: '#fff',
                mirror: true,
                color: '#fff',
                zeroline: false,
                showgrid: false,
                showticklabels: false,
                tickcolor: '#ffffff',
                fixedrange: true,
                ticks: "",
                exponentformat: "none",
                range: [0, 1.1 * data['pressure' + suffix].max()],
                //overlaying: 'y',
                anchor: 'x' + suffix,
            }
            pumpLogLayout['yaxis' + (j + PUMPLOG_SUBPLOTS)] = {
                linecolor: '#fff',
                mirror: true,
                zeroline: false,
                showgrid: false,
                showticklabels: false,
                tickcolor: '#ffffff',
                fixedrange: true,
                ticks: "",
                exponentformat: "none",
                range: [0, 1.5 * data['density' + suffix].max()],
                overlaying: 'y' + suffix,
                anchor: 'x' + suffix,
            }
            pumpLogLayout['yaxis' + ((2 * PUMPLOG_SUBPLOTS) + j)] = {
                linecolor: '#fff',
                mirror: true,
                zeroline: false,
                showgrid: false,
                showticklabels: false,
                tickcolor: '#ffffff',
                fixedrange: true,
                ticks: "",
                exponentformat: "none",
                range: [0, 2.0 * data['rate'].max()],
                overlaying: 'y' + suffix,
                anchor: 'x' + suffix,
            }
            pumpLogLayout['yaxis' + ((3 * PUMPLOG_SUBPLOTS) + j)] = {
                linecolor: '#fff',
                mirror: true,
                ticks: "",
                showticklabels: false,
                zeroline: false,
                color: '#fff',
                showgrid: false,
                tickcolor: '#ffffff',
                exponentformat: "none",
                fixedrange: true,
                exponentformat: "none",
                range: [0, Math.round(1.25 * modeCount(eventTime))],
                overlaying: 'y' + suffix,
                anchor: 'x' + suffix,
            }
        }
        // yaxis2: {
        //     linecolor: '#fff',
        //     mirror: true,
        //     color: '#fff',
        //     zeroline: false,
        //     showgrid: false,
        //     showticklabels: false,
        //     tickcolor: '#ffffff',
        //     fixedrange: true,
        //     ticks: "",
        //     exponentformat: "none",
        //     range: [0, 1.1 * pressure2.max()],
        //     anchor: 'x2',
        //     //overlaying: 'y2',

        // },
        // yaxis4: {
        //     linecolor: '#fff',
        //     mirror: true,
        //     zeroline: false,
        //     showgrid: false,
        //     showticklabels: false,
        //     tickcolor: '#ffffff',
        //     fixedrange: true,
        //     ticks: "",
        //     exponentformat: "none",
        //     range: [0, 1.3 * density2.max()],
        //     anchor: 'x2',
        //     overlaying: 'y2',
        // },
        // yaxis6: {
        //     linecolor: '#fff',
        //     mirror: true,
        //     zeroline: false,
        //     showgrid: false,
        //     showticklabels: false,
        //     tickcolor: '#ffffff',
        //     fixedrange: true,
        //     ticks: "",
        //     exponentformat: "none",
        //     range: [0, 1.5 * rate2.max()],
        //     anchor: 'x2',
        //     overlaying: 'y2',
        // },
        // yaxis8: {
        //     linecolor: '#fff',
        //     mirror: true,
        //     ticks: "",
        //     showticklabels: false,
        //     zeroline: false,
        //     color: '#fff',
        //     showgrid: false,
        //     tickcolor: '#ffffff',
        //     exponentformat: "none",
        //     fixedrange: true,
        //     exponentformat: "none",
        //     range: [0, Math.round(1.25 * modeCount(eventTime))],
        //     overlaying: 'y2',
        //     anchor: 'x2',
        // },

        // hovermode: 'x', 


        //console.log('@@@PumpData', pumpLogData);
        console.log('@@@@@@PumpLogLayout', pumpLogLayout);
        Plotly.newPlot('pumplogs', pumpLogData, pumpLogLayout, {
                displaylogo: false,
                modeBarButtonsToRemove: ['toImage', 'sendDataToCloud', 'hoverCompareCartesian', 'select2d', 'lasso2d', 'toggleSpikelines']
            })
            .then(function(gd) {
                updateStatus("Plotted Pumplogs graph");
            });


        document.getElementById('pumplogs').on('plotly_click', function(eventdata) {


            console.log(eventdata);
            return;
            x = data.points[0].pointIndex[1];
            if (FIBER_TIMELAPSE_START) {
                FIBER_DATA_INDEX = x;
            } else {
                FIBER_TIMELAPSE_START = true;
                render();
                FIBER_DATA_INDEX = x;
                FIBER_TIMELAPSE_START = false;
            }
        });



        document.getElementById('pumplogs').on('plotly_relayout', function(eventdata) {

            if (eventdata['dragmode'] != null) {
                return;
            }

            let minX = Date.parse(eventdata['xaxis.range[0]']);
            let maxX = Date.parse(eventdata['xaxis.range[1]']);
            let minXSet, maxXSet;

            if (STRAIN_PRESENT) {

                if (minX < Date.parse(filteredStrainTime[0]) || minX == null) {
                    minXSet = 0;
                } else {

                    for (var i = 0; i < filteredStrainTime.length; i++) {
                        if (Date.parse(filteredStrainTime[i]) < minX) {
                            minXSet = i;
                        }
                    }
                }

                if (maxX > Date.parse(filteredStrainTime[filteredStrainTime.length - 1]) || maxX == null) {
                    maxXSet = filteredStrainTime.length - 1;
                } else {
                    for (var i = 0; i < filteredStrainTime.length; i++) {
                        if (Date.parse(filteredStrainTime[i]) > maxX) {
                            maxXSet = i;
                            break;
                        }
                    }
                }

                Plotly.update('strainPlots', {}, {
                    'xaxis.range': [minXSet, maxXSet]
                }, [0]);

            }

            if (events.length > 0 && !ET_TIMELAPSE_MODE) {

                timelapse = [];
                for (var i = 0; i < events.length; i++) {
                    if (events[i].time < minX || events[i].time > maxX) {
                        timelapse.push(0, 0, 0);
                    } else {
                        timelapse.push(1, 1, 1);
                    }
                }
                eventGeomDrawn.setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timelapse), 3));
                eventGeomPicking.setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timelapse), 3));

                addUpdateDepthErrorBars();
                addUpdateNorthingErrorBars();
                addUpdateEastErrorBars();
                addUpdateAziErrorBars();
            }

            if (sourceTensor.length > 0 && !ST_TIMELAPSE_MODE) {

                let timelapse = [];
                for (var i = 0; i < sourceTensor.length; i++) {
                    if (sourceTensor[i].time < minX || sourceTensor[i].time > maxX) {
                        timelapse.push(0, 0, 0);
                    } else {
                        timelapse.push(1, 1, 1);
                    }
                }
                sourceTensorGeomDrawn[0].setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timelapse), 3));
                sourceTensorGeomDrawn[1].setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timelapse), 3));
                sourceTensorGeomDrawn[2].setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timelapse), 3));
                sourceTensorGeomDrawn[3].setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timelapse), 3));
                sourceTensorGeomPicking.setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timelapse), 3));

            }



            if (gyration.length > 0) {
                iTimelapse = [];
                for (var i = 0; i < gyration.length; i++) {
                    if (gyration[i].time < minX || gyration[i].time > maxX) {
                        iTimelapse.push(0, 0, 0);
                    } else {
                        iTimelapse.push(1, 1, 1);
                    }
                }
                gyrationGeomDrawn.setAttribute('iTimelapse', new THREE.InstancedBufferAttribute(new Float32Array(iTimelapse), 3))
            };

        });


    }

    //////////////////////////////
    ///////// STRAIN /////////////
    //////////////////////////////

    if (strainDataUrl != "") {

        fetch(strainDataUrl)
            .then(res => res.json())
            .then((out) => {
                strainData = out;
                console.log('Checkout this JSON! ', out);
                createStrainPlots();
            })
            .catch(err => { throw err });
    } else {
        document.getElementById('strainPlots').style.display = 'none';
    }

    /////////////////////////////////
    /////////// COMPASS /////////////
    /////////////////////////////////

    if (true) {

        // dom
        compassContainer = document.getElementById('compass');

        // renderer
        compassRenderer = new THREE.WebGLRenderer({
            alpha: true,
            antialias: true,
        });
        compassRenderer.setClearColor(0x000000, 0); // the default
        compassRenderer.setSize(COMPASS_WIDTH, COMPASS_HEIGHT, false);
        // compassRenderer.setSize(parentContainer.width() , parentContainer.height() , false);
        compassContainer.appendChild(compassRenderer.domElement);

        // scene
        compassScene = new THREE.Scene();

        var arrowWidth = 10;
        var axisHelper = new THREE.Group();
        var dir = new THREE.Vector3(1, 0, 0);
        var origin = new THREE.Vector3(0, 0, 0);
        var length = 45;
        arrowHelper = new THREE.ArrowHelper(dir, origin, length, 0x00ff00, 10, 10);
        arrowHelper.line.material.linewidth = arrowWidth;
        arrowHelper.line.material.needsUpdate = true;
        arrowHelper.frustumCulled = false;

        var dir2 = new THREE.Vector3(0, -1, 0);
        arrowHelper2 = new THREE.ArrowHelper(dir2, origin, length, 0x4666FF, 10, 10);
        arrowHelper2.line.material.linewidth = arrowWidth;
        arrowHelper2.line.material.needsUpdate = true;
        arrowHelper2.frustumCulled = false;

        var dir3 = new THREE.Vector3(0, 0, 1);
        arrowHelper3 = new THREE.ArrowHelper(dir3, origin, length, 0xff0000, 10, 10);
        arrowHelper3.line.material.linewidth = arrowWidth;
        arrowHelper3.line.material.needsUpdate = true;
        arrowHelper3.frustumCulled = false;

        axisHelper.add(arrowHelper);
        axisHelper.add(arrowHelper2);
        axisHelper.add(arrowHelper3);

        var whiteColor = new THREE.Color();
        whiteColor.setRGB(255, 250, 250).convertSRGBToLinear();

        var textGeo = new THREE.TextGeometry('N', {
            size: 10,
            height: 18,
            font: font,
        });
        textGeo.computeBoundingBox();
        textGeo.computeVertexNormals();
        textGeo.center();
        var centerOffsetX = 0.5 * (textGeo.boundingBox.max.x - textGeo.boundingBox.min.x);
        var centerOffsetY = -0.5 * (textGeo.boundingBox.max.y - textGeo.boundingBox.min.y);
        var centerOffsetZ = -0.5 * (textGeo.boundingBox.max.z - textGeo.boundingBox.min.z);
        var textMaterial = new THREE.MeshBasicMaterial({ color: whiteColor });

        xText = new THREE.Mesh(textGeo, textMaterial);
        xText.position.x = 1.15 * length; // + centerOffsetX ;
        xText.position.y = 0; // + centerOffsetY;
        xText.position.z = 0; // + centerOffsetZ;
        xText.frustumCulled = false;
        axisHelper.add(xText);


        var textGeo = new THREE.TextGeometry('E', {
            size: 10,
            height: 18,
            font: font,
        });
        textGeo.computeBoundingBox();
        textGeo.computeVertexNormals();
        textGeo.center();
        var centerOffsetX = -0.5 * (textGeo.boundingBox.max.x - textGeo.boundingBox.min.x);
        var centerOffsetY = -0.5 * (textGeo.boundingBox.max.y - textGeo.boundingBox.min.y);
        var centerOffsetZ = 0.5 * (textGeo.boundingBox.max.z - textGeo.boundingBox.min.z);
        var textMaterial = new THREE.MeshBasicMaterial({ color: whiteColor });
        yText = new THREE.Mesh(textGeo, textMaterial);
        yText.position.x = 0; // + centerOffsetX;
        yText.position.y = 0; // + centerOffsetY ;
        yText.position.z = 1.15 * length; // + centerOffsetZ; //arrowHelper.geometry.vertices[1].z;
        yText.frustumCulled = false;
        axisHelper.add(yText);


        var textGeo = new THREE.TextGeometry('Z', {
            size: 10,
            height: 18,
            font: font,
        });
        textGeo.computeBoundingBox();
        textGeo.computeVertexNormals();
        textGeo.center();
        var centerOffsetX = -0.5 * (textGeo.boundingBox.max.x - textGeo.boundingBox.min.x);
        var centerOffsetY = -0.5 * (textGeo.boundingBox.max.y - textGeo.boundingBox.min.y);
        var centerOffsetZ = 0.5 * (textGeo.boundingBox.max.z - textGeo.boundingBox.min.z);
        var textMaterial = new THREE.MeshBasicMaterial({ color: whiteColor });
        zText = new THREE.Mesh(textGeo, textMaterial);
        zText.position.x = 0; // + centerOffsetX;
        zText.position.z = 0; // + centerOffsetY ;
        zText.position.y = -1.15 * length; //+ centerOffsetZ; //arrowHelper.geometry.vertices[1].z;
        zText.frustumCulled = false;
        axisHelper.add(zText);

        axisHelper.frustumCulled = false;
        compassScene.add(axisHelper);

    }



    /////////////////////////////////
    ///// REVEAL DATA FOLDER    /////
    /////////////////////////////////




    /////////////////////////////////
    ///// 	EVENTS FOLDER       /////
    /////////////////////////////////

    if (events.length > 0) {

        ////////////////////////////////////////////////////////
        /////////// add code for the dataset, etc here.
        ////////////////////////////////////////////////////////


        var eventFolder = gui.addFolder('Events');

        if (true) {

            var eventWells = [],
                eventStages = [],
                eventDatasets = [];

            eventCheckBoxes = [
                [
                    []
                ]
            ];
            eventControllers = [
                [
                    []
                ]
            ];


            eventControllers['All Events'] = new Array();
            eventControllers['All Events'][0] = true;

            eventCheckBoxes['All Events'] = eventFolder.add(eventControllers['All Events'], 0, eventControllers['All Events'][0]).name("All Events").onChange(function(value) {

                var iScale = [];
                for (var i = 0; i < events.length; i++) {
                    if (value) {
                        iScale.push(1, 1, 1);
                    } else {
                        iScale.push(0, 0, 0);
                    }
                }

                if (value) {
                    eventGeomDrawn.instanceCount = events.length;
                    eventGeomPicking.instanceCount = events.length;
                } else {
                    eventGeomDrawn.instanceCount = 0;
                    eventGeomPicking.instanceCount = 0;
                }


                eventGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                eventGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

                addUpdateDepthErrorBars();
                addUpdateNorthingErrorBars();
                addUpdateEastErrorBars();
                addUpdateAziErrorBars();
                var eventDatasets = [];
                // Show Hide Values
                for (var i = 0; i < events.length; i++) {
                    eventDatasets.push(events[i].dataset);
                }
                eventDatasets = eventDatasets.unique();

                for (var i2 = 0; i2 < eventDatasets.length; i2++) {

                    eventControllers[eventDatasets[i2]][eventDatasets[i2]] = value;
                    try {
                        eventCheckBoxes[eventDatasets[i2]][eventDatasets[i2]].updateDisplay();
                    } catch (e) {
                        console.log(e);
                    }
                    var eventWellsTemp = [];
                    for (var i = 0; i < events.length; i++) {
                        if (events[i].dataset == eventDatasets[i2])
                            eventWellsTemp.push(events[i].well);
                    }
                    eventWellsTemp = eventWellsTemp.unique();

                    for (var i = 0; i < eventWellsTemp.length; i++) {
                        var eventWellsKeys = Object.keys(eventControllers[eventDatasets[i2]][eventWellsTemp[i]]);
                        for (var i1 = 0; i1 < eventWellsKeys.length; i1++) {
                            eventControllers[eventDatasets[i2]][eventWellsTemp[i]][eventWellsKeys[i1]] = value;
                            try {
                                eventCheckBoxes[eventDatasets[i2]][eventWellsTemp[i]][eventWellsKeys[i1]].updateDisplay();
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }

                }

                resetEventTimelapse();

                //shuffleEventGeometry();


            });


            // Get all unique datasets
            for (var i = 0; i < events.length; i++) {
                eventDatasets.push(events[i].dataset);
            }
            eventDatasets = eventDatasets.unique();


            for (k = 0; k < eventDatasets.length; k++) {

                var datasetFolder = eventFolder.addFolder(eventDatasets[k]);

                eventCheckBoxes[eventDatasets[k]] = new Array();
                eventControllers[eventDatasets[k]] = new Array();
                eventControllers[eventDatasets[k]][eventDatasets[k]] = true; //eventWells[j]);

                eventCheckBoxes[eventDatasets[k]][eventDatasets[k]] = datasetFolder.add(eventControllers[eventDatasets[k]], eventDatasets[k], eventControllers[eventDatasets[k]][eventDatasets[k]]).name("All Wells").onChange(function(value) {

                    if (eventGeomDrawn.instanceCount == 0) {
                        eventGeomDrawn.instanceCount = events.length;
                        eventGeomPicking.instanceCount = events.length;
                    }


                    var iScale = [];
                    for (var i = 0; i < events.length; i++) {
                        if (events[i].dataset == this.property) {
                            if (value) {
                                iScale.push(1, 1, 1);
                            } else {
                                iScale.push(0, 0, 0);
                            }
                        } else {
                            iScale.push(eventGeomDrawn.attributes.iScale.array[3 * i], eventGeomDrawn.attributes.iScale.array[3 * i + 1], eventGeomDrawn.attributes.iScale.array[3 * i + 2]);
                        }
                    }
                    eventGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                    eventGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

                    addUpdateDepthErrorBars();
                    addUpdateNorthingErrorBars();
                    addUpdateEastErrorBars();
                    addUpdateAziErrorBars();
                    var eventWellsTemp = [];
                    for (var i = 0; i < events.length; i++) {
                        if (events[i].dataset == this.property)
                            eventWellsTemp.push(events[i].well);
                    }
                    eventWellsTemp = eventWellsTemp.unique();


                    for (var i = 0; i < eventWellsTemp.length; i++) {
                        var eventWellsKeys = Object.keys(eventControllers[this.property][eventWellsTemp[i]]);
                        for (var i1 = 0; i1 < eventWellsKeys.length; i1++) {
                            eventControllers[this.property][eventWellsTemp[i]][eventWellsKeys[i1]] = value;
                            try {
                                eventCheckBoxes[this.property][eventWellsTemp[i]][eventWellsKeys[i1]].updateDisplay();
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }

                    // eventControllers[this.dataset][this.property][key] = value;
                    // eventCheckBoxes[this.dataset][this.property][key].updateDisplay();


                    resetEventTimelapse();
                    //shuffleEventGeometry();

                });

                eventCheckBoxes[eventDatasets[k]][eventDatasets[k]].dataset = eventDatasets[k];

                var eventWells = [];
                for (var i = 0; i < events.length; i++) {
                    if (events[i].dataset == eventDatasets[k])
                        eventWells.push(events[i].well);
                }
                eventWells = eventWells.unique();

                for (j = 0; j < eventWells.length; j++) {

                    var wellFolder = datasetFolder.addFolder(eventWells[j]);

                    eventCheckBoxes[eventDatasets[k]][eventWells[j]] = new Array();
                    eventControllers[eventDatasets[k]][eventWells[j]] = new Array();
                    eventControllers[eventDatasets[k]][eventWells[j]][eventWells[j]] = true; //eventWells[j]);

                    eventCheckBoxes[eventDatasets[k]][eventWells[j]][eventWells[j]] = wellFolder.add(eventControllers[eventDatasets[k]][eventWells[j]], eventWells[j], eventControllers[eventDatasets[k]][eventWells[j]][eventWells[j]]).name("All Stages").onChange(function(value) {

                        if (eventGeomDrawn.instanceCount == 0) {
                            eventGeomDrawn.instanceCount = events.length;
                            eventGeomPicking.instanceCount = events.length;
                        }

                        var iScale = [];
                        for (var i = 0; i < events.length; i++) {
                            if (events[i].well == this.property && events[i].dataset == this.dataset) {
                                if (value) {
                                    iScale.push(1, 1, 1);
                                } else {
                                    iScale.push(0, 0, 0);
                                }
                            } else {
                                iScale.push(eventGeomDrawn.attributes.iScale.array[3 * i], eventGeomDrawn.attributes.iScale.array[3 * i + 1], eventGeomDrawn.attributes.iScale.array[3 * i + 2]);
                            }
                        }
                        eventGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                        eventGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

                        addUpdateDepthErrorBars();
                        addUpdateNorthingErrorBars();
                        addUpdateEastErrorBars();
                        addUpdateAziErrorBars();

                        for (var key in eventControllers[this.dataset][this.property]) {
                            try {
                                eventControllers[this.dataset][this.property][key] = value;
                                eventCheckBoxes[this.dataset][this.property][key].updateDisplay();
                            } catch (x) {
                                console.log(x);
                            }
                        }

                        resetEventTimelapse();

                        //shuffleEventGeometry();

                    });

                    eventCheckBoxes[eventDatasets[k]][eventWells[j]][eventWells[j]].dataset = eventDatasets[k];

                    // Get all unique stages for this well,
                    var eventStages = [];
                    for (var i = 0; i < events.length; i++) {
                        if (events[i].well == eventWells[j] && events[i].dataset == eventDatasets[k])
                            eventStages.push(events[i].name);
                    }
                    eventStages = eventStages.unique();

                    for (var l = 0; l < eventStages.length; l++) {

                        eventControllers[eventDatasets[k]][eventWells[j]][eventStages[l]] = true;
                        eventCheckBoxes[eventDatasets[k]][eventWells[j]][eventStages[l]] = wellFolder.add(eventControllers[eventDatasets[k]][eventWells[j]], eventStages[l], eventControllers[eventDatasets[k]][eventWells[j]][eventStages[l]]).name(eventStages[l]).onChange(function(value) {


                            if (eventGeomDrawn.instanceCount == 0) {
                                eventGeomDrawn.instanceCount = events.length;
                                eventGeomPicking.instanceCount = events.length;
                            }

                            var iScale = [];
                            for (var i = 0; i < events.length; i++) {
                                if (events[i].name == this.property && events[i].dataset == this.dataset) {
                                    if (value) {
                                        iScale.push(1, 1, 1);
                                    } else {
                                        iScale.push(0, 0, 0);
                                    }
                                } else {
                                    iScale.push(eventGeomDrawn.attributes.iScale.array[3 * i], eventGeomDrawn.attributes.iScale.array[3 * i + 1], eventGeomDrawn.attributes.iScale.array[3 * i + 2]);
                                }
                            }
                            eventGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                            eventGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

                            addUpdateDepthErrorBars();
                            addUpdateNorthingErrorBars();
                            addUpdateEastErrorBars();
                            addUpdateAziErrorBars();

                            resetEventTimelapse();
                            //shuffleEventGeometry();

                        });

                        eventCheckBoxes[eventDatasets[k]][eventWells[j]][eventStages[l]].dataset = eventDatasets[k];
                    }

                }



            }



        }

        var eventOptions = eventFolder.addFolder('Display Options');

        // Event Sizing
        var eventSizeMod = function() {
            this.eventSize = 8;
        };

        var eventSizeModInstance = new eventSizeMod();
        // var eventSizeSlider = eventFolder.add(eventSizeModInstance, 'eventSize', ['-', 'Magnitude']).name('Size By');
        var eventSizeSlider = eventOptions.add(eventSizeModInstance, 'eventSize', [1, 2, 4, 6, 8, 10, 12, 14, 16]).name('Size');
        eventSizeSlider.onChange(function(value) {

            iSize = [];

            for (var i = 0; i < events.length; i++) {
                iSize.push(value / 10, value / 10, value / 10);
            }

            eventGeomDrawn.setAttribute('iSize', new THREE.InstancedBufferAttribute(new Float32Array(iSize), 3));

        });

        // Event Sizing By Property
        var eventSizeVariation = function() {
            this.property = '-';
        };

        var eventSizeVariationInstance = new eventSizeVariation();

        var sizeProperties = ['-'];
        if (events.length > 0 && events[0].hasOwnProperty('misfit')) {
            sizeProperties.push('Misfit');
        }

        if (events.length > 0 && events[0].hasOwnProperty('magnitude')) {
            sizeProperties.push('Magnitude');
        }

        if (events.length > 0 && events[0].hasOwnProperty('fractureArea')) {
            sizeProperties.push('Fracture Area');
        }

        var eventSizeVariationSlider = eventOptions.add(eventSizeVariationInstance, 'property', sizeProperties).name('Size By');
        eventSizeVariationSlider.onChange(function(value) {

            var iSize = [];
            if (value == '-') {
                for (var i = 0; i < events.length; i++) {
                    iSize.push(1, 1, 1);
                }
            }

            if (value == 'Magnitude') {

                var minMag = Infinity;
                var maxMag = -Infinity;
                for (var n = 0; n < events.length; n++) {
                    if (!isNaN(events[n].magnitude)) {
                        minMag = Math.min(minMag, events[n].magnitude);
                        maxMag = Math.max(maxMag, events[n].magnitude);
                    }
                }

                if (localStorage.getItem("mag-min-3d-plot" + project_id) !== null) {
                    minMag = Number(localStorage.getItem("mag-min-3d-plot" + project_id));
                }

                if (localStorage.getItem("mag-max-3d-plot" + project_id) !== null) {
                    maxMag = Number(localStorage.getItem("mag-max-3d-plot" + project_id));
                }

                for (var i = 0; i < events.length; i++) {
                    if (isNaN(minMag) || isNaN(maxMag)) {
                        iSize.push(1, 1, 1);
                    } else {
                        factor = (Number(events[i].magnitude) - minMag) / (maxMag - minMag);
                        iSize.push(factor, factor, factor);
                    }
                }
            }


            if (value == 'Misfit') {

                var minMisfit = Infinity;
                var maxMisfit = -Infinity;
                for (var n = 0; n < events.length; n++) {
                    if (!isNaN(events[n].misfit)) {
                        minMisfit = Math.min(minMisfit, events[n].misfit);
                        maxMisfit = Math.max(maxMisfit, events[n].misfit);
                    }
                }

                if (localStorage.getItem("misfit-min-3d-plot" + project_id) !== null) {
                    minMisfit = Number(localStorage.getItem("misfit-min-3d-plot" + project_id));
                }

                if (localStorage.getItem("misfit-max-3d-plot" + project_id) !== null) {
                    maxMisfit = Number(localStorage.getItem("misfit-max-3d-plot" + project_id));
                }

                for (var i = 0; i < events.length; i++) {
                    if (isNaN(minMisfit) || isNaN(maxMisfit)) {
                        iSize.push(1, 1, 1);
                    } else {
                        var factor = (Number(events[i].misfit) - minMisfit) / (maxMisfit - minMisfit);
                        iSize.push(factor, factor, factor);
                    }
                }
            }


            if (value == 'Fracture Area') {

                var fractureMin = Infinity;
                var fractureMax = -Infinity;
                for (var n = 0; n < events.length; n++) {
                    if (!isNaN(events[n].fractureArea)) {
                        fractureMin = Math.min(fractureMin, events[n].fractureArea);
                        fractureMax = Math.max(fractureMax, events[n].fractureArea);
                    }
                }

                for (var i = 0; i < events.length; i++) {
                    if (isNaN(fractureMin) || isNaN(fractureMax)) {
                        iSize.push(1, 1, 1);
                    } else {
                        factor = (Number(events[i].fractureArea) - fractureMin) / (fractureMax - fractureMin);
                        iSize.push(factor, factor, factor);
                    }
                }
            }


            eventGeomDrawn.setAttribute('iSizeVariation', new THREE.InstancedBufferAttribute(new Float32Array(iSize), 3));

            addUpdateDepthErrorBars();
            addUpdateNorthingErrorBars();
            addUpdateEastErrorBars();
            addUpdateAziErrorBars();

            //shuffleEventGeometry();


        });



        // Event Color
        var eventColorMod = function() {
            this.eventColor = 'Stage';
        };

        var eventColorModInstance = new eventColorMod();

        var colorProperties = ['Stage', 'Well'];
        if (events.length > 0 && events[0].hasOwnProperty('misfit')) {
            colorProperties.push('Misfit');
        }

        if (events.length > 0 && events[0].hasOwnProperty('magnitude')) {
            colorProperties.push('Magnitude');
        }

        if (events.length > 0 && events[0].hasOwnProperty('fractureArea')) {
            colorProperties.push('Fracture Area');
        }

        if (events.length > 0 && events[0].hasOwnProperty('formation')) {
            colorProperties.push('Formation');
        }

        if (events.length > 0 && events[0].hasOwnProperty('elapsedTime')) {
            colorProperties.push('Elapsed Time');
        }

        colorProperties.push('Arrays Picked');
        colorProperties.push('No Mtr Wells');

        eventColorSlider = eventOptions.add(eventColorModInstance, 'eventColor', colorProperties).name('Color By');

        eventColorSlider.onChange(function(value) {

            LEGEND_TEXT = [];
            LEGEND_COLOR = [];

            if (value == 'Arrays Picked') {


                var formationList = [];
                for (var i = 0; i < events.length; i++) {
                    if (events[i].noArrays) {
                        formationList.push(events[i].noArrays);
                    }
                }
                formations = formationList.unique();
                formations = formations.sort();
                if (formations.length == 0) {
                    alert("Data not present");
                    return;
                }

                var iColor = [];
                for (var i = 0; i < events.length; i++) {
                    eventColor = new THREE.Color(getColor(events[i].noArrays)).convertSRGBToLinear();
                    iColor.push(eventColor.r, eventColor.g, eventColor.b);
                }
                eventGeomDrawn.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColor), 3));

                ///// Adding the legend
                var noOfElements = formations.length;
                document.getElementById("legendLabelText").innerHTML = "";
                document.getElementById("legendLegend").innerHTML = "";
                var heightPerElement = 20;
                var canvas = document.createElement('canvas');
                var div = document.getElementById("legendLegend");
                canvas.id = "legend";
                canvas.position = 'absolute';
                canvas.width = 50;
                canvas.height = noOfElements * heightPerElement;
                canvas.style.zIndex = 8;
                canvas.style.position = "absolute";
                // canvas.style.border   = "1px solid w /dhite";
                var ctx = canvas.getContext("2d");
                // No of variations and colors
                for (var i = 0; i < noOfElements; i++) {
                    // color = magLut.getColor(i);
                    // colorText = 'rgba(' + 255*color.r.toString() + ', ' + 255*color.g.toString() + ', ' + 255*color.b.toString() + ' ,1.0)';
                    var colorText = getColor(formations[i]);
                    var grd = ctx.createLinearGradient(0, 0, 0, 10);
                    grd.addColorStop(0, colorText);
                    grd.addColorStop(1, colorText);
                    ctx.fillStyle = grd;

                    var y0 = i * heightPerElement;
                    var y1 = (i + 1) * heightPerElement;
                    ctx.fillRect(0, Math.ceil(y0) + 1, 20, Math.floor(y1) - 1);

                    document.getElementById("legendLabelText").innerHTML += formations[i];
                    if (i < noOfElements - 1) {
                        document.getElementById("legendLabelText").innerHTML += "<br>";
                    }

                    LEGEND_TEXT.push(formations[i]);
                    LEGEND_COLOR.push(colorText);

                }
                div.appendChild(canvas);

            }

            if (value == 'Misfit') {

                if (events.length == 0 || !events[0].hasOwnProperty("misfit")) {
                    alert('Misfit not available yet.');
                    return;
                }

                var misfitLut = new Lut('rainbow', 512);
                misfitLut.addColorMap("BHS", COLORMAP);
                misfitLut.setColorMap("BHS");

                var normMisfit = [];
                var iColor = [];

                var misfitMin = Infinity;
                var misfitMax = -Infinity;
                for (var n = 0; n < events.length; n++) {
                    if (!isNaN(events[n].misfit)) {
                        misfitMin = Math.min(misfitMin, events[n].misfit);
                        misfitMax = Math.max(misfitMax, events[n].misfit);
                    }
                }

                if (misfitMax > 15) {
                    misfitMax = 15;
                }

                if (localStorage.getItem("misfit-min-3d-plot" + project_id) != null && !isNaN(Number(localStorage.getItem("misfit-min-3d-plot" + project_id)))) {
                    misfitMin = Number(localStorage.getItem("misfit-min-3d-plot" + project_id));
                }

                if (localStorage.getItem("misfit-max-3d-plot" + project_id) != null && !isNaN(Number(localStorage.getItem("misfit-max-3d-plot" + project_id)))) {
                    misfitMax = Number(localStorage.getItem("misfit-max-3d-plot" + project_id));
                }


                for (var n = 0; n < events.length; n++) {
                    var normMisfit = (events[n].misfit - misfitMin) / (misfitMax - misfitMin);
                    normMisfit = 1 - normMisfit;

                    if (normMisfit < 0) {
                        normMisfit = 0;
                    }
                    if (normMisfit > 1) {
                        normMisfit = 1;
                    }
                    // iColor.push( normMisfit, 0.0, 1 - normMisfit );

                    var color = misfitLut.getColor(normMisfit);
                    iColor.push(color.r, color.g, color.b);
                }

                eventGeomDrawn.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColor), 3));


                ///// Adding the legend

                noOfElements = 15;
                var misfitLutLegend = new Lut('rainbow', noOfElements);

                var misfitLutLegend = new Lut('rainbow', noOfElements);
                misfitLutLegend.addColorMap("BHS", COLORMAP);
                misfitLutLegend.setColorMap("BHS", noOfElements);

                document.getElementById("legendLabelText").innerHTML = "";
                document.getElementById("legendLegend").innerHTML = "";
                var heightPerElement = 20;
                var canvas = document.createElement('canvas');
                var div = document.getElementById("legendLegend");
                canvas.id = "legend";
                canvas.position = 'absolute';
                canvas.width = 50;
                canvas.height = noOfElements * heightPerElement;
                canvas.style.zIndex = 8;
                canvas.style.position = "absolute";
                // canvas.style.border   = "1px solid w /dhite";
                var ctx = canvas.getContext("2d");
                // No of variations and colors
                for (var i = 0; i < noOfElements; i++) {
                    var color = misfitLutLegend.getColor(i / noOfElements);
                    var colorText = 'rgba(' + 255 * color.r.toString() + ', ' + 255 * color.g.toString() + ', ' + 255 * color.b.toString() + ' ,1.0)';
                    var grd = ctx.createLinearGradient(0, 0, 0, 10);
                    grd.addColorStop(0, colorText);
                    grd.addColorStop(1, colorText);
                    ctx.fillStyle = grd;

                    var y0 = i * heightPerElement;
                    var y1 = (i + 1) * heightPerElement;
                    ctx.fillRect(0, Math.ceil(y0) + 1, 20, Math.floor(y1) - 1);


                    LEGEND_COLOR.push(colorText);

                    if (i == 0) {
                        LEGEND_TEXT.push((Math.round(100 * misfitMax) / 100 + " ms").toString());
                    } else if (i == noOfElements - 1) {
                        LEGEND_TEXT.push((Math.round(100 * misfitMin) / 100 + " ms").toString());
                    } else {
                        LEGEND_TEXT.push("");
                    }

                    if (i == 0) {
                        document.getElementById("legendLabelText").innerHTML += Math.round(100 * misfitMax) / 100 + " ms";
                    }

                    if (i == noOfElements - 1) {
                        document.getElementById("legendLabelText").innerHTML += Math.round(100 * misfitMin) / 100 + " ms";
                    }

                    if (i < noOfElements - 1) {
                        document.getElementById("legendLabelText").innerHTML += "<br>";
                    }

                }
                div.appendChild(canvas);



            }

            if (value == 'Stage') {


                document.getElementById("legendLabelText").innerHTML = "";
                document.getElementById("legendLegend").innerHTML = "";

                var iColors = [];

                for (var i = 0; i < events.length; i++) {
                    var eventTempColor = new THREE.Color(events[i].color).convertSRGBToLinear();
                    iColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);
                }

                eventGeomDrawn.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));


            }

            if (value == 'Magnitude') {

                if (events.length == 0 || !events[0].hasOwnProperty("magnitude")) {
                    alert('Magnitude not available yet.');
                    return;
                }

                var magLut = new Lut('rainbow', 512);
                magLut.addColorMap("BHS", COLORMAP);
                magLut.setColorMap("BHS");

                var normMagnitude = [];
                var iColor = [];

                var magnitudeMin = Infinity;
                var magnitudeMax = -Infinity;
                for (var n = 0; n < events.length; n++) {
                    if (!isNaN(events[n].magnitude)) {
                        magnitudeMin = Math.min(magnitudeMin, events[n].magnitude);
                        magnitudeMax = Math.max(magnitudeMax, events[n].magnitude);
                    }
                }


                if (localStorage.getItem("mag-min-3d-plot" + project_id) != null && !isNaN(Number(localStorage.getItem("mag-min-3d-plot" + project_id)))) {
                    magnitudeMin = Number(localStorage.getItem("mag-min-3d-plot" + project_id));
                }

                if (localStorage.getItem("mag-max-3d-plot" + project_id) != null && !isNaN(Number(localStorage.getItem("mag-max-3d-plot" + project_id)))) {
                    magnitudeMax = Number(localStorage.getItem("mag-max-3d-plot" + project_id));
                }

                for (var n = 0; n < events.length; n++) {
                    var normMagnitude = (events[n].magnitude - magnitudeMin) / (magnitudeMax - magnitudeMin);
                    normMagnitude = 1 - normMagnitude;

                    if (isNaN(normMagnitude)) {
                        iColor.push(0.0, 0.0, 0.0);
                    } else {
                        // iColor.push( normMagnitude, 0.0, 1 - normMagnitude );
                        var color = magLut.getColor(normMagnitude);
                        iColor.push(color.r, color.g, color.b);


                    }
                }

                eventGeomDrawn.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColor), 3));


                ///// Adding the legend

                var noOfElements = 15;
                var misfitLutLegend = new Lut('rainbow', 512);
                misfitLutLegend.addColorMap("BHS", COLORMAP);
                misfitLutLegend.setColorMap("BHS");

                document.getElementById("legendLabelText").innerHTML = "";
                document.getElementById("legendLegend").innerHTML = "";
                var heightPerElement = 20;
                var canvas = document.createElement('canvas');
                var div = document.getElementById("legendLegend");
                canvas.id = "legend";
                canvas.position = 'absolute';
                canvas.width = 50;
                canvas.height = noOfElements * heightPerElement;
                canvas.style.zIndex = 8;
                canvas.style.position = "absolute";
                // canvas.style.border   = "1px solid w /dhite";
                var ctx = canvas.getContext("2d");
                // No of variations and colors
                for (var i = 0; i < noOfElements; i++) {
                    var color = misfitLutLegend.getColor(i / noOfElements);
                    var colorText = 'rgba(' + 255 * color.r.toString() + ', ' + 255 * color.g.toString() + ', ' + 255 * color.b.toString() + ' ,1.0)';
                    var grd = ctx.createLinearGradient(0, 0, 0, 10);
                    grd.addColorStop(0, colorText);
                    grd.addColorStop(1, colorText);
                    ctx.fillStyle = grd;

                    var y0 = i * heightPerElement;
                    var y1 = (i + 1) * heightPerElement;
                    ctx.fillRect(0, Math.ceil(y0) + 1, 20, Math.floor(y1) - 1);

                    LEGEND_COLOR.push(colorText);

                    if (i == 0) {
                        LEGEND_TEXT.push((Math.round(100 * magnitudeMax) / 100).toString());
                    } else if (i == noOfElements - 1) {
                        LEGEND_TEXT.push((Math.round(100 * magnitudeMin) / 100).toString());
                    } else {
                        LEGEND_TEXT.push("");
                    }

                    if (i == 0) {
                        document.getElementById("legendLabelText").innerHTML += Math.round(100 * magnitudeMax) / 100;
                    }

                    if (i == noOfElements - 1) {
                        document.getElementById("legendLabelText").innerHTML += Math.round(100 * magnitudeMin) / 100;
                    }

                    if (i < noOfElements - 1) {
                        document.getElementById("legendLabelText").innerHTML += "<br>";
                    }

                }
                div.appendChild(canvas);



            }

            if (value == 'Well') {

                var formationList = [];
                for (var i = 0; i < events.length; i++) {
                    formationList.push(events[i].well);
                }
                var formations = formationList.unique();
                iColor = [];
                for (var i = 0; i < events.length; i++) {
                    var index = formations.indexOf(events[i].well)
                    var eventColor = new THREE.Color(getColor(index)).convertSRGBToLinear();
                    iColor.push(eventColor.r, eventColor.g, eventColor.b);
                }
                eventGeomDrawn.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColor), 3));

                ///// Adding the legend
                var noOfElements = formations.length;
                document.getElementById("legendLabelText").innerHTML = "";
                document.getElementById("legendLegend").innerHTML = "";
                var heightPerElement = 20;
                var canvas = document.createElement('canvas');
                var div = document.getElementById("legendLegend");
                canvas.id = "legend";
                canvas.position = 'absolute';
                canvas.width = 50;
                canvas.height = noOfElements * heightPerElement;
                canvas.style.zIndex = 8;
                canvas.style.position = "absolute";
                // canvas.style.border   = "1px solid w /dhite";
                var ctx = canvas.getContext("2d");
                // No of variations and colors
                for (var i = 0; i < noOfElements; i++) {
                    // color = magLut.getColor(i);
                    // colorText = 'rgba(' + 255*color.r.toString() + ', ' + 255*color.g.toString() + ', ' + 255*color.b.toString() + ' ,1.0)';
                    var colorText = getColor(i);
                    var grd = ctx.createLinearGradient(0, 0, 0, 10);
                    grd.addColorStop(0, colorText);
                    grd.addColorStop(1, colorText);
                    ctx.fillStyle = grd;

                    var y0 = i * heightPerElement;
                    var y1 = (i + 1) * heightPerElement;
                    ctx.fillRect(0, Math.ceil(y0) + 1, 20, Math.floor(y1) - 1);


                    LEGEND_COLOR.push(colorText);
                    LEGEND_TEXT.push(formations[i]);

                    document.getElementById("legendLabelText").innerHTML += formations[i];
                    if (i < noOfElements - 1) {
                        document.getElementById("legendLabelText").innerHTML += "<br>";
                    }

                }
                div.appendChild(canvas);

            }

            if (value == 'No Mtr Wells') {


                var formationList = [];
                for (var i = 0; i < events.length; i++) {
                    if (events[i].noWells) {
                        formationList.push(events[i].noWells);
                    }
                }
                var formations = formationList.unique();
                formations.sort();

                if (formations.length == 0) {
                    alert("Data not present");
                    return;
                }

                var iColor = [];

                for (var i = 0; i < events.length; i++) {
                    if (Number(events[i].noWells) > -1) {
                        var eventColor = new THREE.Color(getColor(Number(events[i].noWells))).convertSRGBToLinear();
                    } else {
                        var eventColor = new THREE.Color('rgba(255,255,255,0.0)').convertSRGBToLinear();
                    }
                    iColor.push(eventColor.r, eventColor.g, eventColor.b);
                }

                eventGeomDrawn.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColor), 3));


                ///// Adding the legend
                var noOfElements = formations.length;
                document.getElementById("legendLabelText").innerHTML = "";
                document.getElementById("legendLegend").innerHTML = "";
                var heightPerElement = 20;
                var canvas = document.createElement('canvas');
                var div = document.getElementById("legendLegend");
                canvas.id = "legend";
                canvas.position = 'absolute';
                canvas.width = 50;
                canvas.height = noOfElements * heightPerElement;
                canvas.style.zIndex = 8;
                canvas.style.position = "absolute";
                // canvas.style.border   = "1px solid w /dhite";
                var ctx = canvas.getContext("2d");
                // No of variations and colors
                for (var i = 0; i < noOfElements; i++) {
                    // color = magLut.getColor(i);
                    // colorText = 'rgba(' + 255*color.r.toString() + ', ' + 255*color.g.toString() + ', ' + 255*color.b.toString() + ' ,1.0)';
                    var colorText = getColor(formations[i]);
                    var grd = ctx.createLinearGradient(0, 0, 0, 10);
                    grd.addColorStop(0, colorText);
                    grd.addColorStop(1, colorText);
                    ctx.fillStyle = grd;

                    var y0 = i * heightPerElement;
                    var y1 = (i + 1) * heightPerElement;
                    ctx.fillRect(0, Math.ceil(y0) + 1, 20, Math.floor(y1) - 1);


                    LEGEND_COLOR.push(colorText);
                    LEGEND_TEXT.push(formations[i]);


                    document.getElementById("legendLabelText").innerHTML += formations[i];
                    if (i < noOfElements - 1) {
                        document.getElementById("legendLabelText").innerHTML += "<br>";
                    }

                }
                div.appendChild(canvas);



            }

            if (value == 'Formation') {

                if (events.length == 0 || !events[0].hasOwnProperty("formation")) {
                    alert('Formation Info not available yet.');
                    return;
                }


                var formationList = [];
                for (var i = 0; i < events.length; i++) {
                    formationList.push(events[i].formation);
                }
                var formations = formationList.unique();
                var iColor = [];
                for (var i = 0; i < events.length; i++) {


                    var index = formationTops.map(e => e['name']).indexOf(events[i].formation);
                    if (index == -1) {
                        alert("Mismatch in formation name between events and formations entered on portal. Cannot color by Formation.");
                        return;
                    }
                    var eventColor = new THREE.Color(getColor(index)).convertSRGBToLinear();
                    iColor.push(eventColor.r, eventColor.g, eventColor.b);
                }
                eventGeomDrawn.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColor), 3));


                ///// Adding the legend
                var noOfElements = formations.length;
                document.getElementById("legendLabelText").innerHTML = "";
                document.getElementById("legendLegend").innerHTML = "";
                var heightPerElement = 20;
                var canvas = document.createElement('canvas');
                var div = document.getElementById("legendLegend");
                canvas.id = "legend";
                canvas.position = 'absolute';
                canvas.width = 50;
                canvas.height = noOfElements * heightPerElement;
                canvas.style.zIndex = 8;
                canvas.style.position = "absolute";
                // canvas.style.border   = "1px solid w /dhite";
                var ctx = canvas.getContext("2d");
                // No of variations and colors
                for (var i = 0; i < noOfElements; i++) {
                    // color = magLut.getColor(i);
                    // colorText = 'rgba(' + 255*color.r.toString() + ', ' + 255*color.g.toString() + ', ' + 255*color.b.toString() + ' ,1.0)';

                    // var colorText = getColor(i);
                    var colorText = getColor(formationTops.map(e => e['name']).indexOf(formations[i]));

                    var grd = ctx.createLinearGradient(0, 0, 0, 10);
                    grd.addColorStop(0, colorText);
                    grd.addColorStop(1, colorText);
                    ctx.fillStyle = grd;

                    var y0 = i * heightPerElement;
                    var y1 = (i + 1) * heightPerElement;
                    ctx.fillRect(0, Math.ceil(y0) + 1, 20, Math.floor(y1) - 1);

                    document.getElementById("legendLabelText").innerHTML += formations[i];
                    if (i < noOfElements - 1) {
                        document.getElementById("legendLabelText").innerHTML += "<br>";
                    }

                    LEGEND_COLOR.push(colorText);
                    LEGEND_TEXT.push(formations[i]);

                }
                div.appendChild(canvas);





            }

            if (value == 'Fracture Area') {

                if (events.length == 0 || !events[0].hasOwnProperty("fractureArea")) {
                    alert('Fracture Area not available yet.');
                    return;
                }

                var fractureLut = new Lut('rainbow', 512);
                fractureLut.addColorMap("BHS", COLORMAP);
                fractureLut.setColorMap("BHS");

                var fractureMin = Infinity;
                var fractureMax = -Infinity;
                for (var n = 0; n < events.length; n++) {
                    if (!isNaN(events[n].fractureArea)) {
                        fractureMin = Math.min(fractureMin, events[n].fractureArea);
                        fractureMax = Math.max(fractureMax, events[n].fractureArea);
                    }
                }

                if (localStorage.getItem("fracture-min-3d-plot" + project_id) != null && !isNaN(Number(localStorage.getItem("fracture-min-3d-plot" + project_id)))) {
                    fractureMin = Number(localStorage.getItem("fracture-min-3d-plot" + project_id));
                }

                if (localStorage.getItem("fracture-max-3d-plot" + project_id) != null && !isNaN(Number(localStorage.getItem("fracture-max-3d-plot" + project_id)))) {
                    fractureMax = Number(localStorage.getItem("fracture-max-3d-plot" + project_id));
                }

                var normFracture = [];
                var iColor = [];

                for (var n = 0; n < events.length; n++) {
                    var normFracture = (events[n].fractureArea - fractureMin) / (fractureMax - fractureMin);
                    normFracture = 1 - normFracture;

                    if (isNaN(normFracture)) {
                        iColor.push(0.0, 0.0, 0.0);
                    } else {
                        // iColor.push( normMagnitude, 0.0, 1 - normMagnitude );
                        var color = fractureLut.getColor(normFracture);
                        iColor.push(color.r, color.g, color.b);
                    }
                }

                eventGeomDrawn.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColor), 3));

                ///// Adding the legend
                var noOfElements = 15;
                var fractureLutLegend = new Lut('rainbow', 512);
                fractureLutLegend.addColorMap("BHS", COLORMAP);
                fractureLutLegend.setColorMap("BHS");

                document.getElementById("legendLabelText").innerHTML = "";
                document.getElementById("legendLegend").innerHTML = "";
                var heightPerElement = 20;
                var canvas = document.createElement('canvas');
                var div = document.getElementById("legendLegend");
                canvas.id = "legend";
                canvas.position = 'absolute';
                canvas.width = 50;
                canvas.height = noOfElements * heightPerElement;
                canvas.style.zIndex = 8;
                canvas.style.position = "absolute";
                // canvas.style.border   = "1px solid w /dhite";
                var ctx = canvas.getContext("2d");
                // No of variations and colors
                for (var i = 0; i < noOfElements; i++) {
                    var color = fractureLutLegend.getColor(i / noOfElements);
                    var colorText = 'rgba(' + 255 * color.r.toString() + ', ' + 255 * color.g.toString() + ', ' + 255 * color.b.toString() + ' ,1.0)';
                    var grd = ctx.createLinearGradient(0, 0, 0, 10);
                    grd.addColorStop(0, colorText);
                    grd.addColorStop(1, colorText);
                    ctx.fillStyle = grd;

                    var y0 = i * heightPerElement;
                    var y1 = (i + 1) * heightPerElement;
                    ctx.fillRect(0, Math.ceil(y0) + 1, 20, Math.floor(y1) - 1);

                    LEGEND_COLOR.push(colorText);

                    if (i == 0) {
                        LEGEND_TEXT.push((Math.round(100 * fractureMax) / 100).toString());
                    } else if (i == noOfElements - 1) {
                        LEGEND_TEXT.push((Math.round(100 * fractureMin) / 100).toString());
                    } else {
                        LEGEND_TEXT.push("");
                    }

                    if (i == 0) {
                        document.getElementById("legendLabelText").innerHTML += Math.round(100 * fractureMax) / 100;
                    }

                    if (i == noOfElements - 1) {
                        document.getElementById("legendLabelText").innerHTML += Math.round(100 * fractureMin) / 100;
                    }

                    if (i < noOfElements - 1) {
                        document.getElementById("legendLabelText").innerHTML += "<br>";
                    }

                }
                div.appendChild(canvas);



            }

            if (value == 'Elapsed Time') {

                var elapsedTimeLut = new Lut('rainbow', 512);
                elapsedTimeLut.addColorMap("BHS", COLORMAP);
                elapsedTimeLut.setColorMap("BHS");

                var elapsedTimeMin = Infinity;
                var elapsedTimeMax = -Infinity;
                for (var n = 0; n < events.length; n++) {
                    if (events[n].elapsedTime && !isNaN(events[n].elapsedTime)) {
                        elapsedTimeMin = Math.min(elapsedTimeMin, events[n].elapsedTime);
                        elapsedTimeMax = Math.max(elapsedTimeMax, events[n].elapsedTime);
                    }
                }

                elapsedTimeMax = events.map(e => e.elapsedTime).quantile(0.95);

                if (localStorage.getItem("elapsed-time-min-3d-plot" + project_id) != null && !isNaN(Number(localStorage.getItem("elapsed-time-min-3d-plot" + project_id)))) {
                    elapsedTimeMin = Number(localStorage.getItem("elapsed-time-min-3d-plot" + project_id));
                }

                if (localStorage.getItem("elapsed-time-max-3d-plot" + project_id) != null && !isNaN(Number(localStorage.getItem("elapsed-time-max-3d-plot" + project_id)))) {
                    elapsedTimeMax = Number(localStorage.getItem("elapsed-time-max-3d-plot" + project_id));
                }

                var normFracture = [];
                var iColor = [];

                for (var n = 0; n < events.length; n++) {
                    var elapsedTimeNorm = (events[n].elapsedTime - elapsedTimeMin) / (elapsedTimeMax - elapsedTimeMin);
                    // normFracture = 1 - normFracture;

                    if (isNaN(elapsedTimeNorm)) {
                        iColor.push(0.0, 0.0, 0.0);
                    } else {
                        // iColor.push( normMagnitude, 0.0, 1 - normMagnitude );
                        var color = elapsedTimeLut.getColor(elapsedTimeNorm);
                        iColor.push(color.r, color.g, color.b);
                    }
                }

                eventGeomDrawn.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColor), 3));

                ///// Adding the legend
                var noOfElements = 15;
                var elapsedTimeLutLegend = new Lut('rainbow', 512);
                elapsedTimeLutLegend.addColorMap("BHS", COLORMAP);
                elapsedTimeLutLegend.setColorMap("BHS");

                document.getElementById("legendLabelText").innerHTML = "";
                document.getElementById("legendLegend").innerHTML = "";
                var heightPerElement = 20;
                var canvas = document.createElement('canvas');
                var div = document.getElementById("legendLegend");
                canvas.id = "legend";
                canvas.position = 'absolute';
                canvas.width = 50;
                canvas.height = noOfElements * heightPerElement;
                canvas.style.zIndex = 8;
                canvas.style.position = "absolute";
                // canvas.style.border   = "1px solid w /dhite";
                var ctx = canvas.getContext("2d");
                // No of variations and colors
                for (var i = 0; i < noOfElements; i++) {
                    var color = elapsedTimeLutLegend.getColor(i / noOfElements);
                    var colorText = 'rgba(' + 255 * color.r.toString() + ', ' + 255 * color.g.toString() + ', ' + 255 * color.b.toString() + ' ,1.0)';
                    var grd = ctx.createLinearGradient(0, 0, 0, 10);
                    grd.addColorStop(0, colorText);
                    grd.addColorStop(1, colorText);
                    ctx.fillStyle = grd;

                    var y0 = i * heightPerElement;
                    var y1 = (i + 1) * heightPerElement;
                    ctx.fillRect(0, Math.ceil(y0) + 1, 20, Math.floor(y1) - 1);

                    LEGEND_COLOR.push(colorText);

                    if (i == 0) {
                        LEGEND_TEXT.push(Math.round(elapsedTimeMin).toString() + " min");
                    } else if (i == noOfElements - 1) {
                        LEGEND_TEXT.push(Math.round(elapsedTimeMax).toString() + " min");
                    } else {
                        LEGEND_TEXT.push("");
                    }

                    if (i == 0) {
                        document.getElementById("legendLabelText").innerHTML += Math.round(elapsedTimeMin).toString() + " min";
                    }

                    if (i == noOfElements - 1) {
                        document.getElementById("legendLabelText").innerHTML += Math.round(elapsedTimeMax).toString() + " min";
                    }

                    if (i < noOfElements - 1) {
                        document.getElementById("legendLabelText").innerHTML += "<br>";
                    }

                }
                div.appendChild(canvas);



            }



        });

        ////////////////////////////////////////////////////////////////////
        ///////////////////////// ERROR GUI ////////////////////////////////
        ////////////////////////////////////////////////////////////////////

        eventErrorOptions = {
            eastError: false,
            northError: false,
            depthError: false,
            azimuthError: false,
        }

        if (events.filter(e => e['eastError']).length > 0) {

            eastErrorMaterial = new THREE.LineBasicMaterial({
                color: 0xffffff,
                linewidth: 1,
            });
            eastErrorGeom = new THREE.BufferGeometry();
            eastErrorGeom.setAttribute('position', new THREE.BufferAttribute(new Float32Array(0), 3));
            eastErrorLine = new THREE.LineSegments(eastErrorGeom, eastErrorMaterial);
            eastErrorLine.frustumCulled = false;
            scene.add(eastErrorLine);

            eventOptions.add(eventErrorOptions, 'eastError', eventErrorOptions['eastError']).name('Toggle East Error').onChange((val) => {
                addUpdateEastErrorBars(val);
            });
        }

        if (events.filter(e => e['northError']).length > 0) {

            northErrorMaterial = new THREE.LineBasicMaterial({
                color: 0xffffff,
                linewidth: 1,
            });
            northErrorGeom = new THREE.BufferGeometry();
            northErrorGeom.setAttribute('position', new THREE.BufferAttribute(new Float32Array(0), 3));
            northErrorLine = new THREE.LineSegments(northErrorGeom, northErrorMaterial);
            northErrorLine.frustumCulled = false;
            scene.add(northErrorLine);

            eventOptions.add(eventErrorOptions, 'northError', eventErrorOptions['northError']).name('Toggle North Error').onChange((val) => {
                addUpdateNorthingErrorBars(val);
            });
        }

        if (events.filter(e => e['depthError']).length > 0) {

            depthErrorMaterial = new THREE.LineBasicMaterial({
                color: 0xffffff,
                linewidth: 1,
            });
            depthErrorGeom = new THREE.BufferGeometry();
            depthErrorGeom.setAttribute('position', new THREE.BufferAttribute(new Float32Array(0), 3));
            depthErrorLine = new THREE.LineSegments(depthErrorGeom, depthErrorMaterial);
            depthErrorLine.frustumCulled = false;
            scene.add(depthErrorLine);

            eventOptions.add(eventErrorOptions, 'depthError', eventErrorOptions['depthError']).name('Toggle Depth Error').onChange((val) => {
                addUpdateDepthErrorBars(val);
            });

            // depthErrorLine.geometry.attributes.position.needsUpdate = true;
        }

        if (events.filter(e => e['azimuthError']).length > 0) {

            azimuthErrorMaterial = new THREE.LineBasicMaterial({
                color: 0xffffff
            });
            azimuthErrorGeom = new THREE.BufferGeometry();
            azimuthErrorGeom.setAttribute('position', new THREE.BufferAttribute(new Float32Array(0), 3));
            azimuthErrorLine = new THREE.LineSegments(azimuthErrorGeom, azimuthErrorMaterial);
            azimuthErrorLine.frustumCulled = false;
            scene.add(azimuthErrorLine);

            eventOptions.add(eventErrorOptions, 'azimuthError', eventErrorOptions['azimuthError']).name('Toggle Azi Error').onChange((val) => {
                addUpdateAziErrorBars(val);
            });

        }

        // Custom min, max!
        var customOptions = {
            magMin: localStorage.getItem("mag-min-3d-plot" + project_id) === null ? '0' : localStorage.getItem("mag-min-3d-plot" + project_id),
            magMax: localStorage.getItem("mag-max-3d-plot" + project_id) === null ? '0' : localStorage.getItem("mag-max-3d-plot" + project_id),
            misfitMin: localStorage.getItem("misfit-min-3d-plot" + project_id) === null ? '0' : localStorage.getItem("misfit-min-3d-plot" + project_id),
            misfitMax: localStorage.getItem("misfit-max-3d-plot" + project_id) === null ? '0' : localStorage.getItem("misfit-max-3d-plot" + project_id),
            fractureMin: localStorage.getItem("fracture-min-3d-plot" + project_id) === null ? '0' : localStorage.getItem("fracture-min-3d-plot" + project_id),
            fractureMax: localStorage.getItem("fracture-max-3d-plot" + project_id) === null ? '0' : localStorage.getItem("fracture-max-3d-plot" + project_id),
            elapsedTimeMin: localStorage.getItem("elapsed-time-min-3d-plot" + project_id) === null ? '0' : localStorage.getItem("elapsed-time-min-3d-plot" + project_id),
            elapsedTimeMax: localStorage.getItem("elapsed-time-max-3d-plot" + project_id) === null ? '0' : localStorage.getItem("elapsed-time-max-3d-plot" + project_id),
        }

        if (events.length > 0 && events[0].hasOwnProperty('magnitude')) {

            eventOptions.add(customOptions, 'magMin').name('Min Mag (size)').onChange((val) => {
                if (val == "") {
                    localStorage.removeItem("mag-min-3d-plot" + project_id);
                } else {
                    localStorage.setItem("mag-min-3d-plot" + project_id, (val));
                }
                eventSizeVariationSlider.setValue(eventSizeVariationSlider.getValue())
                eventColorSlider.setValue(eventColorSlider.getValue())
            });

            eventOptions.add(customOptions, 'magMax').name('Max Mag (size)').onChange((val) => {
                if (val == "") {
                    localStorage.removeItem("mag-max-3d-plot" + project_id);
                } else {
                    localStorage.setItem("mag-max-3d-plot" + project_id, (val));
                }
                eventSizeVariationSlider.setValue(eventSizeVariationSlider.getValue())
                eventColorSlider.setValue(eventColorSlider.getValue())
            });

        }

        if (events.length > 0 && events[0].hasOwnProperty('misfit')) {

            eventOptions.add(customOptions, 'misfitMin').name('Min Misfit (size)').onChange((val) => {

                if (val == "") {
                    localStorage.removeItem("misfit-min-3d-plot" + project_id);
                } else {
                    localStorage.setItem("misfit-min-3d-plot" + project_id, (val));
                }
                eventSizeVariationSlider.setValue(eventSizeVariationSlider.getValue())
                eventColorSlider.setValue(eventColorSlider.getValue())

            });

            eventOptions.add(customOptions, 'misfitMax').name('Max Misfit (size)').onChange((val) => {
                if (val == "") {
                    localStorage.removeItem("misfit-max-3d-plot" + project_id);
                } else {
                    localStorage.setItem("misfit-max-3d-plot" + project_id, (val));
                }
                eventSizeVariationSlider.setValue(eventSizeVariationSlider.getValue())
                eventColorSlider.setValue(eventColorSlider.getValue())

            });
        }

        if (events.length > 0 && events[0].hasOwnProperty('fractureArea')) {

            eventOptions.add(customOptions, 'fractureMin').name('Min Frac Area').onChange((val) => {
                if (val == "") {
                    localStorage.removeItem("fracture-min-3d-plot" + project_id);
                } else {
                    localStorage.setItem("fracture-min-3d-plot" + project_id, (val));
                }
                eventSizeVariationSlider.setValue(eventSizeVariationSlider.getValue())
                eventColorSlider.setValue(eventColorSlider.getValue())
            });

            eventOptions.add(customOptions, 'fractureMax').name('Max Frac Area').onChange((val) => {
                if (val == "") {
                    localStorage.removeItem("fracture-max-3d-plot" + project_id);
                } else {
                    localStorage.setItem("fracture-max-3d-plot" + project_id, (val));
                }
                eventSizeVariationSlider.setValue(eventSizeVariationSlider.getValue())
                eventColorSlider.setValue(eventColorSlider.getValue())
            });

        }

        if (events.length > 0 && events[0].hasOwnProperty('elapsedTime')) {

            eventOptions.add(customOptions, 'elapsedTimeMin').name('Elapsed Time Min (m)').onChange((val) => {

                if (val == "") {
                    localStorage.removeItem("elapsed-time-min-3d-plot" + project_id);
                } else {
                    localStorage.setItem("elapsed-time-min-3d-plot" + project_id, (val));
                }
                eventSizeVariationSlider.setValue(eventSizeVariationSlider.getValue())
                eventColorSlider.setValue(eventColorSlider.getValue())

            });

            eventOptions.add(customOptions, 'elapsedTimeMax').name('Elapsed Time Max (m)').onChange((val) => {
                if (val == "") {
                    localStorage.removeItem("elapsed-time-max-3d-plot" + project_id);
                } else {
                    localStorage.setItem("elapsed-time-max-3d-plot" + project_id, (val));
                }
                eventSizeVariationSlider.setValue(eventSizeVariationSlider.getValue())
                eventColorSlider.setValue(eventColorSlider.getValue())
            });
        }



        var eventFilterOptions = eventFolder.addFolder('Filter');

        var eventFilterAttributes = {
            filter_magnitude_min: 0,
            filter_magnitude_max: 0,
            filter_misfit_min: 0,
            filter_misfit_max: 0,
            filter_east_error_min: 0,
            filter_east_error_max: 0,
            filter_north_error_min: 0,
            filter_north_error_max: 0,
            filter_depth_error_min: 0,
            filter_depth_error_max: 0,
            no_wells: 'All',
        }


        if (events.length > 0 && events[0].hasOwnProperty('magnitude')) {


            eventFilterOptions.add(eventFilterAttributes, 'filter_magnitude_min').name('Min. Magnitude').step(0.05).onChange(function(value) {

                MAGNITUDE_MIN = value;
                var iMagnitudeFilter = [];
                for (var i = 0; i < events.length; i++) {
                    if (Number(events[i].magnitude) >= MAGNITUDE_MIN && Number(events[i].magnitude) <= MAGNITUDE_MAX) {
                        iMagnitudeFilter.push(1, 1, 1);
                    } else {
                        iMagnitudeFilter.push(0, 0, 0);
                    }
                }
                eventGeomDrawn.setAttribute('iMagnitudeFilter', new THREE.InstancedBufferAttribute(new Float32Array(iMagnitudeFilter), 3));
                eventGeomPicking.setAttribute('iMagnitudeFilter', new THREE.InstancedBufferAttribute(new Float32Array(iMagnitudeFilter), 3));

                addUpdateDepthErrorBars();
                addUpdateNorthingErrorBars();
                addUpdateEastErrorBars();
                addUpdateAziErrorBars();
                //shuffleEventGeometry();

            });

            eventFilterOptions.add(eventFilterAttributes, 'filter_magnitude_max').name('Max. Magnitude').step(0.05).onChange(function(value) {

                MAGNITUDE_MAX = value;

                var iMagnitudeFilter = [];
                for (var i = 0; i < events.length; i++) {
                    if (Number(events[i].magnitude) >= MAGNITUDE_MIN && Number(events[i].magnitude) <= MAGNITUDE_MAX) {
                        iMagnitudeFilter.push(1, 1, 1);
                    } else {
                        iMagnitudeFilter.push(0, 0, 0);
                    }
                }


                eventGeomDrawn.setAttribute('iMagnitudeFilter', new THREE.InstancedBufferAttribute(new Float32Array(iMagnitudeFilter), 3));
                eventGeomPicking.setAttribute('iMagnitudeFilter', new THREE.InstancedBufferAttribute(new Float32Array(iMagnitudeFilter), 3));

                addUpdateDepthErrorBars();
                addUpdateNorthingErrorBars();
                addUpdateEastErrorBars();
                addUpdateAziErrorBars();
                //shuffleEventGeometry();

            });

        }


        if (events.length > 0 && events[0].hasOwnProperty('misfit')) {

            eventFilterOptions.add(eventFilterAttributes, 'filter_misfit_min').name('Min. Misfit (ms)').step(0.05).onChange(function(value) {

                MISFIT_MIN = value;

                var iMisfitFilter = [];
                for (var i = 0; i < events.length; i++) {
                    if (Number(events[i].misfit) >= MISFIT_MIN && Number(events[i].misfit) <= MISFIT_MAX) {
                        iMisfitFilter.push(1, 1, 1);
                    } else {
                        iMisfitFilter.push(0, 0, 0);
                    }
                }
                eventGeomDrawn.setAttribute('iMisfitFilter', new THREE.InstancedBufferAttribute(new Float32Array(iMisfitFilter), 3));
                eventGeomPicking.setAttribute('iMisfitFilter', new THREE.InstancedBufferAttribute(new Float32Array(iMisfitFilter), 3));

                addUpdateDepthErrorBars();
                addUpdateNorthingErrorBars();
                addUpdateEastErrorBars();
                addUpdateAziErrorBars(); //shuffleEventGeometry();

            });

            eventFilterOptions.add(eventFilterAttributes, 'filter_misfit_max').name('Max. Misfit (ms)').step(0.05).onChange(function(value) {

                MISFIT_MAX = value;

                var iMisfitFilter = [];
                for (var i = 0; i < events.length; i++) {
                    if (Number(events[i].misfit) >= MISFIT_MIN && Number(events[i].misfit <= MISFIT_MAX)) {
                        iMisfitFilter.push(1, 1, 1);
                    } else {
                        iMisfitFilter.push(0, 0, 0);
                    }
                }
                eventGeomDrawn.setAttribute('iMisfitFilter', new THREE.InstancedBufferAttribute(new Float32Array(iMisfitFilter), 3));
                eventGeomPicking.setAttribute('iMisfitFilter', new THREE.InstancedBufferAttribute(new Float32Array(iMisfitFilter), 3));

                addUpdateDepthErrorBars();
                addUpdateNorthingErrorBars();
                addUpdateEastErrorBars();
                addUpdateAziErrorBars(); //shuffleEventGeometry();

            });

        }


        if (events.length > 0 && events[0].hasOwnProperty('eastError')) {

            eventFilterOptions.add(eventFilterAttributes, 'filter_east_error_min').name('Min. East Error').step(1).onChange(function(value) {

                EAST_ERROR_MIN = value;
                var iErrorFilter = eventGeomDrawn.attributes.iErrorFilter.array;
                for (var i = 0; i < events.length; i++) {
                    if (Number(events[i].eastError) >= EAST_ERROR_MIN && Number(events[i].eastError) <= EAST_ERROR_MAX) {
                        iErrorFilter[3 * i] = 1.0;
                    } else {
                        iErrorFilter[3 * i] = 0.0;
                    }
                }
                eventGeomDrawn.setAttribute('iErrorFilter', new THREE.InstancedBufferAttribute(new Float32Array(iErrorFilter), 3));
                eventGeomPicking.setAttribute('iErrorFilter', new THREE.InstancedBufferAttribute(new Float32Array(iErrorFilter), 3));

                addUpdateDepthErrorBars();
                addUpdateNorthingErrorBars();
                addUpdateEastErrorBars();
                addUpdateAziErrorBars(); //shuffleEventGeometry();

            });

            eventFilterOptions.add(eventFilterAttributes, 'filter_east_error_max').name('Max. East Error').step(0.05).onChange(function(value) {

                EAST_ERROR_MAX = value;

                var iErrorFilter = eventGeomDrawn.attributes.iErrorFilter.array;
                for (var i = 0; i < events.length; i++) {
                    if (Number(events[i].eastError) >= EAST_ERROR_MIN && Number(events[i].eastError) <= EAST_ERROR_MAX) {
                        iErrorFilter[3 * i] = 1.0;
                    } else {
                        iErrorFilter[3 * i] = 0.0;
                    }
                }
                eventGeomDrawn.setAttribute('iErrorFilter', new THREE.InstancedBufferAttribute(new Float32Array(iErrorFilter), 3));
                eventGeomPicking.setAttribute('iErrorFilter', new THREE.InstancedBufferAttribute(new Float32Array(iErrorFilter), 3));

                addUpdateDepthErrorBars();
                addUpdateNorthingErrorBars();
                addUpdateEastErrorBars();
                addUpdateAziErrorBars(); //shuffleEventGeometry();

            });

        }

        if (events.length > 0 && events[0].hasOwnProperty('northError')) {

            eventFilterOptions.add(eventFilterAttributes, 'filter_north_error_min').name('Min. North Error').step(1).onChange(function(value) {

                NORTH_ERROR_MIN = value;

                var iErrorFilter = eventGeomDrawn.attributes.iErrorFilter.array;
                for (var i = 0; i < events.length; i++) {
                    if (Number(events[i].northError) >= NORTH_ERROR_MIN && Number(events[i].northError <= NORTH_ERROR_MAX)) {
                        iErrorFilter[3 * i + 1] = 1.0;
                    } else {
                        iErrorFilter[3 * i + 1] = 0.0;
                    }
                }
                eventGeomDrawn.setAttribute('iErrorFilter', new THREE.InstancedBufferAttribute(new Float32Array(iErrorFilter), 3));
                eventGeomPicking.setAttribute('iErrorFilter', new THREE.InstancedBufferAttribute(new Float32Array(iErrorFilter), 3));


                addUpdateDepthErrorBars();
                addUpdateNorthingErrorBars();
                addUpdateEastErrorBars();
                addUpdateAziErrorBars(); //shuffleEventGeometry();

            });

            eventFilterOptions.add(eventFilterAttributes, 'filter_north_error_max').name('Max. North Error').step(0.05).onChange(function(value) {

                NORTH_ERROR_MAX = value;

                var iErrorFilter = eventGeomDrawn.attributes.iErrorFilter.array;
                for (var i = 0; i < events.length; i++) {
                    if (Number(events[i].northError) >= NORTH_ERROR_MIN && Number(events[i].northError <= NORTH_ERROR_MAX)) {
                        iErrorFilter[3 * i + 1] = 1.0;
                    } else {
                        iErrorFilter[3 * i + 1] = 0.0;
                    }
                }
                eventGeomDrawn.setAttribute('iErrorFilter', new THREE.InstancedBufferAttribute(new Float32Array(iErrorFilter), 3));
                eventGeomPicking.setAttribute('iErrorFilter', new THREE.InstancedBufferAttribute(new Float32Array(iErrorFilter), 3));

                addUpdateDepthErrorBars();
                addUpdateNorthingErrorBars();
                addUpdateEastErrorBars();
                addUpdateAziErrorBars(); //shuffleEventGeometry();

            });

        }


        if (events.length > 0 && events[0].hasOwnProperty('depthError')) {

            eventFilterOptions.add(eventFilterAttributes, 'filter_depth_error_min').name('Min. Depth Error').step(1).onChange(function(value) {

                DEPTH_ERROR_MIN = value;

                var iErrorFilter = eventGeomDrawn.attributes.iErrorFilter.array;
                for (var i = 0; i < events.length; i++) {
                    if (Number(events[i].depthError) >= DEPTH_ERROR_MIN && Number(events[i].depthError <= DEPTH_ERROR_MAX)) {
                        iErrorFilter[3 * i + 2] = 1.0;
                    } else {
                        iErrorFilter[3 * i + 2] = 0.0;
                    }
                }
                eventGeomDrawn.setAttribute('iErrorFilter', new THREE.InstancedBufferAttribute(new Float32Array(iErrorFilter), 3));
                eventGeomPicking.setAttribute('iErrorFilter', new THREE.InstancedBufferAttribute(new Float32Array(iErrorFilter), 3));

                addUpdateDepthErrorBars();
                addUpdateNorthingErrorBars();
                addUpdateEastErrorBars();
                addUpdateAziErrorBars(); //shuffleEventGeometry();

            });

            eventFilterOptions.add(eventFilterAttributes, 'filter_depth_error_max').name('Max. Depth Error').step(0.05).onChange(function(value) {

                DEPTH_ERROR_MAX = value;

                var iErrorFilter = eventGeomDrawn.attributes.iErrorFilter.array;
                for (var i = 0; i < events.length; i++) {
                    if (Number(events[i].depthError) >= DEPTH_ERROR_MIN && Number(events[i].depthError <= DEPTH_ERROR_MAX)) {
                        iErrorFilter[3 * i + 2] = 1.0;
                    } else {
                        iErrorFilter[3 * i + 2] = 0.0;
                    }
                }
                eventGeomDrawn.setAttribute('iErrorFilter', new THREE.InstancedBufferAttribute(new Float32Array(iErrorFilter), 3));
                eventGeomPicking.setAttribute('iErrorFilter', new THREE.InstancedBufferAttribute(new Float32Array(iErrorFilter), 3));

                addUpdateDepthErrorBars();
                addUpdateNorthingErrorBars();
                addUpdateEastErrorBars();
                addUpdateAziErrorBars(); //shuffleEventGeometry();

            });


        }


        if (events.length > 0 && events[0].hasOwnProperty('noWells')) {

            eventFilterOptions.add(eventFilterAttributes, 'no_wells', events.map(e => e.noWells).unique().filter(x => x > -1).concat(['All'])).name('No Mtr Wells').onChange(function(value) {

                var iWellPickedFilter = [];

                for (var i = 0; i < events.length; i++) {
                    if (value == 'All' || Number(value) == events[i].noWells) {
                        iWellPickedFilter.push(1, 1, 1);
                    } else {
                        iWellPickedFilter.push(0, 0, 0);
                    }
                }

                eventGeomDrawn.setAttribute('iWellPickedFilter', new THREE.InstancedBufferAttribute(new Float32Array(iWellPickedFilter), 3));
                eventGeomPicking.setAttribute('iWellPickedFilter', new THREE.InstancedBufferAttribute(new Float32Array(iWellPickedFilter), 3));

                addUpdateDepthErrorBars();
                addUpdateNorthingErrorBars();
                addUpdateEastErrorBars();
                addUpdateAziErrorBars(); //shuffleEventGeometry();


            });
        }

        if (events.length > 0 && hide_event_datasets.length > 0) {
            for (let n = 0; n < hide_event_datasets.length; n++) {
                eventCheckBoxes[hide_event_datasets[n]][hide_event_datasets[n]].setValue(false);
            }
        }

        if (well_stage_info.length > 0 && event_datasets.length > 0 && !MODELLING_MODE) {

            var srvFolder = gui.addFolder('SRV Calculation');

            var srvFolderValues = {
                dataset: event_datasets.map(e => e['title'])[0],
                datasets: event_datasets.map(e => e['title']), //.unique(),
                dataset_id: event_datasets.map(e => e['id'])[0],
                well_id: well_stage_info[0]['well_id'],
                well: well_stage_info[0]['well_alias'],
                stage: well_stage_info[0]['stage_name'],
                stage_id: well_stage_info[0]['stage_id'],
                formations: ["All"].concat(formationTops.map(e => e.name)),
                formation: 'All',
                formation_id: -1,
                ftype: '',
                srv: 0,
                linearity: 0,
                cloud_azimuth: 0,
                well_stage: well_stage_info.map(e => e['well_name'] + " " + e['stage_name'])[0],
                well_stages: well_stage_info.map(e => e['well_name'] + " " + e['stage_name']), //.unique(),
                frac_designs: ['All Frac Designs'].concat(frac_designs.map(e => e['name'])),
                frac_design: 'All Frac Designs',
                frac_design_id: null,
                show_src: true,
                alpha: 0.1,
                dist_neighbours: 150,
                min_neighbours: 5,
                frac_length: null,
                frac_width: null,
                frac_height: null,
                frac_toe_heel_shift: null,
                calculateSRV: function() {},
                toggle_srv: function() {},
                save_srv_snapshot: function() {},
                save_srv_values: function() {},
                calculate_srv_for_all_stages_and_all_formations: function() {}
            };

            srvFolder.add(srvFolderValues, 'dataset', srvFolderValues['datasets']).name('Dataset').onChange((val) => {
                srvFolderValues['dataset_id'] = event_datasets.filter(e => e['title'] == val)[0]['id'];
                // updateEventsView();
            });

            var wellStagesSRVDropDown = srvFolder.add(srvFolderValues, 'well_stage', srvFolderValues['well_stages']).name('Well, Stage').onChange((val) => {
                var index = srvFolderValues['well_stages'].indexOf(val);
                srvFolderValues['stage_id'] = well_stage_info[index]['stage_id'];
                srvFolderValues['stage'] = well_stage_info[index]['stage_name'];
                srvFolderValues['well'] = well_stage_info[index]['well_alias'];
                srvFolderValues['well_id'] = well_stage_info[index]['well_id'];
                // updateEventsView();
                // eventCheckBoxes[snapShotValues['dataset']][snapShotValues['well']][snapShotValues['well']].setValue(true);
                console.log("Called on change..");
            }).listen();

            var fracDesignDropdown = srvFolder.add(srvFolderValues, 'frac_design', srvFolderValues['frac_designs']).name('Frac Design').onChange((val) => {
                var index = frac_designs.map(e => e['name']).indexOf(val);
                if (index == -1) {
                    srvFolderValues['frac_design_id'] = null;
                } else {
                    srvFolderValues['frac_design_id'] = frac_designs[index]['id'];
                }
            }).listen();

            var formationsSRVDropdown = srvFolder.add(srvFolderValues, 'formation', srvFolderValues['formations']).name('Formation').onChange((val) => {
                var index = formationTops.map((e) => e.name).indexOf(val);
                srvFolderValues['formation_id'] = index == -1 ? -1 : formationTops[index]['id'];
            }).listen();

            if (admin) {

                srvFolder.add(srvFolderValues, 'ftype', srv_figure_types.map(e => e['type']).sort()).name('Figure Type').onChange((val) => {

                    var index = srv_figure_types.map(e => e['type']).indexOf(val);
                    for (let n = 0; n < sceneView.length; n++) {
                        sceneView[n]['aspect'] = srv_figure_types[index]['aspect'];
                    }
                    // window.dispatchPerf(new Perf('resize'));
                    onWindowResize();
                });

            }


            srvFolder.add(srvFolderValues, 'alpha').min(-1).max(1).step(0.01).name('Alpha');

            srvFolder.add(srvFolderValues, 'dist_neighbours').min(0).max(1000).step(1).name('Dist Neighbours');

            srvFolder.add(srvFolderValues, 'min_neighbours').step(1).min(0).max(10).name('Min. Neighbours');

            var calculateSRVButton = srvFolder.add(srvFolderValues, 'calculateSRV').name('Calc SRV').onChange(async function(val) {

                document.getElementById("srvCalculation").innerHTML = "";

                srvFolderValues['frac_length'] = null;
                srvFolderValues['frac_width'] = null;
                srvFolderValues['frac_height'] = null;
                srvFolderValues['frac_tool_heel_shift'] = null;
                srvFolderValues['srv'] = null;
                srvFolderValues['cloud_azimuth'] = null;
                srvFolderValues['volume'] = null;
                srvFolderValues['linearity'] = null;

                var signedVolumeOfTriangle = function(ip1, ip2, ip3) {
                    return ip1.dot(ip2.cross(ip3)) / 6.0;
                }

                // Remove existing SRV's;
                srvFolderValues['show_src'] = true;
                try {
                    var srvObject = scene.getObjectByName("SRV Volume");
                    if (srvObject) {
                        scene.remove(srvObject);
                    }
                } catch (e) {
                    console.log("Error while showing/hiding SRV volume object", e);
                }

                try {
                    var srvObject = scene.getObjectByName("SRV Boundary");
                    if (srvObject) {
                        scene.remove(srvObject);
                    }
                } catch (e) {
                    console.log("Error while showing/hiding SRV Boundary object", e);
                }

                if (srvFolderValues['dataset'] == "") {
                    alert("Select a dataset first.");
                    return;
                }

                // console.log("srvFolderValues", srvFolderValues);
                // console.log("events[0]", events[0]);

                if (srvFolderValues['stage'] != "All") {
                    var srvEvents = events.filter((e) => e['dataset'] == srvFolderValues['dataset'] && e['well'] == srvFolderValues['well'] && e['stage'] == srvFolderValues['stage']);
                } else {
                    var srvEvents = events.filter((e) => e['dataset'] == srvFolderValues['dataset'] && e['well'] == srvFolderValues['well']);
                }

                if (srvFolderValues['formation'] != "All") {
                    srvEvents = srvEvents.filter((e) => e['formation'] == srvFolderValues['formation']);
                }

                if (srvFolderValues['frac_design'] != "All Frac Designs") {
                    var index = frac_designs.map(e => e['name']).indexOf(srvFolderValues['frac_design']);
                    var frac_design_wells_stage = frac_designs[index]['stage_info'].map(e => e['well_alias'] + " " + e['stage_name']);
                    srvEvents = srvEvents.filter((e) => frac_design_wells_stage.indexOf(e['name']) > -1);
                }

                if (srvEvents.length < 1) {
                    // alert("Fewer than 10 events found. Please try another combination");
                    // return;
                }

                eventCheckBoxes['All Events'].setValue(false);

                eventGeomDrawn.instanceCount = events.length;
                eventGeomPicking.instanceCount = events.length;


                // if (srvFolderValues['stage'] == "All") {
                //     eventCheckBoxes[srvFolderValues['dataset']][srvFolderValues['well']][srvFolderValues['well']].setValue(true);
                // } else {
                //     eventCheckBoxes[srvFolderValues['dataset']][srvFolderValues['well']][srvFolderValues['well'] + " " + srvFolderValues['stage']].setValue(true);
                // }

                var iScale = [];

                for (var i = 0; i < events.length; i++) {

                    if (i == 0) {
                        //                        console.log(srvFolderValues);
                        //                      console.log(events[0]);
                    }

                    var output = 1;
                    if (srvFolderValues['dataset'] != events[i].dataset) {
                        output = 0;
                    }

                    if (srvFolderValues['well'] != events[i].well) {
                        output = 0;
                    }

                    if (srvFolderValues['stage'] != "All" && srvFolderValues['stage'] != events[i].stage) {
                        output = 0;
                    }

                    if (srvFolderValues['formation'] != "All" && srvFolderValues['formation'] != events[i].formation) {
                        output = 0;
                    }

                    if (srvFolderValues['frac_design'] != "All Frac Designs" && frac_design_wells_stage.indexOf(events[i].name) == -1) {
                        output = 0;
                    }

                    iScale.push(output, output, output);

                }

                eventGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                eventGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

                eventSizeSlider.setValue(4);

                var positionArray = [];
                var positions = [];

                var srvEventsFiltered = [];
                for (var j = 0; j < srvEvents.length; j++) {
                    var srvCounter = 0;
                    for (var j1 = 0; j1 < srvEvents.length; j1++) {
                        var distance = Math.pow(Math.pow(srvEvents[j].x - srvEvents[j1].x, 2) + Math.pow(srvEvents[j].y - srvEvents[j1].y, 2) + Math.pow(srvEvents[j].z - srvEvents[j1].z, 2), 0.5);
                        if (distance <= srvFolderValues['dist_neighbours']) {
                            srvCounter++;
                        }

                        if (srvCounter > srvFolderValues['min_neighbours']) {

                            break;
                        }
                    }

                    if (srvCounter <= srvFolderValues['min_neighbours']) {
                        continue;
                    }

                    srvEventsFiltered.push(srvEvents[j]);

                    positionArray.push([
                        (DOWNSAMPLE * srvEvents[j].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                        (DOWNSAMPLE * srvEvents[j].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                        (DOWNSAMPLE * srvEvents[j].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                    ]);

                    positions.push(new THREE.Vector3(
                        (DOWNSAMPLE * srvEvents[j].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                        (DOWNSAMPLE * srvEvents[j].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                        (DOWNSAMPLE * srvEvents[j].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                    ));

                }

                if (srvEventsFiltered.length < 4) {
                    return;
                }

                try {
                    var lengthSrv = srvEventsFiltered.map(e => e['length']);
                    var indexMin = lengthSrv.indexOf(lengthSrv.min());
                    var indexMax = lengthSrv.indexOf(lengthSrv.max());

                    srvFolderValues['frac_length'] = Math.pow(
                        Math.pow(srvEventsFiltered[indexMin].x - srvEventsFiltered[indexMax].x, 2) +
                        Math.pow(srvEventsFiltered[indexMin].y - srvEventsFiltered[indexMax].y, 2) +
                        Math.pow(srvEventsFiltered[indexMin].z - srvEventsFiltered[indexMax].z, 2), 0.5)

                } catch (e) {
                    console.log("Error calculating frac length ", e);
                }

                try {
                    var heightSrv = srvEventsFiltered.map(e => e['y']);
                    srvFolderValues['frac_height'] = Math.abs(heightSrv.max() - heightSrv.min());

                } catch (e) {
                    console.log("Error calculating frac height ", e);
                }

                srvFolderValues['frac_tool_heel_shift'] = srvEventsFiltered.map(e => e['width']).mean();

                // Calculation of Too-heel only if it belongs to a particular stage;
                // if (srvFolderValues['stage'] != "All") {

                //     var stagePerfs = perf.filter((e) => e['dataset'] == "Master" && e['stage'] == srvFolderValues['stage'] && e['well'] == srvFolderValues['well'])

                //     if (stagePerfs.length > 1) {

                //         var perfsCenter = [stagePerfs.map(e => e['z']).mean(), stagePerfs.map(e => e['x']).mean(), stagePerfs.map(e => e['y']).mean()]; // Northing, TVDSS, Easting

                //         var perfsCenterX = perfsCenter[0] - local_easting;
                //         var perfsCenterY = perfsCenter[1] - local_northing;
                //         var perfsCenterZ = perfsCenter[2] - local_tvdss;

                //         var perfsRotatedX = perfsCenterX * rotation_matrix[0][0] + perfsCenterY * rotation_matrix[0][1] + perfsCenterZ * rotation_matrix[0][2];
                //         // var perfsRotatedY = perfsCenterX * rotation_matrix[1][0] + perfsCenterY * rotation_matrix[1][1] + perfsCenterZ * rotation_matrix[1][2];

                //         var srvEventsCenter = [srvEventsFiltered.map(e => e['z']).mean(), srvEventsFiltered.map(e => e['x']).mean(), srvEventsFiltered.map(e => e['y']).mean()];
                //         var srvEventsCenterX = srvEventsCenter[0] - local_easting
                //         var srvEventsCenterY = srvEventsCenter[1] - local_northing
                //         var srvEventsCenterZ = srvEventsCenter[2] - local_tvdss

                //         var srvRotatedX = srvEventsCenterX * rotation_matrix[0][0] + srvEventsCenterY * rotation_matrix[0][1] + srvEventsCenterZ * rotation_matrix[0][2];
                //         // var srvRotatedY = srvEventsCenterX * rotation_matrix[1][0] + srvEventsCenterY * rotation_matrix[1][1] + srvEventsCenterZ * rotation_matrix[1][2];
                //         srvFolderValues['frac_tool_heel_shift'] = perfsRotatedX - srvRotatedX;
                //     } else {
                //         srvFolderValues['frac_tool_heel_shift'] = srvEventsFiltered.map(e => e['width']).mean();
                //     }
                // }

                var geometry = new THREE.Geometry();
                var material = new THREE.MeshLambertMaterial({ color: 0xFFFFFF, opacity: 1 });
                geometry.vertices = positions;
                var alphaShapeResult = alphaShape(srvFolderValues['alpha'], positionArray);
                for (var l1 = 0; l1 < alphaShapeResult.length; l1++) {
                    geometry.faces.push(new THREE.Face3(alphaShapeResult[l1][0], alphaShapeResult[l1][1], alphaShapeResult[l1][2]));
                }

                // geometry.computeBoundingSphere();
                var mesh = new THREE.Mesh(geometry, material);
                mesh.name = "SRV Volume";
                scene.add(mesh);

                if (srvFolderValues['stage'] != "All") {
                    var srvLineMaterial = new THREE.LineBasicMaterial({ color: new THREE.Color(getColor(Number(srvFolderValues['stage']))).convertSRGBToLinear(), linewidth: 2 });
                } else {
                    var srvLineMaterial = new THREE.LineBasicMaterial({ color: 0xff000f, linewidth: 2 });
                }

                var wireframeGeometry = new THREE.WireframeGeometry(geometry);
                var wireframe = new THREE.LineSegments(wireframeGeometry, srvLineMaterial);
                wireframe.name = "SRV Boundary";
                scene.add(wireframe);

                var volume = 0;
                for (var l1 = 0; l1 < geometry.faces.length; l1++) {
                    var p1 = new THREE.Vector3(1, 1, 1);
                    var p2 = new THREE.Vector3(1, 1, 1);
                    var p3 = new THREE.Vector3(1, 1, 1);
                    p1.copy(geometry.vertices[geometry.faces[l1].a]).multiplyScalar(1 / DOWNSAMPLE);
                    p2.copy(geometry.vertices[geometry.faces[l1].b]).multiplyScalar(1 / DOWNSAMPLE);
                    p3.copy(geometry.vertices[geometry.faces[l1].c]).multiplyScalar(1 / DOWNSAMPLE);
                    volume += signedVolumeOfTriangle(p3, p2, p1);
                }
                var result = findLinearityAzimuthOfPoints(positionArray.map(e => e[2]), positionArray.map(e => e[0]));


                document.getElementById("srvCalculation").innerHTML = "SRV - " + Math.round(10 * volume) / 10 + " cubic ft.";
                document.getElementById("srvCalculation").innerHTML += "<br>Azimuth - " + Math.round(100 * result.azimuth) / 100;
                document.getElementById("srvCalculation").innerHTML += "<br>Linearity - " + Math.round(100 * result.linearity) / 100;
                document.getElementById("srvCalculation").innerHTML += "<br>Frac Length - " + Math.round(100 * srvFolderValues['frac_length']) / 100 + " ft.";
                document.getElementById("srvCalculation").innerHTML += "<br>Frac Height - " + Math.round(100 * srvFolderValues['frac_height']) / 100 + " ft.";
                document.getElementById("srvCalculation").innerHTML += "<br>Frac Toe-Heel Shift - " + Math.round(100 * srvFolderValues['frac_toe_heel_shift']) / 100 + " ft.";
                // document.getElementById("srvCalculation").innerHTML += "<br>Frac Toe-Heel Shift Test - " + Math.round(100 * srvFolderValues['frac_toe_heel_shift_test']) / 100 + " ft.";



                srvFolderValues['srv'] = volume;
                srvFolderValues['cloud_azimuth'] = result.azimuth;
                srvFolderValues['linearity'] = result.azimuth;


            });

            srvFolder.add(srvFolderValues, 'toggle_srv').name('Show/Hide SRV').onChange((val) => {

                srvFolderValues['show_src'] = !srvFolderValues['show_src'];

                try {
                    if (!srvFolderValues['show_src']) {
                        eventSizeSlider.setValue(10);
                        document.getElementById("srvCalculation").innerHTML = "";
                    }
                } catch (e) {
                    console.log("Error while updating event size.", e);
                }

                try {
                    var srvObject = scene.getObjectByName("SRV Volume");
                    if (srvObject) {
                        srvObject.visible = srvFolderValues['show_src'];
                    }
                } catch (e) {
                    console.log("Error while showing/hiding SRV Volume object", e);
                }

                try {
                    var srvObject = scene.getObjectByName("SRV Boundary");
                    if (srvObject) {
                        srvObject.visible = srvFolderValues['show_src'];
                    }
                } catch (e) {
                    console.log("Error while showing/hiding SRV Boundary object", e);
                }


            });

            if (admin) {

                srvFolder.add(srvFolderValues, 'save_srv_snapshot').name('Save Values & Figures').onChange((val) => {


                    if (srvFolderValues["ftype"] == '') {
                        alert("Select a figure type first.");
                        return;
                    }

                    if (srvFolderValues["ftype"].includes("Well") && srvFolderValues["stage_id"] != -1) {
                        alert("You've selected figure type that includes well, for a stage-wise combination. Please check.");
                        return;
                    }

                    // console.log("well_id", reportFiguresFolderValues["well_id"]);
                    // console.log("stage_id", reportFiguresFolderValues["stage_id"]);
                    // console.log("ftype", reportFiguresFolderValues["ftype"]);
                    // console.log("project_id", project_id);
                    if (SCENE_ROWS * SCENE_COLS > 1) {
                        alert("This feature is not available in multiple scenes.");
                        return;
                    }

                    let sceneIndex = 0;

                    let factor = SNAPSHOT_FACTOR;
                    let factor_compass_grid = COMPASS_FACTOR;
                    let widthImg = factor * sceneView[sceneIndex].maxWidth * sceneView[sceneIndex].width;
                    let heightImg = factor * sceneView[sceneIndex].maxHeight * sceneView[sceneIndex].height
                    let leftImg = factor * sceneView[sceneIndex].maxWidth * sceneView[sceneIndex].left;
                    let bottomImg = factor * sceneView[sceneIndex].maxHeight * sceneView[sceneIndex].bottom;

                    if (!isNaN(sceneView[sceneIndex].aspect) && Number(sceneView[sceneIndex].aspect) != 0) {

                        let newHeightImg = widthImg / sceneView[sceneIndex].aspect;
                        if (newHeightImg > heightImg) {
                            let newWidthImg = heightImg * sceneView[sceneIndex].aspect
                            leftImg = leftImg + Math.abs(0.5 * (newWidthImg - widthImg));
                            widthImg = newWidthImg;
                        } else {
                            bottomImg = bottomImg + Math.abs(0.5 * (newHeightImg - heightImg));
                            heightImg = newHeightImg;
                        }
                    }

                    renderer.setSize(factor * renderer.getSize().x, factor * renderer.getSize().y, false);
                    compassRenderer.setSize(factor_compass_grid * compassRenderer.getSize().x, factor_compass_grid * compassRenderer.getSize().y, false);
                    render(factor, factor_compass_grid);
                    var masterScreenshot = renderer.domElement.toDataURL();
                    var compassScreenshot = compassRenderer.domElement.toDataURL();


                    renderer.setSize(renderer.getSize().x / factor, renderer.getSize().y / factor, false);
                    compassRenderer.setSize(compassRenderer.getSize().x / factor_compass_grid, compassRenderer.getSize().y / factor_compass_grid, false);

                    render();

                    arrowHelper.setLength(45, 10, 10);
                    arrowHelper2.setLength(45, 10, 10);
                    arrowHelper3.setLength(45, 10, 10);

                    var img1 = document.createElement('img');
                    var img2 = document.createElement('img');
                    var img3 = document.createElement('img');
                    var canvas = document.createElement('canvas');

                    img1.src = masterScreenshot;
                    img1.onload = function() {
                        img2.src = compassScreenshot;
                        canvas.width = widthImg;
                        canvas.height = heightImg;
                    };

                    img2.onload = function() {
                        if (SCENE_COLS > 1 || SCENE_ROWS > 1)
                            img3.src = img2.src;
                        else {
                            let imageData = removeImageBlanks(img2); //Will return cropped image data
                            img3.src = imageData;
                        }
                    }

                    img3.onload = function() {

                        var fontSize = 12 * factor_compass_grid; //Math.round(Math.min(img1.height, img1.width)/50);

                        var context = canvas.getContext('2d');
                        context.drawImage(img1, leftImg, bottomImg, widthImg, heightImg, 0, 0, widthImg, heightImg);
                        if (SCENE_COLS > 1 || SCENE_ROWS > 1) {
                            context.drawImage(img3, leftImg, bottomImg, widthImg, heightImg, 0, 0, widthImg, heightImg);
                        } else {
                            // context.drawImage(img3, 0.025*Math.min(img1.height, img1.width), img1.height - img3.height - 0.025*Math.min(img1.height, img1.width));
                            context.drawImage(img3, 0.025 * Math.min(heightImg, widthImg), heightImg - img3.height - 0.025 * Math.min(heightImg, widthImg));
                        }
                        context.font = fontSize.toString() + 'pt Nunito';
                        context.fillStyle = 'white';
                        context.fillText("Grid size " + gridSize + " ft", 0.025 * Math.min(heightImg, widthImg), 0.025 * Math.min(heightImg, widthImg) + fontSize);

                        var image_data = canvas.toDataURL()


                        $(function() {
                            $.ajax({
                                type: "POST",
                                url: "/projects/" + project_id + "/event_volumes",
                                data: {
                                    'event_volume': {
                                        'event_dataset_id': srvFolderValues['dataset_id'],
                                        'stage_id': srvFolderValues["stage_id"] == -1 ? null : srvFolderValues["stage_id"],
                                        'well_id': srvFolderValues["well_id"],
                                        'project_id': project_id,
                                        'formation_id': srvFolderValues["formation_id"] == -1 ? null : srvFolderValues['formation_id'],
                                        'volume': srvFolderValues["srv"],
                                        'linearity': srvFolderValues["linearity"],
                                        'cloud_azimuth': srvFolderValues["cloud_azimuth"],
                                        'no_of_neighbours': srvFolderValues["min_neighbours"],
                                        'alpha': srvFolderValues["alpha"],
                                        'distance_neighbours': srvFolderValues["dist_neighbours"],
                                        'frac_tool_heel_shift': srvFolderValues["frac_tool_heel_shift"],
                                        'frac_length': srvFolderValues["frac_length"],
                                        'frac_height': srvFolderValues["frac_height"],
                                        'frac_design_id': srvFolderValues["frac_design_id"],
                                    }
                                },
                                async: false,
                                success: function(data) {
                                    console.log(data);
                                    $('#loadingStatus').html(data['message'])
                                    $('#loadingStatus').show()
                                    setTimeout(function() {
                                        $('#loadingStatus').fadeOut('slow');
                                    }, 3000); //
                                }
                            });
                        });




                        $(function() {
                            $.ajax({
                                type: "POST",
                                url: "/projects/" + project_id + "/figures",
                                data: {
                                    'figure': {
                                        'event_dataset_id': srvFolderValues['dataset_id'],
                                        'stage_id': srvFolderValues["stage_id"] == -1 ? null : srvFolderValues["stage_id"],
                                        'well_id': srvFolderValues["well_id"],
                                        'project_id': project_id,
                                        'ftype': srvFolderValues["ftype"],
                                        'frac_design_id': srvFolderValues["frac_design_id"],
                                        'image': image_data,
                                        'formation_id': srvFolderValues["formation_id"] == -1 ? null : srvFolderValues['formation_id'],
                                        'image_file_name': srvFolderValues['well_stage'] + ".png"
                                    }
                                },
                                success: function(data) {
                                    console.log(data);
                                    $('#loadingStatus').html(data['message'])
                                    $('#loadingStatus').show()
                                    setTimeout(function() {
                                        $('#loadingStatus').fadeOut('slow');
                                    }, 3000); //
                                }
                            });
                        });



                    };

                });

                var srvValuesSaveButton = srvFolder.add(srvFolderValues, 'save_srv_values').name('Save SRV Values').onChange((val) => {

                    $(function() {
                        $.ajax({
                            type: "POST",
                            url: "/projects/" + project_id + "/event_volumes",
                            data: {
                                'event_volume': {
                                    'event_dataset_id': srvFolderValues['dataset_id'],
                                    'stage_id': srvFolderValues["stage_id"] == -1 ? null : srvFolderValues["stage_id"],
                                    'well_id': srvFolderValues["well_id"],
                                    'project_id': project_id,
                                    'formation_id': srvFolderValues["formation_id"] == -1 ? null : srvFolderValues['formation_id'],
                                    'volume': srvFolderValues["srv"],
                                    'linearity': srvFolderValues["linearity"],
                                    'cloud_azimuth': srvFolderValues["cloud_azimuth"],
                                    'no_of_neighbours': srvFolderValues["min_neighbours"],
                                    'alpha': srvFolderValues["alpha"],
                                    'distance_neighbours': srvFolderValues["dist_neighbours"],
                                    'frac_tool_heel_shift': srvFolderValues["frac_tool_heel_shift"],
                                    'frac_length': srvFolderValues["frac_length"],
                                    'frac_design_id': srvFolderValues["frac_design_id"],
                                    'frac_height': srvFolderValues["frac_height"],
                                }
                            },
                            async: false,
                            success: function(data) {
                                console.log(data);
                                $('#loadingStatus').html(data['message'])
                                $('#loadingStatus').show()
                                setTimeout(function() {
                                    $('#loadingStatus').fadeOut('slow');
                                }, 3000); //
                            }
                        });
                    });
                });

                srvFolder.add(srvFolderValues, 'calculate_srv_for_all_stages_and_all_formations').name('Calculate for all Stages & Formations').onChange(async function(val) {

                    var well_stage_index = srvFolderValues['well_stages'].indexOf(srvFolderValues['well_stage']);

                    var formation_index = srvFolderValues['formations'].indexOf(srvFolderValues['formation']);

                    console.log("well_stage_index", well_stage_index, "formation_index", formation_index, "formations", srvFolderValues['formations'], "well_stages", srvFolderValues['well_stages']);

                    var counter = 0;
                    for (var wsi = well_stage_index; wsi < srvFolderValues['well_stages'].length; wsi++) {
                        for (var fi = formation_index; fi < srvFolderValues['formations'].length; fi++) {

                            console.log("wsi", wsi, "fi", fi);
                            if (srvFolderValues['well_stages'][wsi].endsWith("All")) {
                                continue;
                            }
                            counter = counter + 1;

                            (function(well_stage_i, form_i, timeout) {
                                setTimeout(function() {
                                    srvFolderValues['well_stage'] = srvFolderValues['well_stages'][well_stage_i];
                                    wellStagesSRVDropDown.__onChange(srvFolderValues['well_stages'][well_stage_i]);
                                    srvFolderValues['formation'] = srvFolderValues['formations'][form_i];
                                    formationsSRVDropdown.__onChange(srvFolderValues['formations'][form_i]);
                                    calculateSRVButton.__onChange("123");
                                    srvValuesSaveButton.__onChange("123");
                                }, timeout * 6000);
                            })(wsi, fi, counter);


                        }
                    }



                });

            }








        }




    }

    ///////////////////////////////////////////
    ///// 	MOMENT TENSOR MODIFIED START  /////
    ///////////////////////////////////////////

    if (momentTensor.length > 0) {
        var momentTensorFolder = gui.addFolder('Moment Tensors');

        if (true) {

            var momentTensorWells = [],
                momentTensorStages = [],
                momentTensorDatasets = [];

            momentTensorCheckBoxes = [
                [
                    []
                ]
            ];
            momentTensorControllers = [
                [
                    []
                ]
            ];


            momentTensorControllers['All momentTensors'] = new Array();
            momentTensorControllers['All momentTensors'][0] = false;

            momentTensorCheckBoxes['All momentTensors'] = momentTensorFolder.add(momentTensorControllers['All momentTensors'], 0, momentTensorControllers['All momentTensors'][0]).name("All").onChange(function(value) {

                iScale = [];
                for (var i = 0; i < momentTensor.length; i++) {
                    if (value) {
                        iScale.push(1, 1, 1);
                    } else {
                        iScale.push(0, 0, 0);
                    }
                }

                for (var a = 0; a < 4; a++) {
                    momentTensorGeomDrawn[a].setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                }
                momentTensorGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

                momentTensorDatasets = [];
                // Show Hide Values
                for (var i = 0; i < momentTensor.length; i++) {
                    momentTensorDatasets.push(momentTensor[i].dataset);
                }
                momentTensorDatasets = momentTensorDatasets.unique();

                for (var i2 = 0; i2 < momentTensorDatasets.length; i2++) {

                    momentTensorControllers[momentTensorDatasets[i2]][momentTensorDatasets[i2]] = value;
                    try {
                        momentTensorCheckBoxes[momentTensorDatasets[i2]][momentTensorDatasets[i2]].updateDisplay();
                    } catch (e) {
                        console.log(e);
                    }
                    var momentTensorWellsTemp = [];
                    for (var i = 0; i < momentTensor.length; i++) {
                        if (momentTensor[i].dataset == momentTensorDatasets[i2])
                            momentTensorWellsTemp.push(momentTensor[i].well);
                    }
                    momentTensorWellsTemp = momentTensorWellsTemp.unique();

                    for (var i = 0; i < momentTensorWellsTemp.length; i++) {
                        var momentTensorWellsKeys = Object.keys(momentTensorControllers[momentTensorDatasets[i2]][momentTensorWellsTemp[i]]);
                        for (var i1 = 0; i1 < momentTensorWellsKeys.length; i1++) {
                            momentTensorControllers[momentTensorDatasets[i2]][momentTensorWellsTemp[i]][momentTensorWellsKeys[i1]] = value;
                            try {
                                momentTensorCheckBoxes[momentTensorDatasets[i2]][momentTensorWellsTemp[i]][momentTensorWellsKeys[i1]].updateDisplay();
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }

                }


            });


            // Get all unique datasets
            for (var i = 0; i < momentTensor.length; i++) {
                momentTensorDatasets.push(momentTensor[i].dataset);
            }
            momentTensorDatasets = momentTensorDatasets.unique();


            for (k = 0; k < momentTensorDatasets.length; k++) {

                datasetFolder = momentTensorFolder.addFolder(momentTensorDatasets[k]);

                momentTensorCheckBoxes[momentTensorDatasets[k]] = new Array();
                momentTensorControllers[momentTensorDatasets[k]] = new Array();
                momentTensorControllers[momentTensorDatasets[k]][momentTensorDatasets[k]] = false; //momentTensorWells[j]);

                momentTensorCheckBoxes[momentTensorDatasets[k]][momentTensorDatasets[k]] = datasetFolder.add(momentTensorControllers[momentTensorDatasets[k]], momentTensorDatasets[k], momentTensorControllers[momentTensorDatasets[k]][momentTensorDatasets[k]]).name("All Wells").onChange(function(value) {

                    iScale = [];
                    for (var i = 0; i < momentTensor.length; i++) {
                        if (momentTensor[i].dataset == this.property) {
                            if (value) {
                                iScale.push(1, 1, 1);
                            } else {
                                iScale.push(0, 0, 0);
                            }
                        } else {
                            iScale.push(momentTensorGeomPicking.attributes.iScale.array[3 * i], momentTensorGeomPicking.attributes.iScale.array[3 * i + 1], momentTensorGeomPicking.attributes.iScale.array[3 * i + 2]);
                        }
                    }

                    for (var a = 0; a < 4; a++) {
                        momentTensorGeomDrawn[a].setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                    }
                    momentTensorGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));


                    var momentTensorWellsTemp = [];
                    for (var i = 0; i < momentTensor.length; i++) {
                        if (momentTensor[i].dataset == this.property)
                            momentTensorWellsTemp.push(momentTensor[i].well);
                    }
                    momentTensorWellsTemp = momentTensorWellsTemp.unique();


                    for (var i = 0; i < momentTensorWellsTemp.length; i++) {
                        var momentTensorWellsKeys = Object.keys(momentTensorControllers[this.property][momentTensorWellsTemp[i]]);
                        for (var i1 = 0; i1 < momentTensorWellsKeys.length; i1++) {
                            momentTensorControllers[this.property][momentTensorWellsTemp[i]][momentTensorWellsKeys[i1]] = value;
                            try {
                                momentTensorCheckBoxes[this.property][momentTensorWellsTemp[i]][momentTensorWellsKeys[i1]].updateDisplay();
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }

                    // momentTensorControllers[this.dataset][this.property][key] = value;
                    // momentTensorCheckBoxes[this.dataset][this.property][key].updateDisplay();



                });

                momentTensorCheckBoxes[momentTensorDatasets[k]][momentTensorDatasets[k]].dataset = momentTensorDatasets[k];

                momentTensorWells = [];
                for (var i = 0; i < momentTensor.length; i++) {
                    if (momentTensor[i].dataset == momentTensorDatasets[k])
                        momentTensorWells.push(momentTensor[i].well);
                }
                momentTensorWells = momentTensorWells.unique();

                for (j = 0; j < momentTensorWells.length; j++) {

                    wellFolder = datasetFolder.addFolder(momentTensorWells[j]);

                    momentTensorCheckBoxes[momentTensorDatasets[k]][momentTensorWells[j]] = new Array();
                    momentTensorControllers[momentTensorDatasets[k]][momentTensorWells[j]] = new Array();
                    momentTensorControllers[momentTensorDatasets[k]][momentTensorWells[j]][momentTensorWells[j]] = false; //momentTensorWells[j]);

                    momentTensorCheckBoxes[momentTensorDatasets[k]][momentTensorWells[j]][momentTensorWells[j]] = wellFolder.add(momentTensorControllers[momentTensorDatasets[k]][momentTensorWells[j]], momentTensorWells[j], momentTensorControllers[momentTensorDatasets[k]][momentTensorWells[j]][momentTensorWells[j]]).name("All Stages").onChange(function(value) {

                        iScale = [];
                        for (var i = 0; i < momentTensor.length; i++) {
                            if (momentTensor[i].well == this.property && momentTensor[i].dataset == this.dataset) {
                                if (value) {
                                    iScale.push(1, 1, 1);
                                } else {
                                    iScale.push(0, 0, 0);
                                }
                            } else {
                                iScale.push(momentTensorGeomPicking.attributes.iScale.array[3 * i], momentTensorGeomPicking.attributes.iScale.array[3 * i + 1], momentTensorGeomPicking.attributes.iScale.array[3 * i + 2]);
                            }
                        }

                        for (var a = 0; a < 4; a++) {
                            momentTensorGeomDrawn[a].setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                        }
                        momentTensorGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

                        for (var key in momentTensorControllers[this.dataset][this.property]) {
                            try {
                                momentTensorControllers[this.dataset][this.property][key] = value;
                                momentTensorCheckBoxes[this.dataset][this.property][key].updateDisplay();
                            } catch (x) {
                                console.log(x);
                            }
                        }

                    });

                    momentTensorCheckBoxes[momentTensorDatasets[k]][momentTensorWells[j]][momentTensorWells[j]].dataset = momentTensorDatasets[k];

                    // Get all unique stages for this well,
                    momentTensorStages = [];
                    for (var i = 0; i < momentTensor.length; i++) {
                        if (momentTensor[i].well == momentTensorWells[j] && momentTensor[i].dataset == momentTensorDatasets[k])
                            momentTensorStages.push(momentTensor[i].name);
                    }
                    momentTensorStages = momentTensorStages.unique();

                    for (l = 0; l < momentTensorStages.length; l++) {

                        momentTensorControllers[momentTensorDatasets[k]][momentTensorWells[j]][momentTensorStages[l]] = true;
                        momentTensorCheckBoxes[momentTensorDatasets[k]][momentTensorWells[j]][momentTensorStages[l]] = wellFolder.add(momentTensorControllers[momentTensorDatasets[k]][momentTensorWells[j]], momentTensorStages[l], momentTensorControllers[momentTensorDatasets[k]][momentTensorWells[j]][momentTensorStages[l]]).name(momentTensorStages[l]).onChange(function(value) {

                            iScale = [];
                            for (var i = 0; i < momentTensor.length; i++) {
                                if (momentTensor[i].name == this.property && momentTensor[i].dataset == this.dataset) {
                                    if (value) {
                                        iScale.push(1, 1, 1);
                                    } else {
                                        iScale.push(0, 0, 0);
                                    }
                                } else {
                                    iScale.push(momentTensorGeomPicking.attributes.iScale.array[3 * i], momentTensorGeomPicking.attributes.iScale.array[3 * i + 1], momentTensorGeomPicking.attributes.iScale.array[3 * i + 2]);
                                }
                            }

                            for (var a = 0; a < 4; a++) {
                                momentTensorGeomDrawn[a].setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                            }
                            momentTensorGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));



                        });

                        momentTensorCheckBoxes[momentTensorDatasets[k]][momentTensorWells[j]][momentTensorStages[l]].dataset = momentTensorDatasets[k];
                    }

                }



            }


        }

        if (false) {


            // momentTensor Sizing
            var momentTensorSizeMod = function() {
                this.momentTensorSize = 10;
            };

            var momentTensorSizeModInstance = new momentTensorSizeMod();

            var momentTensorDisplayOptions = momentTensorFolder.addFolder("Display Options");
            var momentTensorSizeSlider = momentTensorDisplayOptions.add(momentTensorSizeModInstance, 'momentTensorSize', [2, 4, 6, 8, 10, 12, 14, 16])
                .name('Size')
                .onChange(function(value) {

                    iSize = [];

                    for (var i = 0; i < momentTensor.length; i++) {
                        iSize.push(value / 10, value / 10, value / 10);
                    }

                    for (var i = 0; i < momentTensorGeomDrawn.length; i++) {
                        momentTensorGeomDrawn[i].setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iSize), 3));
                    }
                    momentTensorGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iSize), 3));

                });
        }

        var momentTensorFilter = momentTensorFolder.addFolder("Filter");

        momentTensorFilterAttributes = {
            misfitMin: localStorage.getItem("misfit-min-moment-tensor-3d-plot" + project_id) === null ? '0' : localStorage.getItem("misfit-min-moment-tensor-3d-plot" + project_id),
            misfitMax: localStorage.getItem("misfit-max-moment-tensor-3d-plot" + project_id) === null ? '0' : localStorage.getItem("misfit-max-moment-tensor-3d-plot" + project_id),
            confidenceMin: localStorage.getItem("confidence-min-moment-tensor-3d-plot" + project_id) === null ? '0' : localStorage.getItem("confidence-min-moment-tensor-3d-plot" + project_id),
            confidenceMax: localStorage.getItem("confidence-max-moment-tensor-3d-plot" + project_id) === null ? '0' : localStorage.getItem("confidence-max-moment-tensor-3d-plot" + project_id),
        };

        momentTensorFilter.add(momentTensorFilterAttributes, 'misfitMin').name('Min. Misfit').onChange(function(value) {

            momentTensorFilterAttributes['misfitMin'] = value;
            minMisfit = Number(momentTensorFilterAttributes['misfitMin']);
            maxMisfit = Number(momentTensorFilterAttributes['misfitMax']);

            if (isNaN(minMisfit) || isNaN(maxMisfit)) {
                return;
            }

            iMisfitFilter = [];
            for (var i = 0; i < momentTensor.length; i++) {
                if (Number(momentTensor[i].misfit) >= minMisfit && Number(momentTensor[i].misfit) <= maxMisfit) {
                    iMisfitFilter.push(1, 1, 1);
                } else {
                    iMisfitFilter.push(0, 0, 0);
                }
            }

            for (var a = 0; a < 4; a++) {
                momentTensorGeomDrawn[a].setAttribute('iMisfitFilter', new THREE.InstancedBufferAttribute(new Float32Array(iMisfitFilter), 3));
            }
            momentTensorGeomPicking.setAttribute('iMisfitFilter', new THREE.InstancedBufferAttribute(new Float32Array(iMisfitFilter), 3));

        });

        momentTensorFilter.add(momentTensorFilterAttributes, 'misfitMax').name('Max. Misfit').onChange(function(value) {

            momentTensorFilterAttributes['misfitMax'] = value;
            minMisfit = Number(momentTensorFilterAttributes['misfitMin']);
            maxMisfit = Number(momentTensorFilterAttributes['misfitMax']);

            if (isNaN(minMisfit) || isNaN(maxMisfit)) {
                return;
            }

            iMisfitFilter = [];
            for (var i = 0; i < momentTensor.length; i++) {
                if (Number(momentTensor[i].misfit) >= minMisfit && Number(momentTensor[i].misfit) <= maxMisfit) {
                    iMisfitFilter.push(1, 1, 1);
                } else {
                    iMisfitFilter.push(0, 0, 0);
                }
            }

            for (var a = 0; a < 4; a++) {
                momentTensorGeomDrawn[a].setAttribute('iMisfitFilter', new THREE.InstancedBufferAttribute(new Float32Array(iMisfitFilter), 3));
            }
            momentTensorGeomPicking.setAttribute('iMisfitFilter', new THREE.InstancedBufferAttribute(new Float32Array(iMisfitFilter), 3));


        });


        momentTensorFilter.add(momentTensorFilterAttributes, 'confidenceMin').name('Min. Confidence').onChange(function(value) {

            momentTensorFilterAttributes['confidenceMin'] = value;
            confidenceMin = Number(momentTensorFilterAttributes['confidenceMin']);
            confidenceMax = Number(momentTensorFilterAttributes['confidenceMax']);

            localStorage.setItem("confidence-min-moment-tensor-3d-plot" + project_id, value);

            if (isNaN(confidenceMin) || isNaN(maxMisfit)) {
                return;
            }


            iConfidenceFilter = [];
            for (var i = 0; i < momentTensor.length; i++) {
                if (Number(momentTensor[i].misfit) >= confidenceMin && Number(momentTensor[i].misfit) <= confidenceMax) {
                    iConfidenceFilter.push(1, 1, 1);
                } else {
                    iConfidenceFilter.push(0, 0, 0);
                }
            }

            for (var a = 0; a < 4; a++) {
                momentTensorGeomDrawn[a].setAttribute('iConfidenceFilter', new THREE.InstancedBufferAttribute(new Float32Array(iConfidenceFilter), 3));
            }
            momentTensorGeomPicking.setAttribute('iConfidenceFilter', new THREE.InstancedBufferAttribute(new Float32Array(iConfidenceFilter), 3));

        });

        momentTensorFilter.add(momentTensorFilterAttributes, 'confidenceMax').name('Max. Confidence').onChange(function(value) {

            momentTensorFilterAttributes['confidenceMax'] = value;
            confidenceMin = Number(momentTensorFilterAttributes['confidenceMin']);
            confidenceMax = Number(momentTensorFilterAttributes['confidenceMax']);

            localStorage.setItem("confidence-max-moment-tensor-3d-plot" + project_id, value);

            if (isNaN(confidenceMin) || isNaN(confidenceMax)) {
                return;
            }

            iConfidenceFilter = [];
            for (var i = 0; i < momentTensor.length; i++) {
                if (Number(momentTensor[i].confidence) >= confidenceMin && Number(momentTensor[i].confidence) <= confidenceMax) {
                    iConfidenceFilter.push(1, 1, 1);
                } else {
                    iConfidenceFilter.push(0, 0, 0);
                }
            }

            for (var a = 0; a < 4; a++) {
                momentTensorGeomDrawn[a].setAttribute('iConfidenceFilter', new THREE.InstancedBufferAttribute(new Float32Array(iConfidenceFilter), 3));
            }
            momentTensorGeomPicking.setAttribute('iConfidenceFilter', new THREE.InstancedBufferAttribute(new Float32Array(iConfidenceFilter), 3));


        });


    }

    /////////////////////////////////////////
    ///// 	MOMENT TENSOR MODIFIED END  /////
    /////////////////////////////////////////

    /////////////////////////////////////////
    ///// SOURCE TENSOR MODIFIED START  /////
    /////////////////////////////////////////


    if (sourceTensor.length > 0) {

        var sourceTensorFolder = gui.addFolder('Source Tensors');

        if (true) {

            var sourceTensorWells = [],
                sourceTensorStages = [],
                sourceTensorDatasets = [];

            sourceTensorCheckBoxes = [
                [
                    []
                ]
            ];
            sourceTensorControllers = [
                [
                    []
                ]
            ];


            sourceTensorControllers['All sourceTensors'] = new Array();
            sourceTensorControllers['All sourceTensors'][0] = false;

            sourceTensorCheckBoxes['All sourceTensors'] = sourceTensorFolder.add(sourceTensorControllers['All sourceTensors'], 0, sourceTensorControllers['All sourceTensors'][0]).name("All").onChange(function(value) {

                iScale = [];
                for (var i = 0; i < sourceTensor.length; i++) {
                    if (value) {
                        iScale.push(1, 1, 1);
                    } else {
                        iScale.push(0, 0, 0);
                    }
                }

                for (var a = 0; a < 4; a++) {
                    sourceTensorGeomDrawn[a].setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                }
                sourceTensorGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

                sourceTensorDatasets = [];
                // Show Hide Values
                for (var i = 0; i < sourceTensor.length; i++) {
                    sourceTensorDatasets.push(sourceTensor[i].dataset);
                }
                sourceTensorDatasets = sourceTensorDatasets.unique();

                for (var i2 = 0; i2 < sourceTensorDatasets.length; i2++) {

                    sourceTensorControllers[sourceTensorDatasets[i2]][sourceTensorDatasets[i2]] = value;
                    try {
                        sourceTensorCheckBoxes[sourceTensorDatasets[i2]][sourceTensorDatasets[i2]].updateDisplay();
                    } catch (e) {
                        console.log(e);
                    }
                    var sourceTensorWellsTemp = [];
                    for (var i = 0; i < sourceTensor.length; i++) {
                        if (sourceTensor[i].dataset == sourceTensorDatasets[i2])
                            sourceTensorWellsTemp.push(sourceTensor[i].well);
                    }
                    sourceTensorWellsTemp = sourceTensorWellsTemp.unique();

                    for (var i = 0; i < sourceTensorWellsTemp.length; i++) {
                        var sourceTensorWellsKeys = Object.keys(sourceTensorControllers[sourceTensorDatasets[i2]][sourceTensorWellsTemp[i]]);
                        for (var i1 = 0; i1 < sourceTensorWellsKeys.length; i1++) {
                            sourceTensorControllers[sourceTensorDatasets[i2]][sourceTensorWellsTemp[i]][sourceTensorWellsKeys[i1]] = value;
                            try {
                                sourceTensorCheckBoxes[sourceTensorDatasets[i2]][sourceTensorWellsTemp[i]][sourceTensorWellsKeys[i1]].updateDisplay();
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }

                }

                resetSTTimelapse();

            });


            // Get all unique datasets
            for (var i = 0; i < sourceTensor.length; i++) {
                sourceTensorDatasets.push(sourceTensor[i].dataset);
            }
            sourceTensorDatasets = sourceTensorDatasets.unique();


            for (k = 0; k < sourceTensorDatasets.length; k++) {

                datasetFolder = sourceTensorFolder.addFolder(sourceTensorDatasets[k]);

                sourceTensorCheckBoxes[sourceTensorDatasets[k]] = new Array();
                sourceTensorControllers[sourceTensorDatasets[k]] = new Array();
                sourceTensorControllers[sourceTensorDatasets[k]][sourceTensorDatasets[k]] = false; //sourceTensorWells[j]);

                sourceTensorCheckBoxes[sourceTensorDatasets[k]][sourceTensorDatasets[k]] = datasetFolder.add(sourceTensorControllers[sourceTensorDatasets[k]], sourceTensorDatasets[k], sourceTensorControllers[sourceTensorDatasets[k]][sourceTensorDatasets[k]]).name("All Wells").onChange(function(value) {

                    iScale = [];
                    for (var i = 0; i < sourceTensor.length; i++) {
                        if (sourceTensor[i].dataset == this.property) {
                            if (value) {
                                iScale.push(1, 1, 1);
                            } else {
                                iScale.push(0, 0, 0);
                            }
                        } else {
                            iScale.push(sourceTensorGeomPicking.attributes.iScale.array[3 * i], sourceTensorGeomPicking.attributes.iScale.array[3 * i + 1], sourceTensorGeomPicking.attributes.iScale.array[3 * i + 2]);
                        }
                    }

                    for (var a = 0; a < 4; a++) {
                        sourceTensorGeomDrawn[a].setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                    }
                    sourceTensorGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));


                    var sourceTensorWellsTemp = [];
                    for (var i = 0; i < sourceTensor.length; i++) {
                        if (sourceTensor[i].dataset == this.property)
                            sourceTensorWellsTemp.push(sourceTensor[i].well);
                    }
                    sourceTensorWellsTemp = sourceTensorWellsTemp.unique();


                    for (var i = 0; i < sourceTensorWellsTemp.length; i++) {
                        var sourceTensorWellsKeys = Object.keys(sourceTensorControllers[this.property][sourceTensorWellsTemp[i]]);
                        for (var i1 = 0; i1 < sourceTensorWellsKeys.length; i1++) {
                            sourceTensorControllers[this.property][sourceTensorWellsTemp[i]][sourceTensorWellsKeys[i1]] = value;
                            try {
                                sourceTensorCheckBoxes[this.property][sourceTensorWellsTemp[i]][sourceTensorWellsKeys[i1]].updateDisplay();
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }

                    // sourceTensorControllers[this.dataset][this.property][key] = value;
                    // sourceTensorCheckBoxes[this.dataset][this.property][key].updateDisplay();

                    resetSTTimelapse();


                });

                sourceTensorCheckBoxes[sourceTensorDatasets[k]][sourceTensorDatasets[k]].dataset = sourceTensorDatasets[k];

                sourceTensorWells = [];
                for (var i = 0; i < sourceTensor.length; i++) {
                    if (sourceTensor[i].dataset == sourceTensorDatasets[k])
                        sourceTensorWells.push(sourceTensor[i].well);
                }
                sourceTensorWells = sourceTensorWells.unique();

                for (j = 0; j < sourceTensorWells.length; j++) {

                    wellFolder = datasetFolder.addFolder(sourceTensorWells[j]);

                    sourceTensorCheckBoxes[sourceTensorDatasets[k]][sourceTensorWells[j]] = new Array();
                    sourceTensorControllers[sourceTensorDatasets[k]][sourceTensorWells[j]] = new Array();
                    sourceTensorControllers[sourceTensorDatasets[k]][sourceTensorWells[j]][sourceTensorWells[j]] = false; //sourceTensorWells[j]);

                    sourceTensorCheckBoxes[sourceTensorDatasets[k]][sourceTensorWells[j]][sourceTensorWells[j]] = wellFolder.add(sourceTensorControllers[sourceTensorDatasets[k]][sourceTensorWells[j]], sourceTensorWells[j], sourceTensorControllers[sourceTensorDatasets[k]][sourceTensorWells[j]][sourceTensorWells[j]]).name("All Stages").onChange(function(value) {

                        iScale = [];
                        for (var i = 0; i < sourceTensor.length; i++) {
                            if (sourceTensor[i].well == this.property && sourceTensor[i].dataset == this.dataset) {
                                if (value) {
                                    iScale.push(1, 1, 1);
                                } else {
                                    iScale.push(0, 0, 0);
                                }
                            } else {
                                iScale.push(sourceTensorGeomPicking.attributes.iScale.array[3 * i], sourceTensorGeomPicking.attributes.iScale.array[3 * i + 1], sourceTensorGeomPicking.attributes.iScale.array[3 * i + 2]);
                            }
                        }

                        for (var a = 0; a < 4; a++) {
                            sourceTensorGeomDrawn[a].setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                        }
                        sourceTensorGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

                        for (var key in sourceTensorControllers[this.dataset][this.property]) {
                            try {
                                sourceTensorControllers[this.dataset][this.property][key] = value;
                                sourceTensorCheckBoxes[this.dataset][this.property][key].updateDisplay();
                            } catch (x) {
                                console.log(x);
                            }
                        }

                        resetSTTimelapse();

                    });

                    sourceTensorCheckBoxes[sourceTensorDatasets[k]][sourceTensorWells[j]][sourceTensorWells[j]].dataset = sourceTensorDatasets[k];

                    // Get all unique stages for this well,
                    sourceTensorStages = [];
                    for (var i = 0; i < sourceTensor.length; i++) {
                        if (sourceTensor[i].well == sourceTensorWells[j] && sourceTensor[i].dataset == sourceTensorDatasets[k])
                            sourceTensorStages.push(sourceTensor[i].name);
                    }
                    sourceTensorStages = sourceTensorStages.unique();

                    for (l = 0; l < sourceTensorStages.length; l++) {

                        sourceTensorControllers[sourceTensorDatasets[k]][sourceTensorWells[j]][sourceTensorStages[l]] = false;
                        sourceTensorCheckBoxes[sourceTensorDatasets[k]][sourceTensorWells[j]][sourceTensorStages[l]] = wellFolder.add(sourceTensorControllers[sourceTensorDatasets[k]][sourceTensorWells[j]], sourceTensorStages[l], sourceTensorControllers[sourceTensorDatasets[k]][sourceTensorWells[j]][sourceTensorStages[l]]).name(sourceTensorStages[l]).onChange(function(value) {

                            iScale = [];
                            for (var i = 0; i < sourceTensor.length; i++) {
                                if (sourceTensor[i].name == this.property && sourceTensor[i].dataset == this.dataset) {
                                    if (value) {
                                        iScale.push(1, 1, 1);
                                    } else {
                                        iScale.push(0, 0, 0);
                                    }
                                } else {
                                    iScale.push(sourceTensorGeomPicking.attributes.iScale.array[3 * i], sourceTensorGeomPicking.attributes.iScale.array[3 * i + 1], sourceTensorGeomPicking.attributes.iScale.array[3 * i + 2]);
                                }
                            }

                            for (var a = 0; a < 4; a++) {
                                sourceTensorGeomDrawn[a].setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                            }
                            sourceTensorGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

                            resetSTTimelapse();


                        });

                        sourceTensorCheckBoxes[sourceTensorDatasets[k]][sourceTensorWells[j]][sourceTensorStages[l]].dataset = sourceTensorDatasets[k];
                    }

                }



            }

        }

        var sourceTensorDisplayAttributes = {
            display: 'Beach Balls',
            color_by: 'Focal Mechanism',
            color_map: 'mti-trend',
            size_by: '',
        };

        var sourceTensorDisplay = sourceTensorFolder.addFolder("Display");

        const updateSourceTensor = () => {


            let azimuthLut = new Lut();

            if (sourceTensorDisplayAttributes['color_map'] == "mti-trend") {
                azimuthLut.addColorMap("mti-trend", MTI_COLORMAP);
                azimuthLut.setColorMap("mti-trend", 3600);
            } else {
                // let azimuthLut = new Lut(sourceTensorDisplayAttributes['color_map']);
                azimuthLut.setColorMap(sourceTensorDisplayAttributes['color_map']);
            }

            if (sourceTensorDisplayAttributes['color_by'] == 'Strike/Azimuth') {
                azimuthLut.setMin(0);
                azimuthLut.setMax(360);
            }

            if (sourceTensorDisplayAttributes['color_by'] == 'Dip') {
                azimuthLut.setMin(-180);
                azimuthLut.setMax(180);
            }

            document.getElementById("legendLabelText").innerHTML = "";
            document.getElementById("legendLegend").innerHTML = "";

            for (count = 0; count < 4; count++) {

                iColors = [];

                sourceTensorMatArraySize = 4 * sourceTensor.length;
                sourceTensorMatrixArray = [
                    new Float32Array(sourceTensorMatArraySize),
                    new Float32Array(sourceTensorMatArraySize),
                    new Float32Array(sourceTensorMatArraySize),
                    new Float32Array(sourceTensorMatArraySize),
                ];

                for (var i = 0; i < sourceTensor.length; i++) {

                    sourceTensorMatrix = new THREE.Matrix4();

                    if (sourceTensorDisplayAttributes['color_by'] == 'Focal Mechanism') {
                        sourceTensorTempColor = new THREE.Color(
                            sourceTensor[i].color
                        ).convertSRGBToLinear();
                    }

                    if (sourceTensorDisplayAttributes['color_by'] == 'Strike/Azimuth') {
                        sourceTensorTempColor = azimuthLut.getColor(Number(sourceTensor[i].strike));
                    }

                    if (sourceTensorDisplayAttributes['color_by'] == 'Dip') {
                        sourceTensorTempColor = azimuthLut.getColor(Number(sourceTensor[i].dip));
                    }





                    if (sourceTensorDisplayAttributes['display'] == "Beach Balls" && (count == 1 || count == 3)) {
                        sourceTensorTempColor = new THREE.Color(0xffffff).convertSRGBToLinear();
                    }

                    iColors.push(sourceTensorTempColor.r, sourceTensorTempColor.g, sourceTensorTempColor.b);

                    sourceTensorPosition = new THREE.Vector3(
                        (EXAGGERATION_X * DOWNSAMPLE * sourceTensor[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                        (EXAGGERATION_Y * DOWNSAMPLE * sourceTensor[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                        (EXAGGERATION_Z * DOWNSAMPLE * sourceTensor[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                    );

                    if (sourceTensorDisplayAttributes['display'] == "Beach Balls") {
                        sourceTensorScale = new THREE.Vector3(1, 1, 1);
                    } else {
                        sourceTensorScale = new THREE.Vector3(1, 0.01, 1);
                        // sourceTensorScale = new THREE.Vector3(3, 0.01, 1); --> if disc needed!
                    }

                    sourceTensorQuaternion = new THREE.Quaternion();

                    if (sourceTensorDisplayAttributes['display'] == "Beach Balls") {

                        var q0 = new THREE.Quaternion();
                        q0.setFromAxisAngle(e1, -90 * Math.PI / 180);
                        sourceTensorQuaternion.multiply(q0);

                        // Define rotation into strike direction
                        q1 = new THREE.Quaternion();
                        q1.setFromAxisAngle(e3, (-sourceTensor[i].strike) * Math.PI / 180);

                        //rotate original coordinate into strike direction to get new coordinate system
                        e1a = e1.clone();
                        e1a.applyQuaternion(q1);
                        e2a = e2.clone();
                        e2a.applyQuaternion(q1);
                        e3a = e3.clone();
                        e3a.applyQuaternion(q1);
                        q2 = new THREE.Quaternion();
                        e2a.normalize();
                        q2.setFromAxisAngle(e1a, -sourceTensor[i].dip * Math.PI / 180);

                        var e1b = e1a.clone();
                        e1b.applyQuaternion(q2);
                        var e2b = e2a.clone();
                        e2b.applyQuaternion(q2);
                        var e3b = e3a.clone();
                        e3b.applyQuaternion(q2);

                        var q3 = new THREE.Quaternion();
                        var e1bxe2b_plane = new THREE.Vector3().crossVectors(e1b, e2b);
                        e1bxe2b_plane.normalize();

                        q3.setFromAxisAngle(e1bxe2b_plane, sourceTensor[i].slip * Math.PI / 180);

                        var e1c = e1b.clone();
                        e1c.applyQuaternion(q3);
                        var e2c = e2b.clone();
                        e2c.applyQuaternion(q3);
                        var e3c = e3b.clone();
                        e3c.applyQuaternion(q3);

                        sourceTensorQuaternion.multiply(q3);
                        sourceTensorQuaternion.multiply(q2);
                        sourceTensorQuaternion.multiply(q1);

                    } else {

                        // Define rotation into strike direction
                        // sourceTensor[i].strike = 45; sourceTensor[i].dip = 45;
                        q1 = new THREE.Quaternion();
                        q1.setFromAxisAngle(e2, (-sourceTensor[i].strike) * Math.PI / 180);

                        //rotate original coordinate into strike direction to get new coordinate system
                        e1a = e1.clone();
                        e1a.applyQuaternion(q1);
                        e2a = e2.clone();
                        e2a.applyQuaternion(q1);
                        e3a = e3.clone();
                        e3a.applyQuaternion(q1);
                        q2 = new THREE.Quaternion();
                        e2a.normalize();
                        q2.setFromAxisAngle(e1a, sourceTensor[i].dip * Math.PI / 180);

                        sourceTensorQuaternion.multiply(q2);
                        sourceTensorQuaternion.multiply(q1);



                    }


                    sourceTensorMatrix.compose(sourceTensorPosition, sourceTensorQuaternion, sourceTensorScale);

                    for (r = 0; r < 4; r++) {
                        for (c = 0; c < 4; c++) {
                            sourceTensorMatrixArray[r][4 * i + c] = sourceTensorMatrix.elements[r * 4 + c];
                        }
                    }
                }

                if (count == 0) {
                    boxGeometry = new THREE.SphereBufferGeometry(1, 8, 8, 0, Math.PI / 2);
                }
                if (count == 1) {
                    boxGeometry = new THREE.SphereBufferGeometry(1, 8, 8, -Math.PI / 2, Math.PI / 2);
                }
                if (count == 2) {
                    boxGeometry = new THREE.SphereBufferGeometry(1, 8, 8, Math.PI, Math.PI / 2);
                }
                if (count == 3) {
                    boxGeometry = new THREE.SphereBufferGeometry(1, 8, 8, Math.PI / 2, Math.PI / 2);
                }


                if (sourceTensorDisplayAttributes['display'] != "Beach Balls") {
                    boxGeometry = new THREE.SphereBufferGeometry(1, 8, 8);
                }

                sourceTensorGeomDrawn[count].setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
                for (var i = 0; i < sourceTensorMatrixArray.length; i++) {
                    sourceTensorGeomDrawn[count].setAttribute(
                        `aInstanceMatrix${i}`,
                        new THREE.InstancedBufferAttribute(sourceTensorMatrixArray[i], 4)
                    );
                }

            }

            // Making the legend;
            LEGEND_TEXT = [];
            LEGEND_COLOR = [];
            noOfElements = 12;
            heightPerElement = 20;
            spacing = 360 / noOfElements;
            var canvas = document.createElement('canvas');
            div = document.getElementById("legendLegend");
            canvas.id = "legend";
            canvas.position = 'absolute';
            canvas.width = 50;
            canvas.height = noOfElements * heightPerElement;
            canvas.style.zIndex = 8;
            canvas.style.position = "absolute";
            // canvas.style.border   = "1px solid w /dhite";
            var ctx = canvas.getContext("2d");
            // No of variations and colors
            if (sourceTensorDisplayAttributes['color_by'] == 'Strike/Azimuth') {

                for (n = 0; n < noOfElements; n++) {

                    aziValue = spacing * n;
                    aziValueNext = spacing * (n + 1);
                    color = azimuthLut.getColor(aziValue);
                    colorText = 'rgba(' + 255 * color.r.toString() + ', ' + 255 * color.g.toString() + ', ' + 255 * color.b.toString() + ' ,1.0)';
                    // colorText = getColor(formations[i]);
                    grd = ctx.createLinearGradient(0, 0, 0, 10);
                    grd.addColorStop(0, colorText);
                    grd.addColorStop(1, colorText);
                    ctx.fillStyle = grd;

                    y0 = n * heightPerElement;
                    y1 = (n + 1) * heightPerElement;
                    ctx.fillRect(0, Math.ceil(y0) + 1, 20, Math.floor(y1) - 1);

                    document.getElementById("legendLabelText").innerHTML += Math.round(aziValue) + "-" + Math.round(aziValueNext);
                    if (n < noOfElements - 1) {
                        document.getElementById("legendLabelText").innerHTML += "<br>";
                    }

                    LEGEND_TEXT.push(Math.round(aziValue) + "-" + Math.round(aziValueNext));
                    LEGEND_COLOR.push(colorText);

                }

                div.appendChild(canvas);

            }


            if (sourceTensorDisplayAttributes['color_by'] == 'Dip') {

                for (n = 0; n < noOfElements; n++) {

                    aziValue = -180 + spacing * n;
                    aziValueNext = -180 + spacing * (n + 1);
                    color = azimuthLut.getColor(aziValue);
                    colorText = 'rgba(' + 255 * color.r.toString() + ', ' + 255 * color.g.toString() + ', ' + 255 * color.b.toString() + ' ,1.0)';
                    // colorText = getColor(formations[i]);
                    grd = ctx.createLinearGradient(0, 0, 0, 10);
                    grd.addColorStop(0, colorText);
                    grd.addColorStop(1, colorText);
                    ctx.fillStyle = grd;

                    y0 = n * heightPerElement;
                    y1 = (n + 1) * heightPerElement;
                    ctx.fillRect(0, Math.ceil(y0) + 1, 20, Math.floor(y1) - 1);

                    document.getElementById("legendLabelText").innerHTML += Math.round(aziValue) + " - " + Math.round(aziValueNext);
                    if (n < noOfElements - 1) {
                        document.getElementById("legendLabelText").innerHTML += "<br>";
                    }

                    LEGEND_TEXT.push(Math.round(aziValue) + " - " + Math.round(aziValueNext));
                    LEGEND_COLOR.push(colorText);

                }

                div.appendChild(canvas);

            }





        }

        sourceTensorDisplay.add(sourceTensorDisplayAttributes, 'display', ['Beach Balls', 'Disc']).name('Display As')
            .onChange(function(value) {
                updateSourceTensor();
            });


        // http://localhost:3000/plots/viewer?id=120&project_id=33	

        sourceTensorDisplay.add(sourceTensorDisplayAttributes, 'color_by', ['Focal Mechanism', 'Strike/Azimuth', 'Dip']).name('Color By').onChange(function(value) {
            updateSourceTensor();
        });


        sourceTensorDisplay.add(sourceTensorDisplayAttributes, 'color_map', ["mti-trend", "rainbow", "cooltowarm", "blackbody", "grayscale"]).name('Color Map').onChange(function(value) {
            updateSourceTensor();
        });




        if (sourceTensor.length > 0 && sourceTensor[0].hasOwnProperty("fractureArea")) {

            sourceTensorDisplay.add(sourceTensorDisplayAttributes, 'size_by', ['', 'Fracture Area']).name('Size by').onChange(function(value) {
                iSizeVariation = [];
                for (count = 0; count < 4; count++) {
                    for (var i = 0; i < sourceTensor.length; i++) {
                        if (value == '') {
                            iSizeVariation.push(1, 1, 1);
                        } else {
                            iSizeVariation.push(sourceTensor[i].fractureArea / 60, sourceTensor[i].fractureArea / 60, sourceTensor[i].fractureArea / 60);
                        }
                    }
                    sourceTensorGeomDrawn[count].setAttribute('iSizeVariation', new THREE.InstancedBufferAttribute(new Float32Array(iSizeVariation), 3));
                    sourceTensorGeomPicking.setAttribute('iSizeVariation', new THREE.InstancedBufferAttribute(new Float32Array(iSizeVariation), 3));
                }
            });

        }



        var sourceTensorFilter = sourceTensorFolder.addFolder("Filter");

        sourceTensorFilterAttributes = {
            misfitMin: localStorage.getItem("misfit-min-source-tensor-3d-plot" + project_id) === null ? '0' : localStorage.getItem("misfit-min-source-tensor-3d-plot" + project_id),
            misfitMax: localStorage.getItem("misfit-max-source-tensor-3d-plot" + project_id) === null ? '0' : localStorage.getItem("misfit-max-source-tensor-3d-plot" + project_id),
            confidenceMin: localStorage.getItem("confidence-min-source-tensor-3d-plot" + project_id) === null ? '0' : localStorage.getItem("confidence-min-source-tensor-3d-plot" + project_id),
            confidenceMax: localStorage.getItem("confidence-max-source-tensor-3d-plot" + project_id) === null ? '0' : localStorage.getItem("confidence-max-source-tensor-3d-plot" + project_id),
        };

        sourceTensorFilter.add(sourceTensorFilterAttributes, 'misfitMin').name('Min. Misfit').onChange(function(value) {

            sourceTensorFilterAttributes['misfitMin'] = value;
            minMisfit = Number(sourceTensorFilterAttributes['misfitMin']);
            maxMisfit = Number(sourceTensorFilterAttributes['misfitMax']);

            if (isNaN(minMisfit) || isNaN(maxMisfit)) {
                return;
            }

            iMisfitFilter = [];
            for (var i = 0; i < sourceTensor.length; i++) {
                if (Number(sourceTensor[i].misfit) >= minMisfit && Number(sourceTensor[i].misfit) <= maxMisfit) {
                    iMisfitFilter.push(1, 1, 1);
                } else {
                    iMisfitFilter.push(0, 0, 0);
                }
            }

            for (var a = 0; a < 4; a++) {
                sourceTensorGeomDrawn[a].setAttribute('iMisfitFilter', new THREE.InstancedBufferAttribute(new Float32Array(iMisfitFilter), 3));
            }
            sourceTensorGeomPicking.setAttribute('iMisfitFilter', new THREE.InstancedBufferAttribute(new Float32Array(iMisfitFilter), 3));

        });

        sourceTensorFilter.add(sourceTensorFilterAttributes, 'misfitMax').name('Max. Misfit').onChange(function(value) {

            sourceTensorFilterAttributes['misfitMax'] = value;
            minMisfit = Number(sourceTensorFilterAttributes['misfitMin']);
            maxMisfit = Number(sourceTensorFilterAttributes['misfitMax']);

            if (isNaN(minMisfit) || isNaN(maxMisfit)) {
                return;
            }

            iMisfitFilter = [];
            for (var i = 0; i < sourceTensor.length; i++) {
                if (Number(sourceTensor[i].misfit) >= minMisfit && Number(sourceTensor[i].misfit) <= maxMisfit) {
                    iMisfitFilter.push(1, 1, 1);
                } else {
                    iMisfitFilter.push(0, 0, 0);
                }
            }

            for (var a = 0; a < 4; a++) {
                sourceTensorGeomDrawn[a].setAttribute('iMisfitFilter', new THREE.InstancedBufferAttribute(new Float32Array(iMisfitFilter), 3));
            }
            sourceTensorGeomPicking.setAttribute('iMisfitFilter', new THREE.InstancedBufferAttribute(new Float32Array(iMisfitFilter), 3));


        });


        sourceTensorFilter.add(sourceTensorFilterAttributes, 'confidenceMin').name('Min. Confidence').onChange(function(value) {

            sourceTensorFilterAttributes['confidenceMin'] = value;
            confidenceMin = Number(sourceTensorFilterAttributes['confidenceMin']);
            confidenceMax = Number(sourceTensorFilterAttributes['confidenceMax']);

            localStorage.setItem("confidence-min-source-tensor-3d-plot" + project_id, value);

            if (isNaN(confidenceMin) || isNaN(maxMisfit)) {
                return;
            }


            iConfidenceFilter = [];
            for (var i = 0; i < sourceTensor.length; i++) {
                if (Number(sourceTensor[i].misfit) >= confidenceMin && Number(sourceTensor[i].misfit) <= confidenceMax) {
                    iConfidenceFilter.push(1, 1, 1);
                } else {
                    iConfidenceFilter.push(0, 0, 0);
                }
            }

            for (var a = 0; a < 4; a++) {
                sourceTensorGeomDrawn[a].setAttribute('iConfidenceFilter', new THREE.InstancedBufferAttribute(new Float32Array(iConfidenceFilter), 3));
            }
            sourceTensorGeomPicking.setAttribute('iConfidenceFilter', new THREE.InstancedBufferAttribute(new Float32Array(iConfidenceFilter), 3));

        });

        sourceTensorFilter.add(sourceTensorFilterAttributes, 'confidenceMax').name('Max. Confidence').onChange(function(value) {

            sourceTensorFilterAttributes['confidenceMax'] = value;
            confidenceMin = Number(sourceTensorFilterAttributes['confidenceMin']);
            confidenceMax = Number(sourceTensorFilterAttributes['confidenceMax']);

            localStorage.setItem("confidence-max-source-tensor-3d-plot" + project_id, value);

            if (isNaN(confidenceMin) || isNaN(confidenceMax)) {
                return;
            }

            iConfidenceFilter = [];
            for (var i = 0; i < sourceTensor.length; i++) {
                if (Number(sourceTensor[i].confidence) >= confidenceMin && Number(sourceTensor[i].confidence) <= confidenceMax) {
                    iConfidenceFilter.push(1, 1, 1);
                } else {
                    iConfidenceFilter.push(0, 0, 0);
                }
            }

            for (var a = 0; a < 4; a++) {
                sourceTensorGeomDrawn[a].setAttribute('iConfidenceFilter', new THREE.InstancedBufferAttribute(new Float32Array(iConfidenceFilter), 3));
            }
            sourceTensorGeomPicking.setAttribute('iConfidenceFilter', new THREE.InstancedBufferAttribute(new Float32Array(iConfidenceFilter), 3));


        });



    }

    /////////////////////////////////////////
    ///// 	SOURCE TENSOR MODIFIED END  /////
    /////////////////////////////////////////

    /////////////////////////////////
    ///// 	FORMATION TOPS      /////
    /////////////////////////////////


    if (formationTops.length > 0 || formationTopsPoints.length > 0) {

        formationTopsFolder = gui.addFolder('Formation Tops');
        formationTopsFolder.A = new Array();
        formationTopsFolder.colorMap = new Array();
        formationTopsFolder.minVal = new Array();
        formationTopsFolder.maxVal = new Array();
        formationTopsFolder.showHistogram = new Array();
        formationTopsFolder.size = new Array();
        formationTopsFolder.mode = 'Plane';
        formationTopsFolder.labelSize = 10;
        formationTopsFolder.opacity = formationOpacity;


        if (formationTopGeomPicking.length > 0 || formationTopsPoints.length > 0) {
            for (var i = 0; i < formationTopGeomPicking.length; i++) {
                formationTopsFolder.A[formationTops[i].name] = false;
            }

            formationTopsFolder.A['All Tops'] = false;

            formationTopsFolder.add(formationTopsFolder.A, 'All Tops', formationTopsFolder.A['All Tops'])
                .onChange(function(value) {
                    for (var i = 0; i < formationTops.length; i++) {

                        if (formationTopsFolder.mode == "Plane") {
                            scene.getObjectByName(formationTops[i].name).visible = value;
                            try {
                                pickingScene.getObjectByName(formationTops[i].name).visible = value;
                            } catch {}
                        }

                        if (formationTopsFolder.mode == "Line - Side View") {
                            scene.getObjectByName(formationTops[i].name + "Line - Side View").visible = value;
                        }

                        if (formationTopsFolder.mode == "Line - Barrel View") {
                            scene.getObjectByName(formationTops[i].name + "Line - Barrel View").visible = value;
                        }

                        try {
                            scene.getObjectByName("Form Label" + formationTops[i].name).visible = value;
                        } catch {

                        }

                        try {
                            pickingScene.getObjectByName("Form Label" + formationTops[i].name).visible = value;
                        } catch {

                        }

                        formationTopsFolder.A[formationTops[i].name] = value;
                        formTopCheckBoxes[formationTops[i].name].updateDisplay();
                    }

                    for (var i = 0; i < formationTopsPoints.length; i++) {
                        scene.getObjectByName(formationTopsPoints[i].name).visible = value;
                        try {
                            pickingScene.getObjectByName(formationTopsPoints[i].name).visible = value;
                        } catch {}
                        formationTopsFolder.A[formationTopsPoints[i].name] = value;
                        formTopCheckBoxes[formationTopsPoints[i].name].updateDisplay();
                    }

                });
        }

        for (var i = 0; i < formationTopGeomPicking.length; i++) {

            formTopCheckBoxes[formationTopGeomPicking[i].name] = formationTopsFolder.add(formationTopsFolder.A, formationTopGeomPicking[i].name, formationTopsFolder.A[formationTopGeomPicking[i].name])
                .onChange(function(value) {


                    if (formationTopsFolder.mode == "Plane") {
                        scene.getObjectByName(this.property).visible = value;
                        try {
                            pickingScene.getObjectByName(this.property).visible = value;
                        } catch {}
                    }

                    if (formationTopsFolder.mode == "Line - Side View") {
                        scene.getObjectByName(this.property + "Line - Side View").visible = value;
                    }

                    if (formationTopsFolder.mode == "Line - Barrel View") {
                        scene.getObjectByName(this.property + "Line - Barrel View").visible = value;
                    }


                    try {
                        scene.getObjectByName("Form Label" + this.property).visible = value;
                    } catch {

                    }


                    try {
                        pickingScene.getObjectByName("Form Label" + this.property).visible = value;
                    } catch {

                    }

                });
        }

        formationTopsFolder.add(formationTopsFolder, 'mode', ['Plane', 'Line - Side View', 'Line - Barrel View'])
            .name("Plotting Mode").onChange(function(value) {

                for (var i = 0; i < formationTopGeomPicking.length; i++) {

                    if (formationTopsFolder.A[formationTops[i].name]) {
                        scene.getObjectByName(formationTops[i].name).visible = false;
                        scene.getObjectByName(formationTops[i].name + "Line - Side View").visible = false;
                        scene.getObjectByName(formationTops[i].name + "Line - Barrel View").visible = false;

                        if (value == "Plane") {
                            scene.getObjectByName(formationTops[i].name).visible = true;
                        } else {
                            scene.getObjectByName(formationTops[i].name + value).visible = true;
                        }
                    }

                }

            });



        formationTopsFolder.add(formationTopsFolder, 'labelSize', [2, 4, 6, 8, 10, 12, 14, 16])
            .name("Label Size").onChange(function(value) {

                console.log("Form label", formLabel);
                for (var i = 0; i < formLabel.length; i++) {

                    try {
                        let label = scene.getObjectByName("Form Label" + formLabel[i].name);
                        scene.remove(label);
                    } catch (e) {}

                    try {
                        let label = pickingScene.getObjectByName("Form Label" + formLabel[i].name);
                        pickingScene.remove(label);
                    } catch (e) {}

                }

                formLabelMaterial = [
                    new THREE.MeshLambertMaterial({ color: 0xffffff, flatShading: true }), // front
                    new THREE.MeshPhongMaterial({ color: 0xffffff }) // side
                ];

                for (var j = 0; j < formLabel.length; j++) {

                    var textGeo = new THREE.TextGeometry(formLabel[j].name, {
                        font: font,
                        size: value / 2.5,
                        height: value / 5,
                    });
                    textGeo.computeBoundingBox();
                    textGeo.computeVertexNormals();

                    var centerOffsetX = -0.5 * (textGeo.boundingBox.max.x - textGeo.boundingBox.min.x);
                    var centerOffsetY = -1 * (textGeo.boundingBox.max.y - textGeo.boundingBox.min.y);
                    var centerOffsetZ = -0.5 * (textGeo.boundingBox.max.z - textGeo.boundingBox.min.z);


                    var formationTop = formationTops[j];
                    var ref_y = DOWNSAMPLE * formationTop.depth - bk_y_min - 0.5 * (bk_y_max - bk_y_min);

                    formLabelGeom[j] = new THREE.BufferGeometry().fromGeometry(textGeo);
                    formLabelGeom[j].center();
                    formLabelMesh[j] = new THREE.Mesh(formLabelGeom[j], formLabelMaterial);

                    formLabelMesh[j].position.x = 0;
                    formLabelMesh[j].position.y = ref_y + centerOffsetY;
                    formLabelMesh[j].position.z = 0;

                    formLabelMesh[j].name = "Form Label" + formLabel[j].name;
                    formLabelMesh[j].visible = formationTopsFolder.A[formLabel[j].name];

                    formLabelMesh[j].renderOrder = 999;
                    formLabelMesh[j].onBeforeRender = function(renderer) { renderer.clearDepth(); };

                    scene.add(formLabelMesh[j]);

                }



            });


        formationTopsFolder.add(formationTopsFolder, 'opacity', formationTopsFolder['opacity']).min(0).max(1).step(0.01)
            .name("Opacity").onChange(function(value) {

                var opacity = new Float32Array(4);
                for (let i = 0; i < 4; i++) {
                    opacity[i] = value;
                }

                for (var i = 0; i < formationTopGeomPicking.length; i++) {
                    formationTopGeomPicking[i].geometry.setAttribute('opacity', new THREE.BufferAttribute(opacity, 1));
                }

                for (var i = 0; i < formationTopPointGeomDrawn.length; i++) {
                    var opacity = new Float32Array(formationTopsPoints[i].formColor.length);
                    for (var j = 0; j < formationTopsPoints[i].formColor.length; j++) {
                        opacity[j] = value;
                    }
                    formationTopPointGeomDrawn[i].geometry.setAttribute('opacity', new THREE.BufferAttribute(opacity, 1));
                }

            });




        for (var i = 0; i < formationTopPointGeomDrawn.length; i++) {
            formationTopsFolder.A[formationTopsPoints[i].name] = false;
            formationTopsFolder.colorMap[formationTopsPoints[i].name] = 'rainbow';
            formationTopsFolder.minVal[formationTopsPoints[i].name] = '';
            formationTopsFolder.maxVal[formationTopsPoints[i].name] = '';
            formationTopsFolder.size[formationTopsPoints[i].name] = 2.0;
            formationTopsFolder.showHistogram[formationTopsPoints[i].name] = function() {};
        }



        for (var i = 0; i < formationTopPointGeomDrawn.length; i++) {

            var formationTopFolder = formationTopsFolder.addFolder(formationTopPointGeomDrawn[i].name);
            formTopCheckBoxes[formationTopPointGeomDrawn[i].name] = formationTopFolder.add(formationTopsFolder.A, formationTopPointGeomDrawn[i].name, formationTopsFolder.A[formationTopsPoints[i].name])
                .name("Show/Hide")
                .onChange(function(value) {

                    let index = formationTopPointGeomDrawn.map(e => e.name).indexOf(this.property);
                    formationTopPointGeomDrawn[index].visible = value;
                    // scene.getObjectByName(this.property).visible = value;
                    // pickingScene.getObjectByName(this.property).visible = value;
                });

            formTopColormaps[formationTopPointGeomDrawn[i].name] = formationTopFolder.add(formationTopsFolder.colorMap, formationTopPointGeomDrawn[i].name, ["rainbow", "cooltowarm", "blackbody", "grayscale"]).name("Color Map")
                .onChange(function(value) {

                    let formationLut = new Lut(value);
                    // for ( n = 0; n < formationTopPointGeomDrawn.length; n ++){
                    let b = formationTopPointGeomDrawn.map(e => e['name']).indexOf(this.property);
                    let formationTop = formationTopsPoints[b];
                    let colorAttr;

                    if (formationTop.formColor.length > 0) {

                        let colorMin = Infinity;
                        let colorMax = -Infinity;

                        for (let a = 0; a < formationTop.formColor.length; a++) {
                            if (!isNaN(formationTop.formColor[a])) {
                                colorMin = Math.min(colorMin, formationTop.formColor[a]);
                                colorMax = Math.max(colorMax, formationTop.formColor[a]);
                            }
                        }

                        if (formationTopsFolder.minVal[this.property].length > 0 && !isNaN(Number(formationTopsFolder.minVal[this.property]))) {
                            colorMin = Number(formationTopsFolder.minVal[this.property]);
                        }

                        if (formationTopsFolder.maxVal[this.property].length > 0 && !isNaN(Number(formationTopsFolder.maxVal[this.property]))) {
                            colorMax = Number(formationTopsFolder.maxVal[this.property]);
                        }

                        colorMin = colorMin * 0.99999;
                        colorMax = colorMax * 1.00001;

                        formationLut.setMin(colorMin);
                        formationLut.setMax(colorMax);

                        colorAttr = new Float32Array(formationTop.formColor.length * 3);
                        for (let a = 0; a < formationTop.formColor.length; a++) {
                            if (!isNaN(formationTop.formColor[a])) {
                                // console.log(formationLut);
                                // coef = (formationTop.formColor[i] - colorMin)/(colorMax - colorMin);
                                let formColor = formationLut.getColor(Number(formationTop.formColor[a]));
                                colorAttr[3 * a] = (formColor.r);
                                colorAttr[3 * a + 1] = (formColor.g);
                                colorAttr[3 * a + 2] = (formColor.b);
                            } else {
                                let formColor = formationLut.getColor(colorMin);
                                colorAttr[3 * a] = (formColor.r);
                                colorAttr[3 * a + 1] = (formColor.g);
                                colorAttr[3 * a + 2] = (formColor.b);
                            }
                        }

                    } else {

                        let depthMin = Infinity;
                        let depthMax = -Infinity;

                        for (let a = 0; a < formationTop.y.length; i++) {
                            depthMin = Math.min(depthMin, formationTop.y[a]);
                            depthMax = Math.max(depthMax, formationTop.y[a]);
                        }


                        if (!isNaN(Number(formationTopsFolder.minVal[this.property]))) {
                            depthMin = Number(formationTopsFolder.minVal[this.property]);
                        }

                        if (!isNaN(Number(formationTopsFolder.maxVal[this.property]))) {
                            depthMax = Number(formationTopsFolder.maxVal[this.property]);
                        }

                        depthMin = depthMin * 0.99999;
                        depthMax = depthMax * 1.00001;

                        formationLut.setMin(depthMin);
                        formationLut.setMax(depthMax);

                        colorAttr = new Float32Array(formationTop.y.length * 3);
                        for (let a = 0; a < formationTop.y.length; a++) {
                            let formColor = formationLut.getColor(formationTop.y[a]);
                            colorAttr[3 * a] = (formColor.r);
                            colorAttr[3 * a + 1] = (formColor.g);
                            colorAttr[3 * a + 2] = (formColor.b);
                        }

                    }



                    formationTopPointGeomDrawn[b].geometry.setAttribute('colorAttr', new THREE.BufferAttribute(colorAttr, 3));

                    // formationTopPointGeomDrawn[b].geometry.attributes.colorAttr.needsUpdate = true;

                    // }

                });


            formationTopFolder.add(formationTopsFolder.minVal, formationTopPointGeomDrawn[i].name).name("Min Val")
                .onChange(function(value) {
                    formationTopsFolder.minVal[this.property] = value;
                    formTopColormaps[this.property].setValue(formTopColormaps[this.property].getValue());
                });

            formationTopFolder.add(formationTopsFolder.maxVal, formationTopPointGeomDrawn[i].name).name("Max Val")
                .onChange(function(value) {
                    formationTopsFolder.maxVal[this.property] = value;
                    formTopColormaps[this.property].setValue(formTopColormaps[this.property].getValue());

                });


            formationTopFolder.add(formationTopsFolder.showHistogram, formationTopPointGeomDrawn[i].name).name("Show/Hide Histogram")
                .onChange(function(value) {

                    try {
                        if (document.getElementById("histogramAnalysis").layout.title.text.includes(this.property)) {
                            Plotly.purge("histogramAnalysis");
                            return;
                        }
                    } catch {

                    }

                    // alert("New histogram for " + this.property);
                    // myWindow = window.open("", this.property, "width=200,height=100");   // Opens a new window

                    let n = formationTopPointGeomDrawn.map(e => e['name']).indexOf(this.property);

                    let formationTop = formationTopsPoints[n];
                    let colorCoef = formationTop.y;
                    if (formationTop.formColor.length > 0) {
                        colorCoef = formationTop.formColor;
                    }

                    let min = Infinity,
                        max = -Infinity;

                    for (let a = 0; a < colorCoef.length; a++) {

                        if (Number(colorCoef[a]) < min) min = Number(colorCoef[a]);

                        if (Number(colorCoef[a]) > max) max = Number(colorCoef[a]);
                    }

                    let formationLut = new Lut(formationTopsFolder.colorMap[this.property]);
                    formationLut.setMin(0);
                    formationLut.setMax(30);

                    if (formationTopsFolder.minVal[this.property].length > 0 && !isNaN(Number(formationTopsFolder.minVal[this.property]))) {
                        min = Number(formationTopsFolder.minVal[this.property]);
                    }

                    if (formationTopsFolder.maxVal[this.property].length > 0 && !isNaN(Number(formationTopsFolder.maxVal[this.property]))) {
                        max = Number(formationTopsFolder.maxVal[this.property]);
                    }

                    let binsSize = (max - min) / 30;

                    let dataFMHist = [];
                    for (let a = 0; a < 30; a++) {

                        let data = colorCoef.filter(e => Number(e) > min + a * binsSize && Number(e) <= min + (a + 1) * binsSize);
                        if (data.length == 0) {
                            // continue;
                        }

                        dataFMHist.push({
                            y: data.map(e => Number(e)),
                            opacity: 0.5,
                            // autobinx: true,
                            type: 'histogram',
                            marker: {
                                color: "rgba(" + Math.round(255 * formationLut.getColor(a).r).toString() + ", " + Math.round(255 * formationLut.getColor(a).g).toString() + ", " + Math.round(255 * formationLut.getColor(a).b).toString() + ", 1.0)",
                                line: {
                                    color: "rgba(" + Math.round(255 * formationLut.getColor(a).r).toString() + ", " + Math.round(255 * formationLut.getColor(a).g).toString() + ", " + Math.round(255 * formationLut.getColor(a).b).toString() + ", 1.0)",
                                    width: 1
                                },
                            },
                            xbins: {
                                start: min + a * binsSize,
                                end: min + (a + 1) * binsSize,
                                size: binsSize,
                            },
                            showlegend: false,
                            hoverinfo: 'skip',
                        });

                    }

                    let layoutFmTopHist = {
                        width: $(window).width() * 0.2,
                        height: $(window).width() * 0.2, //0.9 * $("#fmTopHist").height(),
                        margin: {
                            l: 20,
                            r: 0,
                            b: 20,
                            p: 0,
                            t: 20,
                        },
                        xaxis: {
                            tickformat: '.0f',
                            linecolor: '#ffffff',
                            linewidth: 0.5,
                            fixedrange: true,
                            mirror: false,
                            title: "Count",
                            showgrid: false,
                        },
                        showlegend: false,
                        hoverlabel: {},
                        font: {
                            family: 'Open Sans, sans-serif',
                            size: 8,
                            color: '#ffffff',
                        },
                        title: '<b>' + this.property + '</b>',
                        yaxis: {
                            linecolor: '#777777',
                            linewidth: 0.5,
                            mirror: true,
                            fixedrange: true,
                        },
                        paper_bgcolor: 'rgba(0,0,0,0)',
                        plot_bgcolor: 'rgba(0,0,0,0)',
                        // bargroupgap: 0.2,
                        barmode: "overlay",
                    };


                    Plotly.newPlot("histogramAnalysis", dataFMHist, layoutFmTopHist, {
                        displaylogo: false,
                        toImageButtonOptions: {
                            format: 'png', // one of png, svg, jpeg, webp
                            filename: "Histogram_" + this.property.replace(" ", "_"),
                            height: null,
                            width: null,
                            scale: 4 /// Multiply title/legend/axis/canvas sizes by this factor
                        },
                        modeBarButtonsToRemove: ['sendDataToCloud', 'hoverCompareCartesian', 'select2d', 'lasso2d', 'toggleSpikelines']
                    });





                });




        }





    }

    ///////////////////////////////////////////////////
    //////////// Fiber Plotting + Folder //////////////
    ///////////////////////////////////////////////////

    if (FIBER_DATA.length > 0) {

        FIBER_MIN = new Array(FIBER_DATA.length);
        FIBER_MAX = new Array(FIBER_DATA.length);
        FIBER_LUT = new Array(FIBER_DATA.length);
        fiberData = new Array(FIBER_DATA.length);

        FIBER_MIN_MD = new Array(FIBER_DATA.length);
        FIBER_MAX_MD = new Array(FIBER_DATA.length);

        fibRecGeomPicking = new Array(FIBER_DATA.length);
        fibRecGeomDrawn = new Array(FIBER_DATA.length);
        fibRecMaterial = new Array(FIBER_DATA.length);
        fibRecMesh = new Array(FIBER_DATA.length);
        fibRecCount = new Array(FIBER_DATA.length);

        fibDataGeomDrawn = new Array(FIBER_DATA.length);
        fibDataGeomPicking = new Array(FIBER_DATA.length);
        fibDataMaterial = new Array(FIBER_DATA.length);
        fibDataMesh = new Array(FIBER_DATA.length);
        fibDataCount = new Array(FIBER_DATA.length);


        for (var a = 0; a < FIBER_DATA.length; a++) {

            const request = async(j) => {

                console.log("Fiber Data", FIBER_DATA);
                console.log("Fiber Url ", FIBER_DATA[j].url);
                const response = await fetch(FIBER_DATA[j].url);
                fiberData[j] = await response.json();

                fiberData[j].easting = fiberData[j].easting.flat();
                fiberData[j].northing = fiberData[j].northing.flat();
                fiberData[j].tvdss = fiberData[j].tvdss.flat();
                fiberData[j].md = fiberData[j].md.flat();

                FIBER_MIN[j] = fiberData[j].data.map(e => e.min()).sort()[Math.floor(fiberData[j].data.length * 0.8)];
                FIBER_MAX[j] = fiberData[j].data.map(e => e.max()).sort()[Math.floor(fiberData[j].data.length * 0.8)];

                FIBER_MIN_MD[j] = fiberData[j].md.map(e => Number(e)).min();
                FIBER_MAX_MD[j] = fiberData[j].md.map(e => Number(e)).max();

                FIBER_LUT[j] = new Lut();
                FIBER_LUT[j].addColorMap("Strain", STRAIN_JET_COLORMAP);
                FIBER_LUT[j].setColorMap("Strain", STRAIN_JET_COLORMAP.length);
                FIBER_LUT[j].setMin(FIBER_MIN[j]);
                FIBER_LUT[j].setMax(FIBER_MAX[j]);

                if (FIBER_DATA.map(e => e.name).indexOf(FIBER_DATA_GUI['dataSet']) == j) {
                    createFiberDataPlots(j, FIBER_DATA_GUI['showHide']);
                }

            }

            request(a);

        }


        //////////////////////////////////////////////////////////////////////////
        //  Fiber Data GUI -
        //  -1. Select Fiber Dataset,
        //  0. Show/Hide Checkbox,
        // 	1. Min Data,
        // 	2. Max Data,
        // 	3. Min-Ylim,
        // 	4. Max-Ylim
        // 	5. Start/Pause Timelapse,
        //  6. Stop/Reset Timelapse,
        //////////////////////////////////////////////////////////////////////////


        FIBER_DATA_GUI = {
            dataSet: '',
            showHide: false,
            minData: '',
            maxData: '',
            minYLim: '',
            maxYLim: '',
            startPauseTimelapse: function() {},
            stopResetTimelapse: function() {},
            refreshPlot: function() {},
        }


        var fiberMasterFolder = gui.addFolder('Fiber Data');

        fiberMasterFolder.add(FIBER_DATA_GUI, 'dataSet', FIBER_DATA.map(e => e.name))
            .name("Dataset")
            .onChange(function(value) {
                FIBER_DATA_GUI['dataSet'] = value;
                j = FIBER_DATA.map(e => e.name).indexOf(FIBER_DATA_GUI['dataSet']);
                createFiberDataPlots(j, FIBER_DATA_GUI['showHide']);
                FIBER_TIMELAPSE_START = false;
                FIBER_TIMELAPSE_STOP = false;
                FIBER_DATA_INDEX = 0;
            });

        fiberMasterFolder.add(FIBER_DATA_GUI, 'showHide', FIBER_DATA_GUI['showHide']).name("Show/Hide").onChange(function(value) {
            j = FIBER_DATA.map(e => e.name).indexOf(FIBER_DATA_GUI['dataSet']);
            if (j > -1) {
                createFiberDataPlots(j, value);
                FIBER_TIMELAPSE_START = false;
                FIBER_TIMELAPSE_STOP = false;
                FIBER_DATA_INDEX = 0;
            } else {
                alert("Please select a dataset.");
            }
            FIBER_DATA_GUI['showHide'] = value;
        });

        fiberMasterFolder.add(FIBER_DATA_GUI, 'startPauseTimelapse').name("Start/Pause Timelapse").onChange(function(value) {

            if (FIBER_DATA.map(e => e.name).indexOf(FIBER_DATA_GUI['dataSet']) > -1) {
                FIBER_TIMELAPSE_STOP = false;
                FIBER_TIMELAPSE_START = !FIBER_TIMELAPSE_START;
            } else {
                alert("Please select a dataset.");
            }

        });

        fiberMasterFolder.add(FIBER_DATA_GUI, 'stopResetTimelapse').name("Stop/Reset Timelapse").onChange(function(value) {

            if (FIBER_DATA.map(e => e.name).indexOf(FIBER_DATA_GUI['dataSet']) > -1) {
                FIBER_TIMELAPSE_STOP = true;
                FIBER_DATA_INDEX = 0;
            } else {
                alert("Please select a dataset.");
            }
        });

        fiberMasterFolder.add(FIBER_DATA_GUI, 'minData', FIBER_DATA_GUI['minData']).name("Min Data Val.").onChange(function(value) {
            FIBER_DATA_GUI['minData'] = value;
            for (n = 0; n < FIBER_MIN.length; n++) {
                FIBER_MIN[n] = value;
            }
        });

        fiberMasterFolder.add(FIBER_DATA_GUI, 'maxData', FIBER_DATA_GUI['maxData']).name("Max Data Val.").onChange(function(value) {
            FIBER_DATA_GUI['maxData'] = value;
            for (n = 0; n < FIBER_MAX.length; n++) {
                FIBER_MAX[n] = value;
            }
        });


        fiberMasterFolder.add(FIBER_DATA_GUI, 'minYLim', FIBER_DATA_GUI['minYLim']).name("Starting MD").onChange(function(value) {
            FIBER_DATA_GUI['minYLim'] = value;
            for (n = 0; n < FIBER_MIN_MD.length; n++) {
                if (value.length == 0) {
                    FIBER_MIN_MD[n] = NaN;
                } else {
                    FIBER_MIN_MD[n] = Number(value);
                }
            }
        });

        fiberMasterFolder.add(FIBER_DATA_GUI, 'maxYLim', FIBER_DATA_GUI['maxYLim']).name("Ending MD").onChange(function(value) {
            FIBER_DATA_GUI['maxYLim'] = value;
            for (n = 0; n < FIBER_MAX_MD.length; n++) {
                if (value.length == 0) {
                    FIBER_MAX_MD[n] = NaN;
                } else {
                    FIBER_MAX_MD[n] = Number(value);
                }
            }

        });

        fiberMasterFolder.add(FIBER_DATA_GUI, 'refreshPlot').name("Refresh").onChange(function(value) {

            j = FIBER_DATA.map(e => e.name).indexOf(FIBER_DATA_GUI['dataSet']);
            if (j > -1 && FIBER_DATA_GUI['showHide']) {
                FIBER_TIMELAPSE_START = false;
                FIBER_TIMELAPSE_STOP = false;
                createFiberDataPlots(j, true);
            }
        });

    }

    ///////////////////////////////////////////////////
    /////////// Volume Plotting + Folder //////////////
    ///////////////////////////////////////////////////

    if (volumeDataUrl.length > 0) {

        var volumeMasterFolder = gui.addFolder('Volumes');
        const reloadVolume = (l) => {

            console.log("Reloading volume with l - ", l);
            let alphas = volumePointCloud[l].geometry.attributes.alpha.array;
            let colorCoef = volumePointCloud[l].geometry.attributes.color.array;

            let index = 0;
            for (var i = 0; i < volumeData[l].val.length; i++) {

                // This is X, Y for loop
                for (let k = 0; k < volumeData[l].val[i].length; k++) {

                    let x = volumeData[l].x[k];
                    let y = volumeData[l].y[k];
                    let z = volumeData[l].z[i];

                    if (x >= volumeDataGUIControl[l].xmin && x <= volumeDataGUIControl[l].xmax && y >= volumeDataGUIControl[l].ymin && y <= volumeDataGUIControl[l].ymax && z >= volumeDataGUIControl[l].zmin && z <= volumeDataGUIControl[l].zmax) {
                        alphas[index] = 1.0;
                    } else {
                        alphas[index] = 0.0;
                    }

                    let colorVol = volumeLut[l].getColor(volumeData[l].val[i][k]);
                    colorCoef[3 * index] = colorVol.r;
                    colorCoef[3 * index + 1] = colorVol.g;
                    colorCoef[3 * index + 2] = colorVol.b;


                    index = index + 1;
                }
            }

            volumePointCloud[l].geometry.setAttribute('alpha', new THREE.BufferAttribute(new Float32Array(alphas), 1));
            volumePointCloud[l].geometry.setAttribute('color', new THREE.BufferAttribute(colorCoef, 3));

        }

        const requestVolume = async(j) => {

            const response = await fetch(volumeDataUrl[j]);
            const json = await response.json();

            volumeData[j] = json;

            volumeData[j].x = volumeData[j].x.flat();
            volumeData[j].y = volumeData[j].y.flat();
            volumeData[j].z = volumeData[j].z.flat();

            var MAX_POINTS = volumeData[j].val.length * volumeData[j].val[0].length; // volumeData[j].length;
            volumeGeometryDrawn[j] = new THREE.BufferGeometry();
            var volPositions = new Float32Array(MAX_POINTS * 3);
            var colorCoef = new Float32Array(MAX_POINTS * 3);
            var alphas = new Float32Array(MAX_POINTS);

            var minVal = Infinity;
            var maxVal = -Infinity; //volumeData[j][0][3];
            for (var i = 0; i < volumeData[j].val.length; i++) {
                for (l = 0; l < volumeData[j].val[i].length; l++) {
                    minVal = Math.min(minVal, volumeData[j].val[i][l]);
                    maxVal = Math.max(maxVal, volumeData[j].val[i][l]);
                }
            }

            volumeMin[j] = minVal; //new Array(volumeDataUrl.length);
            volumeMax[j] = maxVal; //new Array(volumeDataUrl.length);



            volumeLut[j].setMin(minVal);
            volumeLut[j].setMax(maxVal);

            var index = 0;
            // This is depth for loop
            for (var i = 0; i < volumeData[j].val.length; i++) {

                var z = DOWNSAMPLE * volumeData[j].z[i] - bk_y_min - 0.5 * (bk_y_max - bk_y_min);
                volumeDataBounds[j].zmin = Math.min(volumeDataBounds[j].zmin, volumeData[j].z[i]);
                volumeDataBounds[j].zmax = Math.max(volumeDataBounds[j].zmax, volumeData[j].z[i]);

                // This is X, Y for loop
                for (l = 0; l < volumeData[j].val[i].length; l += 1) {

                    var x = DOWNSAMPLE * volumeData[j].x[l] - bk_x_min - 0.5 * (bk_x_max - bk_x_min);
                    var y = DOWNSAMPLE * volumeData[j].y[l] - bk_z_min - 0.5 * (bk_z_max - bk_z_min);
                    volPositions[3 * index] = EXAGGERATION_X * x;
                    volPositions[3 * index + 1] = EXAGGERATION_Y * z;
                    volPositions[3 * index + 2] = EXAGGERATION_Z * y;

                    volumeDataBounds[j].xmin = Math.min(volumeDataBounds[j].xmin, volumeData[j].x[l]);
                    volumeDataBounds[j].xmax = Math.max(volumeDataBounds[j].xmax, volumeData[j].x[l]);
                    volumeDataBounds[j].ymin = Math.min(volumeDataBounds[j].ymin, volumeData[j].y[l]);
                    volumeDataBounds[j].ymax = Math.max(volumeDataBounds[j].ymax, volumeData[j].y[l]);

                    var colorVol = volumeLut[j].getColor(volumeData[j].val[i][l]);
                    colorCoef[3 * index] = colorVol.r;
                    colorCoef[3 * index + 1] = colorVol.g;
                    colorCoef[3 * index + 2] = colorVol.b;
                    alphas[index] = 0.5
                    index = index + 1;
                }
            }

            // console.log(index);

            volumeDataGUIControl[j] = {

            };

            volumeDataGUIControl[j].xmin = volumeDataBounds[j].xmin;
            volumeDataGUIControl[j].xmax = volumeDataBounds[j].xmax;
            volumeDataGUIControl[j].ymin = volumeDataBounds[j].ymin;
            volumeDataGUIControl[j].ymax = volumeDataBounds[j].ymax;
            volumeDataGUIControl[j].zmin = volumeDataBounds[j].zmin;
            volumeDataGUIControl[j].zmax = volumeDataBounds[j].zmax;


            volumeGeometryDrawn[j].setAttribute('position', new THREE.BufferAttribute(volPositions, 3));
            volumeGeometryDrawn[j].setAttribute('color', new THREE.BufferAttribute(colorCoef, 3));
            volumeGeometryDrawn[j].setAttribute('alpha', new THREE.BufferAttribute(alphas, 1));
            volumeGeometryDrawn[j].verticesNeedUpdate = true;

            // console.log('Added point cloud');
            // console.log(volumeData);

            // volumeDataGUIControl[j] = volumeDataBounds[j].clone();


            volumeDataGUIControl[j].xmin = volumeDataBounds[j].xmin;
            volumeDataGUIControl[j].xmax = volumeDataBounds[j].xmax;
            volumeDataGUIControl[j].ymin = volumeDataBounds[j].ymin;
            volumeDataGUIControl[j].ymax = volumeDataBounds[j].ymax;
            volumeDataGUIControl[j].zmin = volumeDataBounds[j].zmin;
            volumeDataGUIControl[j].zmax = volumeDataBounds[j].zmax;


            volOptions['slider_x_min' + j.toString()] = 0; //volumeDataBounds[j].xmin; //0;
            volOptions['slider_x_max' + j.toString()] = 100; //volumeDataBounds[j].xmax; //100;

            volOptions['slider_y_min' + j.toString()] = 0; //volumeDataBounds[j].zmin; //0;
            volOptions['slider_y_max' + j.toString()] = 100; //volumeDataBounds[j].zmax; //100;

            volOptions['slider_z_min' + j.toString()] = volumeDataBounds[j].zmin; //0;
            volOptions['slider_z_max' + j.toString()] = volumeDataBounds[j].zmax; //100;

            volOptions['color_min' + j.toString()] = minVal; //0;
            volOptions['color_max' + j.toString()] = maxVal; //100;

            volOptions['point size' + j.toString()] = 1.0; //100;


            volTypeFolder[j].add(volOptions, 'slider_x_min' + j.toString(), 0, 100).name('Min Northing').onChange(function(value) {
                volumeDataGUIControl[j].xmin = volumeDataBounds[j].xmin + (value * (volumeDataBounds[j].xmax - volumeDataBounds[j].xmin) / 100);
                reloadVolume(j);
            });

            volTypeFolder[j].add(volOptions, 'slider_x_max' + j.toString(), 0, 100).name('Max Northing').onChange(function(value) {
                volumeDataGUIControl[j].xmax = volumeDataBounds[j].xmin + (value * (volumeDataBounds[j].xmax - volumeDataBounds[j].xmin) / 100);
                reloadVolume(j);
            });

            volTypeFolder[j].add(volOptions, 'slider_y_min' + j.toString(), 0, 100).name('Min Easting').onChange(function(value) {
                volumeDataGUIControl[j].ymin = volumeDataBounds[j].ymin + (value * (volumeDataBounds[j].ymax - volumeDataBounds[j].ymin) / 100);
                reloadVolume(j);
            });

            volTypeFolder[j].add(volOptions, 'slider_y_max' + j.toString(), 0, 100).name('Max Easting').onChange(function(value) {
                volumeDataGUIControl[j].ymax = volumeDataBounds[j].ymin + (value * (volumeDataBounds[j].ymax - volumeDataBounds[j].ymin) / 100);
                reloadVolume(j);
            });

            volTypeFolder[j].add(volOptions, 'slider_z_min' + j.toString(), volumeDataBounds[j].zmin, volumeDataBounds[j].zmax).name('Min Depth').onChange(function(value) {
                volumeDataGUIControl[j].zmin = value; // volumeDataBounds[j].ymin + (value*( volumeDataBounds[j].ymax - volumeDataBounds[j].ymin )/100) ;
                reloadVolume(j);
            });

            volTypeFolder[j].add(volOptions, 'slider_z_max' + j.toString(), volumeDataBounds[j].zmin, volumeDataBounds[j].zmax).name('Max Depth').onChange(function(value) {
                volumeDataGUIControl[j].zmax = value; // volumeDataBounds[j].ymin + (value*( volumeDataBounds[j].ymax - volumeDataBounds[j].ymin )/100) ;
                reloadVolume(j);
            });

            volTypeFolder[j].add(volOptions, 'color_min' + j.toString()).name('Min Color Axis').onChange(function(value) {
                if (isNaN(value)) {
                    volumeLut[j].setMin(volumeMin[j]);
                } else {
                    volumeLut[j].setMin(value);
                }

                reloadVolume(j);
            });

            volTypeFolder[j].add(volOptions, 'color_max' + j.toString()).name('Max Color Axis').onChange(function(value) {
                if (isNaN(value)) {
                    volumeLut[j].setMax(volumeMax[j]);
                } else {
                    volumeLut[j].setMax(value);
                }
                reloadVolume(j);
            });


            volTypeFolder[j].add(volOptions, 'point size' + j.toString(), [1.0, 3.0, 5.0, 7.0, 10.0, 12.0, 15.0]).name('Point Size').onChange(function(value) {
                if (volumePointCloud[j]) {
                    volumePointCloud[j].material.size = Number(value);
                    volumePointCloud[j].material.needsUpdate = true;
                }
            });

            volTypeFolder[j].add(volOptions, 'plotHistogram').name('Show/Hide Histogram').onChange(function(value) {

                try {
                    if (document.getElementById("histogramAnalysis").layout.title.text.includes(volumeDataName[j])) {
                        Plotly.purge("histogramAnalysis");
                        return;
                    }
                } catch {

                }
                // if (!($('.modal.in').length)) {
                // $('.modal-dialog').css({
                // 	top: 0,
                // 	left: 0
                // });
                // }
                // $('#fmTopHistModal').modal({
                // 	backdrop: false,
                // 	show: true
                // });

                // $('.modal-dialog').draggable({
                // handle: ".modal-header"
                // });

                // $("#fmTopLabel").html("Volume Data Histogram");
                // $("#fmTopHist").empty();
                // $("#fmTopHist").height(parentContainer.height() *0.6);


                var min = Infinity,
                    max = -Infinity;

                var histLut = new Lut(volOptions['colorBar'][volumeDataName[j]]);
                histLut.setMin(0);
                histLut.setMax(30);

                min = volumeMin[j];
                max = volumeMax[j];

                if (!isNaN(Number(volOptions['color_min' + j.toString()]))) {
                    min = Number(volOptions['color_min' + j.toString()]);
                }

                if (!isNaN(Number(volOptions['color_max' + j.toString()]))) {
                    max = Number(volOptions['color_max' + j.toString()]);
                }

                var binsSize = (max - min) / 30;

                var dataFMHist = [];
                for (let a = 0; a < 30; a++) {
                    var data = volumeData[j].val.flat().filter(e => Number(e) > min + a * binsSize && Number(e) <= min + (a + 1) * binsSize);
                    if (data.length == 0) {
                        // continue;
                    }

                    dataFMHist.push({
                        y: data.map(e => Number(e)),
                        opacity: 0.5,
                        // autobinx: true,
                        type: 'histogram',
                        marker: {
                            color: "rgba(" + Math.round(255 * histLut.getColor(a).r).toString() + ", " + Math.round(255 * histLut.getColor(a).g).toString() + ", " + Math.round(255 * histLut.getColor(a).b).toString() + ", 1.0)",
                            line: {
                                color: "rgba(" + Math.round(255 * histLut.getColor(a).r).toString() + ", " + Math.round(255 * histLut.getColor(a).g).toString() + ", " + Math.round(255 * histLut.getColor(a).b).toString() + ", 1.0)",
                                width: 1
                            },
                        },
                        xbins: {
                            start: min + a * binsSize,
                            end: min + (a + 1) * binsSize,
                            size: binsSize,
                        },
                        showlegend: false,
                        hoverinfo: 'skip',
                    });

                }

                var layoutFmTopHist = {
                    width: $(window).width() * 0.2,
                    height: $(window).width() * 0.2, //0.9 * $("#fmTopHist").height(),
                    margin: {
                        l: 20,
                        r: 0,
                        b: 20,
                        p: 0,
                        t: 20,
                    },
                    xaxis: {
                        tickformat: '.0f',
                        linecolor: '#ffffff',
                        linewidth: 0.5,
                        fixedrange: true,
                        mirror: false,
                        title: "Count",
                        showgrid: false,
                    },
                    showlegend: false,
                    hoverlabel: {},
                    font: {
                        family: 'Open Sans, sans-serif',
                        size: 8,
                        color: '#ffffff',
                    },
                    title: '<b>' + volumeDataName[j] + '</b>',
                    yaxis: {
                        linecolor: '#ffffff',
                        linewidth: 0.5,
                        mirror: true,
                    },
                    paper_bgcolor: 'rgba(0,0,0,0)',
                    plot_bgcolor: 'rgba(0,0,0,0)',
                    // bargroupgap: 0.2,
                    barmode: "overlay",
                };


                Plotly.newPlot("histogramAnalysis", dataFMHist, layoutFmTopHist, {
                    displaylogo: false,
                    toImageButtonOptions: {
                        format: 'png', // one of png, svg, jpeg, webp
                        filename: "Histogram_" + volumeDataName[j].replace(" ", "_"),
                        height: null,
                        width: null,
                        scale: 4 /// Multiply title/legend/axis/canvas sizes by this factor
                    },
                    modeBarButtonsToRemove: ['sendDataToCloud', 'hoverCompareCartesian', 'select2d', 'lasso2d', 'toggleSpikelines']
                });

            });

        }

        volumeData = new Array(volumeDataUrl.length);
        volumeLut = new Array(volumeDataUrl.length);
        volumePointCloud = new Array(volumeDataUrl.length);
        volumeGeometryDrawn = new Array(volumeDataUrl.length);
        volumeDataBounds = new Array(volumeDataUrl.length);
        volumeDataGUIControl = new Array(volumeDataUrl.length);
        volOptions = {
            'showHide': [],
            'plotHistogram': function() {},
            'colorBar': []
        }
        volTypeFolder = new Array(volumeDataUrl.length);

        volumeMin = new Array(volumeDataUrl.length);
        volumeMax = new Array(volumeDataUrl.length);

        for (k = 0; k < volumeDataUrl.length; k++) {

            // lut = new Lut();
            volumeLut[k] = new Lut();
            volumeLut[k].setColorMap('rainbow');


            volumeDataBounds[k] = {
                xmin: Infinity,
                xmax: -Infinity, //
                ymin: Infinity,
                ymax: -Infinity,
                zmin: Infinity,
                zmax: -Infinity,
            }

            volTypeFolder[k] = volumeMasterFolder.addFolder(volumeDataName[k]);

            volOptions['showHide'][volumeDataName[k]] = false;
            volOptions['colorBar'][volumeDataName[k]] = 'rainbow';

            volTypeFolder[k].add(volOptions['showHide'], volumeDataName[k])
                .name('Show/Hide')
                .onChange(function(value) {

                    var volIndex = volumeDataName.indexOf(this.property);
                    if (volIndex == -1) return;

                    if (!volumeData[volIndex] || !volumeData[volIndex].x) {
                        if (value) {
                            alert('Data being downloaded. Please try in a minute.');
                            this.setValue(false);
                        }
                    } else {
                        createVolumePlots(volIndex, value);
                    }



                });


            volTypeFolder[k].add(volOptions['colorBar'], volumeDataName[k], ["rainbow", "faulttrends", "cooltowarm", "blackbody", "grayscale"])
                .name('Colormap')
                .onChange(function(value) {

                    var arrIndex = volumeDataName.indexOf(this.property);

                    if (arrIndex == -1) return;

                    if (value == "faulttrends") {

                        volumeLut[arrIndex].addColorMap("faulttrends", [
                            [0.0, 0x000000],
                            [0.01, 0x000000],
                            [0.011, 0xFF0000],
                            [0.25, 0xFF0000],
                            [0.251, 0xFFFF00],
                            [0.5, 0xFFFF00],
                            [0.51, 0x0066FF],
                            [0.75, 0x0066FF],
                            [0.751, 0x00FF00],
                            [1.0, 0x00FF00]
                        ]);
                        volumeLut[arrIndex].setColorMap("faulttrends");
                    } else {

                        volumeLut[arrIndex].setColorMap(value);

                    }


                    var NO_POINTS = volumeData[arrIndex].val.length * volumeData[arrIndex].val[0].length; // volumeData[j].length;
                    var colorCoefUpdate = new Float32Array(NO_POINTS * 3);
                    var index = 0;
                    // This is depth for loop
                    for (var i = 0; i < volumeData[arrIndex].val.length; i++) {
                        // This is X, Y for loop
                        for (l = 0; l < volumeData[arrIndex].val[i].length; l++) {

                            var colorVol = volumeLut[arrIndex].getColor(volumeData[arrIndex].val[i][l]);
                            colorCoefUpdate[3 * index] = colorVol.r;
                            colorCoefUpdate[3 * index + 1] = colorVol.g;
                            colorCoefUpdate[3 * index + 2] = colorVol.b;
                            index = index + 1;
                        }
                    }
                    volumeGeometryDrawn[arrIndex].setAttribute('color', new THREE.BufferAttribute(colorCoefUpdate, 3));
                });

            requestVolume(k);
        }
    }

    ///////////////////////////////////////
    /////////// ADDED VOLUME //////////////
    ///////////////////////////////////////

    /////////////////////////////////
    ////// 	 GRID   FOLDER   ////////
    /////////////////////////////////

    if (true) {

        var gridFolder = gui.addFolder('Grid');

        var gridSettings = {
            gridShow: true,
            gridColor: "#9c9b9b",
            gridSize: gridSize,
            gridX: gridXScale,
            gridY: gridYScale,
            gridZ: gridZScale,
            show_hide_pumplogs: function() {
                PUMPLOGS_PRESENT = !PUMPLOGS_PRESENT;
                onWindowResize();
            }
        };

        gridFolder.add(gridSettings, 'gridShow').name('Show/Hide Grid')
            .onChange(function(value) {
                gridMesh.visible = value;
            });

        gridFolder.addColor(gridSettings, 'gridColor').name('Grid Color')
            .onChange(function(value) {
                var gridC = new THREE.Color(value).convertSRGBToLinear();
                gridMesh.material.color.setHex(gridC.getHex());
            });

        gridFolder.add(gridSettings, 'gridX', [0.25, 0.5, 0.75, 1, 1.5, 2, 2.5]).name('Grid-X Scale')
            .onChange(function(value) {
                gridXScale = value;
                updateGridGeometry();
            });

        gridFolder.add(gridSettings, 'gridY', [0.25, 0.5, 0.75, 1, 1.5, 2, 2.5]).name('Grid-Y Scale')
            .onChange(function(value) {
                gridYScale = value;
                updateGridGeometry();
            });

        gridFolder.add(gridSettings, 'gridZ', [0.25, 0.5, 0.75, 1, 1.5, 2, 2.5]).name('Grid-Z Scale')
            .onChange(function(value) {
                gridZScale = value;
                updateGridGeometry();
            });

        gridSizeDropdown = gridFolder.add(gridSettings, 'gridSize', [100, 250, 500, 750, 1000]).name('Grid Size (ft)')
            .onChange(function(value) {
                gridSize = value;
                updateGridGeometry();
            });

    }


    /////////////////////////////////
    ///// 	GYRATION  FOLDER   //////
    /////////////////////////////////

    if (gyration.length > 0) {

        var gyrationFolder = gui.addFolder('Gyration');

        var gyrationSettings = {
            show_gyration: false,
            color_by: 'Time in Stage',
            timelapse_mode: 'All Ellipsoids',
        };

        gyrationFolder.add(gyrationSettings, 'show_gyration').name('Show/Hide Gyration')
            .onChange(function(value) {

                var iShow = [];
                for (var i = 0; i < gyration.length; i++) {
                    if (!value) {
                        iShow.push(0, 0, 0);
                    } else {
                        iShow.push(1, 1, 1);
                    }
                }
                gyrationGeomDrawn.setAttribute('iShow', new THREE.InstancedBufferAttribute(new Float32Array(iShow), 3));


            });

        gyrationFolder.add(gyrationSettings, 'color_by', ['Time in Stage', 'Stage']).name('Color By')
            .onChange(function(value) {

                console.log(value);
                if (value == 'Time in Stage') {

                    lut = new Lut();
                    lut.setColorMap('rainbow');
                    lut.setMax(1);
                    lut.setMin(0);
                    iColors = [];

                    for (var i = 0; i < gyration.length; i++) {
                        var colorGyration = lut.getColor(gyration[i].color);
                        iColors.push(colorGyration.r, colorGyration.g, colorGyration.b);
                    }
                    gyrationGeomDrawn.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));


                } else {

                    var iColors = [];
                    for (var i = 0; i < gyration.length; i++) {
                        var eventTempColor = new THREE.Color(getColor(gyration[i].stage)).convertSRGBToLinear();
                        iColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);
                    }
                    gyrationGeomDrawn.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));

                }

            });


        gyrationFolder.add(gyrationSettings, 'timelapse_mode', ['Final Ellipsoid', 'All Ellipsoids']).name('Show')
            .onChange(function(value) {

                if (value == 'All Ellipsoids') {

                    var iMode = [];
                    for (var i = 0; i < gyration.length; i++) {
                        iMode.push(1, 1, 1);
                    }
                    gyrationGeomDrawn.setAttribute('iMode', new THREE.InstancedBufferAttribute(new Float32Array(iMode), 3));

                } else {

                    var iMode = [];
                    for (var i = 0; i < gyration.length - 1; i++) {
                        if (gyration[i].well == gyration[i + 1].well && gyration[i].stage == gyration[i + 1].stage) {
                            iMode.push(0, 0, 0);
                        } else {
                            iMode.push(1, 1, 1);
                        }
                    }
                    iMode.push(1, 1, 1);
                    gyrationGeomDrawn.setAttribute('iMode', new THREE.InstancedBufferAttribute(new Float32Array(iMode), 3));

                }

            });

    }

    /////////////////////////////////////////////
    /////// TIMELAPSE FOLDER EVENTS & ST ////////
    /////////////////////////////////////////////

    if (events.length > 0 && events[0].hasOwnProperty('time') && !MODELLING_MODE) {

        var etTimelapseFolder = eventFolder.addFolder('Timelapse');

        var etTimelapseSettings = {
            timelapse_pause_play: function() {
                ET_TIMELAPSE_MODE = true;
                ET_TIMELAPSE = !ET_TIMELAPSE;
                if (ET_TIMELAPSE) {

                    for (var i = 0; i < events.length; i++) {
                        if (eventGeomDrawn.attributes.iScale.array[3 * i]) {
                            visibleEvents++;
                        }
                    }

                }
            },
            timelapse_stop_reset: function() {
                ET_TIMELAPSE_MODE = false;
                eventCounterRender = 0;
                visibleEvents = 0;
                render();
                resetEventTimelapse();
                //shuffleEventGeometry();
            },
            show_mode: 'All Stages',
        };

        etTimelapseFolder.add(etTimelapseSettings, 'timelapse_pause_play').name('Start/Pause');
        etTimelapseFolder.add(etTimelapseSettings, 'timelapse_stop_reset').name('Stop/Reset');

        etTimelapseFolder.add(etTimelapseSettings, 'show_mode', ['All Stages', 'Current Stage']).name('Show Mode')
            .onChange(function(value) {
                if (value == 'All Stages') {
                    ET_TIMELAPSE_CURRENT_STAGE = false;
                } else {
                    ET_TIMELAPSE_CURRENT_STAGE = true;
                }
            });

    }


    if (events.length > 0 && event_datasets.length > 0 && admin) {

        let Maincircle = document.getElementById("eventSelector");
        TweenMax.set(Maincircle, { scale: 1, xPercent: 0, yPercent: 0 });

        editEventsFolder = eventFolder.addFolder('Edit Events');

        editEventsFolderValues = {
            dataset: '',
            cursorSize: 100,
            event_dataset_id: null,
            toggleEdit: function() {},
            undo: function() {},
            resetSelection: function() {},
            saveSelection: function() {},
        };


        editEventsFolder.add(editEventsFolderValues, 'toggleEdit').name('Toggle Edit Mode')
            .onChange((val) => {

                if (editEventsFolderValues['dataset'] == '') {
                    alert("Please select a dataset.");
                    return;
                }


                let circle = document.getElementById("eventSelector");
                if (!editMode) {
                    alert("In Edit mode. Press shift to start deleting events.");
                    circle.style.display = "block";
                } else {
                    circle.style.display = "none";
                }
                editMode = !editMode;
            });


        editEventsFolder.add(editEventsFolderValues, 'dataset', event_datasets.map(e => e['title'])).name('Dataset')
            .onChange((val) => {
                eventCheckBoxes['All Events'].setValue(false);
                eventCheckBoxes[val][val].setValue(true);
                editEventsFolderValues['event_dataset_id'] = event_datasets.filter(e => e.title == val)[0]['id'];
            });

        editEventsFolder.add(editEventsFolderValues, 'cursorSize', [10, 20, 50, 75, 100, 150, 200]).name('Cursor Size')
            .onChange((val) => {
                let circle = document.getElementById("eventSelector");
                circle.style.width = val + "px";
                circle.style.height = val + "px";
            });



        editEventsFolder.add(editEventsFolderValues, 'resetSelection').name('Reset')
            .onChange(function(value) {

                let iVisible = eventGeomDrawn.attributes.iVisible.array;

                for (let pixelBufferId = 0; pixelBufferId < events.length; pixelBufferId++) {
                    if (events[pixelBufferId].dataset == editEventsFolderValues['dataset']) {
                        iVisible[3 * pixelBufferId] = 1;
                        iVisible[3 * pixelBufferId + 1] = 1;
                        iVisible[3 * pixelBufferId + 2] = 1;
                    }
                }

                eventGeomDrawn.setAttribute('iVisible', new THREE.InstancedBufferAttribute(iVisible, 3));
                eventGeomPicking.setAttribute('iVisible', new THREE.InstancedBufferAttribute(iVisible, 3));

            });


        editEventsFolder.add(editEventsFolderValues, 'saveSelection').name('Save')
            .onChange(function(value) {

                if (editEventsFolderValues['dataset'] == '') {
                    alert("Please select a dataset.");
                    return;
                }

                var answer = window.confirm("Are you sure? This will overwrite the database dataset.");
                if (answer) {

                    let event_ids = [];
                    for (let n = 0; n < events.length; n++) {
                        if (events[n].dataset == editEventsFolderValues['dataset'] && eventGeomDrawn.attributes.iVisible.array[3 * n] == 1) {
                            event_ids.push(events[n].id);
                        }
                    }

                    $('#loadingStatus').html('Updating event dataset..');
                    $('#loadingStatus').show()


                    $(function() {
                        $.ajax({
                            type: "PATCH",
                            headers: {
                                'X-Transaction': 'Updating Event dataset results.',
                                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
                            },
                            url: "/projects/" + project_id + "/event_datasets/" + editEventsFolderValues['event_dataset_id'] + "/update_ids",
                            data: {
                                'authenticity_token': $('[name="csrf-token"]')[0].content,
                                'id': editEventsFolderValues['event_dataset_id'],
                                'event_result_ids': event_ids
                            },
                            success: function(data) {
                                $('#loadingStatus').html(data['message']);
                                $('#loadingStatus').show()
                                setTimeout(function() {
                                    $('#loadingStatus').fadeOut('slow');
                                }, 3000); //
                            },


                        });
                    });



                }



            });



    }




    if (sourceTensor.length > 0 && sourceTensor[0].hasOwnProperty("time")) {

        var stTimelapseFolder = sourceTensorFolder.addFolder('Timelapse');

        var stTimelapseSettings = {
            timelapse_pause_play: function() {
                ST_TIMELAPSE_MODE = true;
                ST_TIMELAPSE = !ST_TIMELAPSE;
                if (ST_TIMELAPSE) {

                    for (var i = 0; i < sourceTensor.length; i++) {
                        if (sourceTensorGeomDrawn[0].attributes.iScale.array[3 * i]) {
                            visibleST++;
                        }
                    }

                }
            },
            timelapse_stop_reset: function() {
                ST_TIMELAPSE_MODE = false;
                stCounterRender = 0;
                visibleST = 0;
                render();
                resetSTTimelapse();
            },
            show_mode: 'All Stages',
        };

        stTimelapseFolder.add(stTimelapseSettings, 'timelapse_pause_play').name('Start/Pause');
        stTimelapseFolder.add(stTimelapseSettings, 'timelapse_stop_reset').name('Stop/Reset');

        stTimelapseFolder.add(stTimelapseSettings, 'show_mode', ['All Stages', 'Current Stage']).name('Show Mode')
            .onChange(function(value) {
                if (value == 'All Stages') {
                    ST_TIMELAPSE_CURRENT_STAGE = false;
                } else {
                    ST_TIMELAPSE_CURRENT_STAGE = true;
                }
            });


    }



    //////////////////////////////////
    /////////	SCENE FOLDER   ///////
    //////////////////////////////////

    var scenesFolder = gui.addFolder('Views');

    var scenesSettings = {
        noRows: '1',
        noCols: '1',
        snapshotFactor: '1.5',
        compassFactor: '1.5'
    }

    sceneView = [];

    for (var m = 0; m < 4; m++) {

        var aspect = (parentContainer.width()) / (parentContainer.height());

        sceneView[m] = {
            maxWidth: parentContainer.width(),
            maxHeight: parentContainer.height(),
            left: 0,
            aspect: '', //parentContainer.width() /(parentContainer.height() ),
            bottom: 0,
            height: 1,
            width: 1,
            camera_type: 0,
            camera_type_name: 'Orthographic',
            oCamera: new THREE.OrthographicCamera(-SCREEN_WIDTH / 2, SCREEN_WIDTH / 2, SCREEN_HEIGHT / 2, -SCREEN_HEIGHT / 2, NEAR, FAR),
            pCamera: new THREE.PerspectiveCamera(VIEW_ANGLE, ASPECT, 0.1 * NEAR, 1000 * FAR),
            compassCamera: new THREE.OrthographicCamera(-COMPASS_WIDTH / 2, COMPASS_WIDTH / 2, COMPASS_HEIGHT / 2, -COMPASS_HEIGHT / 2, 200, 1000),
            rotation: true,
            rotateSpeed: 1,
            top_view: function() {},
            side_view: function() {},
            take_snapshot: function() {},
            save_view: function() {},
        };

        scene.add(sceneView[m]['pCamera']);
        scene.add(sceneView[m]['oCamera']);
        scene.add(sceneView[m]['compassCamera']);

        // CONTROLS
        sceneView[m]['oControls'] = new OrbitControls(sceneView[m]['oCamera'], document.getElementById('container' + (m + 1).toString()));
        sceneView[m]['oControls'].screenSpacePanning = true;
        sceneView[m]['oControls'].damping = 0.2;

        // CONTROLS
        sceneView[m]['pControls'] = new OrbitControls(sceneView[m]['pCamera'], document.getElementById('container' + (m + 1).toString()));
        sceneView[m]['pControls'].screenSpacePanning = true;
        sceneView[m]['pControls'].damping = 0.2;


        // DRAG CONTROLS
        sceneView[m]['pDragControls'] = new DragControls([...wellLabelMesh, ...formLabelMesh], sceneView[m]['pCamera'], document.getElementById('subcontainer' + (m + 1).toString()));

        sceneView[m]['pDragControls'].addEventListener('dragstart', function(event) {
            for (var n = 0; n < 4; n++) {
                sceneView[n]['oControls'].enabled = false;
                sceneView[n]['pControls'].enabled = false;
            }
        });

        sceneView[m]['pDragControls'].addEventListener('dragend', function(event) {
            for (var n = 0; n < 4; n++) {
                sceneView[n]['oControls'].enabled = true;
                sceneView[n]['pControls'].enabled = true;
            }
        });


        // DRAG CONTROLS
        sceneView[m]['oDragControls'] = new DragControls([...wellLabelMesh, ...formLabelMesh], sceneView[m]['oCamera'], document.getElementById('subcontainer' + (m + 1).toString()));

        sceneView[m]['oDragControls'].addEventListener('dragstart', function(event) {
            for (var n = 0; n < 4; n++) {
                sceneView[n]['oControls'].enabled = false;
                sceneView[n]['pControls'].enabled = false;
            }
        });

        sceneView[m]['oDragControls'].addEventListener('dragend', function(event) {
            for (var n = 0; n < 4; n++) {
                sceneView[n]['oControls'].enabled = true;
                sceneView[n]['pControls'].enabled = true;
            }
        });

        // Initialize Camera;
        sceneView[m]['oCamera'].position.set(0.0 * (bk_x_max - bk_x_min), 0.5 * [(bk_y_max - bk_y_min), (bk_x_max - bk_x_min), (bk_z_max - bk_z_min)].min(), 0.0 * (bk_z_max - bk_z_min));
        sceneView[m]['oCamera'].lookAt(0, 0, 0);
        sceneView[m]['oCamera'].quaternion.copy(new THREE.Quaternion());
        sceneView[m]['oControls'].target.set(0.001, 0, 0);


        // pointLight = new THREE.PointLight( 0xffffff, 0.8 );
        // sceneView[m]['oCamera'].add(new THREE.PointLight(0xffffff, 0.8));
        sceneView[m]['oControls'].update();

        sceneView[m]['pCamera'].position.set(0.0 * (bk_x_max - bk_x_min), 0.5 * (bk_y_max - bk_y_min), 0.0 * (bk_z_max - bk_z_min));
        sceneView[m]['pCamera'].quaternion.copy(new THREE.Quaternion());

        // sceneView[m]['pCamera'].add(new THREE.PointLight(0xffffff, 0.8));
        sceneView[m]['pCamera'].updateMatrixWorld();
        sceneView[m]['pCamera'].updateProjectionMatrix();
        sceneView[m]['pControls'].target.set(0.001, 0, 0);
        sceneView[m]['pControls'].update();
        sceneView[m]['compassCamera'].up = sceneView[m].camera_type == 0 ? sceneView[m].oCamera.up : sceneView[m].oCamera.up;

        // var light = new THREE.PointLight(0xffffff, 1);
        // sceneView[m]['oCamera'].add(light);
        // sceneView[m]['pCamera'].add(light);


    }

    scenesFolder.add(scenesSettings, 'noRows', ['1', '2']).name('No of Rows').onChange(function(val) {
        SCENE_ROWS = Number(val);
        updateScenes(SCENE_ROWS, SCENE_COLS);
    })

    if (!MODELLING_MODE) {

        scenesFolder.add(scenesSettings, 'noCols', ['1', '2']).name('No of Cols').onChange(function(val) {
            SCENE_COLS = Number(val);
            updateScenes(SCENE_ROWS, SCENE_COLS);
        })

        scenesFolder.add(scenesSettings, 'snapshotFactor', ['0.5', '0.75', '1.0', '1.25', '1.5', '1.75', '2', '2.5', '3', '4']).name('Snapshot Factor').onChange(function(val) {
            SNAPSHOT_FACTOR = Number(val);
        })

        scenesFolder.add(scenesSettings, 'compassFactor', ['0.5', '0.75', '1.0', '1.25', '1.5', '1.75', '2', '2.5', '3', '4']).name('Annot Factor').onChange(function(val) {
            COMPASS_FACTOR = Number(val);
        })
    }

    updateScenes();

    // cameraFolder = gui.addFolder('Camera');

    // camera_view = {
    // 	camera_type: 'Perspective', //getCookie("CameraType_V_1_1" + @plot.id %>') == '0' ?  'Orthographic': 'Perspective',
    // 	rotation: false,
    // 	width: parentContainer.width() ,
    // 	height: parentContainer.height() ,
    // 	aspect: (parentContainer.width() )/parentContainer.height() ,
    // 	take_snapshot: function( filename = Date.now().toString() ){

    // 		factor = 3;
    // 		renderer.setSize( factor*renderer.getSize().x, factor*renderer.getSize().y, false);
    // 		compassRenderer.setSize( factor*compassRenderer.getSize().x, factor*compassRenderer.getSize().y, false);

    // 		// arrowHelper.setLength(45, 10*factor*0.5, 10*factor*0.5);
    // 		// arrowHelper2.setLength(45, 10*factor*0.5, 10*factor*0.5);
    // 		// arrowHelper3.setLength(45, 10*factor*0.5, 10*factor*0.5);

    // 		if (camera_type == 0 ){
    // 			renderer.render(scene, oCamera);
    // 		}
    // 		else {
    // 			renderer.render(scene, pCamera);
    // 		}
    // 	    compassRenderer.render( compassScene, compassCamera );

    // 		var masterScreenshot = renderer.domElement.toDataURL();
    // 		var compassScreenshot = compassRenderer.domElement.toDataURL();

    // 		var img1 = document.createElement('img'); 
    // 		var img2 = document.createElement('img'); 
    // 		var img3 = document.createElement('img'); 
    // 		var canvas = document.createElement('canvas');

    // 		img1.src = masterScreenshot;
    // 		img1.onload = function() {
    // 			img2.src = compassScreenshot;
    // 			canvas.width = img1.width;
    // 			canvas.height = img1.height;
    // 		};

    // 		img2.onload = function() {
    // 		    var imageData = removeImageBlanks(img2); //Will return cropped image data
    // 			img3.src = imageData;
    // 		}


    // 		img3.onload = function() {

    // 			fontSize = 36; //Math.round(Math.min(img1.height, img1.width)/50);


    // 			var context = canvas.getContext('2d');
    // 			context.drawImage(img1, 0, 0);
    // 			context.drawImage(img3, 0.025*Math.min(img1.height, img1.width), img1.height - img3.height - 0.025*Math.min(img1.height, img1.width));
    // 			context.font = fontSize.toString() + 'pt Nunito';
    // 			context.fillStyle = 'white';
    // 			context.fillText("Grid size " + gridSize + " ft" , 0.025*Math.min(img1.height, img1.width), 0.025*Math.min(img1.height, img1.width) + fontSize );

    // 			heightPerElement = 20*factor;
    // 			for ( let l = 0; l < LEGEND_TEXT.length; l ++){
    // 				grd = context.createLinearGradient(0,0,0,10);
    // 				grd.addColorStop(0, LEGEND_COLOR[l]);
    // 				grd.addColorStop(1, LEGEND_COLOR[l]);
    // 				context.fillStyle = grd;
    // 				y0 = l*heightPerElement + 0.025*Math.min(img1.height, img1.width);
    // 				y1 = (l+1)*heightPerElement + 0.025*Math.min(img1.height, img1.width);
    // 				context.fillRect(img1.width - 0.025*Math.min(img1.height, img1.width) - 20*factor, Math.ceil(y0)+1, 20*factor, 20*factor);


    // 				context.font = Math.round(0.6*(y1 - y0)).toString() + 'pt Nunito';
    // 				context.fillStyle = 'white';
    // 				context.textAlign = "end";
    // 				context.fillText(LEGEND_TEXT[l] , 
    // 								img1.width - 0.025*Math.min(img1.height, img1.width) - 30*factor, 
    // 								y1 -  Math.round(0.2*(y1 - y0))
    // 				);


    // 			}

    // 			link = document.createElement('a');
    // 			link.download = 'BHS_Plot_' + filename + '.png';
    // 			link.href = canvas.toDataURL()
    // 			link.click();

    // 		};   

    // 		renderer.setSize( renderer.getSize().x/factor, renderer.getSize().y/factor, false);
    // 		compassRenderer.setSize( compassRenderer.getSize().x/factor, compassRenderer.getSize().y/factor, false);

    // 		if (camera_type == 0 ){
    // 			renderer.render(scene, oCamera);
    // 		}
    // 		else {
    // 			renderer.render(scene, pCamera);
    // 		}
    // 		compassRenderer.render( compassScene, compassCamera );

    // 		arrowHelper.setLength(45, 10, 10);
    // 		arrowHelper2.setLength(45, 10, 10);
    // 		arrowHelper3.setLength(45, 10, 10);

    // 	},			
    // };

    // sceneWidthSettings = cameraFolder.add(camera_view, 'width' ).name('Set Scene Width').onChange((val) => {
    // 	if ( Number(val) > parentContainer.width() ){
    // 		RENDERER_WIDTH = parentContainer.width() ;
    // 	}
    // 	else {
    // 		RENDERER_WIDTH = Number(val);
    // 	}
    // 	onWindowResize(RENDERER_WIDTH, RENDERER_HEIGHT);
    // });

    // sceneHeightSettings = cameraFolder.add(camera_view, 'height' ).name('Set Scene Height').onChange((val) => {
    // 	if ( Number(val) > parentContainer.height() ){
    // 		RENDERER_HEIGHT = parentContainer.height() ;
    // 	}
    // 	else {
    // 		RENDERER_HEIGHT = Number(val);
    // 	}
    // 	onWindowResize(RENDERER_WIDTH, RENDERER_HEIGHT);
    // });

    // snapShotButton = cameraFolder.add(camera_view, 'take_snapshot').name('Take Snapshot');

    // onDocumentKeyDown = function (event) {
    // 	console.log("Taking screenshot.");
    // 	console.log(event);
    // 	var keyCode = event.which;
    // 	if ( keyCode == 70 && event.altKey){
    // 		snapShotButton.fire();
    // 	}
    // };

    // document.addEventListener("keydown", onDocumentKeyDown, false);

    // window.onunload = function() {
    // 	document.removeEventListener('keydown', onDocumentKeyDown);
    // 	try {
    // 		eventGeomDrawn.dispose();
    // 		eventGeomPicking.dispose();
    // 		eventMaterial.dispose();
    // 		eventMaterialPick.dispose();
    // 	}
    // 	catch {

    // 	}

    // 	return;
    // }


    // cameraFolder.add(camera_view, 'move_around' ).name('Move Camera');

    /////////////////////////////////
    ///// CUSTOM TOOLS FOLDER   /////
    /////////////////////////////////

    if (false && MODELLING_MODE) {

        var modellingFolder = gui.addFolder('Modelling');

        const radCorrP = 0.4;
        const radCorrS = 0.66;
        const comCorr = 0.7;
        const receiverCorrect = 1e-3 / 2.2 * 0.0254;

        var modellingSettings = {
            Vp: 15000,
            Zsou: 4600,
            rho: 2.5,
            noiseLevelType: 'Weak',
            noiseLevel: 3.0e-8 * 2 * receiverCorrect,
            plotViewingDistance: function() {

                console.log("modellingSettings", modellingSettings);
                if (modellingSettings['selectedDesign'] == '') {
                    alert("Select a design first.");
                    return;
                }


                var minNorthGrid = [trtWell, mtrWell].flat().map(e => e.x).flat().min() - 2500;
                var maxNorthGrid = [trtWell, mtrWell].flat().map(e => e.x).flat().max() + 2500;

                var minEastGrid = [trtWell, mtrWell].flat().map(e => e.z).flat().min() - 2500;
                var maxEastGrid = [trtWell, mtrWell].flat().map(e => e.z).flat().max() + 2500;

                var planeDepth = [trtWell, mtrWell].flat().map(e => e.y).flat().min() - 2500;

                var currentReceivers = rec.filter(e => e.design == modellingSettings['selectedDesign']);
                var diffGrid = 25; // Calculation in 25 ft difference;
                // Velocity in m/sec
                var pVelocity = modellingSettings['Vp'] / 3.28084;

                var viewingPlane = {
                    x: [],
                    y: [],
                    z: [],
                    val: [],
                }

                // X = Northing, 
                // Y = Depth
                // Z = Easting

                var depth = -1 * modellingSettings['Zsou'];
                // var firstTime = true;
                for (var z = minEastGrid; z < maxEastGrid; z += diffGrid) {
                    for (var x = minNorthGrid; x < maxNorthGrid; x += diffGrid) {
                        var dist = [];
                        for (var n = 0; n < currentReceivers.length; n++) {
                            dist[n] = Math.pow(
                                Math.pow(x - currentReceivers[n].x, 2) +
                                Math.pow(depth - currentReceivers[n].y, 2) +
                                Math.pow(z - currentReceivers[n].z, 2), 0.5);
                        }
                        // if (firstTime) {
                        //     console.log("dist Array", dist);
                        // }
                        dist = dist.min();

                        // if (firstTime) {
                        //     console.log("dist", dist);
                        // }

                        let M0 = ((4 * Math.PI * modellingSettings['rho'] * 1000 * Math.pow(pVelocity, 3) * dist) / radCorrP) * modellingSettings['noiseLevel']

                        // if (firstTime) {
                        //     console.log("M0", M0);
                        // }

                        let Mw = 2 / 3 * Math.log10(M0) - 6.0;

                        // firstTime = false;

                        viewingPlane.x.push(x);
                        viewingPlane.y.push(depth);
                        viewingPlane.z.push(z);
                        viewingPlane.val.push(Mw);
                    }
                }

                console.log("viewingPlane", viewingPlane);

                var viewingDistLut = new Lut('rainbow', 256);
                console.log("viewingDistLut", viewingDistLut);

                var vertices = new Float32Array(viewingPlane.x.length * 3);
                var size = new Float32Array(viewingPlane.x.length);

                var points = [];

                for (k = 0; k < viewingPlane.x.length; k++) {
                    size[k] = 2.0;
                    vertices[3 * k] = (DOWNSAMPLE * viewingPlane.x[k] - bk_x_min - 0.5 * (bk_x_max - bk_x_min));
                    vertices[3 * k + 1] = (DOWNSAMPLE * viewingPlane.y[k] - bk_y_min - 0.5 * (bk_y_max - bk_y_min));
                    vertices[3 * k + 2] = (DOWNSAMPLE * viewingPlane.z[k] - bk_z_min - 0.5 * (bk_z_max - bk_z_min));
                    points.push(new THREE.Vector3(vertices[3 * k], vertices[3 * k + 1], vertices[3 * k + 2]));
                }

                var colorMin = -2.5; //formationTop.formColor[0];
                var colorMax = -1; //formationTop.formColor[0];

                var colorAttr = new Float32Array(viewingPlane.val.length * 3);
                for (var i = 0; i < viewingPlane.val.length; i++) {
                    let coef = (viewingPlane.val[i] - colorMin) / (colorMax - colorMin);

                    if (coef < 0) {
                        coef = 0;
                    }

                    if (coef > 1) {
                        coef = 1;
                    }

                    var formColor;
                    if (isNaN(coef)) {
                        formColor = viewingDistLut.getColor(0);
                    } else {
                        formColor = viewingDistLut.getColor(coef);
                    }

                    colorAttr[3 * i] = (formColor.r);
                    colorAttr[3 * i + 1] = (formColor.g);
                    colorAttr[3 * i + 2] = (formColor.b);
                }

                let shaderMaterial = new THREE.ShaderMaterial({
                    transparent: false,
                    side: THREE.DoubleSide,
                    vertexShader: document.getElementById('fm-vertexshader').textContent,
                    fragmentShader: document.getElementById('viewingPlane-fragmentshader').textContent,
                });


                let geomDelaunator = new THREE.BufferGeometry().setFromPoints(points);
                geomDelaunator.dynamic = true;

                // triangulate x, z
                var indexDelaunay = Delaunator.from(
                    points.map(v => {
                        return [v.x, v.z];
                    })
                );

                var meshIndex = []; // delaunay index => three.js index
                for (let a = 0; a < indexDelaunay.triangles.length; a++) {
                    meshIndex.push(indexDelaunay.triangles[a]);
                }
                geomDelaunator.setIndex(meshIndex); // add three.js index to the existing geometry
                geomDelaunator.computeVertexNormals();
                geomDelaunator.computeBoundingBox();
                geomDelaunator.setAttribute('colorAttr', new THREE.BufferAttribute(colorAttr, 3));
                geomDelaunator.setAttribute('vSize', new THREE.BufferAttribute(size, 1));

                let viewingMesh = new THREE.Mesh(
                    geomDelaunator, // re-use the existing geometry
                    shaderMaterial
                );
                viewingMesh.frustumCulled = false;
                viewingMesh.name = "Viewing Plane";
                viewingMesh.visible = true;

                try {
                    var selectedObject = scene.getObjectByName("Viewing Plane");
                    scene.remove(selectedObject);
                } catch (e) {
                    console.log("No object removed!");
                }
                scene.add(viewingMesh);

            },
            hideViewingDistance: function() {
                try {
                    var selectedObject = scene.getObjectByName("Viewing Plane");
                    scene.remove(selectedObject);
                } catch (e) {
                    console.log("No object removed!");
                }
            },
            selectedDesign: '',
        };


        modellingFolder.add(modellingSettings, 'Vp', 9000, 25000).name('P-velocity (ft/s)');
        modellingFolder.add(modellingSettings, 'Zsou', 0, 15000).name('Depth (ft)');
        modellingFolder.add(modellingSettings, 'rho', 1, 5).name('Density');

        modellingFolder.add(modellingSettings, 'noiseLevelType', ['Weak', 'Medium', 'Strong', 'DAS']).name('Noise').
        onChange((value) => {

            if (value == 'Weak') {
                modellingSettings['noiseLevel'] = 3.0e-8 * 2 * receiverCorrect;
            }

            if (value == 'Medium') {
                modellingSettings['noiseLevel'] = 10.0e-8 * 2 * receiverCorrect;
            }

            if (value == 'Strong') {
                modellingSettings['noiseLevel'] = 30.0e-8 * 2 * receiverCorrect;
            }

            if (value == 'DAS') {
                modellingSettings['noiseLevel'] = 3.0e-8 * 2 * receiverCorrect * 8;
            }

        });


        modellingFolder.add(modellingSettings, 'selectedDesign', rec.map(e => e.design).unique()).name('Rec Design');
        modellingFolder.add(modellingSettings, 'plotViewingDistance').name('Plot Viewing Dist');

        modellingFolder.add(modellingSettings, 'hideViewingDistance').name('Hide Viewing Dist');


    }




    var settingsFolder = gui.addFolder('Tools, Settings');

    var exaggerateScene = () => {


        // window.open("https://www.w3schools.com");

        // let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
        // width=0,height=0,left=-1000,top=-1000`;

        // var url = window.location.href;    
        // if (url.indexOf('?') > -1){
        // url += '&hide_header=1'
        // }else{
        // url += '?hide_header=1'
        // }

        updateGridGeometry();

        // window.open(url, 'test', params);

        // Update Treatment Wells
        console.log("treatment wells", trtWell);
        for (j = 0; j < tmWellMesh.length; j++) {

            var well = trtWell[j];
            var curvePoints = [];
            var curvePointsArray = [];
            for (var i = 0; i < well.x.length; i++) {
                curvePointsArray.push(EXAGGERATION_X * (DOWNSAMPLE * well.x[i] - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    EXAGGERATION_Y * (DOWNSAMPLE * well.y[i] - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    EXAGGERATION_Z * (DOWNSAMPLE * well.z[i] - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));
                if (i > 0) {
                    curvePointsArray.push(
                        EXAGGERATION_X * (DOWNSAMPLE * well.x[i] - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                        EXAGGERATION_Y * (DOWNSAMPLE * well.y[i] - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                        EXAGGERATION_Z * (DOWNSAMPLE * well.z[i] - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));
                }
            }
            tmWellMesh[j].geometry.setPositions(curvePointsArray);

            if (EXAGGERATION_X > 1 || EXAGGERATION_Y > 1 || EXAGGERATION_Z > 1) {
                tmWellMeshPick[j].visible = false;
            } else {
                tmWellMeshPick[j].visible = true;
            }
        }

        // Update monitor Wells
        for (j = 0; j < mtWellMesh.length; j++) {

            var well = mtrWell[j];
            var curvePoints = [];
            var curvePointsArray = [];
            for (var i = 0; i < well.x.length; i++) {

                curvePointsArray.push(EXAGGERATION_X * (DOWNSAMPLE * well.x[i] - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    EXAGGERATION_Y * (DOWNSAMPLE * well.y[i] - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    EXAGGERATION_Z * (DOWNSAMPLE * well.z[i] - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));
                if (i > 0) {
                    curvePointsArray.push(
                        EXAGGERATION_X * (DOWNSAMPLE * well.x[i] - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                        EXAGGERATION_Y * (DOWNSAMPLE * well.y[i] - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                        EXAGGERATION_Z * (DOWNSAMPLE * well.z[i] - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));
                }
            }

            mtWellMesh[j].geometry.setPositions(curvePointsArray);
            // mtWellMeshPick[j].geometry.setPositions(curvePointsArray);
            if (EXAGGERATION_X > 1 || EXAGGERATION_Y > 1 || EXAGGERATION_Z > 1) {
                mtWellMeshPick[j].visible = false;
            } else {
                mtWellMeshPick[j].visible = true;
            }

        }

        // Update additional wells;
        for (j = 0; j < addWellMesh.length; j++) {
            var well = addWell[j];
            var curvePoints = [];
            var curvePointsArray = [];
            for (var i = 0; i < well.x.length; i++) {

                curvePointsArray.push(
                    EXAGGERATION_X * (DOWNSAMPLE * well.x[i] - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    EXAGGERATION_Y * (DOWNSAMPLE * well.y[i] - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    EXAGGERATION_Z * (DOWNSAMPLE * well.z[i] - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));

                if (i > 0) {
                    curvePointsArray.push(
                        EXAGGERATION_X * (DOWNSAMPLE * well.x[i] - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                        EXAGGERATION_Y * (DOWNSAMPLE * well.y[i] - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                        EXAGGERATION_Z * (DOWNSAMPLE * well.z[i] - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));
                }
            }

            addWellMesh[j].geometry.setPositions(curvePointsArray);
            //addWellMeshPick[j].geometry.setPositions(curvePointsArray);

            if (EXAGGERATION_X > 1 || EXAGGERATION_Y > 1 || EXAGGERATION_Z > 1) {
                addWellMeshPick[j].visible = false;
            } else {
                addWellMeshPick[j].visible = true;
            }
        }

        // Well Labels Update

        var textConfig = {
            font: font,
            size: 4,
            height: 2
        };

        for (var j = 0; j < wellLabel.length; j++) {

            var textGeo = new THREE.TextGeometry(wellLabel[j].name, textConfig);
            textGeo.computeBoundingBox();
            textGeo.computeVertexNormals();
            var wellOffsetX = DOWNSAMPLE * wellLabel[j].x[wellLabel[j].x.length - 1] - DOWNSAMPLE * wellLabel[j].x[wellLabel[j].x.length - 2];
            var wellOffsetY = DOWNSAMPLE * wellLabel[j].y[wellLabel[j].y.length - 1] - DOWNSAMPLE * wellLabel[j].y[wellLabel[j].y.length - 2];
            var wellOffsetZ = DOWNSAMPLE * wellLabel[j].z[wellLabel[j].z.length - 1] - DOWNSAMPLE * wellLabel[j].z[wellLabel[j].z.length - 2];

            var centerOffsetX = -0.5 * (textGeo.boundingBox.max.x - textGeo.boundingBox.min.x);
            var centerOffsetY = -0.5 * (textGeo.boundingBox.max.y - textGeo.boundingBox.min.y);
            var centerOffsetZ = -0.5 * (textGeo.boundingBox.max.z - textGeo.boundingBox.min.z);

            //rotation = wellLabelMesh[j].rotation;

            wellLabelGeom[j] = new THREE.BufferGeometry().fromGeometry(textGeo);
            wellLabelGeom[j].center();
            wellLabelMesh[j] = new THREE.Mesh(wellLabelGeom[j], wellLabelMaterial);

            if (wellLabel[j].y.length > 10) {
                wellLabelMesh[j].position.x = DOWNSAMPLE * centerOffsetX + EXAGGERATION_X * (DOWNSAMPLE * wellLabel[j].x[wellLabel[j].x.length - 1] - bk_x_min - 0.5 * (bk_x_max - bk_x_min)) + wellOffsetX;
                wellLabelMesh[j].position.y = DOWNSAMPLE * centerOffsetY + EXAGGERATION_Y * (DOWNSAMPLE * wellLabel[j].y[wellLabel[j].y.length - 1] - bk_y_min - 0.5 * (bk_y_max - bk_y_min)) + wellOffsetY;
                wellLabelMesh[j].position.z = DOWNSAMPLE * centerOffsetZ + EXAGGERATION_Z * (DOWNSAMPLE * wellLabel[j].z[wellLabel[j].z.length - 1] - bk_z_min - 0.5 * (bk_z_max - bk_z_min)) + wellOffsetZ;
            } else {
                wellLabelMesh[j].position.x = DOWNSAMPLE * centerOffsetX + EXAGGERATION_X * (DOWNSAMPLE * wellLabel[j].x[wellLabel[j].x.length - 1] - bk_x_min - 0.5 * (bk_x_max - bk_x_min)); // + wellOffsetX;
                wellLabelMesh[j].position.y = DOWNSAMPLE * centerOffsetY + EXAGGERATION_Y * (DOWNSAMPLE * wellLabel[j].y[wellLabel[j].y.length - 1] - bk_y_min - 0.5 * (bk_y_max - bk_y_min)); // + wellOffsetY; 
                wellLabelMesh[j].position.z = DOWNSAMPLE * centerOffsetZ + EXAGGERATION_Z * (DOWNSAMPLE * wellLabel[j].z[wellLabel[j].z.length - 1] - bk_z_min - 0.5 * (bk_z_max - bk_z_min)); // + wellOffsetZ;
            }

            wellLabelMesh[j].name = "Well Label" + wellLabel[j].name;

            var selectedObject = scene.getObjectByName("Well Label" + wellLabel[j].name);
            scene.remove(selectedObject);

            wellLabelMesh[j].visible = wellLabelController[wellLabel[j].name].getValue()
            scene.add(wellLabelMesh[j]);

        }

        // Update for perfs;
        if (perfCount > 0) {

            var offsets = [],
                iColors = [],
                iScale = [];

            var perfMatArraySize = 4 * perfCount;
            var perfMatrixArray = [
                new Float32Array(perfMatArraySize),
                new Float32Array(perfMatArraySize),
                new Float32Array(perfMatArraySize),
                new Float32Array(perfMatArraySize),
            ];


            for (var i = 0; i < perfCount; i++) {
                offsets.push(EXAGGERATION_X * (DOWNSAMPLE * perf[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    EXAGGERATION_Y * (DOWNSAMPLE * perf[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    EXAGGERATION_Z * (DOWNSAMPLE * perf[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));
                eventTempColor = new THREE.Color(perf[i].color).convertSRGBToLinear();
                iColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);
                iScale.push(1, 1, 1);

                var perfPosition = new THREE.Vector3(
                    EXAGGERATION_X * (DOWNSAMPLE * perf[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    EXAGGERATION_Y * (DOWNSAMPLE * perf[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    EXAGGERATION_Z * (DOWNSAMPLE * perf[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                );

                var perfRotation = new THREE.Euler();
                // perfRotation.x = 0.5 * Math.PI;
                perfRotation.x = 0.33 * Math.PI;

                var perfScale = new THREE.Vector3(1, 1, 1);
                var perfQuaternion = new THREE.Quaternion();

                perfQuaternion.setFromEuler(perfRotation, false);
                var perfMatrix = new THREE.Matrix4();
                perfMatrix.compose(perfPosition, perfQuaternion, perfScale);


                for (var r = 0; r < 4; r++) {
                    for (var c = 0; c < 4; c++) {
                        perfMatrixArray[r][4 * i + c] = perfMatrix.elements[r * 4 + c];
                    }
                }

            }

            perfGeomDrawn.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));

            for (let i = 0; i < perfMatrixArray.length; i++) {
                perfGeomDrawn.setAttribute(
                    `aInstanceMatrix${i}`,
                    new THREE.InstancedBufferAttribute(perfMatrixArray[i], 4)
                );
            }


        }

        if (rec.length > 0) {

            var recMatArraySize = rec.length * 4;
            var recMatrixArray = [
                new Float32Array(recMatArraySize),
                new Float32Array(recMatArraySize),
                new Float32Array(recMatArraySize),
                new Float32Array(recMatArraySize),
            ];

            var roffsets = [],
                riColors = [],
                riScale = [];
            var recScale = new THREE.Vector3(1, 1, 1);
            var recQuaternion = new THREE.Quaternion();

            for (let recInd = 0; recInd < rec.length; recInd++) {

                roffsets.push(
                    EXAGGERATION_X * (DOWNSAMPLE * rec[recInd].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    EXAGGERATION_Y * (DOWNSAMPLE * rec[recInd].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    EXAGGERATION_Z * (DOWNSAMPLE * rec[recInd].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                );
                riScale.push(1, 1, 1);
                var recPosition = new THREE.Vector3(
                    EXAGGERATION_X * (DOWNSAMPLE * rec[recInd].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    EXAGGERATION_Y * (DOWNSAMPLE * rec[recInd].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    EXAGGERATION_Z * (DOWNSAMPLE * rec[recInd].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                );

                if (recInd < (rec.length - 1) && rec[recInd].name == rec[recInd + 1].name) {

                    var recPositionNew = new THREE.Vector3(
                        EXAGGERATION_X * (DOWNSAMPLE * rec[recInd + 1].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                        EXAGGERATION_Y * (DOWNSAMPLE * rec[recInd + 1].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                        EXAGGERATION_Z * (DOWNSAMPLE * rec[recInd + 1].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                    );

                    var dir1 = new THREE.Vector3();
                    dir1.subVectors(recPositionNew, recPosition);
                    var dir2 = new THREE.Vector3();
                    dir2.subVectors(recPosition, recPositionNew);
                    // dir2.dot(dir1);

                    var recQuaternion = new THREE.Quaternion().setFromRotationMatrix(new THREE.Matrix4().lookAt(dir2, new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 1, 0)));

                }


                var recMatrix = new THREE.Matrix4();
                recMatrix.compose(recPosition, recQuaternion, recScale);


                for (var r = 0; r < 4; r++) {
                    for (var c = 0; c < 4; c++) {
                        recMatrixArray[r][recInd * 4 + c] = recMatrix.elements[r * 4 + c];
                    }
                }

            }

            for (var i = 0; i < recMatrixArray.length; i++) {
                recGeomDrawn.setAttribute(
                    `aInstanceMatrix${i}`,
                    new THREE.InstancedBufferAttribute(recMatrixArray[i], 4)
                );
            }

            recGeomPicking.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(roffsets), 3));


        }

        /////////////////////////////////
        ///// WELL LOG	 	    START ///
        /////////////////////////////////

        if (wellLog.length > 0) {

            for (var counter = 0; counter < wellLog.length; counter++) {

                var wellLogCount = wellLog[counter].length;

                var offsets = [],
                    iColors = [],
                    iScale = [],
                    iSize = [];

                var minLogValue = wellLog[counter][0].value
                var maxLogValue = wellLog[counter][0].value

                for (var i = 0; i < wellLogCount; i++) {
                    minLogValue = Math.min(minLogValue, wellLog[counter][i].value);
                    maxLogValue = Math.max(maxLogValue, wellLog[counter][i].value);
                }


                var wellLogMatArraySize = 4 * wellLogCount;
                var wellLogMatrixArray = [
                    new Float32Array(wellLogMatArraySize),
                    new Float32Array(wellLogMatArraySize),
                    new Float32Array(wellLogMatArraySize),
                    new Float32Array(wellLogMatArraySize),
                ];

                for (var i = 0; i < wellLogCount; i++) {
                    offsets.push(
                        EXAGGERATION_X * (DOWNSAMPLE * wellLog[counter][i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                        EXAGGERATION_Y * (DOWNSAMPLE * wellLog[counter][i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                        EXAGGERATION_Z * (DOWNSAMPLE * wellLog[counter][i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                    );
                    var scale = ((wellLog[counter][i].value - minLogValue) / (maxLogValue - minLogValue));
                    iSize.push(1, 1, 1);
                    iScale.push(0, 0, 0);

                    var wellLogPosition = new THREE.Vector3(
                        EXAGGERATION_X * (DOWNSAMPLE * wellLog[counter][i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                        EXAGGERATION_Y * (DOWNSAMPLE * wellLog[counter][i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                        EXAGGERATION_Z * (DOWNSAMPLE * wellLog[counter][i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                    );

                    var wellLogRotation = new THREE.Euler();
                    wellLogRotation.x = -0.5 * Math.PI;

                    var wellLogScale = new THREE.Vector3(1, scale, 1);
                    var wellLogQuaternion = new THREE.Quaternion();

                    var e1 = new THREE.Vector3(1, 0, 0);
                    var e2 = new THREE.Vector3(0, 1, 0);
                    var e3 = new THREE.Vector3(0, 0, 1);

                    var q0 = new THREE.Quaternion();
                    q0.setFromAxisAngle(e1, 90 * Math.PI / 180);

                    // Define rotation into strike direction
                    var q1 = new THREE.Quaternion();
                    q1.setFromAxisAngle(e3, (wellLog[counter][i].azimuth) * Math.PI / 180);

                    //rotate original coordinate into strike direction to get new coordinate system
                    var e1a = e1.clone();
                    e1a.applyQuaternion(q1);
                    var e2a = e2.clone();
                    e2a.applyQuaternion(q1);
                    // (*strike direction*)
                    var e3a = e3.clone();
                    e3a.applyQuaternion(q1);

                    // (*Define rotation around strike direction to accomodate dip*)
                    var q2 = new THREE.Quaternion();
                    e2a.normalize();
                    q2.setFromAxisAngle(e2a, -wellLog[counter][i].dip * Math.PI / 180);

                    wellLogQuaternion.multiply(q0);
                    wellLogQuaternion.multiply(q2);
                    wellLogQuaternion.multiply(q1);

                    var wellLogMatrix = new THREE.Matrix4();
                    wellLogMatrix.compose(wellLogPosition, wellLogQuaternion, wellLogScale);


                    for (r = 0; r < 4; r++) {
                        for (c = 0; c < 4; c++) {
                            wellLogMatrixArray[r][4 * i + c] = wellLogMatrix.elements[r * 4 + c];
                        }
                    }
                }

                wellLogGeomDrawn[counter].setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));

                for (let i = 0; i < wellLogMatrixArray.length; i++) {
                    wellLogGeomDrawn[counter].setAttribute(
                        `aInstanceMatrix${i}`,
                        new THREE.InstancedBufferAttribute(wellLogMatrixArray[i], 4)
                    );
                }

                wellLogGeomPicking[counter].setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));


            }


        }

        /////////////////////////////////
        ///// FRACTURE 			START ///
        /////////////////////////////////

        if (fracture.length > 0) {

            fractureCount = fracture.length;

            offsets = [], iColors = [], iScale = [];

            ////////// TRYING ROTATION //////////////

            fracMatArraySize = fractureCount * 4
            fracMatrixArray = [
                new Float32Array(fracMatArraySize),
                new Float32Array(fracMatArraySize),
                new Float32Array(fracMatArraySize),
                new Float32Array(fracMatArraySize),
            ];

            /////////////////////////////////////////

            for (var i = 0; i < fractureCount; i++) {


                offsets.push(
                    EXAGGERATION_X * (DOWNSAMPLE * fracture[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    EXAGGERATION_Y * (DOWNSAMPLE * fracture[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    EXAGGERATION_Z * (DOWNSAMPLE * fracture[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                );

                eventTempColor = new THREE.Color(fracture[i].color).convertSRGBToLinear();
                iColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);
                iScale.push(1, 1, 1);

                fractureMatrix = new THREE.Matrix4();

                fracturePosition = new THREE.Vector3(
                    EXAGGERATION_X * (DOWNSAMPLE * fracture[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    EXAGGERATION_Y * (DOWNSAMPLE * fracture[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    EXAGGERATION_Z * (DOWNSAMPLE * fracture[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                );

                fractureQuaternion = new THREE.Quaternion();

                var e1 = new THREE.Vector3(1, 0, 0);
                var e2 = new THREE.Vector3(0, 1, 0);
                var e3 = new THREE.Vector3(0, 0, 1);

                // Dip taken care of
                var q0 = new THREE.Quaternion();
                q0.setFromAxisAngle(e3, fracture[i].dip * Math.PI / 180);
                fractureQuaternion.multiply(q0);

                // Azimuth figuring
                var q1 = new THREE.Quaternion();
                q1.setFromAxisAngle(e1, fracture[i].azimuth * Math.PI / 180);
                fractureQuaternion.multiply(q1);

                fractureScale = new THREE.Vector3(1, 1, 1);

                fractureMatrix.compose(fracturePosition, fractureQuaternion, fractureScale);

                for (r = 0; r < 4; r++) {
                    for (c = 0; c < 4; c++) {
                        fracMatrixArray[r][i * 4 + c] = fractureMatrix.elements[r * 4 + c];
                    }
                }

            }

            fractureGeomDrawn.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
            for (let i = 0; i < fracMatrixArray.length; i++) {
                fractureGeomDrawn.setAttribute(
                    `aInstanceMatrix${i}`,
                    new THREE.InstancedBufferAttribute(fracMatrixArray[i], 4)
                );
            }

            for (let i = 0; i < fracMatrixArray.length; i++) {
                fractureGeomPicking.setAttribute(
                    `aInstanceMatrix${i}`,
                    new THREE.InstancedBufferAttribute(fracMatrixArray[i], 4)
                );
            }

        }

        /////////////////////////////////
        //////   REVEAL DATA START //////
        /////////////////////////////////

        if (revealData.length > 0) {


            let revealDataCount = revealData.length;

            offsets = [], iColors = [], iScale = [];

            for (var i = 0; i < revealDataCount; i++) {

                offsets.push(
                    EXAGGERATION_X * (DOWNSAMPLE * revealData[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    EXAGGERATION_Y * (DOWNSAMPLE * revealData[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    EXAGGERATION_Z * (DOWNSAMPLE * revealData[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                );

            }

            revealDataGeomDrawn.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
            revealDataGeomPicking.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));


            ////////////////////////////////
            /////// REVEAL DATA LINE ///////
            ////////////////////////////////

            var revealDataUnique = [];
            for (var i = 0; i < revealData.length; i++) {
                revealDataUnique.push(revealData[i].group);
            }
            revealDataUnique = revealDataUnique.unique();

            for (j = 0; j < revealDataUnique.length; j++) {

                curvePoints = [];
                for (var i = 0; i < revealData.length; i++) {
                    if (revealData[i].group == revealDataUnique[j]) {
                        curvePoints.push(
                            new THREE.Vector3(
                                EXAGGERATION_X * (DOWNSAMPLE * revealData[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                                EXAGGERATION_Y * (DOWNSAMPLE * revealData[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                                EXAGGERATION_Z * (DOWNSAMPLE * revealData[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                            )
                        );
                    }
                }

                var curve = new THREE.CatmullRomCurve3(curvePoints);
                var points = curve.getPoints(1000);
                var geometry = new THREE.BufferGeometry().setFromPoints(points);
                var material = new THREE.ShaderMaterial({
                    uniforms: {
                        color: { value: new THREE.Color(0x00ee00).convertSRGBToLinear() },
                    },
                    transparent: false,
                    vertexShader: document.getElementById('well-vertexshader').textContent,
                    fragmentShader: document.getElementById('well-fragmentshader').textContent,
                });

                // Create the final object to add to the scene
                let tempVar = new THREE.Line(geometry, material);
                tempVar.name = "Reveal Data - " + revealDataUnique[j];

                var selectedObject = scene.getObjectByName(tempVar.name);
                scene.remove(selectedObject);
                scene.add(tempVar);
                console.log("Plotted Reveal Data Lines");

            }


        }

        /////////////////////////////////
        ///// EVENT		 	    START ///
        /////////////////////////////////

        if (events.length > 0) {

            eventCount = events.length;
            offsets = [];

            for (var i = 0; i < eventCount; i++) {
                offsets.push(
                    EXAGGERATION_X * (DOWNSAMPLE * events[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    EXAGGERATION_Y * (DOWNSAMPLE * events[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    EXAGGERATION_Z * (DOWNSAMPLE * events[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                );
            }

            eventGeomDrawn.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
            eventGeomPicking.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));

            addUpdateEastErrorBars();
            addUpdateDepthErrorBars();
            addUpdateNorthingErrorBars();
            addUpdateAziErrorBars();
        }

        /////////////////////////////////
        ///// EVENT		 	     DONE ///
        /////////////////////////////////

        /////////////////////////////////
        ///////// GYRATION	START ///////
        /////////////////////////////////

        if (gyration.length > 0) {

            gyrationCount = gyration.length;
            gyrationMatArraySize = 4 * gyrationCount;
            gyrationMatrixArray = [
                new Float32Array(gyrationMatArraySize),
                new Float32Array(gyrationMatArraySize),
                new Float32Array(gyrationMatArraySize),
                new Float32Array(gyrationMatArraySize),
            ];

            for (var i = 0; i < gyrationCount; i++) {

                gyrationMatrix = new THREE.Matrix4();

                gyrationPosition = new THREE.Vector3(
                    EXAGGERATION_X * (DOWNSAMPLE * gyration[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    EXAGGERATION_Y * (DOWNSAMPLE * gyration[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    EXAGGERATION_Z * (DOWNSAMPLE * gyration[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                );

                gyrationScale = new THREE.Vector3(DOWNSAMPLE * gyration[i].xl, DOWNSAMPLE * gyration[i].zl, DOWNSAMPLE * gyration[i].yl);
                gyrationQuaternion = new THREE.Quaternion();

                // 1 = north, 2 = down, 3 = east;
                a1 = new THREE.Vector3(-1, 0, 0);
                a2 = new THREE.Vector3(0, -1, 0);
                a3 = new THREE.Vector3(0, 0, -1);

                e1 = new THREE.Vector3(gyration[i].x1, -gyration[i].x2, gyration[i].x3);
                e2 = new THREE.Vector3(gyration[i].y1, -gyration[i].y2, gyration[i].y3);
                e3 = new THREE.Vector3(gyration[i].z1, -gyration[i].z2, gyration[i].z3);

                gyrationRotation = new THREE.Euler(e1.angleTo(a1), e2.angleTo(a2), e3.angleTo(a3), 'XYZ');
                gyrationQuaternion = new THREE.Quaternion();
                gyrationQuaternion.setFromEuler(gyrationRotation);
                gyrationMatrix.compose(gyrationPosition, gyrationQuaternion, gyrationScale);

                for (r = 0; r < 4; r++) {
                    for (c = 0; c < 4; c++) {
                        gyrationMatrixArray[r][4 * i + c] = gyrationMatrix.elements[r * 4 + c];
                    }
                }

            }

            for (let i = 0; i < gyrationMatrixArray.length; i++) {
                gyrationGeomDrawn.setAttribute(
                    `aInstanceMatrix${i}`,
                    new THREE.InstancedBufferAttribute(gyrationMatrixArray[i], 4)
                );
            }

        }

        /////////////////////////////////
        // MODIFIED MOMENT TENSOR 	/////
        /////////////////////////////////

        if (momentTensor.length > 0) {

            momentTensorCount = momentTensor.length;


            e1 = new THREE.Vector3(1, 0, 0);
            e2 = new THREE.Vector3(0, 1, 0);
            e3 = new THREE.Vector3(0, 0, 1);

            // Get the colors & offsets in an array first;

            offsets = [], iScale = [];
            iFilter = [];

            for (var i = 0; i < momentTensorCount; i++) {
                offsets.push(
                    EXAGGERATION_X * (DOWNSAMPLE * momentTensor[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    EXAGGERATION_Y * (DOWNSAMPLE * momentTensor[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    EXAGGERATION_Z * (DOWNSAMPLE * momentTensor[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                );
                iScale.push(0, 0, 0);
                iFilter.push(1, 1, 1);
            }

            for (count = 0; count < 4; count++) {

                momentTensorMatArraySize = 4 * momentTensorCount;
                momentTensorMatrixArray = [
                    new Float32Array(momentTensorMatArraySize),
                    new Float32Array(momentTensorMatArraySize),
                    new Float32Array(momentTensorMatArraySize),
                    new Float32Array(momentTensorMatArraySize),
                ];

                for (var i = 0; i < momentTensorCount; i++) {

                    momentTensorMatrix = new THREE.Matrix4();
                    momentTensorPosition = new THREE.Vector3(
                        EXAGGERATION_X * (DOWNSAMPLE * momentTensor[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                        EXAGGERATION_Y * (DOWNSAMPLE * momentTensor[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                        EXAGGERATION_Z * (DOWNSAMPLE * momentTensor[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                    );

                    momentTensorScale = new THREE.Vector3(1, 1, 1);
                    momentTensorQuaternion = new THREE.Quaternion();

                    var q0 = new THREE.Quaternion();
                    q0.setFromAxisAngle(e1, -90 * Math.PI / 180);
                    momentTensorQuaternion.multiply(q0);

                    // Define rotation into strike direction
                    var q1 = new THREE.Quaternion();
                    q1.setFromAxisAngle(e3, (-momentTensor[i].strike) * Math.PI / 180);

                    //rotate original coordinate into strike direction to get new coordinate system
                    var e1a = e1.clone();
                    e1a.applyQuaternion(q1);
                    var e2a = e2.clone();
                    e2a.applyQuaternion(q1);
                    var e3a = e3.clone();
                    e3a.applyQuaternion(q1);
                    var q2 = new THREE.Quaternion();
                    e2a.normalize();
                    q2.setFromAxisAngle(e2a, -momentTensor[i].dip * Math.PI / 180);

                    var e1b = e1a.clone();
                    e1b.applyQuaternion(q2);
                    var e2b = e2a.clone();
                    e2b.applyQuaternion(q2);
                    var e3b = e3a.clone();
                    e3b.applyQuaternion(q2);

                    var q3 = new THREE.Quaternion();
                    var e1bxe2b_plane = new THREE.Vector3().crossVectors(e1b, e2b);
                    e1bxe2b_plane.normalize();

                    q3.setFromAxisAngle(e1bxe2b_plane, momentTensor[i].slip * Math.PI / 180);

                    var e1c = e1b.clone();
                    e1c.applyQuaternion(q3);
                    var e2c = e2b.clone();
                    e2c.applyQuaternion(q3);
                    var e3c = e3b.clone();
                    e3c.applyQuaternion(q3);

                    momentTensorQuaternion.multiply(q3);
                    momentTensorQuaternion.multiply(q2);
                    momentTensorQuaternion.multiply(q1);

                    momentTensorMatrix.compose(momentTensorPosition, momentTensorQuaternion, momentTensorScale);

                    for (r = 0; r < 4; r++) {
                        for (c = 0; c < 4; c++) {
                            momentTensorMatrixArray[r][4 * i + c] = momentTensorMatrix.elements[r * 4 + c];
                        }
                    }
                }

                if (count == 0) {
                    boxGeometry = new THREE.SphereBufferGeometry(1, 8, 8, 0, Math.PI / 2);
                }
                if (count == 1) {
                    boxGeometry = new THREE.SphereBufferGeometry(1, 8, 8, -Math.PI / 2, Math.PI / 2);
                }
                if (count == 2) {
                    boxGeometry = new THREE.SphereBufferGeometry(1, 8, 8, Math.PI, Math.PI / 2);
                }
                if (count == 3) {
                    boxGeometry = new THREE.SphereBufferGeometry(1, 8, 8, Math.PI / 2, Math.PI / 2);
                }

                momentTensorGeomDrawn[count].setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
                for (let i = 0; i < momentTensorMatrixArray.length; i++) {
                    momentTensorGeomDrawn[count].setAttribute(
                        `aInstanceMatrix${i}`,
                        new THREE.InstancedBufferAttribute(momentTensorMatrixArray[i], 4)
                    );
                }

            }

            offsets = [];
            for (var i = 0; i < momentTensorCount; i++) {
                offsets.push(
                    EXAGGERATION_X * (DOWNSAMPLE * momentTensor[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    EXAGGERATION_Y * (DOWNSAMPLE * momentTensor[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    EXAGGERATION_Z * (DOWNSAMPLE * momentTensor[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                );
            }

            momentTensorGeomPicking.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));

        }

        /////////////////////////////////
        // MODIFIED MOMENT TENSOR END ///
        /////////////////////////////////

        /////////////////////////////////
        // MODIFIED SOURCE TENSOR 	/////
        /////////////////////////////////

        if (sourceTensor.length > 0) {

            sourceTensorCount = sourceTensor.length;


            e1 = new THREE.Vector3(1, 0, 0);
            e2 = new THREE.Vector3(0, 1, 0);
            e3 = new THREE.Vector3(0, 0, 1);

            // Get the colors & offsets in an array first;

            offsets = [], iScale = [];
            iFilter = [];

            for (var i = 0; i < sourceTensorCount; i++) {
                offsets.push(
                    EXAGGERATION_X * (DOWNSAMPLE * sourceTensor[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    EXAGGERATION_Y * (DOWNSAMPLE * sourceTensor[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    EXAGGERATION_Z * (DOWNSAMPLE * sourceTensor[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                );
            }

            for (count = 0; count < 4; count++) {

                sourceTensorMatArraySize = 4 * sourceTensorCount;
                sourceTensorMatrixArray = [
                    new Float32Array(sourceTensorMatArraySize),
                    new Float32Array(sourceTensorMatArraySize),
                    new Float32Array(sourceTensorMatArraySize),
                    new Float32Array(sourceTensorMatArraySize),
                ];

                for (var i = 0; i < sourceTensorCount; i++) {

                    sourceTensorMatrix = new THREE.Matrix4();
                    sourceTensorPosition = new THREE.Vector3(
                        EXAGGERATION_X * (DOWNSAMPLE * sourceTensor[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                        EXAGGERATION_Y * (DOWNSAMPLE * sourceTensor[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                        EXAGGERATION_Z * (DOWNSAMPLE * sourceTensor[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                    );

                    if (sourceTensorDisplayAttributes['display'] == "Beach Balls") {
                        sourceTensorScale = new THREE.Vector3(1, 1, 1);
                    } else {
                        sourceTensorScale = new THREE.Vector3(1, 3, 1);
                    }

                    sourceTensorQuaternion = new THREE.Quaternion();

                    var q0 = new THREE.Quaternion();
                    q0.setFromAxisAngle(e1, -90 * Math.PI / 180);
                    sourceTensorQuaternion.multiply(q0);

                    // Define rotation into strike direction
                    q1 = new THREE.Quaternion();
                    q1.setFromAxisAngle(e3, (-sourceTensor[i].strike) * Math.PI / 180);

                    //rotate original coordinate into strike direction to get new coordinate system
                    e1a = e1.clone();
                    e1a.applyQuaternion(q1);
                    e2a = e2.clone();
                    e2a.applyQuaternion(q1);
                    e3a = e3.clone();
                    e3a.applyQuaternion(q1);
                    q2 = new THREE.Quaternion();
                    e2a.normalize();
                    q2.setFromAxisAngle(e1a, -sourceTensor[i].dip * Math.PI / 180);

                    var e1b = e1a.clone();
                    e1b.applyQuaternion(q2);
                    var e2b = e2a.clone();
                    e2b.applyQuaternion(q2);
                    var e3b = e3a.clone();
                    e3b.applyQuaternion(q2);

                    var q3 = new THREE.Quaternion();
                    var e1bxe2b_plane = new THREE.Vector3().crossVectors(e1b, e2b);
                    e1bxe2b_plane.normalize();

                    q3.setFromAxisAngle(e1bxe2b_plane, sourceTensor[i].slip * Math.PI / 180);

                    var e1c = e1b.clone();
                    e1c.applyQuaternion(q3);
                    var e2c = e2b.clone();
                    e2c.applyQuaternion(q3);
                    var e3c = e3b.clone();
                    e3c.applyQuaternion(q3);

                    sourceTensorQuaternion.multiply(q3);
                    sourceTensorQuaternion.multiply(q2);
                    sourceTensorQuaternion.multiply(q1);

                    sourceTensorMatrix.compose(sourceTensorPosition, sourceTensorQuaternion, sourceTensorScale);

                    for (r = 0; r < 4; r++) {
                        for (c = 0; c < 4; c++) {
                            sourceTensorMatrixArray[r][4 * i + c] = sourceTensorMatrix.elements[r * 4 + c];
                        }
                    }
                }

                sourceTensorGeomDrawn[count].setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
                for (let i = 0; i < sourceTensorMatrixArray.length; i++) {
                    sourceTensorGeomDrawn[count].setAttribute(
                        `aInstanceMatrix${i}`,
                        new THREE.InstancedBufferAttribute(sourceTensorMatrixArray[i], 4)
                    );
                }

            }

            sourceTensorGeomPicking.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));

        }

        /////////////////////////////////
        // MODIFIED SOURCE TENSOR END ///
        /////////////////////////////////

        /////////////////////////////////
        ///// FORMATION TOPS START //////
        /////////////////////////////////

        //assign different positions to the points
        for (var i = 0; i < formationTops.length; i++) {

            var formTempColor = new THREE.Color(0xffffff).convertSRGBToLinear();

            var formGeometry = new THREE.PlaneBufferGeometry(0.5 * (x_max - x_min), 0.5 * (z_max - z_min), 100, 100);

            var formMaterial = new THREE.MeshBasicMaterial({
                color: getColor(i),
                side: THREE.DoubleSide,
                transparent: true,
                opacity: 0.2,
            });

            var formPlane = new THREE.Mesh(formGeometry, formMaterial);
            formPlane.position.set(x_min + 0.5 * (x_max - x_min), EXAGGERATION_Y * (DOWNSAMPLE * formationTops[i].depth - bk_y_min - 0.5 * (bk_y_max - bk_y_min)), z_min + 0.5 * (z_max - z_min));
            formPlane.rotateX(Math.PI / 2);

            formPlane.name = formationTops[i].name;
            var selectedObject = scene.getObjectByName(formPlane.name);
            formPlane.visible = selectedObject.visible;
            scene.remove(selectedObject);
            scene.add(formPlane);
            formationTopGeomDrawn.push(formPlane);


            // // Configuring Picking Scene;
            var formGeometryPick = formGeometry.clone();
            formGeometryPick.color = formTempColor.setHex(pickingID);
            var formMaterialPick = new THREE.MeshBasicMaterial({
                color: formTempColor.setHex(pickingID),
                side: THREE.DoubleSide,
            });

            // formGeometryPick.color = formTempColor.setHex( pickingID ); 
            // formMaterialPick.color = formTempColor.setHex( pickingID );

            var formPlanePick = new THREE.Mesh(formGeometryPick, formMaterialPick);
            formPlanePick.visible = false;
            formPlanePick.position.set(0, Number(formationTops[i].depth) - (Number(bk_y_min) + 0.5 * (Number(bk_y_max) - Number(bk_y_min))), 0);
            formPlanePick.rotateX(Math.PI / 2);
            formPlanePick.name = formationTops[i].name;

            formationTopGeomPicking[i] = formPlanePick;

            var selectedObject = pickingScene.getObjectByName(formationTops[i].name);
            pickingScene.remove(selectedObject);

            pickingScene.add(formPlanePick);


            pickingData[pickingID] = {
                name: "<b>Formation Top</b> - " + formationTops[i].name.toString() + "<br>Depth: " + formationTops[i].depth.toString() + " ft"
            };

            pickingID = pickingID + 1;

        }

        /////////////////////////////////
        ///// FORMATION TOPS END   //////
        /////////////////////////////////

        /////////////////////////////////
        // FORMATION TOPS POINTS START //
        /////////////////////////////////

        //assign different positions to the points
        for (j = 0; j < formationTopsPoints.length; j++) {


            var formationTop = formationTopsPoints[j];
            var vertices = new Float32Array(formationTop.x.length * 3);
            for (k = 0; k < formationTop.x.length; k++) {
                vertices[3 * k] = EXAGGERATION_X * (DOWNSAMPLE * formationTop.x[k] - bk_x_min - 0.5 * (bk_x_max - bk_x_min));
                vertices[3 * k + 1] = EXAGGERATION_Y * (DOWNSAMPLE * formationTop.y[k] - bk_y_min - 0.5 * (bk_y_max - bk_y_min));
                vertices[3 * k + 2] = EXAGGERATION_Z * (DOWNSAMPLE * formationTop.z[k] - bk_z_min - 0.5 * (bk_z_max - bk_z_min));
            }

            formationTopPointGeomDrawn[j].geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
            // formationTopPointGeomPicking[j].geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));



            // for ( l = 0; l < 100; l ++){
            // 	points.push(l, l, l);
            // }


        }

        /////////////////////////////////
        /// FORMATION TOPS POINTS END ///
        /////////////////////////////////

        //////////////////////////////////
        //////////// VOLUMES /////////////


        for (j = 0; j < volumeData.length; j++) {

            if (!volumeGeometryDrawn[j] || !volumeData[j])
                continue;

            var MAX_POINTS = volumeData[j].val.length * volumeData[j].val[0].length; // volumeData[j].length;
            // volumeGeometryDrawn[j] = new THREE.BufferGeometry();
            var volPositions = new Float32Array(MAX_POINTS * 3);

            var index = 0;
            // This is depth for loop
            for (var i = 0; i < volumeData[j].val.length; i++) {

                var z = DOWNSAMPLE * volumeData[j].z[i] - bk_y_min - 0.5 * (bk_y_max - bk_y_min);

                // This is X, Y for loop
                for (l = 0; l < volumeData[j].val[i].length; l += 1) {

                    var x = DOWNSAMPLE * volumeData[j].x[l] - bk_x_min - 0.5 * (bk_x_max - bk_x_min);
                    var y = DOWNSAMPLE * volumeData[j].y[l] - bk_z_min - 0.5 * (bk_z_max - bk_z_min);
                    volPositions[3 * index] = EXAGGERATION_X * x;
                    volPositions[3 * index + 1] = EXAGGERATION_Y * z;
                    volPositions[3 * index + 2] = EXAGGERATION_Z * y;
                    index = index + 1;
                }
            }

            volumeGeometryDrawn[j].setAttribute('position', new THREE.BufferAttribute(volPositions, 3));

            if (volumePointCloud[j]) {
                volumePointCloud[j].geometry.setAttribute('position', new THREE.BufferAttribute(volPositions, 3));
            }



        }

    }




    var settingsOptions = {

        measure_distance: function() {

            if (measurementMode) {

                measurementMode = false;
                // alert("Measurement mode off");
                document.getElementById("measurementText").innerHTML = "";
                document.getElementById("measurementText").style.marginLeft = "-50px";

            } else {
                measurementMode = true;
                alert("In Measurement mode");

                document.getElementById("measurementText").innerHTML = "<u>Measurement Stats</u><br>";
                document.getElementById("measurementText").style.marginLeft = "0px";
            }

            for (var i = 0; i < tmWellMeshPick.length; i++) {
                tmWellMeshPick[i].visible = measurementMode;
            }

            for (var i = 0; i < mtWellMeshPick.length; i++) {
                mtWellMeshPick[i].visible = measurementMode;
            }

            try {
                let measObject = scene.getObjectByName("MeasurementLine");
                scene.remove(measObject);
            } catch (e) {
                console.log(e);
            }

        },

        sceneColor: "#141414",
        show_hide_pumplogs: function() {
            PUMPLOGS_PRESENT = !PUMPLOGS_PRESENT;
            onWindowResize();
        },
        show_hide_strain: function() {
            STRAIN_PRESENT = !STRAIN_PRESENT;
            onWindowResize();
        },
        exaggerate_x: "1.00",
        exaggerate_y: "1.00",
        exaggerate_z: "1.00",

    };


    if (!MODELLING_MODE) {

        settingsFolder.add(settingsOptions, 'exaggerate_z', ["1.00", '1.25', '1.50', '1.75', '2.00', '2.50', '3.00', '5.00', '10.00'])
            .name("Exag Easting")
            .onChange((val) => {
                if (EXAGGERATION_Z == Number(val)) return;
                EXAGGERATION_Z = Number(val);
                exaggerateScene();
            });

        settingsFolder.add(settingsOptions, 'exaggerate_x', ["1.00", '1.25', '1.50', '1.75', '2.00', '2.50', '3.00', '5.00', '10.00'])
            .name("Exag Northing")
            .onChange((val) => {
                if (EXAGGERATION_X == Number(val)) return;

                EXAGGERATION_X = Number(val);
                exaggerateScene();
            });

        settingsFolder.add(settingsOptions, 'exaggerate_y', ["1.00", '1.25', '1.50', '1.75', '2.00', '2.50', '3.00', '5.00', '10.00'])
            .name("Exag Depth")
            .onChange((val) => {
                if (EXAGGERATION_Y == Number(val)) return;
                EXAGGERATION_Y = Number(val);
                exaggerateScene();
            });

    }

    settingsFolder.add(settingsOptions, 'measure_distance').name('Measurement Tool');

    settingsFolder.addColor(settingsOptions, 'sceneColor').name('Scene Color')
        .onChange(function(value) {

            scene.background = new THREE.Color(value).convertSRGBToLinear();

        });

    if (pumpLogs.length > 0) {
        settingsFolder.add(settingsOptions, 'show_hide_pumplogs').name('Show/Hide Pumplogs');
    }

    if (STRAIN_PRESENT) {
        settingsFolder.add(settingsOptions, 'show_hide_strain').name('Show/Hide Strain');
    }

    /////////////////////////////////
    ///// WELL MODELLING FOLDER   ////
    /////////////////////////////////

    if (admin && !MODELLING_MODE) {

        noModels = 0;
        wellModelObjects = [];
        var modelValues = {
            wellName: '',
            startingDepth: 0,
            noRec: 0,
            distanceBetweenReceivers: 0,
            plot: function() {},
            exportCSV: function() {},
            removeArray: function() {}
        };

        var mtrWellNames = [];

        for (l = 0; l < trtWell.length; l++) { mtrWellNames.push(trtWell[l].name); }
        for (l = 0; l < mtrWell.length; l++) { mtrWellNames.push(mtrWell[l].name); }
        for (l = 0; l < addWell.length; l++) { mtrWellNames.push(addWell[l].name); }

        var wellModelFolder = gui.addFolder('Well Modelling');

        var wellModelFolderFunctions = {
            add_receiver_array: function() {

                var doc = prompt("Receiver Array Name?", "Receiver Array #");

                if (doc == null) return;

                var index = noModels;

                var receiverArrayFolder = wellModelFolder.addFolder(doc);

                receiverArrayFolder.add(modelValues, 'wellName', mtrWellNames).name('Well Name').onChange((val) => {
                    wellModelObjects[index].wellName = val;
                });

                receiverArrayFolder.add(modelValues, 'startingDepth').min(0).step(1).name('Starting MD (ft)').onChange((val) => {
                    wellModelObjects[index].startingDepth = val;
                });

                receiverArrayFolder.add(modelValues, 'noRec').min(0).step(1).name('No Rec').onChange((val) => {
                    wellModelObjects[index].noRec = val;
                });

                receiverArrayFolder.add(modelValues, 'distanceBetweenReceivers').name('Spacing (ft)').onChange((val) => {
                    wellModelObjects[index].distanceBetweenReceivers = val;
                });

                receiverArrayFolder.add(modelValues, 'plot').name('Plot').onChange((val) => {
                    plotModelRec(index);
                });

                receiverArrayFolder.add(modelValues, 'exportCSV').name('Export CSV').onChange(() => {

                    var rows = [
                        ["MD", "Northing", "Easting", "TVDSS"],
                    ];

                    for (var ss = 0; ss < wellModelObjects[index].recModel.length; ss++) {
                        rows.push([wellModelObjects[index].recModel[ss].md, wellModelObjects[index].recModel[ss].x, wellModelObjects[index].recModel[ss].z, wellModelObjects[index].recModel[ss].y]);
                    }

                    let csvContent = "data:text/csv;charset=utf-8," +
                        rows.map(e => e.join(",")).join("\n");

                    var encodedUri = encodeURI(csvContent);
                    var link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", doc + ".csv");
                    document.body.appendChild(link); // Required for FF

                    link.click(); // This will download the data file named "my_data.csv".
                });

                receiverArrayFolder.add(modelValues, 'removeArray').name('Remove Array').onChange(() => {
                    wellModelFolder.removeFolder(doc);

                    var selectedObject1 = scene.getObjectByName("Rec Model" + index);
                    scene.remove(selectedObject1);

                    var selectedObject2 = pickingScene.getObjectByName("Rec Model" + index);
                    pickingScene.remove(selectedObject2);


                });

                wellModelObjects.push({
                    index: index,
                    wellName: modelValues.wellName,
                    startingDepth: modelValues.startingDepth,
                    noRec: modelValues.noRec,
                    distanceBetweenReceivers: modelValues.distanceBetweenReceivers,
                    toolStringName: doc
                });

                noModels = noModels + 1;
            }
        }

        wellModelFolder.add(wellModelFolderFunctions, 'add_receiver_array').name('Add Receiver Array');

    }

    /////////////////////////////////
    // WELL MODELLING FOLDER END  ///
    /////////////////////////////////


    /////////////////////////////////
    //////  SNAPSHOTS FOLDER  ///////
    /////////////////////////////////

    if (admin && !MODELLING_MODE && false) {

        var snapShotFolder = gui.addFolder('Snapshots');

        var snapShotValues = {
            dataset: '',
            datasets: events.map(e => e['dataset']).unique(),
            well: '',
            wells: events.map(e => e['well']).unique(),
            takesnapshot: function() {},
        };

        snapShotFolder.add(snapShotValues, 'dataset', snapShotValues['datasets']).name('Dataset').onChange((val) => {
            snapShotValues['dataset'] = val;
        });

        snapShotFolder.add(snapShotValues, 'well', snapShotValues['wells']).name('Well').onChange((val) => {
            snapShotValues['well'] = val;
        });

        snapShotFolder.add(snapShotValues, 'takesnapshot').name('Take Snapshots').onChange((val) => {

            if (snapShotValues['dataset'] && snapShotValues['dataset'] != '' && snapShotValues['well'] && snapShotValues['well'] != '') {

                eventCheckBoxes['All Events'].setValue(false);

                var stageLoop = Object.keys(eventCheckBoxes[snapShotValues['dataset']][snapShotValues['well']]);

                for (let i = 0; i < stageLoop.length; i++) {

                    if (stageLoop[i] == snapShotValues['well']) {
                        continue;
                    } else {
                        console.log(i, stageLoop, snapShotValues['dataset'], snapShotValues['well'], stageLoop[i]);
                        eventCheckBoxes[snapShotValues['dataset']][snapShotValues['well']][stageLoop[i]].setValue(true);
                        console.log(i, stageLoop, snapShotValues['dataset'], snapShotValues['well'], stageLoop[i]);


                        for (let m = 0; m < SCENE_ROWS * SCENE_COLS; m++) {
                            take_snapshot_button[m].__onChange(stageLoop[i]);
                        }
                        // snapShotButton.fire();
                        eventCheckBoxes[snapShotValues['dataset']][snapShotValues['well']][stageLoop[i]].setValue(false);

                    }

                }

                eventCheckBoxes[snapShotValues['dataset']][snapShotValues['well']][snapShotValues['well']].setValue(true);

                for (let m = 0; m < SCENE_ROWS * SCENE_COLS; m++) {
                    take_snapshot_button[m].__onChange(snapShotValues['well']);
                }

                // for ( let n = 0; n < sceneView.length; n ++ ){
                // 	sceneView[n]['take_snapshot'](snapShotValues['well']);
                // }
                // snapShotButton.fire();

            } else {
                alert("Please select well or dataset.");
            }

        });


    }


    /////////////////////////////////
    ////  SNAPSHOTS FOLDER END  /////
    /////////////////////////////////


    /////////////////////////////////
    //////  SNAPSHOTS FOLDER  ///////
    /////////////////////////////////

    if (admin && (event_datasets.length > 0 || perf_models.length > 0) && (event_figure_types.length > 0 || perf_figure_types > 0)) {

        var reportFiguresFolder = gui.addFolder('Automated Report Figures');

        // well_stage_info = data['well_stage_info']
        // event_datasets = data['event_datasets']

        if (event_datasets.length > 0 && well_stage_info.length > 0) {

            var updateEventsView = () => {

                if (reportEventFiguresFolderValues['dataset'] != '' && reportEventFiguresFolderValues['ftype'] != '' && reportEventFiguresFolderValues['well_stage'] != '') {

                    console.log("eventCheckBoxes", eventCheckBoxes);
                    eventCheckBoxes['All Events'].setValue(false);

                    var dataset = reportEventFiguresFolderValues['dataset'];

                    var figureType = reportEventFiguresFolderValues['ftype'];

                    var index = reportEventFiguresFolderValues['well_stages'].indexOf(reportEventFiguresFolderValues['well_stage']);

                    if (figureType.includes("Project Summary")) {
                        eventCheckBoxes[dataset][dataset].setValue(true);
                    }

                    if (figureType.includes("Well Summary")) {
                        eventCheckBoxes[dataset][well_stage_info[index]['well_alias']][well_stage_info[index]['well_alias']].setValue(true);
                    }

                    if (figureType.includes("Stage Summary")) {
                        eventCheckBoxes[dataset][well_stage_info[index]['well_alias']][well_stage_info[index]['well_alias'] + " " + well_stage_info[index]['stage_name']].setValue(true);
                    }
                }


            }


            var reportEventFiguresFolder = reportFiguresFolder.addFolder('Event Locations');

            var ftypes = event_figure_types.map(e => e['type']).sort();

            var reportEventFiguresFolderValues = {
                dataset: '',
                datasets: event_datasets.map(e => e['title']), //.unique(),
                dataset_id: event_datasets.map(e => e['id'])[0],
                ftype: '', //event_figure_types[0]['type'],
                well_id: well_stage_info[0]['well_id'],
                stage_id: well_stage_info[0]['stage_id'],
                well_stage: '', //well_stage_info.map(e => e['well_name'] + " " + e['stage_name'])[0],
                well_stages: well_stage_info.map(e => e['well_name'] + " " + e['stage_name']), //.unique(),
                takesnapshot: function() {},
            };

            reportEventFiguresFolder.add(reportEventFiguresFolderValues, 'dataset', reportEventFiguresFolderValues['datasets'])
                .name('Dataset').onChange((val) => {
                    reportEventFiguresFolderValues['dataset_id'] = event_datasets.filter(e => e['title'] == val)[0]['id'];
                    updateEventsView();
                });

            reportEventFiguresFolder.add(reportEventFiguresFolderValues, 'well_stage', reportEventFiguresFolderValues['well_stages'])
                .name('Well, Stage').onChange((val) => {
                    var index = reportEventFiguresFolderValues['well_stages'].indexOf(val);
                    reportEventFiguresFolderValues['stage_id'] = well_stage_info[index]['stage_id'];
                    reportEventFiguresFolderValues['well_id'] = well_stage_info[index]['well_id'];
                    updateEventsView();
                    // eventCheckBoxes[snapShotValues['dataset']][snapShotValues['well']][snapShotValues['well']].setValue(true);
                });


            reportEventFiguresFolder.add(reportEventFiguresFolderValues, 'ftype', ftypes)
                .name('Figure Type').onChange((val) => {
                    console.log("Figure type - ", val);
                    if (val.includes("Colored by Well")) {
                        eventColorSlider.setValue("Well");
                        eventSizeVariationSlider.setValue("-");
                    }

                    if (val.includes("Colored by Stage")) {
                        eventColorSlider.setValue("Stage");
                        eventSizeVariationSlider.setValue("-");
                    }

                    if (val.includes("Colored, Sized by Magnitude")) {
                        eventColorSlider.setValue("Magnitude");
                        eventSizeVariationSlider.setValue("Magnitude");
                    }

                    var index = event_figure_types.map(e => e['type']).indexOf(val);

                    for (let n = 0; n < sceneView.length; n++) {
                        sceneView[n]['aspect'] = event_figure_types[index]['aspect'];
                    }
                    // window.dispatchEvent(new Event('resize'));
                    onWindowResize();

                    updateEventsView();

                });


            reportEventFiguresFolder.add(reportEventFiguresFolderValues, 'takesnapshot').name('Take Snapshots').onChange((val) => {

                if (reportEventFiguresFolderValues["ftype"] == '') {
                    alert("Select a figure type first.");
                    return;
                }

                // console.log("well_id", reportFiguresFolderValues["well_id"]);
                // console.log("stage_id", reportFiguresFolderValues["stage_id"]);
                // console.log("ftype", reportFiguresFolderValues["ftype"]);
                // console.log("project_id", project_id);
                if (SCENE_ROWS * SCENE_COLS > 1) {
                    alert("This feature is not available in multiple scenes.");
                    return;
                }

                let sceneIndex = 0;

                let factor = SNAPSHOT_FACTOR;
                let factor_compass_grid = COMPASS_FACTOR;
                let widthImg = factor * sceneView[sceneIndex].maxWidth * sceneView[sceneIndex].width;
                let heightImg = factor * sceneView[sceneIndex].maxHeight * sceneView[sceneIndex].height
                let leftImg = factor * sceneView[sceneIndex].maxWidth * sceneView[sceneIndex].left;
                let bottomImg = factor * sceneView[sceneIndex].maxHeight * sceneView[sceneIndex].bottom;

                if (!isNaN(sceneView[sceneIndex].aspect) && Number(sceneView[sceneIndex].aspect) != 0) {

                    let newHeightImg = widthImg / sceneView[sceneIndex].aspect;
                    if (newHeightImg > heightImg) {
                        let newWidthImg = heightImg * sceneView[sceneIndex].aspect
                        leftImg = leftImg + Math.abs(0.5 * (newWidthImg - widthImg));
                        widthImg = newWidthImg;
                    } else {
                        bottomImg = bottomImg + Math.abs(0.5 * (newHeightImg - heightImg));
                        heightImg = newHeightImg;
                    }
                }

                renderer.setSize(factor * renderer.getSize().x, factor * renderer.getSize().y, false);
                compassRenderer.setSize(factor_compass_grid * compassRenderer.getSize().x, factor_compass_grid * compassRenderer.getSize().y, false);
                render(factor, factor_compass_grid);
                var masterScreenshot = renderer.domElement.toDataURL();
                var compassScreenshot = compassRenderer.domElement.toDataURL();


                renderer.setSize(renderer.getSize().x / factor, renderer.getSize().y / factor, false);
                compassRenderer.setSize(compassRenderer.getSize().x / factor_compass_grid, compassRenderer.getSize().y / factor_compass_grid, false);

                render();

                arrowHelper.setLength(45, 10, 10);
                arrowHelper2.setLength(45, 10, 10);
                arrowHelper3.setLength(45, 10, 10);

                var img1 = document.createElement('img');
                var img2 = document.createElement('img');
                var img3 = document.createElement('img');
                var canvas = document.createElement('canvas');

                img1.src = masterScreenshot;
                img1.onload = function() {
                    img2.src = compassScreenshot;
                    canvas.width = widthImg;
                    canvas.height = heightImg;
                };

                img2.onload = function() {
                    if (SCENE_COLS > 1 || SCENE_ROWS > 1)
                        img3.src = img2.src;
                    else {
                        let imageData = removeImageBlanks(img2); //Will return cropped image data
                        img3.src = imageData;
                    }
                }

                img3.onload = function() {

                    var fontSize = 12 * factor_compass_grid; //Math.round(Math.min(img1.height, img1.width)/50);

                    var context = canvas.getContext('2d');
                    context.drawImage(img1, leftImg, bottomImg, widthImg, heightImg, 0, 0, widthImg, heightImg);
                    if (SCENE_COLS > 1 || SCENE_ROWS > 1) {
                        context.drawImage(img3, leftImg, bottomImg, widthImg, heightImg, 0, 0, widthImg, heightImg);
                    } else {
                        // context.drawImage(img3, 0.025*Math.min(img1.height, img1.width), img1.height - img3.height - 0.025*Math.min(img1.height, img1.width));
                        context.drawImage(img3, 0.025 * Math.min(heightImg, widthImg), heightImg - img3.height - 0.025 * Math.min(heightImg, widthImg));
                    }
                    context.font = fontSize.toString() + 'pt Nunito';
                    context.fillStyle = 'white';
                    context.fillText("Grid size " + gridSize + " ft", 0.025 * Math.min(heightImg, widthImg), 0.025 * Math.min(heightImg, widthImg) + fontSize);

                    var heightPerElement = 20 * factor_compass_grid;
                    for (let l = 0; l < LEGEND_TEXT.length; l++) {
                        let grd = context.createLinearGradient(0, 0, 0, 10);
                        grd.addColorStop(0, LEGEND_COLOR[l]);
                        grd.addColorStop(1, LEGEND_COLOR[l]);
                        context.fillStyle = grd;
                        let y0 = l * heightPerElement + 0.025 * Math.min(heightImg, widthImg);
                        let y1 = (l + 1) * heightPerElement + 0.025 * Math.min(heightImg, widthImg);
                        context.fillRect(widthImg - 0.025 * Math.min(heightImg, widthImg) - 20 * factor_compass_grid, Math.ceil(y0) + 1, 20 * factor_compass_grid, 20 * factor_compass_grid);
                        context.font = Math.round(0.6 * (y1 - y0)).toString() + 'pt Nunito';
                        context.fillStyle = 'white';
                        context.textAlign = "end";
                        context.fillText(LEGEND_TEXT[l],
                            widthImg - 0.025 * Math.min(heightImg, widthImg) - 30 * factor_compass_grid,
                            y1 - Math.round(0.2 * (y1 - y0))
                        );
                    }

                    var image_data = canvas.toDataURL()

                    $(function() {
                        $.ajax({
                            type: "POST",
                            url: "/projects/" + project_id + "/figures",
                            data: {
                                'figure': {
                                    'event_dataset_id': reportEventFiguresFolderValues['dataset_id'],
                                    'stage_id': reportEventFiguresFolderValues["stage_id"] == -1 ? null : reportEventFiguresFolderValues["stage_id"],
                                    'well_id': reportEventFiguresFolderValues["well_id"],
                                    'project_id': project_id,
                                    'ftype': reportEventFiguresFolderValues["ftype"],
                                    'image': image_data,
                                    'image_file_name': reportEventFiguresFolderValues['well_stage'] + ".png"
                                }
                            },
                            success: function(data) {
                                $('#loadingStatus').html(data['message']);
                                $('#loadingStatus').show()
                                setTimeout(function() {
                                    $('#loadingStatus').fadeOut('slow');
                                }, 3000); //

                            },

                        });
                    });


                };

            });


        }


        if (perf_models.length > 0 && well_info.length > 0) {


            var updatePerfsView = () => {

                if (reportPerfFiguresFolderValues['dataset'] != '' && reportPerfFiguresFolderValues['ftype'] != '' && reportPerfFiguresFolderValues['well_stage'] != '') {

                    console.log("perfcheckboxes", perfCheckBoxes);

                    eventCheckBoxes['All Events'].setValue(false);
                    perfCheckBoxes['All Perfs'].setValue(false);
                    perfCheckBoxes['Master']['Master'].setValue(true);
                    var dataset = reportPerfFiguresFolderValues['dataset'];
                    var index = reportPerfFiguresFolderValues['well_stages'].indexOf(reportPerfFiguresFolderValues['well_stage']);
                    if (index != -1) {
                        perfCheckBoxes[dataset][well_info[index]['alias']][well_info[index]['alias']].setValue(true);
                    }
                }

            }

            var reportPerfFiguresFolder = reportFiguresFolder.addFolder('Perf Relocation');

            var reportPerfFiguresFolderValues = {
                dataset: '',
                datasets: perf_models.map(e => e['title']), //.unique(),
                dataset_id: perf_models.map(e => e['id'])[0],
                ftype: '', //perf_figure_types[0]['type'],
                well_id: '',
                well_stage: '',
                well_stages: well_info.map(e => e['name']), //.unique(),
                takesnapshot: function() {},
            };

            reportPerfFiguresFolder.add(reportPerfFiguresFolderValues, 'dataset', reportPerfFiguresFolderValues['datasets'])
                .name('Dataset').onChange((val) => {
                    reportPerfFiguresFolderValues['dataset_id'] = perf_models.filter(e => e['title'] == val)[0]['id'];
                    updatePerfsView();
                });

            reportPerfFiguresFolder.add(reportPerfFiguresFolderValues, 'well_stage', reportPerfFiguresFolderValues['well_stages'])
                .name('Well').onChange((val) => {
                    var index = reportPerfFiguresFolderValues['well_stages'].indexOf(val);
                    reportPerfFiguresFolderValues['well_id'] = well_info[index]['id'];
                    updatePerfsView();
                });


            reportPerfFiguresFolder.add(reportPerfFiguresFolderValues, 'ftype', perf_figure_types.map(e => e['type']).sort())
                .name('Figure Type').onChange((val) => {

                    var index = perf_figure_types.map(e => e['type']).indexOf(val);
                    for (let n = 0; n < sceneView.length; n++) {
                        sceneView[n]['aspect'] = perf_figure_types[index]['aspect'];
                    }
                    // window.dispatchPerf(new Perf('resize'));
                    onWindowResize();
                    updatePerfsView();
                });


            reportPerfFiguresFolder.add(reportPerfFiguresFolderValues, 'takesnapshot').name('Take Snapshots').onChange((val) => {

                if (reportPerfFiguresFolderValues["ftype"] == '') {
                    alert("Select a figure type first.");
                    return;
                }

                // console.log("well_id", reportFiguresFolderValues["well_id"]);
                // console.log("stage_id", reportFiguresFolderValues["stage_id"]);
                // console.log("ftype", reportFiguresFolderValues["ftype"]);
                // console.log("project_id", project_id);
                if (SCENE_ROWS * SCENE_COLS > 1) {
                    alert("This feature is not available in multiple scenes.");
                    return;
                }

                let sceneIndex = 0;

                let factor = SNAPSHOT_FACTOR;
                let factor_compass_grid = COMPASS_FACTOR;
                let widthImg = factor * sceneView[sceneIndex].maxWidth * sceneView[sceneIndex].width;
                let heightImg = factor * sceneView[sceneIndex].maxHeight * sceneView[sceneIndex].height
                let leftImg = factor * sceneView[sceneIndex].maxWidth * sceneView[sceneIndex].left;
                let bottomImg = factor * sceneView[sceneIndex].maxHeight * sceneView[sceneIndex].bottom;

                if (!isNaN(sceneView[sceneIndex].aspect) && Number(sceneView[sceneIndex].aspect) != 0) {

                    let newHeightImg = widthImg / sceneView[sceneIndex].aspect;
                    if (newHeightImg > heightImg) {
                        let newWidthImg = heightImg * sceneView[sceneIndex].aspect
                        leftImg = leftImg + Math.abs(0.5 * (newWidthImg - widthImg));
                        widthImg = newWidthImg;
                    } else {
                        bottomImg = bottomImg + Math.abs(0.5 * (newHeightImg - heightImg));
                        heightImg = newHeightImg;
                    }
                }

                renderer.setSize(factor * renderer.getSize().x, factor * renderer.getSize().y, false);
                compassRenderer.setSize(factor_compass_grid * compassRenderer.getSize().x, factor_compass_grid * compassRenderer.getSize().y, false);
                render(factor, factor_compass_grid);
                var masterScreenshot = renderer.domElement.toDataURL();
                var compassScreenshot = compassRenderer.domElement.toDataURL();


                renderer.setSize(renderer.getSize().x / factor, renderer.getSize().y / factor, false);
                compassRenderer.setSize(compassRenderer.getSize().x / factor_compass_grid, compassRenderer.getSize().y / factor_compass_grid, false);

                render();

                arrowHelper.setLength(45, 10, 10);
                arrowHelper2.setLength(45, 10, 10);
                arrowHelper3.setLength(45, 10, 10);

                var img1 = document.createElement('img');
                var img2 = document.createElement('img');
                var img3 = document.createElement('img');
                var canvas = document.createElement('canvas');

                img1.src = masterScreenshot;
                img1.onload = function() {
                    img2.src = compassScreenshot;
                    canvas.width = widthImg;
                    canvas.height = heightImg;
                };

                img2.onload = function() {
                    if (SCENE_COLS > 1 || SCENE_ROWS > 1)
                        img3.src = img2.src;
                    else {
                        let imageData = removeImageBlanks(img2); //Will return cropped image data
                        img3.src = imageData;
                    }
                }

                img3.onload = function() {

                    var fontSize = 12 * factor_compass_grid; //Math.round(Math.min(img1.height, img1.width)/50);

                    var context = canvas.getContext('2d');
                    context.drawImage(img1, leftImg, bottomImg, widthImg, heightImg, 0, 0, widthImg, heightImg);
                    if (SCENE_COLS > 1 || SCENE_ROWS > 1) {
                        context.drawImage(img3, leftImg, bottomImg, widthImg, heightImg, 0, 0, widthImg, heightImg);
                    } else {
                        // context.drawImage(img3, 0.025*Math.min(img1.height, img1.width), img1.height - img3.height - 0.025*Math.min(img1.height, img1.width));
                        context.drawImage(img3, 0.025 * Math.min(heightImg, widthImg), heightImg - img3.height - 0.025 * Math.min(heightImg, widthImg));
                    }
                    context.font = fontSize.toString() + 'pt Nunito';
                    context.fillStyle = 'white';
                    context.fillText("Grid size " + gridSize + " ft", 0.025 * Math.min(heightImg, widthImg), 0.025 * Math.min(heightImg, widthImg) + fontSize);

                    var heightPerElement = 20 * factor_compass_grid;
                    for (let l = 0; l < LEGEND_TEXT.length; l++) {
                        let grd = context.createLinearGradient(0, 0, 0, 10);
                        grd.addColorStop(0, LEGEND_COLOR[l]);
                        grd.addColorStop(1, LEGEND_COLOR[l]);
                        context.fillStyle = grd;
                        let y0 = l * heightPerElement + 0.025 * Math.min(heightImg, widthImg);
                        let y1 = (l + 1) * heightPerElement + 0.025 * Math.min(heightImg, widthImg);
                        context.fillRect(widthImg - 0.025 * Math.min(heightImg, widthImg) - 20 * factor_compass_grid, Math.ceil(y0) + 1, 20 * factor_compass_grid, 20 * factor_compass_grid);
                        context.font = Math.round(0.6 * (y1 - y0)).toString() + 'pt Nunito';
                        context.fillStyle = 'white';
                        context.textAlign = "end";
                        context.fillText(LEGEND_TEXT[l],
                            widthImg - 0.025 * Math.min(heightImg, widthImg) - 30 * factor_compass_grid,
                            y1 - Math.round(0.2 * (y1 - y0))
                        );
                    }

                    var image_data = canvas.toDataURL()

                    $(function() {
                        $.ajax({
                            type: "POST",
                            url: "/projects/" + project_id + "/figures",
                            data: {
                                'figure': {
                                    'perf_model_id': reportPerfFiguresFolderValues['dataset_id'],
                                    'well_id': reportPerfFiguresFolderValues["well_id"],
                                    'project_id': project_id,
                                    'ftype': reportPerfFiguresFolderValues["ftype"],
                                    'image': image_data,
                                    'image_file_name': reportPerfFiguresFolderValues['well_stage'] + ".png"
                                }
                            },
                            success: function(data) {
                                console.log(data);
                                $('#loadingStatus').html(data['message'])
                                $('#loadingStatus').show()
                                setTimeout(function() {
                                    $('#loadingStatus').fadeOut('slow');
                                }, 3000); //

                            }
                        });
                    });


                };

            });


        }



    }




    /////////////////////////////////
    ////  SNAPSHOTS FOLDER END  /////
    /////////////////////////////////

    // document.getElementById(divId).addEventListener('resize', onWindowResize, false);
    window.addEventListener('resize', onWindowResize, false);

    document.body.addEventListener('touchmove', function(event) {
        event.preventDefault();
    }, false);


    document.addEventListener('mousedown', () => drag = false);
    document.addEventListener('mousemove', () => drag = true);
    document.addEventListener('mouseup', () => {
        if (drag) {
            $("#tooltip").hide();
        }
    });

    document.addEventListener("wheel", () => {
        $("#tooltip").hide();
    });

    document.addEventListener("onmousewheel", () => {
        $("#tooltip").hide();
    });

    document.addEventListener("onwheel", () => {
        $("#tooltip").hide();
    });

}

function initializeGUI() {

    gui = new dat.GUI({
        autoplace: false,
        resizable: false,
        scrollable: true,
        height: 300,
    });

    gui.closed = true;
    // dat.GUI.toggleHide();

    gui.domElement.id = 'canvas';
    $('.moveGUI').append($(gui.domElement));

    wellsFolder = gui.addFolder('Wells');

}

var wellsFolder;

function plotMonitorWells() {

    /////////////////////////////////
    ///// MONITOR WELL FOLDER   /////
    /////////////////////////////////

    if (mtrWell.length > 0 && wellsFolder.__folders['Monitor Wells'] == null) {

        var monitorFolder = wellsFolder.addFolder('Monitor Wells');
        monitorFolder.A = new Array();
        for (var i = 0; i < mtrWell.length; i++) {
            let key = plot_id + "_" + "Monitor Well " + mtrWell[i].name;
            if (localStorage.getItem(key) == null || localStorage.getItem(key) == "true") {
                localStorage.setItem(key, true);
                monitorFolder.A[mtrWell[i].name] = true;
            } else {
                monitorFolder.A[mtrWell[i].name] = false;
            }
        }

        for (var i = 0; i < mtrWell.length; i++) {
            monitorFolder.add(monitorFolder.A, mtrWell[i].name, monitorFolder.A[mtrWell[i].name]).name(textFix(mtrWell[i].name))
                .onChange(function(value) {
                    scene.getObjectByName("Monitor Well " + this.property).visible = value;
                    pickingScene.getObjectByName("Monitor Well " + this.property).visible = value;
                    localStorage.setItem(plot_id + "_" + "Monitor Well " + this.property, value);
                    //pickingScene.getObjectByName(this.property).visible = value;
                });
        }
    } else {
        monitorFolder = wellsFolder.__folders['Monitor Wells'];
    }

    for (var j = 0; j < mtrWell.length; j++) {

        var well = mtrWell[j];
        var curvePoints = [];
        var curvePointsArray = [];
        for (var i = 0; i < well.x.length; i++) {

            curvePointsArray.push((DOWNSAMPLE * well.x[i] - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                (DOWNSAMPLE * well.y[i] - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                (DOWNSAMPLE * well.z[i] - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));

            if (i > 0) {
                curvePointsArray.push(
                    DOWNSAMPLE * well.x[i] - bk_x_min - 0.5 * (bk_x_max - bk_x_min),
                    DOWNSAMPLE * well.y[i] - bk_y_min - 0.5 * (bk_y_max - bk_y_min),
                    DOWNSAMPLE * well.z[i] - bk_z_min - 0.5 * (bk_z_max - bk_z_min));
            }


            curvePoints.push(new THREE.Vector3((DOWNSAMPLE * well.x[i] - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                (DOWNSAMPLE * well.y[i] - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                (DOWNSAMPLE * well.z[i] - bk_z_min - 0.5 * (bk_z_max - bk_z_min))));

        }

        // curve = new THREE.CatmullRomCurve3(curvePoints);
        // points = curve.getPoints( 1000 );
        // mtWellGeomDrawn[j] = new THREE.BufferGeometry().setFromPoints( curvePoints );
        // mtWellGeomDrawn[j].frustumCulled = false;

        mtWellGeomDrawn[j] = new LineSegmentsGeometry().setPositions(curvePointsArray);

        wellMaterial = new LineMaterial({ color: 0x2f8dff, linewidth: 2 });
        wellMaterial.resolution.set(parentContainer.width(), parentContainer.height()); // important, for now
        mtWellMesh[j] = new LineSegments2(mtWellGeomDrawn[j], wellMaterial);
        mtWellMesh[j].name = "Monitor Well " + well.name;
        mtWellMesh[j].frustumCulled = false;
        mtWellMesh[j].visible = Boolean(monitorFolder.A[mtrWell[j].name]);
        scene.remove(scene.getObjectByName(mtWellMesh[j].name));
        scene.add(mtWellMesh[j]);

        // Configuring Picking Scene;
        if (true) {

            var mtWellMaterialPick = new THREE.PointsMaterial({
                size: 1,
                vertexColors: THREE.VertexColors
            });

            mtWellMaterialPick.onBeforeCompile = function(shader) {

                shader.vertexShader =
                    `
					uniform float size;
					uniform float scale;
					attribute float alpha;

					#include <common>
					#include <color_pars_vertex>
					#include <fog_pars_vertex>
					#include <morphtarget_pars_vertex>
					#include <logdepthbuf_pars_vertex>
					#include <clipping_planes_pars_vertex>
					void main() {
						#include <color_vertex>
						#include <begin_vertex>
						#include <morphtarget_vertex>
						#include <project_vertex>
						gl_PointSize = size;
						#ifdef USE_SIZEATTENUATION
							bool isPerspective = ( projectionMatrix[ 2 ][ 3 ] == - 1.0 );
							if ( isPerspective ) gl_PointSize *= ( scale / - mvPosition.z );

						#endif
						#include <logdepthbuf_vertex>
						#include <clipping_planes_vertex>
						#include <worldpos_vertex>
						#include <fog_vertex>
					}
					`;

                shader.fragmentShader =
                    `
					uniform vec3 diffuse;
					uniform float opacity;
					#include <common>
					#include <color_pars_fragment>
					#include <map_particle_pars_fragment>
					#include <fog_pars_fragment>
					#include <logdepthbuf_pars_fragment>
					#include <clipping_planes_pars_fragment>
					void main() {
						#include <clipping_planes_fragment>
						vec3 outgoingLight = vec3( 0.0 );
						vec4 diffuseColor = vec4( diffuse, opacity );
						#include <logdepthbuf_fragment>
						#include <map_particle_fragment>
						#include <color_fragment>
						#include <alphatest_fragment>
						outgoingLight = diffuseColor.rgb;
						gl_FragColor = vec4( outgoingLight, diffuseColor.a );
						#include <premultiplied_alpha_fragment>
						#include <tonemapping_fragment>
						#include <encodings_fragment>
						#include <fog_fragment>
					}
					`;

                var materialShader = shader;

            };

            var curvePointsArrayPick = [];
            for (var i = 0; i < well.x.length; i++) {
                curvePointsArrayPick.push(
                    new THREE.Vector3(
                        Number(well.x[i]),
                        Number(well.y[i]),
                        Number(well.z[i])
                    )
                    // (DOWNSAMPLE*well.x[i] - bk_x_min - 0.5*(bk_x_max - bk_x_min)), 
                    // (DOWNSAMPLE*well.y[i] - bk_y_min - 0.5*(bk_y_max - bk_y_min)), 
                    // (DOWNSAMPLE*well.z[i] - bk_z_min - 0.5*(bk_z_max - bk_z_min))
                );
            }

            var curvePick = new THREE.CatmullRomCurve3(curvePointsArrayPick);
            var MAX_POINTS = 10000;
            var pointsPick = curvePick.getPoints(MAX_POINTS);
            mtWellGeomPicking[j] = new THREE.BufferGeometry();
            var wellPositions = new Float32Array(MAX_POINTS * 3);
            var colorCoef = new Float32Array(MAX_POINTS * 3);
            var mtWellColor = new THREE.Color(0xffffff).convertSRGBToLinear();

            for (var index = 0; index < MAX_POINTS; index++) {

                wellPositions[3 * index] = (DOWNSAMPLE * pointsPick[index].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)); // EXAGGERATION_X*pointsPick[index].x;
                wellPositions[3 * index + 1] = (DOWNSAMPLE * pointsPick[index].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)); //EXAGGERATION_Y*z;
                wellPositions[3 * index + 2] = (DOWNSAMPLE * pointsPick[index].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min));

                var colorTrtWell = mtWellColor.setHex(pickingID);
                colorCoef[3 * index] = colorTrtWell.r;
                colorCoef[3 * index + 1] = colorTrtWell.g;
                colorCoef[3 * index + 2] = colorTrtWell.b;

                pickingID = pickingID + 1;

                pickingData[pickingID] = {
                    name: "<b>Monitor Well</b><br>" + well.name + "<br>" +
                        "Easting: " + Math.round(pointsPick[index].z).toString() + " ft<br>" +
                        "Northing: " + Math.round(pointsPick[index].x).toString() + " ft<br>" +
                        "Depth (Subsea): " + Math.round(pointsPick[index].y).toString() + " ft",
                    position: new THREE.Vector3(Math.round(pointsPick[index].x), Math.round(pointsPick[index].z), Math.round(pointsPick[index].y))
                };
            }

            mtWellGeomPicking[j].setAttribute('position', new THREE.BufferAttribute(wellPositions, 3));
            mtWellGeomPicking[j].setAttribute('color', new THREE.BufferAttribute(colorCoef, 3));
            mtWellGeomPicking[j].verticesNeedUpdate = true;

            mtWellMeshPick[j] = new THREE.Points(mtWellGeomPicking[j], mtWellMaterialPick);
            mtWellMeshPick[j].visible = Boolean(monitorFolder.A["Monitor Well " + mtrWell[j].name]);
            mtWellMeshPick[j].name = "Monitor Well " + well.name;
            pickingScene.remove(pickingScene.getObjectByName(mtWellMeshPick[j].name));
            pickingScene.add(mtWellMeshPick[j]);
            pickingID = pickingID + 1;

        }

    }

    if (mtrWell.length > 0) {
        console.log("Plotted Monitor Well");
        updateStatus("Plotted Monitor Wells");
    }



}

function plotTreatmentWells() {

    /////////////////////////////////
    ///// MONITOR WELL FOLDER   /////
    /////////////////////////////////

    if (trtWell.length > 0 && wellsFolder.__folders['Treatment Wells'] == null) {

        var treatmentFolder = wellsFolder.addFolder('Treatment Wells');
        treatmentFolder.A = new Array();
        for (var i = 0; i < trtWell.length; i++) {
            let key = plot_id + "_" + "Treatment Well " + trtWell[i].name;
            if (localStorage.getItem(key) == null || localStorage.getItem(key) == "true") {
                localStorage.setItem(key, true);
                treatmentFolder.A[trtWell[i].name] = true;
            } else {
                treatmentFolder.A[trtWell[i].name] = false;
            }
        }

        for (var i = 0; i < trtWell.length; i++) {
            treatmentFolder.add(treatmentFolder.A, trtWell[i].name, treatmentFolder.A[trtWell[i].name]).name(textFix(trtWell[i].name))
                .onChange(function(value) {
                    scene.getObjectByName("Treatment Well " + this.property).visible = value;
                    pickingScene.getObjectByName("Treatment Well " + this.property).visible = value;
                    localStorage.setItem(plot_id + "_" + "Treatment Well " + this.property, value);
                    //pickingScene.getObjectByName(this.property).visible = value;
                });
        }
    } else {
        treatmentFolder = wellsFolder.__folders['Treatment Wells'];
    }

    for (var j = 0; j < trtWell.length; j++) {

        var well = trtWell[j];
        var curvePoints = [];
        var curvePointsArray = [];
        for (var i = 0; i < well.x.length; i++) {

            curvePointsArray.push((DOWNSAMPLE * well.x[i] - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                (DOWNSAMPLE * well.y[i] - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                (DOWNSAMPLE * well.z[i] - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));

            if (i > 0) {
                curvePointsArray.push(
                    DOWNSAMPLE * well.x[i] - bk_x_min - 0.5 * (bk_x_max - bk_x_min),
                    DOWNSAMPLE * well.y[i] - bk_y_min - 0.5 * (bk_y_max - bk_y_min),
                    DOWNSAMPLE * well.z[i] - bk_z_min - 0.5 * (bk_z_max - bk_z_min));
            }


            curvePoints.push(new THREE.Vector3((DOWNSAMPLE * well.x[i] - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                (DOWNSAMPLE * well.y[i] - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                (DOWNSAMPLE * well.z[i] - bk_z_min - 0.5 * (bk_z_max - bk_z_min))));

        }

        // curve = new THREE.CatmullRomCurve3(curvePoints);
        // points = curve.getPoints( 1000 );
        // tmWellGeomDrawn[j] = new THREE.BufferGeometry().setFromPoints( curvePoints );
        // tmWellGeomDrawn[j].frustumCulled = false;

        tmWellGeomDrawn[j] = new LineSegmentsGeometry().setPositions(curvePointsArray);

        wellMaterial = new LineMaterial({ color: 0xff0800, linewidth: 2 });
        wellMaterial.resolution.set(parentContainer.width(), parentContainer.height()); // important, for now
        tmWellMesh[j] = new LineSegments2(tmWellGeomDrawn[j], wellMaterial);
        tmWellMesh[j].name = "Treatment Well " + well.name;
        tmWellMesh[j].frustumCulled = false;
        tmWellMesh[j].visible = Boolean(treatmentFolder.A[trtWell[j].name]);
        scene.remove(scene.getObjectByName(tmWellMesh[j].name));
        scene.add(tmWellMesh[j]);

        // Configuring Picking Scene;
        if (true) {

            var tmWellMaterialPick = new THREE.PointsMaterial({
                size: 1,
                vertexColors: THREE.VertexColors
            });

            tmWellMaterialPick.onBeforeCompile = function(shader) {

                shader.vertexShader =
                    `
					uniform float size;
					uniform float scale;
					attribute float alpha;

					#include <common>
					#include <color_pars_vertex>
					#include <fog_pars_vertex>
					#include <morphtarget_pars_vertex>
					#include <logdepthbuf_pars_vertex>
					#include <clipping_planes_pars_vertex>
					void main() {
						#include <color_vertex>
						#include <begin_vertex>
						#include <morphtarget_vertex>
						#include <project_vertex>
						gl_PointSize = size;
						#ifdef USE_SIZEATTENUATION
							bool isPerspective = ( projectionMatrix[ 2 ][ 3 ] == - 1.0 );
							if ( isPerspective ) gl_PointSize *= ( scale / - mvPosition.z );

						#endif
						#include <logdepthbuf_vertex>
						#include <clipping_planes_vertex>
						#include <worldpos_vertex>
						#include <fog_vertex>
					}
					`;

                shader.fragmentShader =
                    `
					uniform vec3 diffuse;
					uniform float opacity;
					#include <common>
					#include <color_pars_fragment>
					#include <map_particle_pars_fragment>
					#include <fog_pars_fragment>
					#include <logdepthbuf_pars_fragment>
					#include <clipping_planes_pars_fragment>
					void main() {
						#include <clipping_planes_fragment>
						vec3 outgoingLight = vec3( 0.0 );
						vec4 diffuseColor = vec4( diffuse, opacity );
						#include <logdepthbuf_fragment>
						#include <map_particle_fragment>
						#include <color_fragment>
						#include <alphatest_fragment>
						outgoingLight = diffuseColor.rgb;
						gl_FragColor = vec4( outgoingLight, diffuseColor.a );
						#include <premultiplied_alpha_fragment>
						#include <tonemapping_fragment>
						#include <encodings_fragment>
						#include <fog_fragment>
					}
					`;

                var materialShader = shader;

            };

            var curvePointsArrayPick = [];
            for (var i = 0; i < well.x.length; i++) {
                curvePointsArrayPick.push(
                    new THREE.Vector3(
                        Number(well.x[i]),
                        Number(well.y[i]),
                        Number(well.z[i])
                    )
                    // (DOWNSAMPLE*well.x[i] - bk_x_min - 0.5*(bk_x_max - bk_x_min)), 
                    // (DOWNSAMPLE*well.y[i] - bk_y_min - 0.5*(bk_y_max - bk_y_min)), 
                    // (DOWNSAMPLE*well.z[i] - bk_z_min - 0.5*(bk_z_max - bk_z_min))
                );
            }

            var curvePick = new THREE.CatmullRomCurve3(curvePointsArrayPick);
            var MAX_POINTS = 10000;
            var pointsPick = curvePick.getPoints(MAX_POINTS);
            tmWellGeomPicking[j] = new THREE.BufferGeometry();
            var wellPositions = new Float32Array(MAX_POINTS * 3);
            var colorCoef = new Float32Array(MAX_POINTS * 3);
            var tmWellColor = new THREE.Color(0xffffff).convertSRGBToLinear();

            for (var index = 0; index < MAX_POINTS; index++) {

                wellPositions[3 * index] = (DOWNSAMPLE * pointsPick[index].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)); // EXAGGERATION_X*pointsPick[index].x;
                wellPositions[3 * index + 1] = (DOWNSAMPLE * pointsPick[index].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)); //EXAGGERATION_Y*z;
                wellPositions[3 * index + 2] = (DOWNSAMPLE * pointsPick[index].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min));

                var colorTrtWell = tmWellColor.setHex(pickingID);
                colorCoef[3 * index] = colorTrtWell.r;
                colorCoef[3 * index + 1] = colorTrtWell.g;
                colorCoef[3 * index + 2] = colorTrtWell.b;

                pickingID = pickingID + 1;

                pickingData[pickingID] = {
                    name: "<b>Treatment Well</b><br>" + well.name + "<br>" +
                        "Easting: " + Math.round(pointsPick[index].z).toString() + " ft<br>" +
                        "Northing: " + Math.round(pointsPick[index].x).toString() + " ft<br>" +
                        "Depth (Subsea): " + Math.round(pointsPick[index].y).toString() + " ft",
                    position: new THREE.Vector3(Math.round(pointsPick[index].x), Math.round(pointsPick[index].z), Math.round(pointsPick[index].y))
                };
            }

            tmWellGeomPicking[j].setAttribute('position', new THREE.BufferAttribute(wellPositions, 3));
            tmWellGeomPicking[j].setAttribute('color', new THREE.BufferAttribute(colorCoef, 3));
            tmWellGeomPicking[j].verticesNeedUpdate = true;

            tmWellMeshPick[j] = new THREE.Points(tmWellGeomPicking[j], tmWellMaterialPick);
            tmWellMeshPick[j].visible = Boolean(treatmentFolder.A["Treatment Well " + trtWell[j].name]);
            tmWellMeshPick[j].name = "Treatment Well " + well.name;
            pickingScene.remove(pickingScene.getObjectByName(tmWellMeshPick[j].name));
            pickingScene.add(tmWellMeshPick[j]);
            pickingID = pickingID + 1;

        }

    }

    if (trtWell.length > 0) {
        console.log("Plotted Treatment Well");
        updateStatus("Plotted Treatment Wells");
    }



}

function plotWellLabels(font) {

    wellLabelOptions = {
        labelColor: localStorage.getItem('Well Label Color') || "#ffffff",
        labelSize: localStorage.getItem('Well Label Size') || 10
    };

    if (wellLabel.length > 0 && wellsFolder.__folders['Well Labels'] == null) {

        wellLabelFolder = wellsFolder.addFolder('Well Labels');
        wellLabelFolder.A = new Array();
        wellLabelFolder.A["All Labels"] = localStorage.getItem('All Well Labels') == "false" ? false : true;

        wellLabelFolder.add(wellLabelFolder.A, 'All Labels', wellLabelFolder.A['All Labels']).name("All Labels").onChange(function(value) {
            for (j = 1; j < wellLabel.length + 1; j++) {
                wellLabelFolder.__controllers[j].setValue(value);
                localStorage.setItem('Well Label ' + wellLabel[j - 1].name, value);
            }
            localStorage.setItem('All Well Labels', value);
        });

        for (var i = 0; i < wellLabel.length; i++) {
            wellLabelFolder.A[wellLabel[i].name] = localStorage.getItem('Well Label ' + wellLabel[i].name) == "false" ? false : true;
        }

        for (var i = 0; i < wellLabel.length; i++) {
            wellLabelController[wellLabel[i].name] = wellLabelFolder.add(wellLabelFolder.A, wellLabel[i].name, wellLabelFolder.A[i]).name(textFix(wellLabel[i].name))
                .onChange(function(value) {
                    scene.getObjectByName("Well Label " + this.property).visible = value;
                    localStorage.setItem('Well Label ' + this.property, value);
                });
        }

        wellLabelFolder.addColor(wellLabelOptions, 'labelColor').name('Label Color')
            .onChange(function(value) {
                localStorage.setItem('Well Label Color', value);
                for (var i = 0; i < wellLabel.length; i++) {
                    var colorTmp = new THREE.Color(value).convertSRGBToLinear();
                    wellLabelMesh[i].material[0].color.setHex(colorTmp.getHex());
                    wellLabelMesh[i].material[1].color.setHex(colorTmp.getHex());
                }
            });


        wellLabelFolder.add(wellLabelOptions, 'labelSize', [2, 4, 6, 8, 10, 12, 14, 16]).name('Label Size')
            .onChange(function(value) {
                localStorage.setItem('Well Label Size', value);
                plotWellLabels(font);
            });

    }

    /////////////////////////////////
    /////// WELL LABEL START ////////
    /////////////////////////////////


    var wellLabelMaterial = [
        new THREE.MeshLambertMaterial({ color: wellLabelOptions['labelColor'], flatShading: true }), // front
        new THREE.MeshPhongMaterial({ color: wellLabelOptions['labelColor'] }) // side
    ];

    for (var j = 0; j < wellLabel.length; j++) {

        var textGeo = new THREE.TextGeometry(wellLabel[j].name, { font: font, size: wellLabelOptions['labelSize'] / 2.5, height: wellLabelOptions['labelSize'] / 5 });
        textGeo.computeBoundingBox();
        textGeo.computeVertexNormals();

        centerOffsetX = -0.5 * (textGeo.boundingBox.max.x - textGeo.boundingBox.min.x);
        centerOffsetY = -0.5 * (textGeo.boundingBox.max.y - textGeo.boundingBox.min.y);
        centerOffsetZ = -0.5 * (textGeo.boundingBox.max.z - textGeo.boundingBox.min.z);

        wellOffsetX = DOWNSAMPLE * wellLabel[j].x[wellLabel[j].x.length - 1] - DOWNSAMPLE * wellLabel[j].x[wellLabel[j].x.length - 2];
        wellOffsetY = DOWNSAMPLE * wellLabel[j].y[wellLabel[j].y.length - 1] - DOWNSAMPLE * wellLabel[j].y[wellLabel[j].y.length - 2];
        wellOffsetZ = DOWNSAMPLE * wellLabel[j].z[wellLabel[j].z.length - 1] - DOWNSAMPLE * wellLabel[j].z[wellLabel[j].z.length - 2];

        wellLabelGeom[j] = new THREE.BufferGeometry().fromGeometry(textGeo);
        wellLabelGeom[j].center();
        wellLabelMesh[j] = new THREE.Mesh(wellLabelGeom[j], wellLabelMaterial);
        if (wellLabel[j].y.length > 10) {
            wellLabelMesh[j].position.x = DOWNSAMPLE * centerOffsetX + DOWNSAMPLE * wellLabel[j].x[wellLabel[j].x.length - 1] - bk_x_min - 0.5 * (bk_x_max - bk_x_min) + wellOffsetX;
            wellLabelMesh[j].position.y = DOWNSAMPLE * centerOffsetY + DOWNSAMPLE * wellLabel[j].y[wellLabel[j].y.length - 1] - bk_y_min - 0.5 * (bk_y_max - bk_y_min) + wellOffsetY;
            wellLabelMesh[j].position.z = DOWNSAMPLE * centerOffsetZ + DOWNSAMPLE * wellLabel[j].z[wellLabel[j].z.length - 1] - bk_z_min - 0.5 * (bk_z_max - bk_z_min) + wellOffsetZ;
        } else {
            wellLabelMesh[j].position.x = DOWNSAMPLE * centerOffsetX + DOWNSAMPLE * wellLabel[j].x[wellLabel[j].x.length - 1] - bk_x_min - 0.5 * (bk_x_max - bk_x_min); // + wellOffsetX;
            wellLabelMesh[j].position.y = DOWNSAMPLE * centerOffsetY + DOWNSAMPLE * wellLabel[j].y[wellLabel[j].y.length - 1] - bk_y_min - 0.5 * (bk_y_max - bk_y_min); // + wellOffsetY; 
            wellLabelMesh[j].position.z = DOWNSAMPLE * centerOffsetZ + DOWNSAMPLE * wellLabel[j].z[wellLabel[j].z.length - 1] - bk_z_min - 0.5 * (bk_z_max - bk_z_min); // + wellOffsetZ;
        }
        wellLabelMesh[j].name = "Well Label " + wellLabel[j].name;
        wellLabelMesh[j].visible = wellLabelFolder.A[wellLabel[j].name];
        wellLabelMesh[j].renderOrder = 999;
        wellLabelMesh[j].onBeforeRender = function(renderer) { renderer.clearDepth(); };

        scene.remove(scene.getObjectByName(wellLabelMesh[j].name))
        scene.add(wellLabelMesh[j]);

    }


    if (wellLabel.length > 0) {
        console.log("Plotted Well Labels");
        updateStatus("Plotted Well Labels");
    }

    /////////////////////////////////
    //////// WELL LABEL END /////////
    /////////////////////////////////


}

function plotAdditionalWells() {

    /////////////////////////////////
    ///// MONITOR WELL FOLDER   /////
    /////////////////////////////////

    if (addWell.length > 0 && wellsFolder.__folders['Additional Wells'] == null) {

        var additionalFolder = wellsFolder.addFolder('Additional Wells');
        additionalFolder.A = new Array();
        for (var i = 0; i < addWell.length; i++) {
            let key = plot_id + "_" + "Additional Well " + addWell[i].name;
            if (localStorage.getItem(key) == null || localStorage.getItem(key) == "true") {
                localStorage.setItem(key, true);
                additionalFolder.A[addWell[i].name] = true;
            } else {
                additionalFolder.A[addWell[i].name] = false;
            }
        }

        for (var i = 0; i < addWell.length; i++) {
            additionalFolder.add(additionalFolder.A, addWell[i].name, additionalFolder.A[addWell[i].name]).name(textFix(addWell[i].name))
                .onChange(function(value) {
                    scene.getObjectByName("Additional Well " + this.property).visible = value;
                    pickingScene.getObjectByName("Additional Well " + this.property).visible = value;
                    localStorage.setItem(plot_id + "_" + "Additional Well " + this.property, value);
                    //pickingScene.getObjectByName(this.property).visible = value;
                });
        }
    } else {
        additionalFolder = wellsFolder.__folders['Additional Wells'];
    }

    for (var j = 0; j < addWell.length; j++) {

        var well = addWell[j];
        var curvePoints = [];
        var curvePointsArray = [];
        for (var i = 0; i < well.x.length; i++) {

            curvePointsArray.push((DOWNSAMPLE * well.x[i] - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                (DOWNSAMPLE * well.y[i] - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                (DOWNSAMPLE * well.z[i] - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));

            if (i > 0) {
                curvePointsArray.push(
                    DOWNSAMPLE * well.x[i] - bk_x_min - 0.5 * (bk_x_max - bk_x_min),
                    DOWNSAMPLE * well.y[i] - bk_y_min - 0.5 * (bk_y_max - bk_y_min),
                    DOWNSAMPLE * well.z[i] - bk_z_min - 0.5 * (bk_z_max - bk_z_min));
            }


            curvePoints.push(new THREE.Vector3((DOWNSAMPLE * well.x[i] - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                (DOWNSAMPLE * well.y[i] - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                (DOWNSAMPLE * well.z[i] - bk_z_min - 0.5 * (bk_z_max - bk_z_min))));

        }

        // curve = new THREE.CatmullRomCurve3(curvePoints);
        // points = curve.getPoints( 1000 );
        // addWellGeomDrawn[j] = new THREE.BufferGeometry().setFromPoints( curvePoints );
        // addWellGeomDrawn[j].frustumCulled = false;

        addWellGeomDrawn[j] = new LineSegmentsGeometry().setPositions(curvePointsArray);

        wellMaterial = new LineMaterial({ color: 0x00ff00, linewidth: 2 });
        wellMaterial.resolution.set(parentContainer.width(), parentContainer.height()); // important, for now
        addWellMesh[j] = new LineSegments2(addWellGeomDrawn[j], wellMaterial);
        addWellMesh[j].name = "Additional Well " + well.name;
        addWellMesh[j].frustumCulled = false;
        addWellMesh[j].visible = Boolean(additionalFolder.A[addWell[j].name]);
        scene.remove(scene.getObjectByName(addWellMesh[j].name));
        scene.add(addWellMesh[j]);

        // Configuring Picking Scene;
        if (true) {

            var addWellMaterialPick = new THREE.PointsMaterial({
                size: 1,
                vertexColors: THREE.VertexColors
            });

            addWellMaterialPick.onBeforeCompile = function(shader) {

                shader.vertexShader =
                    `
					uniform float size;
					uniform float scale;
					attribute float alpha;

					#include <common>
					#include <color_pars_vertex>
					#include <fog_pars_vertex>
					#include <morphtarget_pars_vertex>
					#include <logdepthbuf_pars_vertex>
					#include <clipping_planes_pars_vertex>
					void main() {
						#include <color_vertex>
						#include <begin_vertex>
						#include <morphtarget_vertex>
						#include <project_vertex>
						gl_PointSize = size;
						#ifdef USE_SIZEATTENUATION
							bool isPerspective = ( projectionMatrix[ 2 ][ 3 ] == - 1.0 );
							if ( isPerspective ) gl_PointSize *= ( scale / - mvPosition.z );

						#endif
						#include <logdepthbuf_vertex>
						#include <clipping_planes_vertex>
						#include <worldpos_vertex>
						#include <fog_vertex>
					}
					`;

                shader.fragmentShader =
                    `
					uniform vec3 diffuse;
					uniform float opacity;
					#include <common>
					#include <color_pars_fragment>
					#include <map_particle_pars_fragment>
					#include <fog_pars_fragment>
					#include <logdepthbuf_pars_fragment>
					#include <clipping_planes_pars_fragment>
					void main() {
						#include <clipping_planes_fragment>
						vec3 outgoingLight = vec3( 0.0 );
						vec4 diffuseColor = vec4( diffuse, opacity );
						#include <logdepthbuf_fragment>
						#include <map_particle_fragment>
						#include <color_fragment>
						#include <alphatest_fragment>
						outgoingLight = diffuseColor.rgb;
						gl_FragColor = vec4( outgoingLight, diffuseColor.a );
						#include <premultiplied_alpha_fragment>
						#include <tonemapping_fragment>
						#include <encodings_fragment>
						#include <fog_fragment>
					}
					`;

                var materialShader = shader;

            };

            var curvePointsArrayPick = [];
            for (var i = 0; i < well.x.length; i++) {
                curvePointsArrayPick.push(
                    new THREE.Vector3(
                        Number(well.x[i]),
                        Number(well.y[i]),
                        Number(well.z[i])
                    )
                    // (DOWNSAMPLE*well.x[i] - bk_x_min - 0.5*(bk_x_max - bk_x_min)), 
                    // (DOWNSAMPLE*well.y[i] - bk_y_min - 0.5*(bk_y_max - bk_y_min)), 
                    // (DOWNSAMPLE*well.z[i] - bk_z_min - 0.5*(bk_z_max - bk_z_min))
                );
            }

            var curvePick = new THREE.CatmullRomCurve3(curvePointsArrayPick);
            var MAX_POINTS = 10000;
            var pointsPick = curvePick.getPoints(MAX_POINTS);
            addWellGeomPicking[j] = new THREE.BufferGeometry();
            var wellPositions = new Float32Array(MAX_POINTS * 3);
            var colorCoef = new Float32Array(MAX_POINTS * 3);
            var addWellColor = new THREE.Color(0xffffff).convertSRGBToLinear();

            for (var index = 0; index < MAX_POINTS; index++) {

                wellPositions[3 * index] = (DOWNSAMPLE * pointsPick[index].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)); // EXAGGERATION_X*pointsPick[index].x;
                wellPositions[3 * index + 1] = (DOWNSAMPLE * pointsPick[index].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)); //EXAGGERATION_Y*z;
                wellPositions[3 * index + 2] = (DOWNSAMPLE * pointsPick[index].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min));

                var colorTrtWell = addWellColor.setHex(pickingID);
                colorCoef[3 * index] = colorTrtWell.r;
                colorCoef[3 * index + 1] = colorTrtWell.g;
                colorCoef[3 * index + 2] = colorTrtWell.b;

                pickingID = pickingID + 1;

                pickingData[pickingID] = {
                    name: "<b>Additional Well</b><br>" + well.name + "<br>" +
                        "Easting: " + Math.round(pointsPick[index].z).toString() + " ft<br>" +
                        "Northing: " + Math.round(pointsPick[index].x).toString() + " ft<br>" +
                        "Depth (Subsea): " + Math.round(pointsPick[index].y).toString() + " ft",
                    position: new THREE.Vector3(Math.round(pointsPick[index].x), Math.round(pointsPick[index].z), Math.round(pointsPick[index].y))
                };
            }

            addWellGeomPicking[j].setAttribute('position', new THREE.BufferAttribute(wellPositions, 3));
            addWellGeomPicking[j].setAttribute('color', new THREE.BufferAttribute(colorCoef, 3));
            addWellGeomPicking[j].verticesNeedUpdate = true;

            addWellMeshPick[j] = new THREE.Points(addWellGeomPicking[j], addWellMaterialPick);
            addWellMeshPick[j].visible = Boolean(additionalFolder.A["Additional Well " + addWell[j].name]);
            addWellMeshPick[j].name = "Additional Well " + well.name;
            pickingScene.remove(pickingScene.getObjectByName(addWellMeshPick[j].name));
            pickingScene.add(addWellMeshPick[j]);
            pickingID = pickingID + 1;

        }

    }

    if (addWell.length > 0) {
        console.log("Plotted Additional Well");
        updateStatus("Plotted Additional Wells");
    }

}

function plotWellLogs() {

    /////////////////////////////////
    ///// WELL LOG FOLDER       /////
    /////////////////////////////////

    if (wellLog.length > 0) {

        var wellLogFolderMaster = gui.addFolder('Well Logs');
        var wellLogUnique = [];
        for (var i = 0; i < wellLog.length; i++) {
            for (j = 0; j < wellLog[i].length; j++) {
                wellLogUnique.push(wellLog[i][j].name);
            }
        }
        wellLogUnique = wellLogUnique.unique();
        wellLogUnique.sort();

        for (var i = 0; i < wellLogUnique.length; i++) {

            var wellLogFolder = wellLogFolderMaster.addFolder(wellLogUnique[i]);
            wellLogFolder.A = new Array();
            wellLogFolder.A['Size' + wellLogUnique[i]] = 10;
            wellLogFolder.A['color_map' + wellLogUnique[i]] = 'rainbow';

            wellLogFolder.add(wellLogFolder.A, 'Size' + wellLogUnique[i], [2, 4, 6, 8, 10, 12, 14, 16]).name('Size').onChange(function(value) {

                for (var k = 0; k < wellLog.length; k++) {

                    var iScale = [];
                    for (l = 0; l < wellLog[k].length; l++) {
                        if ('Size' + wellLog[k][l].name == this.property) {
                            iScale.push(value / 10, value / 10, value / 10);
                        } else {
                            iScale.push(
                                wellLogGeomDrawn[k].attributes.iSize.array[3 * l],
                                wellLogGeomDrawn[k].attributes.iSize.array[3 * l + 1],
                                wellLogGeomDrawn[k].attributes.iSize.array[3 * l + 2]
                            );
                        }
                    }

                    wellLogGeomDrawn[k].setAttribute('iSize', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                    wellLogGeomPicking[k].setAttribute('iSize', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                }

            });

            wellLogFolder.A['name'] = wellLogUnique[i];

            wellLogFolder.A['visible'] = false;

            wellLogFolder.add(wellLogFolder.A, 'visible', wellLogFolder.A['visible']).name('Show/Hide').onChange(function(value) {

                for (var k = 0; k < wellLog.length; k++) {
                    var iScale = [];
                    for (var l = 0; l < wellLog[k].length; l++) {
                        if (wellLog[k][l].name == this.object['name']) {
                            if (value) {
                                iScale.push(1, 1, 1);
                            } else {
                                iScale.push(0, 0, 0);
                            }
                        } else {
                            iScale.push(
                                wellLogGeomDrawn[k].attributes.iScale.array[3 * l],
                                wellLogGeomDrawn[k].attributes.iScale.array[3 * l + 1],
                                wellLogGeomDrawn[k].attributes.iScale.array[3 * l + 2]
                            );
                        }
                    }
                    wellLogGeomDrawn[k].setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                    wellLogGeomPicking[k].setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                }


                for (var key in wellLogControllers[this.object['name']]) {
                    try {
                        wellLogControllers[this.object['name']][key].setValue(value);
                    } catch (x) {
                        console.log(x);
                        console.log('Key, ', key);
                    }
                }

            });

            wellLogFolder.add(wellLogFolder.A, 'color_map' + wellLogUnique[i], ["rainbow", "cooltowarm", "blackbody", "grayscale"]).name('Color Map').onChange(function(value) {

                for (var k = 0; k < wellLog.length; k++) {
                    console.log("k", k);
                    console.log("Well log[k]", wellLog[k]);
                    var wellLogCount = wellLog[k].length;
                    if (wellLogCount == 0) continue;
                    var minLogValue = wellLog[k][0].value
                    var maxLogValue = wellLog[k][0].value
                    for (var j = 0; j < wellLogCount; j++) {
                        minLogValue = Math.min(minLogValue, wellLog[k][j].value);
                        maxLogValue = Math.max(maxLogValue, wellLog[k][j].value);
                    }
                    var wellLogLut = new Lut(value);
                    var iColors = [];
                    for (var l = 0; l < wellLog[k].length; l++) {
                        if (('color_map' + wellLog[k][l].name) == this.property) {
                            var scale = ((wellLog[k][l].value - minLogValue) / (maxLogValue - minLogValue));
                            var wellLogColor = wellLogLut.getColor(scale);
                            iColors.push(wellLogColor.r, wellLogColor.g, wellLogColor.b);
                        } else {
                            iColors.push(
                                wellLogGeomDrawn[k].attributes.iColor.array[3 * l],
                                wellLogGeomDrawn[k].attributes.iColor.array[3 * l + 1],
                                wellLogGeomDrawn[k].attributes.iColor.array[3 * l + 2]
                            );
                        }
                    }

                    console.log("wellLogGeomDrawn[k]", wellLogGeomDrawn[k]);
                    console.log("iColors", iColors);

                    wellLogGeomDrawn[k].setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
                }


            });




            var wellLogStages = [];
            for (var l = 0; l < wellLog.length; l++) {
                for (var j = 0; j < wellLog[l].length; j++) {
                    if (wellLog[l][j].name == wellLogUnique[i])
                        wellLogStages.push(wellLog[l][j].stage);
                }
            }
            var wellLogStages = wellLogStages.unique();
            wellLogStages.reverse();

            if (wellLogStages.length > 1) {

                wellLogControllers[wellLogUnique[i]] = new Array();
                wellLogFolder.A[wellLogUnique[i]] = new Array();
                wellLogFolder.A[wellLogUnique[i]]['well'] = wellLogUnique[i];

                for (var l = 0; l < wellLogStages.length; l++) {

                    wellLogFolder.A[wellLogUnique[i]][wellLogStages[l]] = false;

                    wellLogControllers[wellLogUnique[i]][wellLogStages[l]] = wellLogFolder.add(wellLogFolder.A[wellLogUnique[i]], wellLogStages[l], wellLogFolder.A[wellLogUnique[i]][wellLogStages[l]])
                        .name(wellLogStages[l])
                        .onChange(function(value) {

                            for (var k = 0; k < wellLog.length; k++) {

                                var iScale = [];
                                for (var m = 0; m < wellLog[k].length; m++) {
                                    if (wellLog[k][m].name == this.object['well'] && wellLog[k][m].stage == this.property) {
                                        if (value) {
                                            iScale.push(1, 1, 1);
                                        } else {
                                            iScale.push(0, 0, 0);
                                        }
                                    } else {
                                        iScale.push(
                                            wellLogGeomDrawn[k].attributes.iScale.array[3 * m],
                                            wellLogGeomDrawn[k].attributes.iScale.array[3 * m + 1],
                                            wellLogGeomDrawn[k].attributes.iScale.array[3 * m + 2]
                                        );
                                    }
                                }

                                wellLogGeomDrawn[k].setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                                wellLogGeomPicking[k].setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

                            }

                        });

                }

            }

        }

        var wellLogMaterial = new THREE.MeshBasicMaterial({
            map: new THREE.TextureLoader().load('https://borehole-seismic-biz.s3.amazonaws.com/receiver.jpg'),
            // color: 0xeeeeee,
            vertexColors: THREE.VertexColors,
            side: THREE.DoubleSide,
            // combine: THREE.MultiplyOperation,
            reflectivity: 0.8,
        });

        wellLogMaterial.onBeforeCompile = function(shader) {

            shader.vertexShader =
                `
				#define LAMBERT
				// instanced
				attribute vec3 iOffset;
				attribute vec3 iColor;
				attribute vec3 iScale;
				attribute vec3 iSize;
				varying vec3 vLightFront;
				varying vec3 vIndirectFront;
				attribute vec4 aInstanceMatrix0;
				attribute vec4 aInstanceMatrix1;
				attribute vec4 aInstanceMatrix2;
				attribute vec4 aInstanceMatrix3;

				#ifdef DOUBLE_SIDED
					varying vec3 vLightBack;
					varying vec3 vIndirectBack;
				#endif
				#include <common>
				#include <uv_pars_vertex>
				#include <uv2_pars_vertex>
				#include <envmap_pars_vertex>
				#include <bsdfs>
				#include <lights_pars_begin>
				#include <color_pars_vertex>
				#include <fog_pars_vertex>
				#include <morphtarget_pars_vertex>
				#include <skinning_pars_vertex>
				#include <shadowmap_pars_vertex>
				#include <logdepthbuf_pars_vertex>
				#include <clipping_planes_pars_vertex>
				void main() {
					#include <uv_vertex>
					#include <uv2_vertex>
					#include <color_vertex>
					#ifdef USE_COLOR
						vColor.xyz = iColor.xyz;
					#endif
					#include <beginnormal_vertex>
					#include <morphnormal_vertex>
					#include <skinbase_vertex>
					#include <skinnormal_vertex>
					#include <defaultnormal_vertex>
					#include <begin_vertex>

					// position instanced
					mat4 aInstanceMatrix = mat4(
						aInstanceMatrix0,
						aInstanceMatrix1,
						aInstanceMatrix2,
						aInstanceMatrix3
					);

					vec3 position_new = position * iSize;
					transformed = (aInstanceMatrix * vec4( position_new , 1. )).xyz;
					transformed *= iScale;

					#include <morphtarget_vertex>
					#include <skinning_vertex>
					#include <project_vertex>
					#include <logdepthbuf_vertex>
					#include <clipping_planes_vertex>
					#include <worldpos_vertex>
					#include <envmap_vertex>
					#include <lights_lambert_vertex>
					#include <shadowmap_vertex>
					#include <fog_vertex>
				}
				`;


            var materialShader = shader;
        };

        for (var counter = 0; counter < wellLog.length; counter++) {

            var wellLogCount = wellLog[counter].length;

            var offsets = [],
                iColors = [],
                iScale = [],
                iSize = [];

            var minLogValue = wellLog[counter][0].value
            var maxLogValue = wellLog[counter][0].value

            for (var i = 0; i < wellLogCount; i++) {
                minLogValue = Math.min(minLogValue, wellLog[counter][i].value);
                maxLogValue = Math.max(maxLogValue, wellLog[counter][i].value);
            }


            var wellLogMatArraySize = 4 * wellLogCount;
            var wellLogMatrixArray = [
                new Float32Array(wellLogMatArraySize),
                new Float32Array(wellLogMatArraySize),
                new Float32Array(wellLogMatArraySize),
                new Float32Array(wellLogMatArraySize),
            ];

            var wellLogLut = new Lut("rainbow");

            for (var i = 0; i < wellLogCount; i++) {

                offsets.push((DOWNSAMPLE * wellLog[counter][i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    (DOWNSAMPLE * wellLog[counter][i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    (DOWNSAMPLE * wellLog[counter][i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));
                var eventTempColor = new THREE.Color(wellLog[counter][i].color).convertSRGBToLinear();

                var scale = ((wellLog[counter][i].value - minLogValue) / (maxLogValue - minLogValue));

                var wellLogColor = wellLogLut.getColor(scale);
                iColors.push(wellLogColor.r, wellLogColor.g, wellLogColor.b);

                iSize.push(1, 1, 1);
                iScale.push(0, 0, 0);

                var wellLogPosition = new THREE.Vector3(
                    (DOWNSAMPLE * wellLog[counter][i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    (DOWNSAMPLE * wellLog[counter][i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    (DOWNSAMPLE * wellLog[counter][i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                );

                var wellLogRotation = new THREE.Euler();
                wellLogRotation.x = -0.5 * Math.PI;

                var wellLogScale = new THREE.Vector3(1, scale, 1);
                var wellLogQuaternion = new THREE.Quaternion();

                var e1 = new THREE.Vector3(1, 0, 0);
                var e2 = new THREE.Vector3(0, 1, 0);
                var e3 = new THREE.Vector3(0, 0, 1);

                var q0 = new THREE.Quaternion();
                q0.setFromAxisAngle(e1, 90 * Math.PI / 180);

                // Define rotation into strike direction
                var q1 = new THREE.Quaternion();
                q1.setFromAxisAngle(e3, (wellLog[counter][i].azimuth) * Math.PI / 180);

                //rotate original coordinate into strike direction to get new coordinate system
                var e1a = e1.clone();
                e1a.applyQuaternion(q1);
                var e2a = e2.clone();
                e2a.applyQuaternion(q1);
                // (*strike direction*)
                var e3a = e3.clone();
                e3a.applyQuaternion(q1);

                // (*Define rotation around strike direction to accomodate dip*)
                var q2 = new THREE.Quaternion();
                e2a.normalize();
                q2.setFromAxisAngle(e2a, -wellLog[counter][i].dip * Math.PI / 180);

                wellLogQuaternion.multiply(q0);
                wellLogQuaternion.multiply(q2);
                wellLogQuaternion.multiply(q1);

                var wellLogMatrix = new THREE.Matrix4();
                wellLogMatrix.compose(wellLogPosition, wellLogQuaternion, wellLogScale);


                for (var r = 0; r < 4; r++) {
                    for (var c = 0; c < 4; c++) {
                        wellLogMatrixArray[r][4 * i + c] = wellLogMatrix.elements[r * 4 + c];
                    }
                }
            }

            var boxGeometry = new THREE.CylinderBufferGeometry(1, 1, 8);

            boxGeometry.frustumCulled = false;
            wellLogGeomDrawn[counter] = new THREE.InstancedBufferGeometry();
            wellLogGeomDrawn[counter].dynamic = true;
            wellLogGeomDrawn[counter].instanceCount = wellLogCount;
            wellLogGeomDrawn[counter].index = boxGeometry.index;
            wellLogGeomDrawn[counter].attributes = boxGeometry.attributes;
            wellLogGeomDrawn[counter].setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
            wellLogGeomDrawn[counter].setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
            wellLogGeomDrawn[counter].setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
            wellLogGeomDrawn[counter].setAttribute('iSize', new THREE.InstancedBufferAttribute(new Float32Array(iSize), 3));

            for (let i = 0; i < wellLogMatrixArray.length; i++) {
                wellLogGeomDrawn[counter].setAttribute(
                    `aInstanceMatrix${i}`,
                    new THREE.InstancedBufferAttribute(wellLogMatrixArray[i], 4)
                );
            }

            var offsets = [],
                iColors = [],
                iScale = [];

            for (var i = 0; i < wellLogCount; i++) {

                offsets.push((DOWNSAMPLE * wellLog[counter][i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    (DOWNSAMPLE * wellLog[counter][i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    (DOWNSAMPLE * wellLog[counter][i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));

                var eventTempColor = new THREE.Color(0xeeeeee).convertSRGBToLinear();
                eventTempColor.setHex(pickingID);
                iColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);

                pickingData[pickingID] = {
                    name: "<b>WellLog</b> - " + wellLog[counter][i].name.toString() + "<br>Easting: " + Math.round(wellLog[counter][i].z).toString() +
                        " ft<br>Northing: " + Math.round(wellLog[counter][i].x).toString() +
                        " ft<br>Depth (Subsea): " + Math.round(wellLog[counter][i].y).toString() +
                        " ft<br>Value - " + Math.round(wellLog[counter][i].value).toString(),
                };

                pickingID = pickingID + 1;

                iScale.push(1, 1, 1);
            }

            var boxGeometry = new THREE.CylinderBufferGeometry(1, 1, 8);
            wellLogGeomPicking[counter] = new THREE.InstancedBufferGeometry();
            wellLogGeomPicking[counter].instanceCount = wellLogCount;
            wellLogGeomPicking[counter].index = boxGeometry.index;
            wellLogGeomPicking[counter].attributes = boxGeometry.attributes;
            wellLogGeomPicking[counter].setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
            wellLogGeomPicking[counter].setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
            wellLogGeomPicking[counter].setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
            wellLogGeomPicking[counter].setAttribute('iSize', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

            var vertexShader = [
                "precision highp float;",
                "",
                "uniform mat4 modelViewMatrix;",
                "uniform mat4 projectionMatrix;",
                "",
                "attribute vec3 position;",
                "attribute vec3 iOffset;",
                "attribute vec3 iColor;",
                "attribute vec3 iScale;",
                "attribute vec3 iSize;",
                "varying vec3 scale;",
                "varying vec3 vColor;",
                "",
                "void main() {",
                "",
                "vec3 position_new = position * iSize * iScale;",
                "	gl_Position = projectionMatrix * modelViewMatrix * vec4( iOffset + position_new, 1.0 );",
                "   scale = iScale;",
                "	vColor = iColor;",
                "",
                "}"
            ].join("\n");
            var fragmentShader = [
                "precision highp float;",
                "varying vec3 vColor;",
                "varying vec3 scale;",
                "",
                "void main() {",
                "",
                "if (scale == vec3(0.0,0.0,0.0)) discard;",
                "	gl_FragColor = vec4(vColor, 1.0);",
                "",
                "}"
            ].join("\n");

            var wellLogMaterialPick = new THREE.RawShaderMaterial({
                uniforms: {},
                vertexShader: vertexShader,
                fragmentShader: fragmentShader,
                side: THREE.DoubleSide,
                transparent: false
            });


            wellLogMesh[counter] = new THREE.Mesh(wellLogGeomDrawn[counter], wellLogMaterial);
            wellLogMesh[counter].frustumCulled = false;
            scene.add(wellLogMesh[counter]);

            wellLogMeshPick[counter] = new THREE.Mesh(wellLogGeomPicking[counter], wellLogMaterialPick);
            wellLogMeshPick[counter].frustumCulled = false;
            pickingScene.add(wellLogMeshPick[counter]);

            console.log("Plotted Well Logs", counter);

        }

        updateStatus("Plotted Well Logs");

    }


}

function plotReceivers() {

    if (rec.length > 0) {

        var receiverFolder = gui.addFolder('Receivers');

        var recToolstrings = [],
            perfStages = [],
            recDesigns = [];

        var recCheckboxes = [
            [
                []
            ]
        ];

        var recControllers = [
            [
                []
            ]
        ];

        recControllers['All Receivers'] = new Array();
        recControllers['All Receivers']['All Receivers'] = localStorage.getItem([plot_id, 'All Receivers', 'All Receivers'].join("_")) == 'false' ? false : true;

        receiverFolder.add(recControllers['All Receivers'], 'All Receivers', recControllers['All Receivers']['All Receivers']).name("All Receivers").onChange(function(value) {

            localStorage.setItem([plot_id, 'All Receivers', 'All Receivers'].join("_"), value);

            var iScale = [];
            for (var i = 0; i < rec.length; i++) {
                if (value) {
                    iScale.push(1, 1, 1);
                } else {
                    iScale.push(0, 0, 0);
                }
            }
            recGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
            recGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

            // Show Hide Values
            var recDesigns = rec.map(e => e.design).unique();

            for (var i2 = 0; i2 < recDesigns.length; i2++) {

                recControllers[recDesigns[i2]][recDesigns[i2]] = value;
                localStorage.setItem([plot_id, recDesigns[i2], recDesigns[i2]].join("_"), value);

                try {
                    recCheckboxes[recDesigns[i2]][recDesigns[i2]].updateDisplay();
                } catch (e) {
                    console.log(e);
                }

                var recToolstringsTemp = rec.filter(e => e.design == recDesigns[i2]).map(e => e.name).unique();

                for (var i = 0; i < recToolstringsTemp.length; i++) {
                    var recToolstringsKeys = Object.keys(recControllers[recDesigns[i2]][recToolstringsTemp[i]]);
                    for (var i1 = 0; i1 < recToolstringsKeys.length; i1++) {
                        recControllers[recDesigns[i2]][recToolstringsTemp[i]][recToolstringsKeys[i1]] = value;
                        localStorage.setItem([plot_id, recDesigns[i2], recToolstringsTemp[i], recToolstringsKeys[i1]].join("_"), value);
                        try {
                            recCheckboxes[recDesigns[i2]][recToolstringsTemp[i]][recToolstringsKeys[i1]].updateDisplay();
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }

            }

        });


        // Get all unique designs
        var recDesigns = rec.map(e => e.design).unique();
        for (var k = 0; k < recDesigns.length; k++) {

            var datasetFolder = receiverFolder.addFolder(recDesigns[k]);

            recCheckboxes[recDesigns[k]] = new Array();
            recControllers[recDesigns[k]] = new Array();
            recControllers[recDesigns[k]][recDesigns[k]] = localStorage.getItem([plot_id, recDesigns[k], recDesigns[k]].join("_")) == 'false' ? false : true;

            recCheckboxes[recDesigns[k]][recDesigns[k]] = datasetFolder.add(
                    recControllers[recDesigns[k]], recDesigns[k], recControllers[recDesigns[k]][recDesigns[k]])
                .name("All Toolstrings").onChange(function(value) {

                    localStorage.setItem([plot_id, this.property, this.property].join("_"), value);

                    var iScale = [];
                    for (var i = 0; i < rec.length; i++) {
                        if (rec[i].design == this.property) {
                            if (value) {
                                iScale.push(1, 1, 1);
                            } else {
                                iScale.push(0, 0, 0);
                            }
                        } else {
                            iScale.push(recGeomDrawn.attributes.iScale.array[3 * i], recGeomDrawn.attributes.iScale.array[3 * i + 1], recGeomDrawn.attributes.iScale.array[3 * i + 2]);
                        }
                    }
                    recGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                    recGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

                    var recToolstringsTemp = rec.filter(e => e.design == this.property).map(e => e.name).unique();

                    for (var i = 0; i < recToolstringsTemp.length; i++) {
                        var recToolstringsKeys = Object.keys(recControllers[this.property][recToolstringsTemp[i]]);
                        for (var i1 = 0; i1 < recToolstringsKeys.length; i1++) {
                            recControllers[this.property][recToolstringsTemp[i]][recToolstringsKeys[i1]] = value;
                            localStorage.setItem([plot_id, this.property, recToolstringsTemp[i], recToolstringsKeys[i1]].join("_"), value);
                            try {
                                recCheckboxes[this.property][recToolstringsTemp[i]][recToolstringsKeys[i1]].updateDisplay();
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }


                });

            recCheckboxes[recDesigns[k]][recDesigns[k]].design = recDesigns[k];

            recToolstrings = rec.filter(e => e.design == recDesigns[k]).map(e => e.name).unique();

            for (j = 0; j < recToolstrings.length; j++) {

                // wellFolder = datasetFolder.addFolder(recToolstrings[j]);

                recCheckboxes[recDesigns[k]][recToolstrings[j]] = new Array();
                recControllers[recDesigns[k]][recToolstrings[j]] = new Array();
                recControllers[recDesigns[k]][recToolstrings[j]][recToolstrings[j]] = localStorage.getItem([plot_id, recDesigns[k], recToolstrings[j], recToolstrings[j]].join("_")) == 'false' ? false : true;

                recCheckboxes[recDesigns[k]][recToolstrings[j]][recToolstrings[j]] = datasetFolder.add(recControllers[recDesigns[k]][recToolstrings[j]], recToolstrings[j], recControllers[recDesigns[k]][recToolstrings[j]][recToolstrings[j]]).name(recToolstrings[j]).onChange(function(value) {


                    localStorage.setItem([plot_id, this.design, this.property, this.property].join("_"), value);

                    var iScale = [];
                    for (var i = 0; i < rec.length; i++) {
                        if (rec[i].name == this.property && rec[i].design == this.design) {
                            if (value) {
                                iScale.push(1, 1, 1);
                            } else {
                                iScale.push(0, 0, 0);
                            }
                        } else {
                            iScale.push(recGeomDrawn.attributes.iScale.array[3 * i], recGeomDrawn.attributes.iScale.array[3 * i + 1], recGeomDrawn.attributes.iScale.array[3 * i + 2]);
                        }
                    }
                    recGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                    recGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));


                    for (var key in recControllers[this.design][this.property]) {
                        try {
                            recControllers[this.design][this.property][key] = value;
                            localStorage.setItem([plot_id, this.design, this.property, key].join("_"), value);
                            recCheckboxes[this.design][this.property][key].updateDisplay();
                        } catch (x) {
                            console.log(x);
                        }
                    }

                });

                recCheckboxes[recDesigns[k]][recToolstrings[j]][recToolstrings[j]].design = recDesigns[k];

            }



        }


        recControllers['size'] = localStorage.getItem('Rec Size') || 20;

        receiverFolder.add(recControllers, 'size', [6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 35, 40]).name("Rec Size").onChange(function(value) {
            localStorage.setItem('Rec Size', value);
            var recSize = [];
            for (var i = 0; i < rec.length; i++) {
                recSize.push(value / 10);
            }
            recGeomDrawn.setAttribute('recSize', new THREE.InstancedBufferAttribute(new Float32Array(recSize), 1));
        });

        recMaterial = new THREE.MeshLambertMaterial({
            vertexColors: THREE.VertexColors,
            side: THREE.DoubleSide
        });

        recMaterial.onBeforeCompile = function(shader) {
            shader.vertexShader =
                `
				#define LAMBERT
				// instanced
				attribute vec3 iOffset;
				attribute vec3 iColor;
                attribute vec3 iScale;
                attribute float recSize;

				attribute vec4 aInstanceMatrix0;
				attribute vec4 aInstanceMatrix1;
				attribute vec4 aInstanceMatrix2;
				attribute vec4 aInstanceMatrix3;

				varying vec3 vIndirectFront;
				varying vec3 vLightFront;
				#ifdef DOUBLE_SIDED
					varying vec3 vLightBack;
					varying vec3 vIndirectBack;
				#endif
				#include <common>
				#include <uv_pars_vertex>
				#include <uv2_pars_vertex>
				#include <envmap_pars_vertex>
				#include <bsdfs>
				#include <lights_pars_begin>
				#include <color_pars_vertex>
				#include <fog_pars_vertex>
				#include <morphtarget_pars_vertex>
				#include <skinning_pars_vertex>
				#include <shadowmap_pars_vertex>
				#include <logdepthbuf_pars_vertex>
				#include <clipping_planes_pars_vertex>
				void main() {
					#include <uv_vertex>
					#include <uv2_vertex>
					#include <color_vertex>
					#ifdef USE_COLOR
						vColor.xyz = iColor.xyz;
					#endif
					#include <beginnormal_vertex>
					#include <morphnormal_vertex>
					#include <skinbase_vertex>
					#include <skinnormal_vertex>
					#include <defaultnormal_vertex>
					#include <begin_vertex>

					mat4 aInstanceMatrix = mat4(
						aInstanceMatrix0 * vec4(recSize/2.0, recSize/2.0, recSize/2.0, 0.0),
						aInstanceMatrix1 * vec4(recSize/2.0, recSize/2.0, recSize/2.0, 0.0),
						aInstanceMatrix2 * vec4(recSize/2.0, recSize/2.0, recSize/2.0, 0.0),
						aInstanceMatrix3
					);

					// position instanced
                    transformed = (aInstanceMatrix * vec4( position , 1. )).xyz;
                    
					transformed *= iScale;
					// transformed = transformed + iOffset;

					// transformed = (aInstanceMatrix * vec4( position , 1. )).xyz;
					// transformed *= iScale;

					#include <morphtarget_vertex>
					#include <skinning_vertex>
					#include <project_vertex>
					#include <logdepthbuf_vertex>
					#include <clipping_planes_vertex>
					#include <worldpos_vertex>
					#include <envmap_vertex>
					#include <lights_lambert_vertex>
					#include <shadowmap_vertex>
					#include <fog_vertex>
				}
				`;

            var materialShader = shader;
        };

        var recMatArraySize = rec.length * 4
        var recMatrixArray = [
            new Float32Array(recMatArraySize),
            new Float32Array(recMatArraySize),
            new Float32Array(recMatArraySize),
            new Float32Array(recMatArraySize),
        ];

        var roffsets = [],
            riColors = [],
            rSize = [],
            riScale = [];
        var recScale = new THREE.Vector3(1, 1, 1);
        var recQuaternion = new THREE.Quaternion();

        for (var recInd = 0; recInd < rec.length; recInd++) {

            rSize.push(recControllers['size'] / 10);


            roffsets.push((DOWNSAMPLE * rec[recInd].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                (DOWNSAMPLE * rec[recInd].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                (DOWNSAMPLE * rec[recInd].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));
            eventTempColor = new THREE.Color(0.95, 0.95, 0.95).convertSRGBToLinear();
            riColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);
            riScale.push(1, 1, 1);


            var recPosition = new THREE.Vector3(
                (DOWNSAMPLE * rec[recInd].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                (DOWNSAMPLE * rec[recInd].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                (DOWNSAMPLE * rec[recInd].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
            );

            var recRotation = new THREE.Euler();
            recRotation.z = Math.PI;
            var recQuaternion = new THREE.Quaternion();

            recQuaternion.setFromEuler(recRotation, false);

            var recMatrix = new THREE.Matrix4();
            recMatrix.compose(recPosition, recQuaternion, recScale);

            for (var r = 0; r < 4; r++) {
                for (var c = 0; c < 4; c++) {
                    recMatrixArray[r][recInd * 4 + c] = recMatrix.elements[r * 4 + c];
                }
            }

        }

        var boxGeometry = new THREE.ConeBufferGeometry(2, 3, 8);
        boxGeometry.frustumCulled = false;
        recGeomDrawn = new THREE.InstancedBufferGeometry();
        recGeomDrawn.index = boxGeometry.index;
        recGeomDrawn.dynamic = true;
        recGeomDrawn.attributes = boxGeometry.attributes;

        recGeomDrawn.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(roffsets), 3));
        recGeomDrawn.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(riColors), 3));
        recGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(riScale), 3));
        recGeomDrawn.setAttribute('recSize', new THREE.InstancedBufferAttribute(new Float32Array(rSize), 1));
        for (let i = 0; i < recMatrixArray.length; i++) {
            recGeomDrawn.setAttribute(
                `aInstanceMatrix${i}`,
                new THREE.InstancedBufferAttribute(recMatrixArray[i], 4)
            );
        }

        recMesh = new THREE.Mesh(recGeomDrawn, recMaterial);
        recMesh.frustumCulled = false;
        scene.add(recMesh);


        var offsets = [],
            iColors = [],
            iScale = [];
        for (var i = 0; i < rec.length; i++) {

            offsets.push((DOWNSAMPLE * rec[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                (DOWNSAMPLE * rec[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                (DOWNSAMPLE * rec[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));

            eventTempColor = new THREE.Color(0xffff00).convertSRGBToLinear();
            eventTempColor.setHex(pickingID);
            iColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);

            pickingData[pickingID] = {
                name: "<b>Receiver from " + rec[i].name.toString() + "</b><br>Easting: " + Math.round(rec[i].z).toString() + " ft<br>Northing: " +
                    Math.round(rec[i].x).toString() + " ft<br>Depth (Subsea): " + Math.round(rec[i].y).toString() + " ft",
                position: new THREE.Vector3(Math.round(rec[i].x), Math.round(rec[i].z), Math.round(rec[i].y))

            };

            pickingID = pickingID + 1;

            iScale.push(1, 1, 1);
        }

        boxGeometry = new THREE.ConeBufferGeometry(2, 3, 8);
        boxGeometry.frustumCulled = false;
        recGeomPicking = new THREE.InstancedBufferGeometry();
        recGeomPicking.index = boxGeometry.index;
        recGeomPicking.dynamic = true;
        recGeomPicking.attributes = boxGeometry.attributes;
        recGeomPicking.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
        recGeomPicking.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
        recGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));


        let vertexShader = [
            "precision highp float;",
            "",
            "uniform mat4 modelViewMatrix;",
            "uniform mat4 projectionMatrix;",
            "",
            "attribute vec3 position;",
            "attribute vec3 iOffset;",
            "attribute vec3 iColor;",
            "attribute vec3 iScale;",
            "varying vec3 scale;",
            "varying vec3 vColor;",

            "",
            "void main() {",
            "",
            "	gl_Position = projectionMatrix * modelViewMatrix * vec4( iOffset + position, 1.0 );",
            "   scale = iScale;",
            "	vColor = iColor;",
            "",
            "}"
        ].join("\n");
        let fragmentShader = [
            "precision highp float;",
            "varying vec3 vColor;",
            "varying vec3 scale;",
            "",
            "void main() {",
            "",
            "if (scale == vec3(0.0,0.0,0.0)) discard;",
            "	gl_FragColor = vec4(vColor, 1.0);",
            "",
            "}"
        ].join("\n");

        recMaterialPick = new THREE.RawShaderMaterial({
            uniforms: {},
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            side: THREE.DoubleSide,
            transparent: false
        });

        recMeshPick = new THREE.Mesh(recGeomPicking, recMaterialPick);
        recMeshPick.frustumCulled = false;
        pickingScene.add(recMeshPick);



        ///////////////////// Restoring old state  //////////////////////

        var recDesigns = rec.map(e => e.design).unique();
        for (var k = 0; k < recDesigns.length; k++) {
            recToolstrings = rec.filter(e => e.design == recDesigns[k]).map(e => e.name).unique();
            for (j = 0; j < recToolstrings.length; j++) {
                recCheckboxes[recDesigns[k]][recToolstrings[j]][recToolstrings[j]].setValue(
                    recControllers[recDesigns[k]][recToolstrings[j]][recToolstrings[j]]
                );
            }
        }

        //perfRotation.x = 0.5 * Math.PI;
        console.log("Plotted Receivers");
        updateStatus("Plotted Receivers");

    }





}

function plotPerforations() {

    if (perf.length > 0) {

        var perfFolder = gui.addFolder('Perfs');

        var perfWells = [],
            perfStages = [],
            perfDatasets = [];

        var perfCheckBoxes = [
            [
                []
            ]
        ];
        var perfControllers = [
            [
                []
            ]
        ];

        perfControllers['All Perfs'] = new Array();

        perfControllers['All Perfs']['All Perfs'] = localStorage.getItem([plot_id, 'All Perfs', 'All Perfs'].join("_")) == 'false' ? false : true;
        perfCheckBoxes['All Perfs'] = perfFolder.add(perfControllers['All Perfs'], 'All Perfs', perfControllers['All Perfs']['All Perfs']).name("All perfs").onChange(function(value) {

            localStorage.setItem([plot_id, 'All Perfs', 'All Perfs'].join("_"), value);

            var iScale = [];
            for (var i = 0; i < perf.length; i++) {
                if (value) {
                    iScale.push(1, 1, 1);
                } else {
                    iScale.push(0, 0, 0);
                }
            }
            perfGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
            perfGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

            var perfDatasets = perf.map(e => e.dataset).unique();

            for (var i2 = 0; i2 < perfDatasets.length; i2++) {

                perfControllers[perfDatasets[i2]][perfDatasets[i2]] = value;
                localStorage.setItem([plot_id, perfDatasets[i2], perfDatasets[i2]].join("_"), value);
                try {
                    perfCheckBoxes[perfDatasets[i2]][perfDatasets[i2]].updateDisplay();
                } catch (e) {
                    console.log(e);
                }
                var perfWellsTemp = [];
                for (var i = 0; i < perf.length; i++) {
                    if (perf[i].dataset == perfDatasets[i2])
                        perfWellsTemp.push(perf[i].well);
                }
                perfWellsTemp = perfWellsTemp.unique();

                for (var i = 0; i < perfWellsTemp.length; i++) {
                    var perfWellsKeys = Object.keys(perfControllers[perfDatasets[i2]][perfWellsTemp[i]]);
                    for (var i1 = 0; i1 < perfWellsKeys.length; i1++) {
                        perfControllers[perfDatasets[i2]][perfWellsTemp[i]][perfWellsKeys[i1]] = value;
                        try {
                            localStorage.setItem([plot_id, perfDatasets[i2], perfWellsTemp[i], perfWellsKeys[i1]].join("_"), value);
                            perfCheckBoxes[perfDatasets[i2]][perfWellsTemp[i]][perfWellsKeys[i1]].updateDisplay();
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }

            }

        });


        // Get all unique datasets
        perfDatasets = perf.map(e => e.dataset).unique();

        for (k = 0; k < perfDatasets.length; k++) {

            var datasetFolder = perfFolder.addFolder(perfDatasets[k]);

            perfCheckBoxes[perfDatasets[k]] = new Array();
            perfControllers[perfDatasets[k]] = new Array();

            if (perfDatasets[k] != "Master") {
                perfControllers[perfDatasets[k]][perfDatasets[k]] = localStorage.getItem([plot_id, perfDatasets[k], perfDatasets[k]].join("_")) == 'true' ? true : false;
            } else {
                perfControllers[perfDatasets[k]][perfDatasets[k]] = localStorage.getItem([plot_id, perfDatasets[k], perfDatasets[k]].join("_")) == 'false' ? false : true; //perfWells[j]);
            }

            perfCheckBoxes[perfDatasets[k]][perfDatasets[k]] = datasetFolder.add(perfControllers[perfDatasets[k]], perfDatasets[k], perfControllers[perfDatasets[k]][perfDatasets[k]]).name("All Wells").onChange(function(value) {

                localStorage.setItem([plot_id, this.property, this.property].join("_"), value);

                var iScale = [];
                for (var i = 0; i < perf.length; i++) {
                    if (perf[i].dataset == this.property) {
                        if (value) {
                            iScale.push(1, 1, 1);
                        } else {
                            iScale.push(0, 0, 0);
                        }
                    } else {
                        iScale.push(perfGeomDrawn.attributes.iScale.array[3 * i], perfGeomDrawn.attributes.iScale.array[3 * i + 1], perfGeomDrawn.attributes.iScale.array[3 * i + 2]);
                    }
                }
                perfGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                perfGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));


                var perfWellsTemp = [];
                for (var i = 0; i < perf.length; i++) {
                    if (perf[i].dataset == this.property)
                        perfWellsTemp.push(perf[i].well);
                }
                perfWellsTemp = perfWellsTemp.unique();


                for (var i = 0; i < perfWellsTemp.length; i++) {
                    var perfWellsKeys = Object.keys(perfControllers[this.property][perfWellsTemp[i]]);
                    for (var i1 = 0; i1 < perfWellsKeys.length; i1++) {
                        perfControllers[this.property][perfWellsTemp[i]][perfWellsKeys[i1]] = value;
                        try {
                            localStorage.setItem([plot_id, this.property, perfWellsTemp[i], perfWellsKeys[i1]].join("_"), value);
                            perfCheckBoxes[this.property][perfWellsTemp[i]][perfWellsKeys[i1]].updateDisplay();
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }

                // perfControllers[this.dataset][this.property][key] = value;
                // perfCheckBoxes[this.dataset][this.property][key].updateDisplay();



            });

            perfCheckBoxes[perfDatasets[k]][perfDatasets[k]].dataset = perfDatasets[k];

            perfWells = [];
            for (var i = 0; i < perf.length; i++) {
                if (perf[i].dataset == perfDatasets[k])
                    perfWells.push(perf[i].well);
            }
            perfWells = perfWells.unique();

            for (var j = 0; j < perfWells.length; j++) {

                var wellFolder = datasetFolder.addFolder(perfWells[j]);

                perfCheckBoxes[perfDatasets[k]][perfWells[j]] = new Array();
                perfControllers[perfDatasets[k]][perfWells[j]] = new Array();




                if (perfDatasets[k] != "Master") {
                    perfControllers[perfDatasets[k]][perfWells[j]][perfWells[j]] = localStorage.getItem([plot_id, perfDatasets[k], perfWells[j], perfWells[j]].join("_")) == 'true' ? true : false; //perfWells[j]);
                } else {
                    perfControllers[perfDatasets[k]][perfWells[j]][perfWells[j]] = localStorage.getItem([plot_id, perfDatasets[k], perfWells[j], perfWells[j]].join("_")) == 'false' ? false : true; //perfWells[j]);
                }

                perfCheckBoxes[perfDatasets[k]][perfWells[j]][perfWells[j]] = wellFolder.add(perfControllers[perfDatasets[k]][perfWells[j]], perfWells[j], perfControllers[perfDatasets[k]][perfWells[j]][perfWells[j]]).name("All Stages").onChange(function(value) {

                    localStorage.setItem([plot_id, this.dataset, this.property, this.property].join("_"), value);

                    var iScale = [];
                    for (var i = 0; i < perf.length; i++) {
                        if (perf[i].well == this.property && perf[i].dataset == this.dataset) {
                            if (value) {
                                iScale.push(1, 1, 1);
                            } else {
                                iScale.push(0, 0, 0);
                            }
                        } else {
                            iScale.push(perfGeomDrawn.attributes.iScale.array[3 * i], perfGeomDrawn.attributes.iScale.array[3 * i + 1], perfGeomDrawn.attributes.iScale.array[3 * i + 2]);
                        }
                    }
                    perfGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                    perfGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));


                    for (var key in perfControllers[this.dataset][this.property]) {
                        try {
                            perfControllers[this.dataset][this.property][key] = value;
                            localStorage.setItem([plot_id, this.dataset, this.property, key].join("_"), value);
                            perfCheckBoxes[this.dataset][this.property][key].updateDisplay();
                        } catch (x) {
                            console.log(x);
                        }
                    }

                });

                perfCheckBoxes[perfDatasets[k]][perfWells[j]][perfWells[j]].dataset = perfDatasets[k];

                // Get all unique stages for this well,
                var perfStages = [];
                for (var i = 0; i < perf.length; i++) {
                    if (perf[i].well == perfWells[j] && perf[i].dataset == perfDatasets[k])
                        perfStages.push(perf[i].name);
                }
                perfStages = perfStages.unique();

                for (var l = 0; l < perfStages.length; l++) {

                    if (perfDatasets[k] != "Master") {
                        perfControllers[perfDatasets[k]][perfWells[j]][perfStages[l]] = localStorage.getItem([plot_id, perfDatasets[k], perfWells[j], perfStages[l]].join("_")) == 'true' ? true : false; //perfWells[j]);
                    } else {
                        perfControllers[perfDatasets[k]][perfWells[j]][perfStages[l]] = localStorage.getItem([plot_id, perfDatasets[k], perfWells[j], perfStages[l]].join("_")) == 'false' ? false : true;
                    }

                    perfCheckBoxes[perfDatasets[k]][perfWells[j]][perfStages[l]] = wellFolder.add(perfControllers[perfDatasets[k]][perfWells[j]], perfStages[l], perfControllers[perfDatasets[k]][perfWells[j]][perfStages[l]]).name(perfStages[l]).onChange(function(value) {

                        localStorage.setItem([plot_id, this.dataset, this.well, this.property].join("_"), value);

                        var iScale = [];
                        for (var i = 0; i < perf.length; i++) {
                            if (perf[i].name == this.property && perf[i].dataset == this.dataset) {
                                if (value) {
                                    iScale.push(1, 1, 1);
                                } else {
                                    iScale.push(0, 0, 0);
                                }
                            } else {
                                iScale.push(perfGeomDrawn.attributes.iScale.array[3 * i], perfGeomDrawn.attributes.iScale.array[3 * i + 1], perfGeomDrawn.attributes.iScale.array[3 * i + 2]);
                            }
                        }
                        perfGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
                        perfGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

                    });

                    perfCheckBoxes[perfDatasets[k]][perfWells[j]][perfStages[l]].dataset = perfDatasets[k];
                    perfCheckBoxes[perfDatasets[k]][perfWells[j]][perfStages[l]].well = perfWells[j];

                }

            }



        }

        perfControllers['Size'] = localStorage.getItem('Perf Size') || 20; //true;

        perfFolder.add(perfControllers, 'Size', [6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 35, 40]).name("Perf Size").onChange(function(value) {

            localStorage.setItem('Perf Size', value);

            var iSize = [];
            for (var i = 0; i < perf.length; i++) {
                iSize.push(value / 10, value / 10, value / 10);
            }
            perfGeomDrawn.setAttribute('iSize', new THREE.InstancedBufferAttribute(new Float32Array(iSize), 3));
            perfGeomPicking.setAttribute('iSize', new THREE.InstancedBufferAttribute(new Float32Array(iSize), 3));

        });

        perfMaterial = new THREE.MeshLambertMaterial({
            map: new THREE.TextureLoader().load('https://borehole-seismic-biz.s3.amazonaws.com/receiver.jpg'),
            // map: new THREE.TextureLoader().load('https://borehole-seismic-biz.s3.amazonaws.com/receiver.jpg'),
            vertexColors: THREE.VertexColors,
            // emissive: 0x666666,
            // emissiveIntensity: 0.3,
            side: THREE.DoubleSide
                // side: THREE.DoubleSide
        });

        perfMaterial.onBeforeCompile = function(shader) {
            shader.vertexShader =
                `
				#define LAMBERT
				// instanced
				attribute vec3 iOffset;
				attribute vec3 iColor;
				attribute vec3 iSize;
                attribute vec3 iScale;
                attribute vec3 iPulsate;
				attribute float iType;
				varying vec3 vLightFront;
				varying vec3 vIndirectFront;

				attribute vec4 aInstanceMatrix0;
				attribute vec4 aInstanceMatrix1;
				attribute vec4 aInstanceMatrix2;
				attribute vec4 aInstanceMatrix3;


				#ifdef DOUBLE_SIDED
					varying vec3 vLightBack;
					varying vec3 vIndirectBack;
				#endif
				#include <common>
				#include <uv_pars_vertex>
				#include <uv2_pars_vertex>
				#include <envmap_pars_vertex>
				#include <bsdfs>
				#include <lights_pars_begin>
				#include <color_pars_vertex>
				#include <fog_pars_vertex>
				#include <morphtarget_pars_vertex>
				#include <skinning_pars_vertex>
				#include <shadowmap_pars_vertex>
				#include <logdepthbuf_pars_vertex>
				#include <clipping_planes_pars_vertex>
				void main() {
					#include <uv_vertex>
					#include <uv2_vertex>
					#include <color_vertex>
                    #ifdef USE_COLOR
                        vColor.xyz = iColor.xyz*vec3(iType, iType, iType);
                    #endif
					#include <beginnormal_vertex>
					#include <morphnormal_vertex>
					#include <skinbase_vertex>
					#include <skinnormal_vertex>
					#include <defaultnormal_vertex>
					#include <begin_vertex>

					// mat4 aInstanceMatrix = mat4(
					// 	aInstanceMatrix0,
					// 	aInstanceMatrix1,
					// 	aInstanceMatrix2,
					// 	aInstanceMatrix3
					// );
					// transformed = (aInstanceMatrix * vec4( position , 1. )).xyz;
                    transformed *= iPulsate;
                    transformed *= iScale;
					transformed *= iSize;
					transformed = transformed + iOffset;

					#include <morphtarget_vertex>
					#include <skinning_vertex>
					#include <project_vertex>
					#include <logdepthbuf_vertex>
					#include <clipping_planes_vertex>
					#include <worldpos_vertex>
					#include <envmap_vertex>


                    vec3 diffuse = vec3( 1.0 );
					GeometricContext geometry;
					geometry.position = mvPosition.xyz;
					geometry.normal = normalize( transformedNormal );
					geometry.viewDir = normalize( -mvPosition.xyz );
					GeometricContext backGeometry;
					backGeometry.position = geometry.position;
					backGeometry.normal = -geometry.normal;
					backGeometry.viewDir = geometry.viewDir;
					vLightFront = vec3( 0.0 );
					vIndirectFront = vec3( 0.0 );
					#ifdef DOUBLE_SIDED
						vLightBack = vec3( 0.0 );
						vIndirectBack = vec3( 0.0 );
					#endif
					IncidentLight directLight;
					float dotNL;
					vec3 directLightColor_Diffuse;

					#if NUM_HEMI_LIGHTS > 0
						#pragma unroll_loop
						for ( int i = 0; i < NUM_HEMI_LIGHTS; i ++ ) {
							vIndirectFront += getHemisphereLightIrradiance( hemisphereLights[ i ], geometry );
							#ifdef DOUBLE_SIDED
								vIndirectBack += getHemisphereLightIrradiance( hemisphereLights[ i ], backGeometry );
							#endif
						}
					#endif


					vLightFront += vec3( 0.75 );
					#ifdef DOUBLE_SIDED
						vLightBack += vec3( 0.75 );
					#endif



					#include <shadowmap_vertex>
					#include <fog_vertex>

                }
				`;


            var materialShader = shader;
        };

        perfCount = perf.length;

        var loader = new STLLoader();
        loader.load('https://borehole-seismic-biz.s3.amazonaws.com/perf_model_dodecahedron.stl', function(boxGeometry) {

            perfMatArraySize = 4 * perfCount;
            var perfMatrixArray = [
                new Float32Array(perfMatArraySize),
                new Float32Array(perfMatArraySize),
                new Float32Array(perfMatArraySize),
                new Float32Array(perfMatArraySize),
            ];
            var perfType = []

            var offsets = [],
                iColors = [],
                iSize = [],
                iScale = [],
                iPulsate = [];

            for (var i = 0; i < perfCount; i++) {
                offsets.push((DOWNSAMPLE * perf[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    (DOWNSAMPLE * perf[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    (DOWNSAMPLE * perf[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));
                eventTempColor = new THREE.Color(perf[i].color).convertSRGBToLinear();
                iColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);

                if (perf[i].dataset == "Master") {
                    perfType.push(1);
                    iScale.push(1, 1, 1);
                    iPulsate.push(1, 1, 1);
                    var perfScale = new THREE.Vector3(1, 1, 1);
                } else {
                    perfType.push(1.5);
                    iPulsate.push(1, 1, 1);
                    iScale.push(0, 0, 0);
                    var perfScale = new THREE.Vector3(1.5, 1.5, 1.5);
                }


                iSize.push(perfControllers['Size'] / 10, perfControllers['Size'] / 10, perfControllers['Size'] / 10);

                var perfPosition = new THREE.Vector3(
                    (DOWNSAMPLE * perf[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    (DOWNSAMPLE * perf[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    (DOWNSAMPLE * perf[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                );

                var perfRotation = new THREE.Euler();
                // perfRotation.x = 0.33 * Math.PI;
                var perfQuaternion = new THREE.Quaternion();

                perfQuaternion.setFromEuler(perfRotation, false);
                var perfMatrix = new THREE.Matrix4();
                perfMatrix.compose(perfPosition, perfQuaternion, perfScale);


                for (var r = 0; r < 4; r++) {
                    for (var c = 0; c < 4; c++) {
                        perfMatrixArray[r][4 * i + c] = perfMatrix.elements[r * 4 + c];
                    }
                }

            }


            boxGeometry.computeFaceNormals();
            console.log("Perf Geometry", boxGeometry);

            // let boxGeometry = new THREE.CylinderBufferGeometry(0.25, 0.25, 2, 6)
            boxGeometry.frustumCulled = false;
            boxGeometry.center();

            perfGeomDrawn = new THREE.InstancedBufferGeometry();
            perfGeomDrawn.dynamic = true;
            perfGeomDrawn.instanceCount = perfCount;
            perfGeomDrawn.index = boxGeometry.index;
            perfGeomDrawn.attributes = boxGeometry.attributes;

            for (var j = 0; j < perfGeomDrawn.attributes.position.array.length; j++) {
                perfGeomDrawn.attributes.position.array[j] *= 0.05;
            }

            perfGeomDrawn.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
            perfGeomDrawn.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
            perfGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
            perfGeomDrawn.setAttribute('iPulsate', new THREE.InstancedBufferAttribute(new Float32Array(iPulsate), 3));

            perfGeomDrawn.setAttribute('iSize', new THREE.InstancedBufferAttribute(new Float32Array(iSize), 3));
            perfGeomDrawn.setAttribute('iType', new THREE.InstancedBufferAttribute(new Float32Array(perfType), 1));

            for (var i = 0; i < perfMatrixArray.length; i++) {
                perfGeomDrawn.setAttribute(
                    `aInstanceMatrix${i}`,
                    new THREE.InstancedBufferAttribute(perfMatrixArray[i], 4)
                );
            }

            perfMesh = new THREE.Mesh(perfGeomDrawn, perfMaterial);
            perfMesh.castShadow = true;
            perfMesh.receiveShadow = true;
            perfMesh.frustumCulled = false;
            scene.add(perfMesh);

            // Set presaved values;
            for (k = 0; k < perfDatasets.length; k++) {
                perfWells = perf.filter(e => e.dataset == perfDatasets[k]).map(e => e.well).unique();
                for (var j = 0; j < perfWells.length; j++) {
                    var perfStages = perf.filter(e => e.well == perfWells[j] && e.dataset == perfDatasets[k]).map(e => e.name).unique();
                    for (var l = 0; l < perfStages.length; l++) {
                        if (perfDatasets[k] == "Master") {
                            perfCheckBoxes[perfDatasets[k]][perfWells[j]][perfStages[l]].setValue(
                                localStorage.getItem([plot_id, perfDatasets[k], perfWells[j], perfStages[l]].join("_")) == 'false' ? false : true
                            );
                        } else {
                            perfCheckBoxes[perfDatasets[k]][perfWells[j]][perfStages[l]].setValue(
                                localStorage.getItem([plot_id, perfDatasets[k], perfWells[j], perfStages[l]].join("_")) == 'true' ? true : false
                            );
                        }
                    }
                }
            }


        }); // Completion of loader


        offsets = [], iColors = [], iScale = [];

        for (var i = 0; i < perfCount; i++) {

            offsets.push((DOWNSAMPLE * perf[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                (DOWNSAMPLE * perf[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                (DOWNSAMPLE * perf[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));

            eventTempColor = new THREE.Color(perf[i].color).convertSRGBToLinear();
            eventTempColor.setHex(pickingID);
            iColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);

            iScale.push(1, 1, 1);

            pickingData[pickingID] = {
                name: "<b>Perforation from " + perf[i].well.toString() + " Stage " + perf[i].stage.toString() + "</b>" + "<br>Easting: " + Math.round(perf[i].z).toString() + " ft<br>Northing: " +
                    Math.round(perf[i].x).toString() + " ft<br>Depth (Subsea): " + Math.round(perf[i].y).toString() + " ft",
                position: new THREE.Vector3(Math.round(perf[i].x), Math.round(perf[i].z), Math.round(perf[i].y))
            };

            if (perf[i].fileName.length > 0) {
                pickingData[pickingID].fileName = perf[i].fileName;
            }

            pickingID = pickingID + 1;
        }

        var boxGeometry = new THREE.OctahedronBufferGeometry(1);
        perfGeomPicking = new THREE.InstancedBufferGeometry();
        perfGeomPicking.instanceCount = perfCount;
        perfGeomPicking.index = boxGeometry.index;
        perfGeomPicking.dynamic = true;
        perfGeomPicking.attributes = boxGeometry.attributes;
        perfGeomPicking.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
        perfGeomPicking.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
        perfGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
        //perfRotation.x = 0.5 * Math.PI;

        var vertexShader = [
            "precision highp float;",
            "",
            "uniform mat4 modelViewMatrix;",
            "uniform mat4 projectionMatrix;",
            "",
            "attribute vec3 position;",
            "attribute vec3 iOffset;",
            "attribute vec3 iColor;",
            "attribute vec3 iScale;",
            "varying vec3 scale;",
            "varying vec3 vColor;",

            "",
            "void main() {",
            "",
            "	gl_Position = projectionMatrix * modelViewMatrix * vec4( iOffset + position, 1.0 );",
            "   scale = iScale;",
            "	vColor = iColor;",
            "",
            "}"
        ].join("\n");
        var fragmentShader = [
            "precision highp float;",
            "varying vec3 vColor;",
            "varying vec3 scale;",
            "",
            "void main() {",
            "",
            "if (scale == vec3(0.0,0.0,0.0)) discard;",
            "	gl_FragColor = vec4(vColor, 1.0);",
            "",
            "}"
        ].join("\n");

        perfMaterialPick = new THREE.RawShaderMaterial({
            uniforms: {},
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            side: THREE.DoubleSide,
            transparent: false
        });

        perfMeshPick = new THREE.Mesh(perfGeomPicking, perfMaterialPick);
        perfMeshPick.frustumCulled = false;
        pickingScene.add(perfMeshPick);

        console.log("Plotted Perfs");
        updateStatus("Plotted Perfs");





    }

}




function plotFaults() {

    if (faults.length == 0 || faults == null) {
        return;
    }

    var faultsFolder = gui.addFolder('Faults');
    faultsFolder.A = new Array();
    faultsFolder.opacity = faultOpacity;
    for (var i = 0; i < faults.length; i++) {
        let key = plot_id + "_Faults_" + faults[i].name;
        if (localStorage.getItem(key) == null || localStorage.getItem(key) == "true") {
            localStorage.setItem(key, true);
            faultsFolder.A[faults[i].name] = true;
        } else {
            faultsFolder.A[faults[i].name] = false;
        }
    }


    let allKey = plot_id + "_Faults_All";
    if (localStorage.getItem(allKey) == null || localStorage.getItem(allKey) == "true") {
        localStorage.setItem(allKey, true);
        faultsFolder.A['All'] = true;
    } else {
        faultsFolder.A['All'] = false;
    }


    faultsFolder.add(faultsFolder.A, 'All', faultsFolder.A['All']).name('All Faults')
        .onChange(function(value) {

            localStorage.setItem(plot_id + "_Faults_All", value);

            for (var i = 0; i < faults.length; i++) {
                scene.getObjectByName("Faults " + faults[i].name).visible = value;
                localStorage.setItem(plot_id + "_Faults_" + faults[i].name, value);
                faultsFolder.A[faults[i].name] = value;
                faultCheckBoxes[faults[i].name].updateDisplay();
            }
        });



    for (var i = 0; i < faults.length; i++) {
        faultCheckBoxes[faults[i].name] = faultsFolder.add(faultsFolder.A, faults[i].name, faultsFolder.A[faults[i].name]).name(textFix(faults[i].name))
            .onChange(function(value) {
                scene.getObjectByName("Faults " + this.property).visible = value;
                localStorage.setItem(plot_id + "_Faults_" + this.property, value);
            });
    }

    faultsFolder.add(faultsFolder, 'opacity', faultsFolder['opacity']).min(0).max(1).step(0.01).name("Opacity")
        .onChange(function(value) {
            for (j = 0; j < faults.length; j++) {
                var opacity = new Float32Array(faults[j].x.length);
                for (k = 0; k < faults[j].x.length; k++) {
                    opacity[k] = value;
                }
                faultGeomDrawn[j].geometry.setAttribute('opacity', new THREE.BufferAttribute(opacity, 1));
            }
        });


    for (j = 0; j < faults.length; j++) {

        var vertices = new Float32Array(faults[j].x.length * 3);
        var size = new Float32Array(faults[j].x.length);
        var opacity = new Float32Array(faults[j].x.length);
        var points = [];
        var colorAttr = new Float32Array(faults[j].x.length * 3);
        var faultColor = new THREE.Color(faults[j].color).convertSRGBToLinear();

        for (k = 0; k < faults[j].x.length; k++) {
            size[k] = 2.0;
            opacity[k] = faultOpacity;
            vertices[3 * k] = (DOWNSAMPLE * faults[j].x[k] - bk_x_min - 0.5 * (bk_x_max - bk_x_min));
            vertices[3 * k + 1] = (DOWNSAMPLE * faults[j].y[k] - bk_y_min - 0.5 * (bk_y_max - bk_y_min));
            vertices[3 * k + 2] = (DOWNSAMPLE * faults[j].z[k] - bk_z_min - 0.5 * (bk_z_max - bk_z_min));

            points.push(new THREE.Vector3(vertices[3 * k], vertices[3 * k + 1], vertices[3 * k + 2]));

            colorAttr[3 * k] = faultColor.r;
            colorAttr[3 * k + 1] = faultColor.g;
            colorAttr[3 * k + 2] = faultColor.b;
        }

        var shaderMaterial = new THREE.ShaderMaterial({
            transparent: true,
            side: THREE.DoubleSide,
            vertexShader: document.getElementById('fault-vertexshader').textContent,
            fragmentShader: document.getElementById('fault-fragmentshader').textContent,
        });

        var geomDelaunator = new THREE.BufferGeometry().setFromPoints(points);
        geomDelaunator.dynamic = true;

        // triangulate x, z
        var indexDelaunay = Delaunator.from(
            points.map(v => {
                return [v.x, v.z];
            })
        );

        var meshIndex = []; // delaunay index => three.js index
        for (let a = 0; a < indexDelaunay.triangles.length; a++) {
            meshIndex.push(indexDelaunay.triangles[a]);
        }
        geomDelaunator.setIndex(meshIndex); // add three.js index to the existing geometry
        geomDelaunator.computeVertexNormals();
        geomDelaunator.computeBoundingBox();
        geomDelaunator.setAttribute('colorAttr', new THREE.BufferAttribute(colorAttr, 3));
        geomDelaunator.setAttribute('vSize', new THREE.BufferAttribute(size, 1));
        geomDelaunator.setAttribute('opacity', new THREE.BufferAttribute(opacity, 1));
        geomDelaunator.dynamic = true;

        faultGeomDrawn[j] = new THREE.Mesh(
            geomDelaunator, // re-use the existing geometry
            shaderMaterial
        );
        faultGeomDrawn[j].frustumCulled = false;
        scene.remove(scene.getObjectByName("Faults " + faults[j].name));
        faultGeomDrawn[j].name = "Faults " + faults[j].name;
        faultGeomDrawn[j].visible = Boolean(faultsFolder.A[faults[j].name]);;
        scene.add(faultGeomDrawn[j]);

    }

    if (faults.length > 0) {
        updateStatus("Plotted Faults.");
    }


}


function plotFractures() {

    if (fracture.length == 0 || fracture == null) {
        return;
    }


    var fractureFolder = gui.addFolder('Fractures');
    fractureFolder.A = new Array();
    var fractureUnique = [];
    for (var i = 0; i < fracture.length; i++) {
        fractureUnique.push(fracture[i].name);
    }
    fractureUnique = fractureUnique.unique();

    for (var i = 0; i < fractureUnique.length; i++) {

        let key = plot_id + "_Fractures_" + fractureUnique[i];
        if (localStorage.getItem(key) == null || localStorage.getItem(key) == "false") {
            localStorage.setItem(key, false);
            fractureFolder.A[fractureUnique[i]] = false;
        } else {
            fractureFolder.A[fractureUnique[i]] = true;
        }

    }


    for (var i = 0; i < fractureUnique.length; i++) {
        fractureCheckBox = fractureFolder.add(fractureFolder.A, fractureUnique[i], fractureFolder.A[i]).onChange(function(value) {

            localStorage.setItem(plot_id + "_Fractures_" + this.property, value);

            var iScale = [];
            for (k = 0; k < fracture.length; k++) {
                if (fracture[k].name == this.property) {
                    if (value) {
                        iScale.push(1, 1, 1);
                    } else {
                        iScale.push(0, 0, 0);
                    }
                } else {
                    iScale.push(fractureGeomDrawn.attributes.iScale.array[3 * k], fractureGeomDrawn.attributes.iScale.array[3 * k + 1], fractureGeomDrawn.attributes.iScale.array[3 * k + 2]);
                }
            }
            fractureGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
            fractureGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

        });
    }


    fractureMaterial = new THREE.MeshLambertMaterial({
        map: new THREE.TextureLoader().load('https://borehole-seismic-biz.s3.amazonaws.com/receiver.jpg'),
        combine: THREE.MultiplyOperation,
        reflectivity: 0.8,
        color: 0x00ff00,
        vertexColors: THREE.VertexColors,
        fog: true,
        transparent: false,
    });

    fractureMaterial.onBeforeCompile = function(shader) {
        shader.vertexShader =
            `
            #define LAMBERT
            // instanced
            attribute vec3 iOffset;
            attribute vec3 iColor;
            attribute vec3 iScale;
            varying vec3 vLightFront;
            varying vec3 vIndirectFront;
            attribute vec4 aInstanceMatrix0;
            attribute vec4 aInstanceMatrix1;
            attribute vec4 aInstanceMatrix2;
            attribute vec4 aInstanceMatrix3;


            #ifdef DOUBLE_SIDED
                varying vec3 vLightBack;
                varying vec3 vIndirectBack;
            #endif
            #include <common>
            #include <uv_pars_vertex>
            #include <uv2_pars_vertex>
            #include <envmap_pars_vertex>
            #include <bsdfs>
            #include <lights_pars_begin>
            #include <color_pars_vertex>
            #include <fog_pars_vertex>
            #include <morphtarget_pars_vertex>
            #include <skinning_pars_vertex>
            #include <shadowmap_pars_vertex>
            #include <logdepthbuf_pars_vertex>
            #include <clipping_planes_pars_vertex>
            void main() {
                #include <uv_vertex>
                #include <uv2_vertex>
                #include <color_vertex>
                #ifdef USE_COLOR
                    vColor.xyz = iColor.xyz;
                #endif
                #include <beginnormal_vertex>
                #include <morphnormal_vertex>
                #include <skinbase_vertex>
                #include <skinnormal_vertex>
                #include <defaultnormal_vertex>
                #include <begin_vertex>

                mat4 aInstanceMatrix = mat4(
                    aInstanceMatrix0,
                    aInstanceMatrix1,
                    aInstanceMatrix2,
                    aInstanceMatrix3
                );
                transformed = (aInstanceMatrix * vec4( position , 1. )).xyz;
                transformed *= iScale;

                //vec3 positionEye = ( modelViewMatrix * aInstanceMatrix * vec4( position, 1.0 ) ).xyz;
                //gl_Position = projectionMatrix * vec4( positionEye, 1.0 );

                #include <morphtarget_vertex>
                #include <skinning_vertex>
                #include <project_vertex>
                #include <logdepthbuf_vertex>
                #include <clipping_planes_vertex>
                #include <worldpos_vertex>
                #include <envmap_vertex>
                #include <lights_lambert_vertex>
                #include <shadowmap_vertex>
                #include <fog_vertex>
            }
            `;


        var materialShader = shader;
    };

    var fractureCount = fracture.length;

    var offsets = [],
        iColors = [],
        iScale = [];

    ////////// TRYING ROTATION //////////////

    var fracMatArraySize = fractureCount * 4
    var fracMatrixArray = [
        new Float32Array(fracMatArraySize),
        new Float32Array(fracMatArraySize),
        new Float32Array(fracMatArraySize),
        new Float32Array(fracMatArraySize),
    ];

    /////////////////////////////////////////

    for (var i = 0; i < fractureCount; i++) {

        offsets.push((DOWNSAMPLE * fracture[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
            (DOWNSAMPLE * fracture[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
            (DOWNSAMPLE * fracture[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));
        var eventTempColor = new THREE.Color(fracture[i].color).convertSRGBToLinear();
        iColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);

        if (Boolean(fractureFolder.A[fracture[i].name])) {
            iScale.push(1, 1, 1);
        } else {
            iScale.push(0, 0, 0);
        }

        var fractureMatrix = new THREE.Matrix4();

        var fracturePosition = new THREE.Vector3(
            (DOWNSAMPLE * fracture[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
            (DOWNSAMPLE * fracture[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
            (DOWNSAMPLE * fracture[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
        );

        var fractureQuaternion = new THREE.Quaternion();


        var e1 = new THREE.Vector3(1, 0, 0);
        var e2 = new THREE.Vector3(0, 1, 0);
        var e3 = new THREE.Vector3(0, 0, 1);

        // Dip taken care of
        var q0 = new THREE.Quaternion();
        q0.setFromAxisAngle(e3, fracture[i].dip * Math.PI / 180);
        fractureQuaternion.multiply(q0);

        // Azimuth figuring
        var q1 = new THREE.Quaternion();
        q1.setFromAxisAngle(e1, fracture[i].azimuth * Math.PI / 180);
        fractureQuaternion.multiply(q1);

        var fractureScale = new THREE.Vector3(1, 1, 1);

        fractureMatrix.compose(fracturePosition, fractureQuaternion, fractureScale);

        for (var r = 0; r < 4; r++) {
            for (var c = 0; c < 4; c++) {
                fracMatrixArray[r][i * 4 + c] = fractureMatrix.elements[r * 4 + c];
            }
        }

    }

    var boxGeometry = new THREE.CylinderBufferGeometry(1, 1, 8);
    boxGeometry.frustumCulled = false;
    var fractureGeomDrawn = new THREE.InstancedBufferGeometry();
    fractureGeomDrawn.instanceCount = fractureCount;
    fractureGeomDrawn.index = boxGeometry.index;
    fractureGeomDrawn.attributes = boxGeometry.attributes;
    fractureGeomDrawn.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
    fractureGeomDrawn.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
    fractureGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

    for (let i = 0; i < fracMatrixArray.length; i++) {
        fractureGeomDrawn.setAttribute(
            `aInstanceMatrix${i}`,
            new THREE.InstancedBufferAttribute(fracMatrixArray[i], 4)
        );
    }


    var fractureMesh = new THREE.Mesh(fractureGeomDrawn, fractureMaterial);
    fractureMesh.frustumCulled = false;
    scene.add(fractureMesh);

    var offsets = [],
        iColors = [],
        iScale = [];

    for (var i = 0; i < fractureCount; i++) {

        offsets.push((DOWNSAMPLE * fracture[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
            (DOWNSAMPLE * fracture[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
            (DOWNSAMPLE * fracture[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));

        var eventTempColor = new THREE.Color(0xeeeeee).convertSRGBToLinear();
        eventTempColor.setHex(pickingID);
        iColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);

        pickingData[pickingID] = {
            name: fracture[i].name.toString() + "<br>Easting: " + Math.round(fracture[i].z).toString() + " ft<br>Northing: " +
                Math.round(fracture[i].x).toString() + " ft<br>Depth (Subsea): " + Math.round(fracture[i].y).toString() + " ft<br>Azimuth: " + Math.round(fracture[i].azimuth).toString() + "'.<br>Dip: " + Math.round(fracture[i].dip).toString() + "'"
        };

        pickingID = pickingID + 1;

        iScale.push(1, 1, 1);
    }


    boxGeometry = new THREE.CylinderBufferGeometry(1, 1, 8);
    fractureGeomPicking = new THREE.InstancedBufferGeometry();
    fractureGeomPicking.instanceCount = fractureCount;
    fractureGeomPicking.index = boxGeometry.index;
    fractureGeomPicking.attributes = boxGeometry.attributes;
    fractureGeomPicking.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
    fractureGeomPicking.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
    fractureGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

    for (let i = 0; i < fracMatrixArray.length; i++) {
        fractureGeomPicking.setAttribute(
            `aInstanceMatrix${i}`,
            new THREE.InstancedBufferAttribute(fracMatrixArray[i], 4)
        );
    }

    let vertexShader = [
        "precision highp float;",
        "",
        "uniform mat4 modelViewMatrix;",
        "uniform mat4 projectionMatrix;",
        "",
        "attribute vec3 position;",
        "attribute vec3 iOffset;",
        "attribute vec3 iColor;",
        "attribute vec3 iScale;",
        "varying vec3 scale;",
        "varying vec3 vColor;",

        "",
        "void main() {",
        "",
        "	gl_Position = projectionMatrix * modelViewMatrix * vec4( iOffset + position, 1.0 );",
        "   scale = iScale;",
        "	vColor = iColor;",
        "",
        "}"
    ].join("\n");
    let fragmentShader = [
        "precision highp float;",
        "varying vec3 vColor;",
        "varying vec3 scale;",
        "",
        "void main() {",
        "",
        "if (scale == vec3(0.0,0.0,0.0)) discard;",
        "	gl_FragColor = vec4(vColor, 1.0);",
        "",
        "}"
    ].join("\n");

    fractureMaterialPick = new THREE.RawShaderMaterial({
        uniforms: {},
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
        side: THREE.DoubleSide,
        transparent: false
    });

    fractureMeshPick = new THREE.Mesh(fractureGeomPicking, fractureMaterialPick);
    fractureMeshPick.frustumCulled = false;
    pickingScene.add(fractureMeshPick);

    console.log("Plotted Fractures");
    updateStatus("Plotted Fractures");



}


function plotRevealData() {

    if (revealData == null || revealData.length == 0) {
        return;
    }


    var revealDataFolder = gui.addFolder('Reveal Data');
    revealDataFolder.A = new Array();
    var revealDataUnique = [];
    for (var i = 0; i < revealData.length; i++) {
        revealDataUnique.push(revealData[i].group);
    }
    revealDataUnique = revealDataUnique.unique();

    for (var i = 0; i < revealDataUnique.length; i++) {
        let key = plot_id + "_Reveal_Data_" + revealDataUnique[i];
        if (localStorage.getItem(key) == null || localStorage.getItem(key) == "false") {
            localStorage.setItem(key, false);
            revealDataFolder.A[revealDataUnique[i]] = false;
        } else {
            revealDataFolder.A[revealDataUnique[i]] = true;
        }
    }

    for (var i = 0; i < revealDataUnique.length; i++) {
        revealDataCheckBox = revealDataFolder.add(revealDataFolder.A, revealDataUnique[i], revealDataFolder.A[i]).onChange(function(value) {
            localStorage.setItem(plot_id + "_Reveal_Data_" + this.property, value);
            iScale = [];
            for (k = 0; k < revealData.length; k++) {
                if (revealData[k].group == this.property) {
                    if (value) {
                        iScale.push(1, 1, 1);
                    } else {
                        iScale.push(0, 0, 0);
                    }
                } else {
                    iScale.push(revealDataGeomDrawn.attributes.iScale.array[3 * k], revealDataGeomDrawn.attributes.iScale.array[3 * k + 1], revealDataGeomDrawn.attributes.iScale.array[3 * k + 2]);
                }
            }
            revealDataGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
            revealDataGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));
            scene.getObjectByName("Reveal Data - " + this.property).visible = value;
            // // Add a picking scene overlay
        });
    }



    revealDataMaterial = new THREE.MeshLambertMaterial({
        vertexColors: THREE.VertexColors,
    });

    revealDataMaterial.onBeforeCompile = function(shader) {
        shader.vertexShader =
            `
            #define LAMBERT
            // instanced
            attribute vec3 iOffset;
            attribute vec3 iColor;
            attribute vec3 iScale;
            varying vec3 vIndirectFront;
            varying vec3 vLightFront;
            #ifdef DOUBLE_SIDED
                varying vec3 vLightBack;
                varying vec3 vIndirectBack;
            #endif
            #include <common>
            #include <uv_pars_vertex>
            #include <uv2_pars_vertex>
            #include <envmap_pars_vertex>
            #include <bsdfs>
            #include <lights_pars_begin>
            #include <color_pars_vertex>
            #include <fog_pars_vertex>
            #include <morphtarget_pars_vertex>
            #include <skinning_pars_vertex>
            #include <shadowmap_pars_vertex>
            #include <logdepthbuf_pars_vertex>
            #include <clipping_planes_pars_vertex>
            void main() {
                #include <uv_vertex>
                #include <uv2_vertex>
                #include <color_vertex>
                #ifdef USE_COLOR
                    vColor.xyz = iColor.xyz;
                #endif
                #include <beginnormal_vertex>
                #include <morphnormal_vertex>
                #include <skinbase_vertex>
                #include <skinnormal_vertex>
                #include <defaultnormal_vertex>
                #include <begin_vertex>
                // position instanced
                transformed = transformed + iOffset;
                transformed *= iScale;
                #include <morphtarget_vertex>
                #include <skinning_vertex>
                #include <project_vertex>
                #include <logdepthbuf_vertex>
                #include <clipping_planes_vertex>
                #include <worldpos_vertex>
                #include <envmap_vertex>
                #include <lights_lambert_vertex>
                #include <shadowmap_vertex>
                #include <fog_vertex>
            }
            `;


        var materialShader = shader;
    };



    let revealDataCount = revealData.length;

    let offsets = [],
        iColors = [],
        iScale = [];

    for (var i = 0; i < revealDataCount; i++) {
        offsets.push((DOWNSAMPLE * revealData[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
            (DOWNSAMPLE * revealData[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
            (DOWNSAMPLE * revealData[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));
        eventTempColor = new THREE.Color('rgba(240, 255, 0, 1.0)').convertSRGBToLinear();
        iColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);
        if (Boolean(revealDataFolder.A[revealData[i].group])) {
            iScale.push(1, 1, 1);
        } else {
            iScale.push(0, 0, 0);
        }
    }

    let boxGeometry = new THREE.ConeBufferGeometry(2, 3, 8);
    boxGeometry.frustumCulled = false;
    revealDataGeomDrawn = new THREE.InstancedBufferGeometry();
    revealDataGeomDrawn.index = boxGeometry.index;
    revealDataGeomDrawn.attributes = boxGeometry.attributes;
    revealDataGeomDrawn.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
    revealDataGeomDrawn.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
    revealDataGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

    revealDataMesh = new THREE.Mesh(revealDataGeomDrawn, revealDataMaterial);
    revealDataMesh.frustumCulled = false;
    scene.add(revealDataMesh);

    iColors = [];
    for (var i = 0; i < revealDataCount; i++) {

        eventTempColor = new THREE.Color(0xffff00).convertSRGBToLinear();
        eventTempColor.setHex(pickingID);
        iColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);

        pickingData[pickingID] = {
            name: "<b>Reveal Data</b> - " + revealData[i].group.toString() + "<br>Easting: " + Math.round(revealData[i].z).toString() + " ft<br>Northing: " +
                Math.round(revealData[i].x).toString() + " ft<br>Depth (Subsea): " + Math.round(revealData[i].y).toString() + " ft"
        };

        pickingID = pickingID + 1;
    }

    boxGeometry = new THREE.ConeBufferGeometry(2, 3, 8);
    boxGeometry.frustumCulled = false;
    revealDataGeomPicking = new THREE.InstancedBufferGeometry();
    revealDataGeomPicking.index = boxGeometry.index;
    revealDataGeomPicking.attributes = boxGeometry.attributes;
    revealDataGeomPicking.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
    revealDataGeomPicking.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
    revealDataGeomPicking.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));


    let vertexShader = [
        "precision highp float;",
        "",
        "uniform mat4 modelViewMatrix;",
        "uniform mat4 projectionMatrix;",
        "",
        "attribute vec3 position;",
        "attribute vec3 iOffset;",
        "attribute vec3 iColor;",
        "attribute vec3 iScale;",
        "varying vec3 scale;",
        "varying vec3 vColor;",

        "",
        "void main() {",
        "",
        "	gl_Position = projectionMatrix * modelViewMatrix * vec4( iOffset + position, 1.0 );",
        "   scale = iScale;",
        "	vColor = iColor;",
        "",
        "}"
    ].join("\n");
    let fragmentShader = [
        "precision highp float;",
        "varying vec3 vColor;",
        "varying vec3 scale;",
        "",
        "void main() {",
        "",
        "if (scale == vec3(0.0,0.0,0.0)) discard;",
        "	gl_FragColor = vec4(vColor, 1.0);",
        "",
        "}"
    ].join("\n");

    revealDataMaterialPick = new THREE.RawShaderMaterial({
        uniforms: {},
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
        side: THREE.DoubleSide,
        transparent: false
    });

    revealDataMeshPick = new THREE.Mesh(revealDataGeomPicking, revealDataMaterialPick);
    revealDataMeshPick.frustumCulled = false;
    pickingScene.add(revealDataMeshPick);

    console.log("Plotted Reveal Data Points");
    updateStatus("Plotted Reveal Data Points");

    ////////////////////////////////
    /////// REVEAL DATA LINE ///////
    ////////////////////////////////

    var revealDataUnique = [];
    for (var i = 0; i < revealData.length; i++) {
        revealDataUnique.push(revealData[i].group);
    }
    revealDataUnique = revealDataUnique.unique();

    for (j = 0; j < revealDataUnique.length; j++) {

        let curvePoints = [];
        for (var i = 0; i < revealData.length; i++) {
            if (revealData[i].group == revealDataUnique[j]) {
                curvePoints.push(
                    new THREE.Vector3(
                        DOWNSAMPLE * revealData[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min),
                        DOWNSAMPLE * revealData[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min),
                        DOWNSAMPLE * revealData[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min)
                    )
                );
            }
        }

        var curve = new THREE.CatmullRomCurve3(curvePoints);
        var points = curve.getPoints(1000);
        var geometry = new THREE.BufferGeometry().setFromPoints(points);
        var material = new THREE.ShaderMaterial({
            uniforms: {
                color: { value: new THREE.Color(0x00ee00).convertSRGBToLinear() },
            },
            transparent: false,
            vertexShader: document.getElementById('well-vertexshader').textContent,
            fragmentShader: document.getElementById('well-fragmentshader').textContent,
        });




        // Create the final object to add to the scene
        let tempVar = new THREE.Line(geometry, material);
        tempVar.name = "Reveal Data - " + revealDataUnique[j];

        if (Boolean(revealDataFolder.A[revealDataUnique[j]])) {
            tempVar.visible = true;
        } else {
            tempVar.visible = false;
        }


        scene.add(tempVar);

        console.log("Plotted Reveal Data Lines");
        updateStatus("Plotted Reveal Data Lines");



    }

}


function updateGridGeometry() {

    var grid_x_min = -EXAGGERATION_X * gridXScale * (bk_x_max - bk_x_min),
        grid_x_max = EXAGGERATION_X * gridXScale * (bk_x_max - bk_x_min),
        grid_x_div = EXAGGERATION_X * DOWNSAMPLE * gridSize;
    var grid_y_min = -EXAGGERATION_Y * gridZScale * (bk_y_max - bk_y_min),
        grid_y_max = EXAGGERATION_Y * gridZScale * (bk_y_max - bk_y_min),
        grid_y_div = EXAGGERATION_Y * DOWNSAMPLE * gridSize;
    var grid_z_min = -EXAGGERATION_Z * gridYScale * (bk_z_max - bk_z_min),
        grid_z_max = EXAGGERATION_Z * gridYScale * (bk_z_max - bk_z_min),
        grid_z_div = EXAGGERATION_Z * DOWNSAMPLE * gridSize;

    var grid_positions = [];

    for (var x = grid_x_min; x <= grid_x_max; x += grid_x_div) {
        grid_positions.push(x, grid_y_min, grid_z_min);
        grid_positions.push(x, grid_y_min, grid_z_max);
        grid_positions.push(x, grid_y_max, grid_z_max);
        grid_positions.push(x, grid_y_max, grid_z_min);
        grid_positions.push(x, grid_y_min, grid_z_min);
    }

    for (var y = grid_y_min; y <= grid_y_max; y += grid_y_div) {
        grid_positions.push(grid_x_min, y, grid_z_min);
        grid_positions.push(grid_x_min, y, grid_z_max);
        grid_positions.push(grid_x_max, y, grid_z_max);
        grid_positions.push(grid_x_max, y, grid_z_min);
        grid_positions.push(grid_x_min, y, grid_z_min);
    }

    for (var z = grid_z_min; z <= grid_z_max; z += grid_z_div) {
        grid_positions.push(grid_x_min, grid_y_min, z);
        grid_positions.push(grid_x_min, grid_y_max, z);
        grid_positions.push(grid_x_max, grid_y_max, z);
        grid_positions.push(grid_x_max, grid_y_min, z);
        grid_positions.push(grid_x_min, grid_y_min, z);
    }

    gridGeometry.setAttribute('position', new THREE.Float32BufferAttribute(grid_positions, 3));

}


function plotModelRec(rec_model_index) {

    console.log(wellModelObjects[rec_model_index]);

    var noRec = Number(wellModelObjects[rec_model_index].noRec);
    if (noRec == 0) return;
    console.log("No rec - " + noRec);

    // distRec = wellModelObjects[rec_model_index].distanceBetweenReceivers.replace(" ", "").replace("[", "").replace("]", "").split(',');
    // if (distRec.length != noRec - 1) {
    // 	alert('check dist between receivers');
    // 	return;
    // }
    // console.log("distRec - " + distRec);

    var distRec = Number(wellModelObjects[rec_model_index].distanceBetweenReceivers);
    console.log("distRec - " + distRec);

    if (isNaN(distRec)) {
        alert("Check distance between receivers.");
        return;
    }

    var startingRecDepth = wellModelObjects[rec_model_index].startingDepth;
    if (startingRecDepth < 0) {
        alert('Starting depth cannot be less than 0');
        return;
    }

    console.log("startingRecDepth - " + startingRecDepth);

    var recDepths = []
    recDepths[0] = Number(startingRecDepth);
    for (var i = 1; i < noRec; i++) {
        recDepths[i] = recDepths[i - 1] + distRec; //parseFloat(distRec[i-1])
    }

    console.log("recDepths - " + recDepths);

    var wellName = wellModelObjects[rec_model_index].wellName;

    for (var i = 0; i < mtrWell.length; i++) {
        if (mtrWell[i].name == wellName) {
            var well = JSON.parse(JSON.stringify(mtrWell[i]));
            break;
        }
    }

    for (var i = 0; i < trtWell.length; i++) {
        if (trtWell[i].name == wellName) {
            var well = JSON.parse(JSON.stringify(trtWell[i]));
            break;
        }
    }

    for (var i = 0; i < addWell.length; i++) {
        if (addWell[i].name == wellName) {
            var well = JSON.parse(JSON.stringify(addWell[i]));
            break;
        }
    }

    // const well = mtrWell.filter(mwell => mwell.name == wellModelObjects[rec_model_index].wellName)[0];
    console.log("for-start");

    var recModel = [];
    for (var rec = 0; rec < noRec; rec++) {
        recModel[rec] = findPointOnLine(well.x, well.y, well.z, recDepths[rec]);
        console.log("Plotted point found -" + rec);
    }
    wellModelObjects[rec_model_index].recModel = recModel;

    console.log("for-end");
    console.log(recModel);

    //////////////////////////////////
    ////// PLOTTING STARTS HERE //////		
    //////////////////////////////////

    try {

        var selectedObject1 = scene.getObjectByName("Rec Model" + wellModelObjects[rec_model_index].index);
        scene.remove(selectedObject1);

        var selectedObject2 = pickingScene.getObjectByName("Rec Model" + wellModelObjects[rec_model_index].index);
        pickingScene.remove(selectedObject2);
    } catch (x) {
        console.log(x);
    }


    var recModelMaterial = new THREE.MeshLambertMaterial({
        map: new THREE.TextureLoader().load('https://borehole-seismic-biz.s3.amazonaws.com/event.jpg'),
        side: THREE.DoubleSide,
        vertexColors: THREE.VertexColors,
        color: 0xeeeeee,
        flatShading: false,
        shininess: 0,
        transparent: true,
        opacity: 1,
    });

    recModelMaterial.onBeforeCompile = function(shader) {
        shader.vertexShader =
            `
            #define LAMBERT
            // instanced
            attribute vec3 iOffset;
            attribute vec3 iColor;
            attribute vec3 iScale;
            attribute float recModelSize;
    
            attribute vec4 aInstanceMatrix0;
            attribute vec4 aInstanceMatrix1;
            attribute vec4 aInstanceMatrix2;
            attribute vec4 aInstanceMatrix3;
    
            varying vec3 vIndirectFront;
            varying vec3 vLightFront;
            #ifdef DOUBLE_SIDED
                varying vec3 vLightBack;
                varying vec3 vIndirectBack;
            #endif
            #include <common>
            #include <uv_pars_vertex>
            #include <uv2_pars_vertex>
            #include <envmap_pars_vertex>
            #include <bsdfs>
            #include <lights_pars_begin>
            #include <color_pars_vertex>
            #include <fog_pars_vertex>
            #include <morphtarget_pars_vertex>
            #include <skinning_pars_vertex>
            #include <shadowmap_pars_vertex>
            #include <logdepthbuf_pars_vertex>
            #include <clipping_planes_pars_vertex>
            void main() {
                #include <uv_vertex>
                #include <uv2_vertex>
                #include <color_vertex>
                #ifdef USE_COLOR
                    vColor.xyz = iColor.xyz;
                #endif
                #include <beginnormal_vertex>
                #include <morphnormal_vertex>
                #include <skinbase_vertex>
                #include <skinnormal_vertex>
                #include <defaultnormal_vertex>
                #include <begin_vertex>
    
                mat4 aInstanceMatrix = mat4(
                    aInstanceMatrix0 * vec4(recModelSize, recModelSize, recModelSize, 0.0),
                    aInstanceMatrix1 * vec4(recModelSize, recModelSize, recModelSize, 0.0),
                    aInstanceMatrix2 * vec4(recModelSize, recModelSize, recModelSize, 0.0),
                    aInstanceMatrix3
                );
    
                // position instanced
                // transformed *= iScale;
                // transformed = transformed + iOffset;
    
                transformed = (aInstanceMatrix * vec4( position , 1. )).xyz;
                transformed *= iScale;
    
                #include <morphtarget_vertex>
                #include <skinning_vertex>
                #include <project_vertex>
                #include <logdepthbuf_vertex>
                #include <clipping_planes_vertex>
                #include <worldpos_vertex>
                #include <envmap_vertex>
                #include <lights_lambert_vertex>
                #include <shadowmap_vertex>
                #include <fog_vertex>
            }
            `;

        var materialShader = shader;
    };

    var recModelMatArraySize = recModel.length * 4
    var recModelMatrixArray = [
        new Float32Array(recModelMatArraySize),
        new Float32Array(recModelMatArraySize),
        new Float32Array(recModelMatArraySize),
        new Float32Array(recModelMatArraySize),
    ];

    var rModeloffsets = [],
        rModeliColors = [],
        rModelSize = [],
        rModeliScale = [];
    var recModelScale = new THREE.Vector3(1, 1, 1);
    var recModelQuaternion = new THREE.Quaternion();

    for (var recModelInd = 0; recModelInd < recModel.length; recModelInd++) {

        rModelSize.push(1.0);
        rModeloffsets.push((DOWNSAMPLE * recModel[recModelInd].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
            (DOWNSAMPLE * recModel[recModelInd].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
            (DOWNSAMPLE * recModel[recModelInd].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min)));
        var recModelTempColor = new THREE.Color(0.878, 0.878, 0.878).convertSRGBToLinear();
        rModeliColors.push(recModelTempColor.r, recModelTempColor.g, recModelTempColor.b);
        rModeliScale.push(1, 1, 1);

        var recModelPosition = new THREE.Vector3(
            (DOWNSAMPLE * recModel[recModelInd].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
            (DOWNSAMPLE * recModel[recModelInd].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
            (DOWNSAMPLE * recModel[recModelInd].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
        );

        var recModelRotation = new THREE.Euler();
        recModelRotation.x = 0.5 * Math.PI;
        recModelRotation.y = 0.5 * Math.PI;
        recModelRotation.z = 0.5 * Math.PI;

        var recModelQuaternion = new THREE.Quaternion().setFromEuler(recModelRotation, false);

        var recModelMatrix = new THREE.Matrix4();
        recModelMatrix.compose(recModelPosition, recModelQuaternion, recModelScale);


        for (var r = 0; r < 4; r++) {
            for (var c = 0; c < 4; c++) {
                recModelMatrixArray[r][recModelInd * 4 + c] = recModelMatrix.elements[r * 4 + c];
            }
        }

    }

    console.log("rModeliScale", rModeliScale);
    console.log("rModeloffsets", rModeloffsets);
    console.log("recModelPosition", recModelPosition);


    var recModelGeomLoaded = new THREE.ConeBufferGeometry(2, 3, 8);

    recModelGeomLoaded.center();
    recModelGeomLoaded.frustumCulled = false;
    var recModelGeomDrawn = new THREE.InstancedBufferGeometry();
    recModelGeomDrawn.index = recModelGeomLoaded.index;
    recModelGeomDrawn.dynamic = true;
    recModelGeomDrawn.attributes = recModelGeomLoaded.attributes;
    recModelGeomDrawn.instanceCount = recModel.length;

    recModelGeomDrawn.setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(rModeloffsets), 3));
    recModelGeomDrawn.setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(rModeliColors), 3));
    recModelGeomDrawn.setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(rModeliScale), 3));
    recModelGeomDrawn.setAttribute('recModelSize', new THREE.InstancedBufferAttribute(new Float32Array(rModelSize), 1));


    for (let i = 0; i < recModelMatrixArray.length; i++) {
        recModelGeomDrawn.setAttribute(
            `aInstanceMatrix${i}`,
            new THREE.InstancedBufferAttribute(recModelMatrixArray[i], 4)
        );
    }

    var recModelMesh = new THREE.Mesh(recModelGeomDrawn, recModelMaterial);
    recModelMesh.name = "Rec Model" + wellModelObjects[rec_model_index].index;
    recModelMesh.frustumCulled = false;
    scene.add(recModelMesh);



    //////////////////////////////////
    ////// PLOTTING ENDS HERE ////////		
    //////////////////////////////////

    return true;

}


function findPointOnLine(x, y, z, md) {

    md = Number(md);
    var cordinates = {
        md: md,
        x: 0,
        y: 0,
        z: 0
    };

    var distance = 0;
    var breakPoint = 0;
    for (var i = 0; i < x.length; i++) {
        var temp = Math.sqrt(Math.pow(Number(x[i + 1]) - Number(x[i]), 2) + Math.pow(Number(y[i + 1]) - Number(y[i]), 2) + Math.pow(Number(z[i + 1]) - Number(z[i]), 2));
        // console.log(temp);
        if (md <= (distance + temp)) {
            breakPoint = i;
            break;
        }
        distance = distance + temp;
    }

    if (breakPoint == x.length - 1) {
        breakPoint = breakPoint - 1;
    }

    var diffX = (Number(x[breakPoint + 1]) - Number(x[breakPoint]));
    var diffY = (Number(y[breakPoint + 1]) - Number(y[breakPoint]));
    var diffZ = (Number(z[breakPoint + 1]) - Number(z[breakPoint]));
    var prevMD = distance;
    var newMD = distance + temp;

    cordinates.x = (md - prevMD) * (diffX / (newMD - prevMD)) + Number(x[breakPoint]);
    cordinates.y = (md - prevMD) * (diffY / (newMD - prevMD)) + Number(y[breakPoint]);
    cordinates.z = (md - prevMD) * (diffZ / (newMD - prevMD)) + Number(z[breakPoint]);

    if (false) {
        for (var j = 1; j < 10000; j++) {
            var temp = Math.sqrt(Math.pow(j * 0.0001 * diffX, 2) + Math.pow(j * 0.0001 * diffY, 2) + Math.pow(j * 0.0001 * diffZ, 2));
            if (md < distance + temp) {
                console.log("Breakpoint - " + (distance + temp) + " for MD " + md);
                cordinates.x = Number(x[breakPoint]) + j * 0.0001 * diffX;
                cordinates.y = Number(y[breakPoint]) + j * 0.0001 * diffY;
                cordinates.z = Number(z[breakPoint]) + j * 0.0001 * diffZ;
                break;
            }
        }
    }


    return cordinates;
}

function Parameters() {
    this.rotationx = 0;
    this.rotationy = 0;
    this.rotationz = 0;
}

function applyVertexColors(geometry, color) {

    var position = geometry.attributes.position;
    var colors = [];

    for (var i = 0; i < position.count; i++) {
        colors.push(color.r, color.g, color.b);
    }

    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
    geometry.setAttribute('customColor', new THREE.Float32BufferAttribute(colors, 3));

}


function myTrim(x) {
    return x;
}


function onMouseMove(e) {

    // if (drag) return;

    mouse.x = e.clientX;
    mouse.y = e.clientY;

    if (!document.getElementById("hoverText").innerHTML.startsWith("Azimuth:")) {
        document.getElementById("hoverText").innerHTML = "";
        if (project_name != "") {
            document.getElementById("hoverText").innerHTML += "<b>" + project_name + "</b><br>";
        }

        if (plot_title != "") {
            document.getElementById("hoverText").innerHTML += "<b>" + plot_title + "</b><br>";
        }
    }



    Plotly.purge(document.getElementById("measurementText"));

    var initDiv = document.getElementById("info");
    if (initDiv) {
        initDiv.parentNode.removeChild(initDiv);
    }

    if (editMode) {

        let container = $("#container1");

        var relX = -container.offset().left + mouse.x - 0.5 * (container.width() - pickingTexture.width);
        var relY = mouse.y - container.offset().top; // + container.height() - ;

        TweenMax.to(document.getElementById("eventSelector"), 0.1, { x: relX, y: relY, xPercent: -50, yPercent: -50 });

        if (e.shiftKey) {

            renderer.setRenderTarget(pickingTexture);

            for (let n2 = 0; n2 < SCENE_ROWS * SCENE_COLS; n2++) {

                var leftPick = sceneView[n2].maxWidth * sceneView[n2].left;
                var bottomPick = sceneView[n2].maxHeight * sceneView[n2].bottom;
                var widthPick = sceneView[n2].maxWidth * sceneView[n2].width;
                var heightPick = sceneView[n2].maxHeight * sceneView[n2].height

                if (!isNaN(sceneView[n2].aspect) && Number(sceneView[n2].aspect) != 0) {

                    var newHeightPick = widthPick / Number(sceneView[n2].aspect);
                    if (newHeightPick > heightPick) {
                        var newWidthPick = heightPick * sceneView[n2].aspect
                        leftPick = leftPick + Math.abs(0.5 * (newWidthPick - widthPick));
                        widthPick = newWidthPick;
                    } else {
                        bottomPick = bottomPick + Math.abs(0.5 * (newHeightPick - heightPick));
                        heightPick = newHeightPick;
                    }
                }

                renderer.setViewport(leftPick, bottomPick, widthPick, heightPick);
                renderer.setScissor(leftPick, bottomPick, widthPick, heightPick);
                // renderer.setScissorTest( true );
                renderer.setScissorTest(SCENE_ROWS * SCENE_COLS == 1 ? false : true);

                renderer.render(pickingScene, sceneView[n2].camera_type == 0 ? sceneView[n2].oCamera : sceneView[n2].pCamera);
            }


            let w = editEventsFolderValues['cursorSize'],
                h = editEventsFolderValues['cursorSize'];
            var pixelBuffer = new Uint8Array(4 * w * h);

            let iVisible = eventGeomDrawn.attributes.iVisible.array;

            renderer.readRenderTargetPixels(pickingTexture, -parentContainer.offset().left + mouse.x - 0.5 * (parentContainer.width() - pickingTexture.width) - w / 2,
                parentContainer.offset().top + parentContainer.height() - mouse.y - h / 2, w, h,
                pixelBuffer);

            for (let pixelBufferId = 0; pixelBufferId < (Number(w) * Number(h)); pixelBufferId++) {
                var id = (pixelBuffer[4 * pixelBufferId] << 16) | (pixelBuffer[4 * pixelBufferId + 1] << 8) | (pixelBuffer[4 * pixelBufferId + 2]);
                if (id == 0) { continue; }
                var data = pickingData[id];
                // console.log("Dataset", events[data.eventIndex].dataset);
                if (data && data.eventIndex && events[data.eventIndex].dataset == editEventsFolderValues['dataset']) {
                    iVisible[3 * data.eventIndex] = 0;
                    iVisible[3 * data.eventIndex + 1] = 0;
                    iVisible[3 * data.eventIndex + 2] = 0;
                }
            }

            eventGeomDrawn.setAttribute('iVisible', new THREE.InstancedBufferAttribute(iVisible, 3));
            eventGeomPicking.setAttribute('iVisible', new THREE.InstancedBufferAttribute(iVisible, 3));

        }



    }


}




function onMouseClick(e) {

    mouse.x = e.clientX;
    mouse.y = e.clientY;

    var initDiv = document.getElementById("info");
    if (initDiv) {
        initDiv.parentNode.removeChild(initDiv);
    }

    renderer.setRenderTarget(pickingTexture);

    for (let n = 0; n < SCENE_ROWS * SCENE_COLS; n++) {

        var left = sceneView[n].maxWidth * sceneView[n].left;
        var bottom = sceneView[n].maxHeight * sceneView[n].bottom;
        var width = sceneView[n].maxWidth * sceneView[n].width;
        var height = sceneView[n].maxHeight * sceneView[n].height

        if (!isNaN(sceneView[n].aspect) && Number(sceneView[n].aspect) != 0) {

            var new_height = width / Number(sceneView[n].aspect);
            if (new_height > height) {
                var new_width = height * sceneView[n].aspect
                left = left + Math.abs(0.5 * (new_width - width));
                width = new_width;
            } else {
                bottom = bottom + Math.abs(0.5 * (new_height - height));
                height = new_height;
            }
        }

        renderer.setViewport(left, bottom, width, height);
        renderer.setScissor(left, bottom, width, height);
        renderer.setScissorTest(SCENE_ROWS * SCENE_COLS == 1 ? false : true);
        renderer.render(pickingScene, sceneView[n].camera_type == 0 ? sceneView[n].oCamera : sceneView[n].pCamera);
    }

    var pixelBuffer = new Uint8Array(4);
    renderer.readRenderTargetPixels(pickingTexture, -parentContainer.offset().left + mouse.x - 0.5 * (parentContainer.width() - pickingTexture.width), parentContainer.offset().top + parentContainer.height() - mouse.y, 1, 1, pixelBuffer);
    // renderer.readRenderTargetPixels(pickingTexture, parentContainer.offset().left + mouse.x - 0.5 * (parentContainer.width() - pickingTexture.width), parentContainer.offset().top + parentContainer.height() - mouse.y, 1, 1, pixelBuffer);

    var id = (pixelBuffer[0] << 16) | (pixelBuffer[1] << 8) | (pixelBuffer[2]);
    var data = pickingData[id];

    if (!data || !data.name) {
        $("#tooltip").hide(10);
        return;
    }

    if (measurementMode) {

        if (data) {

            if (data.name.indexOf("<b>Monitor Well") != -1 || data.name.indexOf("<b>Treatment Well") != -1 || data.name.indexOf("<b>Event") != -1 || data.name.indexOf("<b>Receiver") != -1 || data.name.indexOf("<b>Perf") != -1) {

                try {
                    var measObject = scene.getObjectByName("MeasurementLine");
                    scene.remove(measObject);
                } catch (e) {
                    console.log(e);
                }



                if (clickCounter == 0) {
                    xVal1 = parseFloat(data.position.x).toFixed(0);
                    yVal1 = parseFloat(data.position.y).toFixed(0);
                    zVal1 = parseFloat(data.position.z).toFixed(0);

                    clickCounter = 1;

                    measurementAlert = 'Point 1: ' + data.name.replace(/<br\s*[\/]?>/gi, "\n").replace(/<b\s*[\/]?>/gi, "").replace("</b>", "");
                    measurementAlertDiv = '<u>Measurement Stats</u><br><br>Point 1: ' + data.name;

                    document.getElementById("measurementText").innerHTML = measurementAlertDiv;
                    // alert(measurementAlert);
                } else {
                    xVal2 = parseFloat(data.position.x).toFixed(0);
                    yVal2 = parseFloat(data.position.y).toFixed(0);
                    zVal2 = parseFloat(data.position.z).toFixed(0);

                    measurementAlert += '\n\n' + "Point 2: " + data.name.replace(/<br\s*[\/]?>/gi, "\n").replace("<b>", "").replace("</b>", "");
                    measurementAlertDiv += '<br><br>' + "Point 2: " + data.name;
                    var horzDist = parseFloat(Math.sqrt(Math.pow(xVal1 - xVal2, 2) + Math.pow(yVal1 - yVal2, 2))).toFixed(0);
                    var vertDist = Math.abs(zVal1 - zVal2).toFixed(0);
                    var actDist = parseFloat(Math.sqrt(Math.pow(xVal1 - xVal2, 2) + Math.pow(yVal1 - yVal2, 2) + Math.pow(zVal1 - zVal2, 2))).toFixed(0);

                    measurementAlert += '\n\n' + 'Horizontal Dist: ' + horzDist + ' (ft).';
                    measurementAlert += '\n' + 'Vert Dist: ' + vertDist + ' (ft).';
                    measurementAlert += '\n' + 'Actual Dist: ' + actDist + ' (ft).';

                    measurementAlertDiv += '<br><br>' + 'Horizontal Dist: ' + horzDist + ' (ft).';
                    measurementAlertDiv += '<br><br>' + 'Vert Dist: ' + vertDist + ' (ft).';
                    measurementAlertDiv += '<br><br>' + 'Actual Dist: ' + actDist + ' (ft).';

                    clickCounter = 0;

                    // alert(measurementAlert);
                    document.getElementById("measurementText").innerHTML = measurementAlertDiv;

                    var measurementLinePoints = [];
                    measurementLinePoints.push(
                        (DOWNSAMPLE * xVal1 - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                        (DOWNSAMPLE * zVal1 - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                        (DOWNSAMPLE * yVal1 - bk_z_min - 0.5 * (bk_z_max - bk_z_min)),
                    );
                    measurementLinePoints.push(
                        (DOWNSAMPLE * xVal2 - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                        (DOWNSAMPLE * zVal2 - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                        (DOWNSAMPLE * yVal2 - bk_z_min - 0.5 * (bk_z_max - bk_z_min)),
                    );
                    var measurementLineGeom = new LineSegmentsGeometry().setPositions(measurementLinePoints);
                    var measurementLineMat = new LineMaterial({ color: 0xffffff, linewidth: 2 });
                    measurementLineMat.resolution.set(parentContainer.width(), parentContainer.height()); // important, for now
                    var measurementLineMesh = new LineSegments2(measurementLineGeom, measurementLineMat);
                    measurementLineMesh.name = "MeasurementLine";
                    measurementLineMesh.frustumCulled = false;
                    scene.add(measurementLineMesh);

                }


            }
        }

    } else {

        var leftPos = -parentContainer.offset().left + mouse.x - 0.5 * (parentContainer.width() - pickingTexture.width);
        var topPos = mouse.y - parentContainer.offset().top;

        // renderer.readRenderTargetPixels(pickingTexture, parentContainer.offset().left + mouse.x - 0.5 * (parentContainer.width() - pickingTexture.width), parentContainer.offset().top + parentContainer.height() - mouse.y, 1, 1, pixelBuffer);
        $("#tooltip").css("left", leftPos);
        $("#tooltip").css("top", topPos);
        $("#tooltip").show(10);
        document.getElementById("tooltip").innerHTML = data.name;

        console.log("Clicked Data", data);

        if (data.hasOwnProperty("event_result_id") && admin) {


            document.getElementById("tooltip").innerHTML += "<br><button class='btn btn-link p-0' data-id=" + data.event_result_id + " style='font-size:12px; !important;' id='hideEvent'>Discard Event</button>";
            // eventGeomDrawn.setAttribute('iDiscard', )

        }


        if (data.fileName) {

            document.getElementById("tooltip").innerHTML += "<br><span id='fileLink'><i>Loading data link.</i></span>";
            // document.getElementById("tooltip").innerHTML += "<br><span id='rayTracingShowLink'><i>Loading ray traces.</i></span>";
            // document.getElementById("tooltip").innerHTML += "<br><span id='rayTracingHideLink'><i></i></span>";

            $.ajax({
                type: "GET",
                url: "/picked_events/" + data.fileName.replace(".mat", "") + "?project_id=" + project_id,
                dataType: "json",
                success: function(ajaxData) {
                    $("#fileLink").html("<a target='_blank' href='" + ajaxData['image_url'] + "'>Trace Gathers</a>");

                    if (admin && data.name.toLowerCase().includes("event")) {
                        document.getElementById("tooltip").innerHTML += "<br><a data-remote='true' target='_blank' href='/rep_event/" + ajaxData.name + "?project_id=" + project_id + "'>Mark as Rep. Event</a>";
                    }

                    // $("#rayTracingShowLink").html("<a>Plot Ray Tracing</a>");
                    // $("#rayTracingHideLink").html("<a>Hide Ray Tracing</a>");
                    // $( "#rayTracingShowLink" ).click(function() {

                    // 	dataTraces = [];
                    // 	for ( i = 0; i < 200; i ++ ){

                    // 		dataTraces.push(Math.random()*500);
                    // 		dataTraces.push(0.05*-5713 + Math.random()*10);
                    // 		dataTraces.push(Math.random()*500);

                    // 		if ( i > 0 ){
                    // 			dataTraces.push(3*i);
                    // 			dataTraces.push(3*i + 1);
                    // 			dataTraces.push(3*i + 2);
                    // 		}
                    // 	}
                    // 	rayTracingLine.geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(dataTraces), 3));
                    // });
                    // $( "#rayTracingHideLink" ).click(function() {
                    // 	rayTracingLine.geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(0), 3));
                    // });


                },
                error: function(data) {
                    $("#fileLink").html("");
                    // $("#rayTracingShowLink").html("");
                    // $("#rayTracingHideLink").html("");

                }
            });
        }










    }

}

document.addEventListener('click', function(e) {
    if (e.target && e.target.id == 'hideEvent') {

        let index = events.map((e) => e.id).indexOf(Number(e.target.attributes['data-id']['value']));
        console.log("eventGeomDrawn", eventGeomDrawn);
        console.log("events", events);

        let iOffset = eventGeomDrawn.attributes.iOffset.array;
        iOffset[3 * index] = -999999;
        iOffset[3 * index + 1] = -999999;
        iOffset[3 * index + 2] = -999999;

        eventGeomDrawn.setAttribute('iOffset', new THREE.InstancedBufferAttribute(iOffset, 3));
        eventGeomPicking.setAttribute('iOffset', new THREE.InstancedBufferAttribute(iOffset, 3));

        $("#tooltip").hide(10);
        $("#tooltip").html("");

        $.ajax({
            type: "PATCH",
            url: "/projects/" + project_id + "/event_results/" + e.target.attributes['data-id']['value'],
            dataType: "json",
            data: {
                event_result: {
                    discard: true
                }
            },
            success: function(ajaxData) {
                $('#loadingStatus').html("Discarded event from database.")
                $('#loadingStatus').show()
                setTimeout(function() {
                    $('#loadingStatus').fadeOut('slow');
                }, 3000); //

            }
        });

    }
});




function linmap(min_val, max_val, divisions) {

    var temp = [];
    temp.push(min_val.toString());

    var temp_val = min_val;
    for (var i = 0; i < divisions; i += 1) {
        temp_val = temp_val + (max_val / divisions);
        temp.push(Math.round(temp_val).toString());
    }

    return temp;

}

function animate() {

    requestAnimationFrame(animate);
    stats.update();
    render();
}

pickDelay = 0;

function render(factor = 1, compassFactor = 1) {

    if (renderer == undefined) {
        return;
    }
    var gl = renderer.getContext();
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);


    if (FIBER_TIMELAPSE_START) {

        var fiberIndex = FIBER_DATA.map(e => e.name).indexOf(FIBER_DATA_GUI['dataSet']);

        // console.log("fiberIndex", fiberIndex, "FiberDataCount", fibDataCount);

        if (fiberIndex == -1) {
            FIBER_TIMELAPSE_START = false;
        } else {
            if (FIBER_DATA_INDEX < fiberData[fiberIndex].data.length) {

                fibDataCount[fiberIndex] = fiberData[fiberIndex].easting.length;

                var offsets = [],
                    iColors = [],
                    iScale = [];

                ///////////////////// TRYING ROTATION ////////////////////

                var fibDataMatArraySize = fibDataCount[fiberIndex] * 4
                var fibDataMatrixArray = [
                    new Float32Array(fibDataMatArraySize),
                    new Float32Array(fibDataMatArraySize),
                    new Float32Array(fibDataMatArraySize),
                    new Float32Array(fibDataMatArraySize),
                ];

                /////////////////////////////////////////////////////////

                for (var i = 0; i < fibDataCount[fiberIndex]; i++) {

                    offsets.push(
                        (DOWNSAMPLE * Number(fiberData[fiberIndex].northing[i]) - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                        (DOWNSAMPLE * Number(fiberData[fiberIndex].tvdss[i]) - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                        (DOWNSAMPLE * Number(fiberData[fiberIndex].easting[i]) - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                    );

                    iScale.push(1, 1, 1);

                    var fibDataMatrix = new THREE.Matrix4();

                    var fibDataPosition = new THREE.Vector3(
                        (DOWNSAMPLE * Number(fiberData[fiberIndex].northing[i]) - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                        (DOWNSAMPLE * Number(fiberData[fiberIndex].tvdss[i]) - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                        (DOWNSAMPLE * Number(fiberData[fiberIndex].easting[i]) - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                    );

                    if (i < fibDataCount[fiberIndex] - 1) {

                        var fibDataQuaternion = new THREE.Quaternion();

                        var fibDataPositionNew = new THREE.Vector3(
                            (DOWNSAMPLE * Number(fiberData[fiberIndex].northing[i + 1]) - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                            (DOWNSAMPLE * Number(fiberData[fiberIndex].tvdss[i + 1]) - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                            (DOWNSAMPLE * Number(fiberData[fiberIndex].easting[i + 1]) - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                        );

                        var dir1 = new THREE.Vector3();
                        dir1.subVectors(fibDataPositionNew, fibDataPosition);

                        var dir2 = new THREE.Vector3();
                        dir2.subVectors(fibDataPosition, fibDataPositionNew);

                        dir2.dot(dir1);

                        var mx = new THREE.Matrix4().lookAt(dir2, new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 1, 0));
                        var fibDataQuaternion = new THREE.Quaternion().setFromRotationMatrix(mx);

                    }

                    // eventTempColor = new THREE.Color('rgba(255, 255, 255, 1.0)');
                    var eventTempColor = FIBER_LUT[fiberIndex].getColor(fiberData[fiberIndex].data[FIBER_DATA_INDEX][i]); //new THREE.Color('rgba(255, 255, 255, 1.0)');
                    iColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);

                    var maxCoef = Math.max(Math.abs(FIBER_MAX[fiberIndex]), Math.abs(FIBER_MIN[fiberIndex]));

                    var coef = 1;
                    if (Math.abs(fiberData[fiberIndex].data[FIBER_DATA_INDEX][i]) < maxCoef) {
                        coef = Math.abs(fiberData[fiberIndex].data[FIBER_DATA_INDEX][i]) / maxCoef;
                        // coef = 1;
                    }
                    // else {
                    //     coef = Math.abs(fiberData[fiberIndex].data[FIBER_DATA_INDEX][i]) / maxCoef;
                    // }

                    var fibDataScale = new THREE.Vector3(2 * (0.3 + 0.7 * coef), 1, 1);
                    fibDataMatrix.compose(fibDataPosition, fibDataQuaternion, fibDataScale);

                    for (var r = 0; r < 4; r++) {
                        for (var c = 0; c < 4; c++) {
                            fibDataMatrixArray[r][i * 4 + c] = fibDataMatrix.elements[r * 4 + c];
                        }
                    }
                }


                fibDataGeomDrawn[fiberIndex].setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
                for (let i = 0; i < fibDataMatrixArray.length; i++) {
                    fibDataGeomDrawn[fiberIndex].setAttribute(
                        `aInstanceMatrix${i}`,
                        new THREE.InstancedBufferAttribute(fibDataMatrixArray[i], 4)
                    );
                }

                Plotly.relayout('fiberDataPlots', {
                    shapes: [{
                        type: 'line',
                        // x-reference is assigned to the x-values
                        xref: 'x',
                        // y-reference is assigned to the plot paper [0,1]
                        yref: 'y',
                        x0: fiberData[fiberIndex].time[FIBER_DATA_INDEX],
                        x1: fiberData[fiberIndex].time[FIBER_DATA_INDEX],
                        y0: fiberData[fiberIndex].md.min(),
                        y1: fiberData[fiberIndex].md.max(),
                        fillcolor: '#ff0000',
                        line: {
                            width: 2,
                            color: '#ff0000',
                        }
                    }, ]
                });

                FIBER_DATA_INDEX++;

                if (FIBER_TIMELAPSE_STOP) {
                    FIBER_TIMELAPSE_START = false;
                    FIBER_DATA_INDEX = 0;
                    FIBER_TIMELAPSE_STOP = false;
                }


            }
        }

    }

    if (ET_TIMELAPSE) {

        var timelapse = [];
        var eventTimelapseCounter = 0;
        var timeVal = 0;
        var currentEvent = false;

        for (var i = 0; i < events.length; i++) {

            if (eventGeomDrawn.attributes.iScale.array[3 * i]) {
                eventTimelapseCounter++;
            }

            if (eventTimelapseCounter < eventCounterRender % visibleEvents) {
                timelapse.push(1, 1, 1);
                timeVal = events[i].time;
                currentEvent = events[i];
            } else {
                timelapse.push(0, 0, 0);
            }
        }

        if (ET_TIMELAPSE_CURRENT_STAGE && currentEvent) {
            for (var i = 0; i < events.length; i++) {
                if (timelapse[3 * i] == 1 && events[i].name != currentEvent.name) {
                    timelapse[3 * i, 3 * i + 1, 3 * i + 2] = [0, 0, 0];
                }
            }
        }

        eventCounterRender = eventCounterRender + (visibleEvents / 1000);
        eventGeomDrawn.setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timelapse), 3));
        eventGeomPicking.setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timelapse), 3));

        addUpdateDepthErrorBars();
        addUpdateNorthingErrorBars();
        addUpdateEastErrorBars();
        addUpdateAziErrorBars();

        if (gyration.length > 0) {
            var iTimelapse = [];
            for (var i = 0; i < gyration.length; i++) {

                if (gyration[i].time > timeVal) {
                    iTimelapse.push(0, 0, 0);
                } else {

                    if (ET_TIMELAPSE_CURRENT_STAGE && currentEvent) {

                        if (gyration[i].well + " " + gyration[i].stage == currentEvent.name) {
                            iTimelapse.push(1, 1, 1);
                        } else {
                            iTimelapse.push(0, 0, 0);
                        }

                    } else {
                        iTimelapse.push(1, 1, 1);
                    }
                }

            }

            gyrationGeomDrawn.setAttribute('iTimelapse', new THREE.InstancedBufferAttribute(new Float32Array(iTimelapse), 3));
        }

        if (PUMPLOGS_PRESENT) {

            Plotly.relayout('pumplogs', {
                shapes: [{
                    type: 'line',
                    // x-reference is assigned to the x-values
                    xref: 'x',
                    // y-reference is assigned to the plot paper [0,1]
                    yref: 'y',
                    x0: currentEvent.time,
                    x1: currentEvent.time,
                    y0: 0,
                    y1: 1.1 * pumpLogs.map(e => e.pressure).max(),
                    fillcolor: '#ffffff',
                    line: {
                        width: 2,
                        color: '#ffffff',
                    }
                }, ]
            });

        }

    }

    if (ST_TIMELAPSE) {

        timelapse = [];
        stTimelapseCounter = 0;
        timeVal = 0;
        currentST = false;

        for (let i = 0; i < sourceTensor.length; i++) {

            if (sourceTensorGeomDrawn[0].attributes.iScale.array[3 * i]) {
                stTimelapseCounter++;
            }

            if (stTimelapseCounter < stCounterRender % visibleST) {
                timelapse.push(1, 1, 1);
                timeVal = sourceTensor[i].time;
                currentST = sourceTensor[i];
            } else {
                timelapse.push(0, 0, 0);
            }
        }

        if (ST_TIMELAPSE_CURRENT_STAGE && currentST) {
            for (let i = 0; i < sourceTensor.length; i++) {
                if (timelapse[3 * i] == 1 && sourceTensor[i].name != currentST.name) {
                    timelapse[3 * i, 3 * i + 1, 3 * i + 2] = [0, 0, 0];
                }
            }
        }

        stCounterRender = stCounterRender + (visibleST / 1000);
        for (let k = 0; k < 4; k++) {
            sourceTensorGeomDrawn[k].setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timelapse), 3));
        }
        sourceTensorGeomPicking.setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timelapse), 3));

        if (PUMPLOGS_PRESENT && currentST) {

            Plotly.relayout('pumplogs', {
                shapes: [{
                    type: 'line',
                    // x-reference is assigned to the x-values
                    xref: 'x',
                    // y-reference is assigned to the plot paper [0,1]
                    yref: 'y',
                    x0: currentST.time,
                    x1: currentST.time,
                    y0: 0,
                    y1: 1.1 * pumpLogs.map(e => e.pressure).max(),
                    fillcolor: '#ffffff',
                    line: {
                        width: 2,
                        color: '#ffffff',
                    }
                }, ]
            });

        }

    }

    if ((measurementMode && pickDelay % 3 == 0) || (pickDelay % 10 == 0)) {
        pick();
        renderer.setRenderTarget(null);
    }
    pickDelay = pickDelay + 1;

    // Pulsating Perfs
    if (currentStagesPerfIndex.length > 0 && perfGeomDrawn != null) {

        var pulsateArray = Array(perf.length * 3).fill(1);

        for (let p = 0; p < currentStagesPerfIndex.length; p++) {
            pulsateArray[3 * currentStagesPerfIndex[p]] = 1 + 2 * Math.abs(Math.sin(Math.PI * pickDelay / 180));
            pulsateArray[3 * currentStagesPerfIndex[p] + 1] = 1 + 2 * Math.abs(Math.sin(Math.PI * pickDelay / 180));
            pulsateArray[3 * currentStagesPerfIndex[p] + 2] = 1 + 2 * Math.abs(Math.sin(Math.PI * pickDelay / 180));
        }

        perfGeomDrawn.setAttribute('iPulsate', new THREE.InstancedBufferAttribute(new Float32Array(pulsateArray), 3));
    }


    for (var sceneNo = 0; sceneNo < SCENE_ROWS * SCENE_COLS; sceneNo++) {

        if (SCENE_ROWS * SCENE_COLS == 4) {
            var COMPASS_HEIGHT = compassFactor * 80;
            var COMPASS_WIDTH = compassFactor * 80;
        } else {
            var COMPASS_HEIGHT = compassFactor * 125;
            var COMPASS_WIDTH = compassFactor * 125;
        }

        if (sceneView[sceneNo].camera_type == 0) {
            sceneView[sceneNo].oControls.update();
            for (var i = 0; i < wellLabelMesh.length; i++) {
                wellLabelMesh[i].quaternion.copy(sceneView[sceneNo].oCamera.quaternion);
            }

            for (var i = 0; i < formLabelMesh.length; i++) {
                formLabelMesh[i].quaternion.copy(sceneView[sceneNo].oCamera.quaternion);
            }

            for (var i = 0; i < dasEvents.length; i++) {
                let fd = scene.getObjectByName(dasEvents[i]);
                if (fd != null) {
                    fd.quaternion.copy(sceneView[sceneNo].oCamera.quaternion);
                }
            }






        } else {
            // light.position.set( pCamera.position );
            sceneView[sceneNo].pControls.update();

            for (var i = 0; i < wellLabelMesh.length; i++) {
                wellLabelMesh[i].quaternion.copy(sceneView[sceneNo].pCamera.quaternion);
            }

            for (var i = 0; i < formLabelMesh.length; i++) {
                formLabelMesh[i].quaternion.copy(sceneView[sceneNo].pCamera.quaternion);
            }

            for (var i = 0; i < dasEvents.length; i++) {
                let fd = scene.getObjectByName(dasEvents[i]);
                if (fd != null) {
                    fd.quaternion.copy(sceneView[sceneNo].pCamera.quaternion);
                }
            }


        }

        if (sceneView[sceneNo].camera_type == 0) {
            xText.quaternion.copy(sceneView[sceneNo].oCamera.quaternion);
            yText.quaternion.copy(sceneView[sceneNo].oCamera.quaternion);
            zText.quaternion.copy(sceneView[sceneNo].oCamera.quaternion);
        } else {
            xText.quaternion.copy(sceneView[sceneNo].pCamera.quaternion);
            yText.quaternion.copy(sceneView[sceneNo].pCamera.quaternion);
            zText.quaternion.copy(sceneView[sceneNo].pCamera.quaternion);
        }

        var leftPos = factor * sceneView[sceneNo].maxWidth * sceneView[sceneNo].left;
        var bottomPos = factor * sceneView[sceneNo].maxHeight * sceneView[sceneNo].bottom;
        var widthScene = factor * sceneView[sceneNo].maxWidth * sceneView[sceneNo].width;
        var heightScene = factor * sceneView[sceneNo].maxHeight * sceneView[sceneNo].height

        if (!isNaN(sceneView[sceneNo].aspect) && Number(sceneView[sceneNo].aspect) != 0) {

            var newHeightScene = widthScene / Number(sceneView[sceneNo].aspect);
            if (newHeightScene > heightScene) {
                var newWidthScene = heightScene * sceneView[sceneNo].aspect
                leftPos = leftPos + Math.abs(0.5 * (newWidthScene - widthScene));
                widthScene = newWidthScene;
            } else {
                bottomPos = bottomPos + Math.abs(0.5 * (newHeightScene - heightScene));
                heightScene = newHeightScene;
            }
        }


        renderer.setViewport(leftPos, bottomPos, widthScene, heightScene);
        renderer.setScissor(leftPos, bottomPos, widthScene, heightScene);
        // renderer.setScissorTest( true );
        renderer.setScissorTest(SCENE_ROWS * SCENE_COLS == 1 ? false : true);
        renderer.render(scene, sceneView[sceneNo].camera_type == 0 ? sceneView[sceneNo].oCamera : sceneView[sceneNo].pCamera);

        sceneView[sceneNo].compassCamera.position.copy(sceneView[sceneNo].camera_type == 0 ? sceneView[sceneNo].oCamera.position : sceneView[sceneNo].pCamera.position);
        sceneView[sceneNo].compassCamera.position.sub(sceneView[sceneNo].camera_type == 0 ? sceneView[sceneNo].oControls.target : sceneView[sceneNo].pControls.target); // added by @libe

        sceneView[sceneNo].compassCamera.position.setLength(300);
        sceneView[sceneNo].compassCamera.lookAt(compassScene.position);

        if (SCENE_ROWS * SCENE_COLS > 1) {
            compassRenderer.setViewport(leftPos, bottomPos, COMPASS_WIDTH, COMPASS_HEIGHT);
            compassRenderer.setScissor(leftPos, bottomPos, COMPASS_WIDTH, COMPASS_HEIGHT);
            // compassRenderer.setScissorTest( true );
            compassRenderer.setScissorTest(SCENE_ROWS * SCENE_COLS == 1 ? false : true);
        }
        compassRenderer.render(compassScene, sceneView[sceneNo].compassCamera);
    }
}

function pick() {

    renderer.setRenderTarget(pickingTexture);

    for (let n2 = 0; n2 < SCENE_ROWS * SCENE_COLS; n2++) {

        var leftPick = sceneView[n2].maxWidth * sceneView[n2].left;
        var bottomPick = sceneView[n2].maxHeight * sceneView[n2].bottom;
        var widthPick = sceneView[n2].maxWidth * sceneView[n2].width;
        var heightPick = sceneView[n2].maxHeight * sceneView[n2].height

        if (!isNaN(sceneView[n2].aspect) && Number(sceneView[n2].aspect) != 0) {

            var newHeightPick = widthPick / Number(sceneView[n2].aspect);
            if (newHeightPick > heightPick) {
                var newWidthPick = heightPick * sceneView[n2].aspect
                leftPick = leftPick + Math.abs(0.5 * (newWidthPick - widthPick));
                widthPick = newWidthPick;
            } else {
                bottomPick = bottomPick + Math.abs(0.5 * (newHeightPick - heightPick));
                heightPick = newHeightPick;
            }
        }

        renderer.setViewport(leftPick, bottomPick, widthPick, heightPick);
        renderer.setScissor(leftPick, bottomPick, widthPick, heightPick);
        // renderer.setScissorTest( true );
        renderer.setScissorTest(SCENE_ROWS * SCENE_COLS == 1 ? false : true);

        renderer.render(pickingScene, sceneView[n2].camera_type == 0 ? sceneView[n2].oCamera : sceneView[n2].pCamera);
    }

    var pixelBuffer = new Uint8Array(4);
    // renderer.readRenderTargetPixels(pickingTexture, mouse.x - 0.5 * (parentContainer.width() - pickingTexture.width), pickingTexture.height - mouse.y, 1, 1, pixelBuffer);
    renderer.readRenderTargetPixels(pickingTexture, -parentContainer.offset().left + mouse.x - 0.5 * (parentContainer.width() - pickingTexture.width), parentContainer.offset().top + parentContainer.height() - mouse.y, 1, 1, pixelBuffer);

    var id = (pixelBuffer[0] << 16) | (pixelBuffer[1] << 8) | (pixelBuffer[2]);
    var data = pickingData[id];

    if (!document.getElementById("hoverText").innerHTML.startsWith("Azimuth:")) {
        document.getElementById("hoverText").innerHTML = "";
        if (project_name != "") {
            document.getElementById("hoverText").innerHTML += "<b>" + project_name + "</b><br>";
        }
        if (plot_title != "") {
            document.getElementById("hoverText").innerHTML += "<b>" + plot_title + "</b><br>";
        }
    }

    if (data) {
        if (data.name) {

            document.getElementById("hoverText").innerHTML = "";

            if (project_name != "") {
                document.getElementById("hoverText").innerHTML += "<b>" + project_name + "</b><br>";
            }
            if (plot_title != "") {
                document.getElementById("hoverText").innerHTML += "<b>" + plot_title + "</b><br>";
            }

            document.getElementById("hoverText").innerHTML = data.name;

            if (!measurementMode && data.azimuth) {

                Plotly.purge('measurementText');

                var azimuthAngle = data.azimuth;

                var azimuthAngle = Number(azimuthAngle);

                if (!isNaN(azimuthAngle)) {

                    var polarData = [{
                        type: "scatterpolar",
                        mode: "lines",
                        r: [0, 3, 3, 0, 3, 3, 0],
                        theta: [0, azimuthAngle + 2, azimuthAngle - 2, 0, azimuthAngle - 180 + 2, azimuthAngle - 180 - 2, 0],
                        line: {
                            color: "#ff0000",
                            size: 10
                        },
                        subplot: "polar"
                    }, ];

                    var polarLayout = {
                        // title: 'Azimuth - ' + Number(azimuthAngle),
                        titlefont: {
                            // family: 'Courier New, monospace',
                            size: 10,
                            color: '#ffffff'
                        },
                        margin: {
                            l: 80,
                            r: 30,
                            t: 30,
                            b: 30,
                        },
                        width: 250,
                        height: 250,
                        paper_bgcolor: 'rgba(233,233,233,0)',
                        showlegend: false,
                        polar: {
                            radialaxis: {
                                showline: false,
                                showticklabels: false,
                            },
                            angularaxis: {
                                tickfont: {
                                    size: 8
                                },
                                color: "#ffffff",
                                gridcolor: "#444444",
                                tickcolor: "#ffffff",
                                direction: "clockwise"
                            }
                        }
                    }

                    Plotly.plot('measurementText', polarData, polarLayout)

                }

            }
        }
    } else {
        // $("#tooltip").hide();
        Plotly.purge(document.getElementById("measurementText"));
    }


}

function createVolumePlots(j, visible) {


    if (!visible) {
        var vol = scene.getObjectByName(volumeDataName[j]);
        scene.remove(vol);
        return;
    }

    if (!volumeData[j] || !volumeData[j].x) {
        alert("Data being downloaded. Please uncheck and check button in a minute.");
        return;
    }

    var material = new THREE.PointsMaterial({
        size: Number(volOptions['point size' + j.toString()]),
        vertexColors: THREE.VertexColors
    });

    material.onBeforeCompile = function(shader) {

        shader.vertexShader =
            `
			uniform float size;
			uniform float scale;
			attribute float alpha;
			varying float vAlpha;

			#include <common>
			#include <color_pars_vertex>
			#include <fog_pars_vertex>
			#include <morphtarget_pars_vertex>
			#include <logdepthbuf_pars_vertex>
			#include <clipping_planes_pars_vertex>
			void main() {
				vAlpha = alpha;
				#include <color_vertex>
				#include <begin_vertex>
				#include <morphtarget_vertex>
				#include <project_vertex>
				gl_PointSize = size;
				#ifdef USE_SIZEATTENUATION
					bool isPerspective = ( projectionMatrix[ 2 ][ 3 ] == - 1.0 );
					if ( isPerspective ) gl_PointSize *= ( scale / - mvPosition.z );

				#endif
				#include <logdepthbuf_vertex>
				#include <clipping_planes_vertex>
				#include <worldpos_vertex>
				#include <fog_vertex>
			}
			`;

        shader.fragmentShader =
            `
			uniform vec3 diffuse;
			uniform float opacity;
			varying float vAlpha;
			#include <common>
			#include <color_pars_fragment>
			#include <map_particle_pars_fragment>
			#include <fog_pars_fragment>
			#include <logdepthbuf_pars_fragment>
			#include <clipping_planes_pars_fragment>
			void main() {
				if ( vAlpha < 0.1 ) discard;
				#include <clipping_planes_fragment>
				vec3 outgoingLight = vec3( 0.0 );
				vec4 diffuseColor = vec4( diffuse, opacity );
				#include <logdepthbuf_fragment>
				#include <map_particle_fragment>
				#include <color_fragment>
				#include <alphatest_fragment>
				outgoingLight = diffuseColor.rgb;
				gl_FragColor = vec4( outgoingLight, diffuseColor.a );
				#include <premultiplied_alpha_fragment>
				#include <tonemapping_fragment>
				#include <encodings_fragment>
				#include <fog_fragment>
			}
			`;

        var materialShader = shader;

    };

    volumePointCloud[j] = new THREE.Points(volumeGeometryDrawn[j], material);
    volumePointCloud[j].dynamic = true;
    volumePointCloud[j].visible = true;
    volumePointCloud[j].name = volumeDataName[j];
    scene.add(volumePointCloud[j]);

}

function createFiberDataPlots(j, visible) {

    var fdMesh = scene.getObjectByName("Fiber Data Mesh");
    scene.remove(fdMesh);

    var frMesh = scene.getObjectByName("Fiber Rec Mesh");
    scene.remove(frMesh);

    document.getElementById("fiberDataPlots").innerHTML = "";
    $("#fiberDataPlots").height(0);

    // fiberDataPlots

    if (!visible) {
        return;
    }

    if (!fiberData[j] || !fiberData[j].easting) {
        alert("Data being downloaded. Plot will load once download finishes.");
        return;
    }

    $("#fiberDataPlots").height(150)

    fiberData[j].easting = fiberData[j].easting.flat();
    fiberData[j].northing = fiberData[j].northing.flat();
    fiberData[j].tvdss = fiberData[j].tvdss.flat();
    fiberData[j].md = fiberData[j].md.flat();


    fibRecCount[j] = fiberData[j].easting.length;


    fibRecMaterial[j] = new THREE.MeshLambertMaterial({
        map: new THREE.TextureLoader().load('https://borehole-seismic-biz.s3.amazonaws.com/texture.jpg?t=2123213012'),
        vertexColors: THREE.VertexColors,
        side: THREE.DoubleSide
    });

    fibRecMaterial[j].onBeforeCompile = function(shader) {
        shader.vertexShader =
            `
			#define LAMBERT
			// instanced
			attribute vec3 iOffset;
			attribute vec3 iColor;
			attribute vec3 iScale;
			varying vec3 vLightFront;
			varying vec3 vIndirectFront;
			attribute vec4 aInstanceMatrix0;
			attribute vec4 aInstanceMatrix1;
			attribute vec4 aInstanceMatrix2;
			attribute vec4 aInstanceMatrix3;


			#ifdef DOUBLE_SIDED
				varying vec3 vLightBack;
				varying vec3 vIndirectBack;
			#endif
			#include <common>
			#include <uv_pars_vertex>
			#include <uv2_pars_vertex>
			#include <envmap_pars_vertex>
			#include <bsdfs>
			#include <lights_pars_begin>
			#include <color_pars_vertex>
			#include <fog_pars_vertex>
			#include <morphtarget_pars_vertex>
			#include <skinning_pars_vertex>
			#include <shadowmap_pars_vertex>
			#include <logdepthbuf_pars_vertex>
			#include <clipping_planes_pars_vertex>
			void main() {
				#include <uv_vertex>
				#include <uv2_vertex>
				#include <color_vertex>
				#ifdef USE_COLOR
					vColor.xyz = iColor.xyz;
				#endif
				#include <beginnormal_vertex>
				#include <morphnormal_vertex>
				#include <skinbase_vertex>
				#include <skinnormal_vertex>
				#include <defaultnormal_vertex>
				#include <begin_vertex>

				mat4 aInstanceMatrix = mat4(
					aInstanceMatrix0,
					aInstanceMatrix1,
					aInstanceMatrix2,
					aInstanceMatrix3
				);
				transformed = (aInstanceMatrix * vec4( position , 1. )).xyz;
				transformed *= iScale;

				//vec3 positionEye = ( modelViewMatrix * aInstanceMatrix * vec4( position, 1.0 ) ).xyz;
				//gl_Position = projectionMatrix * vec4( positionEye, 1.0 );

				#include <morphtarget_vertex>
				#include <skinning_vertex>
				#include <project_vertex>
				#include <logdepthbuf_vertex>
				#include <clipping_planes_vertex>
				#include <worldpos_vertex>
				#include <envmap_vertex>
				#include <lights_lambert_vertex>
				#include <shadowmap_vertex>
				#include <fog_vertex>
			}
			`;


        var materialShader =
            `
                varying float visible;
                uniform vec3 diffuse;
                uniform vec3 emissive;
                uniform float opacity;
                varying vec3 vLightFront;
                varying vec3 vIndirectFront;
                #ifdef DOUBLE_SIDED
                    varying vec3 vLightBack;
                    varying vec3 vIndirectBack;
                #endif
                #include <common>
                #include <packing>
                #include <dithering_pars_fragment>
                #include <color_pars_fragment>
                #include <uv_pars_fragment>
                #include <uv2_pars_fragment>
                #include <map_pars_fragment>
                #include <alphamap_pars_fragment>
                #include <aomap_pars_fragment>
                #include <lightmap_pars_fragment>
                #include <emissivemap_pars_fragment>
                #include <envmap_common_pars_fragment>
                #include <envmap_pars_fragment>
                #include <bsdfs>
                #include <lights_pars_begin>
                #include <fog_pars_fragment>
                #include <shadowmap_pars_fragment>
                #include <shadowmask_pars_fragment>
                #include <specularmap_pars_fragment>
                #include <logdepthbuf_pars_fragment>
                #include <clipping_planes_pars_fragment>
                void main() {
                    if (visible == 0.0) discard;
                    #include <clipping_planes_fragment>
                    vec4 diffuseColor = vec4( diffuse, opacity );
                    ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
                    vec3 totalEmissiveRadiance = emissive;
                    #include <logdepthbuf_fragment>
                    #include <map_fragment>
                    #include <color_fragment>
                    #include <alphamap_fragment>
                    #include <alphatest_fragment>
                    #include <specularmap_fragment>
                    #include <emissivemap_fragment>
                    reflectedLight.indirectDiffuse = getAmbientLightIrradiance( ambientLightColor );
                    #ifdef DOUBLE_SIDED
                        reflectedLight.indirectDiffuse += ( gl_FrontFacing ) ? vIndirectFront : vIndirectBack;
                    #else
                        reflectedLight.indirectDiffuse += vIndirectFront;
                    #endif
                    #include <lightmap_fragment>
                    reflectedLight.indirectDiffuse *= BRDF_Diffuse_Lambert( diffuseColor.rgb );
                    #ifdef DOUBLE_SIDED
                        reflectedLight.directDiffuse = ( gl_FrontFacing ) ? vLightFront : vLightBack;
                    #else
                        reflectedLight.directDiffuse = vLightFront;
                    #endif
                    reflectedLight.directDiffuse *= BRDF_Diffuse_Lambert( diffuseColor.rgb ) * getShadowMask();
                    #include <aomap_fragment>
                    vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + totalEmissiveRadiance;
                    #include <envmap_fragment>
                    gl_FragColor = vec4( outgoingLight, diffuseColor.a );
                    #include <tonemapping_fragment>
                    #include <encodings_fragment>
                    #include <fog_fragment>
                    #include <premultiplied_alpha_fragment>
                    #include <dithering_fragment>
                }
                `

    };


    offsets = [], iColors = [], iScale = [];

    ////////// TRYING ROTATION //////////////

    let fibRecMatArraySize = fibRecCount[j] * 4
    let fibRecMatrixArray = [
        new Float32Array(fibRecMatArraySize),
        new Float32Array(fibRecMatArraySize),
        new Float32Array(fibRecMatArraySize),
        new Float32Array(fibRecMatArraySize),
    ];

    /////////////////////////////////////////

    console.log("FiberData", fiberData[j]);

    for (var i = 0; i < fibRecCount[j]; i++) {

        offsets.push(
            (DOWNSAMPLE * Number(fiberData[j].northing[i]) - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
            (DOWNSAMPLE * Number(fiberData[j].tvdss[i]) - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
            (DOWNSAMPLE * Number(fiberData[j].easting[i]) - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
        );

        let eventTempColor = new THREE.Color('rgba(255, 255, 255, 1.0)').convertSRGBToLinear();
        iColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);

        let minCond = true;
        let maxCond = true;
        if (!isNaN(FIBER_MIN_MD[j]) && Number(fiberData[j].md[i]) < FIBER_MIN_MD[j]) {
            minCond = false;
        }
        if (!isNaN(FIBER_MAX_MD[j]) && Number(fiberData[j].md[i]) > FIBER_MAX_MD[j]) {
            maxCond = false;
        }

        if (minCond && maxCond) {
            iScale.push(1, 1, 1);
        } else {
            iScale.push(0, 0, 0);
        }

        let fibRecMatrix = new THREE.Matrix4();

        let fibRecPosition = new THREE.Vector3(
            (DOWNSAMPLE * Number(fiberData[j].northing[i]) - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
            (DOWNSAMPLE * Number(fiberData[j].tvdss[i]) - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
            (DOWNSAMPLE * Number(fiberData[j].easting[i]) - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
        );

        let fibRecQuaternion = new THREE.Quaternion();

        let fibRecScale = new THREE.Vector3(1, 1, 1);

        fibRecMatrix.compose(fibRecPosition, fibRecQuaternion, fibRecScale);

        for (var r = 0; r < 4; r++) {
            for (var c = 0; c < 4; c++) {
                fibRecMatrixArray[r][i * 4 + c] = fibRecMatrix.elements[r * 4 + c];
            }
        }

    }

    var boxGeometry = new THREE.CylinderBufferGeometry(0.5, 0, 2);
    boxGeometry.frustumCulled = false;
    fibRecGeomDrawn[j] = new THREE.InstancedBufferGeometry();
    fibRecGeomDrawn[j].instanceCount = fibRecCount;
    fibRecGeomDrawn[j].index = boxGeometry.index;
    fibRecGeomDrawn[j].attributes = boxGeometry.attributes;
    fibRecGeomDrawn[j].setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
    fibRecGeomDrawn[j].setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
    fibRecGeomDrawn[j].setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

    for (let i = 0; i < fibRecMatrixArray.length; i++) {
        fibRecGeomDrawn[j].setAttribute(
            `aInstanceMatrix${i}`,
            new THREE.InstancedBufferAttribute(fibRecMatrixArray[i], 4)
        );
    }

    fibRecMesh[j] = new THREE.Mesh(fibRecGeomDrawn[j], fibRecMaterial[j]);
    fibRecMesh[j].name = "Fiber Rec Mesh";
    fibRecMesh[j].frustumCulled = false;
    scene.add(fibRecMesh[j]);

    fibDataMaterial[j] = new THREE.MeshLambertMaterial({
        map: new THREE.TextureLoader().load('https://borehole-seismic-biz.s3.amazonaws.com/texture.jpg?t=2123213012'),
        vertexColors: THREE.VertexColors,
        side: THREE.DoubleSide
    });

    fibDataMaterial[j].onBeforeCompile = function(shader) {
        shader.vertexShader =
            `
			#define LAMBERT
			// instanced
			attribute vec3 iOffset;
			attribute vec3 iColor;
			attribute vec3 iScale;
			varying vec3 vLightFront;
			varying vec3 vIndirectFront;
			attribute vec4 aInstanceMatrix0;
			attribute vec4 aInstanceMatrix1;
			attribute vec4 aInstanceMatrix2;
			attribute vec4 aInstanceMatrix3;

			#ifdef DOUBLE_SIDED
				varying vec3 vLightBack;
				varying vec3 vIndirectBack;
			#endif
			#include <common>
			#include <uv_pars_vertex>
			#include <uv2_pars_vertex>
			#include <envmap_pars_vertex>
			#include <bsdfs>
			#include <lights_pars_begin>
			#include <color_pars_vertex>
			#include <fog_pars_vertex>
			#include <morphtarget_pars_vertex>
			#include <skinning_pars_vertex>
			#include <shadowmap_pars_vertex>
			#include <logdepthbuf_pars_vertex>
			#include <clipping_planes_pars_vertex>
			void main() {
				#include <uv_vertex>
				#include <uv2_vertex>
				#include <color_vertex>
				#ifdef USE_COLOR
					vColor.xyz = iColor.xyz;
				#endif
				#include <beginnormal_vertex>
				#include <morphnormal_vertex>
				#include <skinbase_vertex>
				#include <skinnormal_vertex>
				#include <defaultnormal_vertex>
				#include <begin_vertex>

				mat4 aInstanceMatrix = mat4(
					aInstanceMatrix0,
					aInstanceMatrix1,
					aInstanceMatrix2,
					aInstanceMatrix3
				);
				transformed = (aInstanceMatrix * vec4( position , 1. )).xyz;
				transformed *= iScale;
				//vec3 positionEye = ( modelViewMatrix * aInstanceMatrix * vec4( position, 1.0 ) ).xyz;
				//gl_Position = projectionMatrix * vec4( positionEye, 1.0 );

				#include <morphtarget_vertex>
				#include <skinning_vertex>
				#include <project_vertex>
				#include <logdepthbuf_vertex>
				#include <clipping_planes_vertex>
				#include <worldpos_vertex>
				#include <envmap_vertex>


				vec3 diffuse = vec3( 1.0 );
				GeometricContext geometry;
				geometry.position = mvPosition.xyz;
				geometry.normal = normalize( transformedNormal );
				geometry.viewDir = normalize( -mvPosition.xyz );
				GeometricContext backGeometry;
				backGeometry.position = geometry.position;
				backGeometry.normal = -geometry.normal;
				backGeometry.viewDir = geometry.viewDir;
				vLightFront = vec3( 0.0 );
				vIndirectFront = vec3( 0.0 );
				#ifdef DOUBLE_SIDED
					vLightBack = vec3( 0.0 );
					vIndirectBack = vec3( 0.0 );
				#endif
				IncidentLight directLight;
				float dotNL;
				vec3 directLightColor_Diffuse;
				// #if NUM_POINT_LIGHTS > 0
				// 	#pragma unroll_loop
				// 	for ( int i = 0; i < NUM_POINT_LIGHTS; i ++ ) {
				// 		getPointDirectLightIrradiance( pointLights[ i ], geometry, directLight );
				// 		dotNL = dot( geometry.normal, directLight.direction );
				// 		directLightColor_Diffuse = 0.5 * PI * directLight.color;
				// 		vLightFront += saturate( dotNL ) * directLightColor_Diffuse;
				// 		#ifdef DOUBLE_SIDED
				// 			vLightBack += saturate( -dotNL ) * directLightColor_Diffuse;
				// 		#endif
				// 	}
				// #endif
				// #if NUM_SPOT_LIGHTS > 0
				// 	#pragma unroll_loop
				// 	for ( int i = 0; i < NUM_SPOT_LIGHTS; i ++ ) {
				// 		getSpotDirectLightIrradiance( spotLights[ i ], geometry, directLight );
				// 		dotNL = dot( geometry.normal, directLight.direction );
				// 		directLightColor_Diffuse = PI * directLight.color;
				// 		vLightFront += saturate( dotNL ) * directLightColor_Diffuse;
				// 		#ifdef DOUBLE_SIDED
				// 			vLightBack += saturate( -dotNL ) * directLightColor_Diffuse;
				// 		#endif
				// 	}
				// #endif
				// #if NUM_DIR_LIGHTS > 0
				// 	#pragma unroll_loop
				// 	for ( int i = 0; i < NUM_DIR_LIGHTS; i ++ ) {
				// 		getDirectionalDirectLightIrradiance( directionalLights[ i ], geometry, directLight );
				// 		dotNL = dot( geometry.normal, directLight.direction );
				// 		directLightColor_Diffuse = PI * directLight.color;
				// 		vLightFront += saturate( dotNL ) * directLightColor_Diffuse;
				// 		#ifdef DOUBLE_SIDED
				// 			vLightBack += saturate( -dotNL ) * directLightColor_Diffuse;
				// 		#endif
				// 	}
				// #endif
				#if NUM_HEMI_LIGHTS > 0
					#pragma unroll_loop
					for ( int i = 0; i < NUM_HEMI_LIGHTS; i ++ ) {
						vIndirectFront += getHemisphereLightIrradiance( hemisphereLights[ i ], geometry );
						#ifdef DOUBLE_SIDED
							vIndirectBack += getHemisphereLightIrradiance( hemisphereLights[ i ], backGeometry );
						#endif
					}
				#endif


				vLightFront += vec3( 1.0 );
				#ifdef DOUBLE_SIDED
					vLightBack += vec3( 1.0 );
				#endif

				#include <shadowmap_vertex>
				#include <fog_vertex>

			}
			`;

        var materialShader =
            `
                varying float visible;
                uniform vec3 diffuse;
                uniform vec3 emissive;
                uniform float opacity;
                varying vec3 vLightFront;
                varying vec3 vIndirectFront;
                #ifdef DOUBLE_SIDED
                    varying vec3 vLightBack;
                    varying vec3 vIndirectBack;
                #endif
                #include <common>
                #include <packing>
                #include <dithering_pars_fragment>
                #include <color_pars_fragment>
                #include <uv_pars_fragment>
                #include <uv2_pars_fragment>
                #include <map_pars_fragment>
                #include <alphamap_pars_fragment>
                #include <aomap_pars_fragment>
                #include <lightmap_pars_fragment>
                #include <emissivemap_pars_fragment>
                #include <envmap_common_pars_fragment>
                #include <envmap_pars_fragment>
                #include <bsdfs>
                #include <lights_pars_begin>
                #include <fog_pars_fragment>
                #include <shadowmap_pars_fragment>
                #include <shadowmask_pars_fragment>
                #include <specularmap_pars_fragment>
                #include <logdepthbuf_pars_fragment>
                #include <clipping_planes_pars_fragment>
                void main() {
                    if (visible == 0.0) discard;
                    #include <clipping_planes_fragment>
                    vec4 diffuseColor = vec4( diffuse, opacity );
                    ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
                    vec3 totalEmissiveRadiance = emissive;
                    #include <logdepthbuf_fragment>
                    #include <map_fragment>
                    #include <color_fragment>
                    #include <alphamap_fragment>
                    #include <alphatest_fragment>
                    #include <specularmap_fragment>
                    #include <emissivemap_fragment>
                    reflectedLight.indirectDiffuse = getAmbientLightIrradiance( ambientLightColor );
                    #ifdef DOUBLE_SIDED
                        reflectedLight.indirectDiffuse += ( gl_FrontFacing ) ? vIndirectFront : vIndirectBack;
                    #else
                        reflectedLight.indirectDiffuse += vIndirectFront;
                    #endif
                    #include <lightmap_fragment>
                    reflectedLight.indirectDiffuse *= BRDF_Diffuse_Lambert( diffuseColor.rgb );
                    #ifdef DOUBLE_SIDED
                        reflectedLight.directDiffuse = ( gl_FrontFacing ) ? vLightFront : vLightBack;
                    #else
                        reflectedLight.directDiffuse = vLightFront;
                    #endif
                    reflectedLight.directDiffuse *= BRDF_Diffuse_Lambert( diffuseColor.rgb ) * getShadowMask();
                    #include <aomap_fragment>
                    vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + totalEmissiveRadiance;
                    #include <envmap_fragment>
                    gl_FragColor = vec4( outgoingLight, diffuseColor.a );
                    #include <tonemapping_fragment>
                    #include <encodings_fragment>
                    #include <fog_fragment>
                    #include <premultiplied_alpha_fragment>
                    #include <dithering_fragment>
                }
                `



    };

    fibDataCount[j] = fiberData[j].easting.length;

    var offsets = [],
        iColors = [];
    // , iScale = [];

    ///////////////////// TRYING ROTATION ////////////////////

    var fibDataMatArraySize = fibDataCount[j] * 4
    var fibDataMatrixArray = [
        new Float32Array(fibDataMatArraySize),
        new Float32Array(fibDataMatArraySize),
        new Float32Array(fibDataMatArraySize),
        new Float32Array(fibDataMatArraySize),
    ];

    /////////////////////////////////////////////////////////


    FIBER_LUT[j].setMin(FIBER_MIN[j]);
    FIBER_LUT[j].setMax(FIBER_MAX[j]);

    for (var i = 0; i < fibDataCount[j]; i++) {

        offsets.push(
            (DOWNSAMPLE * Number(fiberData[j].northing[i]) - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
            (DOWNSAMPLE * Number(fiberData[j].tvdss[i]) - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
            (DOWNSAMPLE * Number(fiberData[j].easting[i]) - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
        );


        // iScale.push(1, 1, 1);

        var fibDataMatrix = new THREE.Matrix4();

        var fibDataPosition = new THREE.Vector3(
            (DOWNSAMPLE * Number(fiberData[j].northing[i]) - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
            (DOWNSAMPLE * Number(fiberData[j].tvdss[i]) - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
            (DOWNSAMPLE * Number(fiberData[j].easting[i]) - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
        );


        if (i < fibDataCount[j] - 1) {

            var fibDataQuaternion = new THREE.Quaternion();

            var fibDataPositionNew = new THREE.Vector3(
                (DOWNSAMPLE * Number(fiberData[j].northing[i + 1]) - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                (DOWNSAMPLE * Number(fiberData[j].tvdss[i + 1]) - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                (DOWNSAMPLE * Number(fiberData[j].easting[i + 1]) - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
            );

            var dir1 = new THREE.Vector3();
            dir1.subVectors(fibDataPositionNew, fibDataPosition);

            var dir2 = new THREE.Vector3();
            dir2.subVectors(fibDataPosition, fibDataPositionNew);

            dir2.dot(dir1);

            var mx = new THREE.Matrix4().lookAt(dir2, new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 1, 0));
            var fibDataQuaternion = new THREE.Quaternion().setFromRotationMatrix(mx);

        }


        // eventTempColor = new THREE.Color('rgba(255, 255, 255, 1.0)');
        // var eventTempColor = FIBER_LUT[j].getColor(fiberData[j].data[Math.round((fiberData[j].data.length - 1) / 2)][i]); //new THREE.Color('rgba(255, 255, 255, 1.0)');


        var eventTempColor = FIBER_LUT[j].getColor(fiberData[j].data[0][i]); //new THREE.Color('rgba(255, 255, 255, 1.0)');
        iColors.push(eventTempColor.r, eventTempColor.g, eventTempColor.b);

        var maxCoef = Math.max(Math.abs(FIBER_MAX[j]), Math.abs(FIBER_MIN[j]));

        var coef = 1;
        if (Math.abs(fiberData[j].data[0][i]) < maxCoef) {
            // coef = 1;
            coef = Math.abs(fiberData[j].data[0][i]) / maxCoef;
        }
        // else {
        //     coef = Math.abs(fiberData[j].data[0][i]) / maxCoef;
        // }

        // fibDataScale = new THREE.Vector3(0.3 + 0.7*coef, 1, 1);
        var fibDataScale = new THREE.Vector3(2 * (0.3 + 0.7 * coef), 1, 1);

        fibDataMatrix.compose(fibDataPosition, fibDataQuaternion, fibDataScale);

        for (var r = 0; r < 4; r++) {
            for (var c = 0; c < 4; c++) {
                fibDataMatrixArray[r][i * 4 + c] = fibDataMatrix.elements[r * 4 + c];
            }
        }
    }

    var boxGeometry = new THREE.BoxBufferGeometry(5, 0.1, 1);
    boxGeometry.frustumCulled = false;
    fibDataGeomDrawn[j] = new THREE.InstancedBufferGeometry();
    fibDataGeomDrawn[j].dynamic = true;
    fibDataGeomDrawn[j].instanceCount = fibDataCount[j];
    fibDataGeomDrawn[j].index = boxGeometry.index;
    fibDataGeomDrawn[j].attributes = boxGeometry.attributes;
    fibDataGeomDrawn[j].setAttribute('iOffset', new THREE.InstancedBufferAttribute(new Float32Array(offsets), 3));
    fibDataGeomDrawn[j].setAttribute('iScale', new THREE.InstancedBufferAttribute(new Float32Array(iScale), 3));

    fibDataGeomDrawn[j].setAttribute('iColor', new THREE.InstancedBufferAttribute(new Float32Array(iColors), 3));
    for (let i = 0; i < fibDataMatrixArray.length; i++) {
        fibDataGeomDrawn[j].setAttribute(
            `aInstanceMatrix${i}`,
            new THREE.InstancedBufferAttribute(fibDataMatrixArray[i], 4)
        );
    }

    fibDataMesh[j] = new THREE.Mesh(fibDataGeomDrawn[j], fibDataMaterial[j]);
    fibDataMesh[j].frustumCulled = false;
    fibDataMesh[j].name = "Fiber Data Mesh";
    scene.add(fibDataMesh[j]);

    // Add Strain Plots now!
    var xValues = [],
        yValues = [],
        zValues = [];

    xValues = fiberData[j].time;
    yValues = fiberData[j].md;

    var yRangeFiberPlot = [yValues.max(), yValues.min()];

    if (!isNaN(FIBER_MIN_MD[j])) {
        yRangeFiberPlot[1] = FIBER_MIN_MD[j];
    }

    if (!isNaN(FIBER_MAX_MD[j])) {
        yRangeFiberPlot[0] = FIBER_MAX_MD[j];
    }

    // yValues = new Array(xValues.length);
    // for ( n = 0; n < yValues.length; n ++){
    // 	yValues[n] = n + 1;
    // }
    var filteredStrainTime = fiberData[j].time;

    var zValues = fiberData[j].data;
    var zValue_trans = zValues[0].map((col, i) => zValues.map(row => row[i]));

    var fiberPlotData = [{
        x: xValues,
        y: yValues,
        z: zValue_trans,
        zmin: FIBER_MIN[j],
        zmax: FIBER_MAX[j],
        type: 'heatmap',
        colorbar: {
            title: '', //set title
            tickcolor: '#ffffff',
            tickfont: {
                color: '#ffffff',
            },
        },
        colorscale: 'Jet',
        hoverinfo: "x+y+z",
        hovertemplate: '<b>Time</b>: %{x}' +
            '<br><b>MD</b>: %{y}<br>' +
            '<b><b>Value: </b>%{z}</b>',
        yaxis: 'y',
        name: FIBER_DATA[j].name,
        // showscale: false,
        // colorscale: colorscaleValue,
    }, ];




    var fiberPlotLayout = {

        autosize: true,
        width: parentContainer.width() - 22.5,
        height: 150,
        margin: {
            l: 50,
            r: 10,
            b: 30,
            t: 10,
            pad: 0
        },
        shapes: [
            // 1st highlight during Feb 4 - Feb 6
            {
                type: 'line',
                // x-reference is assigned to the x-values
                xref: 'x',
                // y-reference is assigned to the plot paper [0,1]
                yref: 'y',
                x0: fiberData[j].time[0],
                y0: yValues.min(),
                x1: fiberData[j].time[0],
                y1: yValues.max(),
                fillcolor: '#ff0000',
                line: {
                    width: 2,
                    color: '#ff0000',
                }
            },
        ],
        titlefont: {
            size: 14,
            color: '#ffffff'
        },
        tickfont: {
            color: '#ffffff'
        },
        xaxis: {
            name: "Time",
            title: FIBER_DATA[j].name,
            color: '#fff',
            tickcolor: '#ffffff',
            linecolor: '#fff',
            gridcolor: '#777',
            ticks: "",
            showticklabels: false,
        },
        yaxis: {
            name: "Depth",
            autorange: 'false',
            // ticks: "",
            // showticklabels: true,
            color: '#fff',
            showgrid: false,
            tickcolor: '#ffffff',
            // fixedrange: true,
            // exponentformat: "none", 
            hoverformat: '.2f',
            // range: [2000, 5000]
            range: yRangeFiberPlot, //[yValues.min(), yValues.max()]
        },
        showlegend: false,
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)'
    }

    Plotly.newPlot('fiberDataPlots', fiberPlotData, fiberPlotLayout, {
        displaylogo: false,
        modeBarButtonsToRemove: ['toImage', 'sendDataToCloud', 'hoverCompareCartesian', 'select2d', 'lasso2d', 'toggleSpikelines']
    });

    document.getElementById('fiberDataPlots').on('plotly_click', function(data) {
        // console.log("Fiber plot click: ", data);
        if (FIBER_TIMELAPSE_START) {
            FIBER_DATA_INDEX = data.points[0].pointIndex[1];
        } else {
            FIBER_TIMELAPSE_START = true;
            FIBER_DATA_INDEX = data.points[0].pointIndex[1];
            render();
            FIBER_TIMELAPSE_START = false;
        }
    });






}

function createStrainPlots() {


    xValues = [], yValues = [], zValues = [];

    console.log('here');

    xValues = strainData.x;
    yValues = strainData.y;

    filteredStrainTime = strainData.x;

    for (var i = 0; i < strainData.data.length; i++) {
        zValues[i] = strainData.data[i].data;
    }

    zValue_trans = zValues[0].map((col, i) => zValues.map(row => row[i]));

    colorscaleValue = [
        ['-0.05', 'rgb(0,0,255)'],
        ['-0.0475', 'rgb(12.7500000000000,12.7500000000000,255)'],
        ['-0.0450', 'rgb(25.5000000000000,25.5000000000000,255)'],
        ['-0.0425', 'rgb(25.5000000000000,38.2500000000000,255)'],
        ['-0.0400', 'rgb(51,51,255)'],
        ['-0.0375', 'rgb(63.7500000000000,63.7500000000000,255)'],
        ['-0.0350', 'rgb(76.5000000000000,76.5000000000000,255)'],
        ['-0.0325', 'rgb(89.2500000000000,89.2500000000000,255)'],
        ['-0.0300', 'rgb(102,102,255)'],
        ['-0.0275', 'rgb(114.750000000000,114.750000000000,255)'],
        ['-0.0250', 'rgb(127.500000000000,127.500000000000,255)'],
        ['-0.0225', 'rgb(140.250000000000,140.250000000000,255)'],
        ['-0.0200', 'rgb(153,153,255)'],
        ['-0.0175', 'rgb(165.750000000000,165.750000000000,255)'],
        ['-0.0150', 'rgb(178.500000000000,178.500000000000,255)'],
        ['-0.0125', 'rgb(191.250000000000,191.250000000000,255)'],
        ['-0.0100', 'rgb(204,204,255)'],
        ['-0.0075', 'rgb(216.750000000000,216.750000000000,255)'],
        ['-0.0050', 'rgb(229.500000000000,229.500000000000,255)'],
        ['-0.0025', 'rgb(242.250000000000,242.250000000000,255)'],
        ['0', 'rgb(255,255,255)'],
        ['0.0025', 'rgb(255,242.250000000000,242.250000000000)'],
        ['0.0050', 'rgb(255,229.500000000000,229.500000000000)'],
        ['0.0075', 'rgb(255,216.750000000000,216.750000000000)'],
        ['0.0100', 'rgb(255,204,204)'],
        ['0.0125', 'rgb(255,191.250000000000,191.250000000000)'],
        ['0.0150', 'rgb(255,178.500000000000,178.500000000000)'],
        ['0.0175', 'rgb(255,165.750000000000,165.750000000000)'],
        ['0.0200', 'rgb(255,153,153)'],
        ['0.0225', 'rgb(255,140.250000000000,140.250000000000)'],
        ['0.0250', 'rgb(255,127.500000000000,127.500000000000)'],
        ['0.0275', 'rgb(255,114.750000000000,114.750000000000)'],
        ['0.0300', 'rgb(255,102,102)'],
        ['0.0325', 'rgb(255,89.2500000000000,89.2500000000000)'],
        ['0.0350', 'rgb(255,76.5000000000000,76.5000000000000)'],
        ['0.0375', 'rgb(255,63.7500000000000,63.7500000000000)'],
        ['0.0400', 'rgb(255,51,51)'],
        ['0.0425', 'rgb(255,38.2500000000000,38.2500000000000)'],
        ['0.0450', 'rgb(255,25.5000000000000,25.5000000000000)'],
        ['0.0475', 'rgb(255,12.7500000000000,12.7500000000000)'],
        ['0.05', 'rgb(255,48,39)'],
    ]

    var strainDataPlot = [{
        x: xValues,
        y: yValues,
        z: zValue_trans,
        type: 'heatmap',
        colorbar: {
            title: 'Speed(RPM)<br\><br\>', //set title
        },
        hoverinfo: "x+y",
        showscale: false,
    }, ];

    var strainLayout = {
        coloraxis: {
            showscale: false,
        },
        autosize: true,
        width: parentContainer.width() - 22.5,
        height: 150,
        margin: {
            l: 10,
            r: 10,
            b: 30,
            t: 20,
            pad: 0
        },
        //title: 'Pumplogs',
        titlefont: {
            // family: 'Courier New, monospace',
            size: 14,
            color: '#ffffff'
        },
        tickfont: {
            color: '#ffffff'
        },
        xaxis: {
            name: "Time",
            title: 'Strain Data - Time ( - 0.05 : 0 : +0.05 - Blue : White : Red)',
            color: '#fff',
            tickcolor: '#ffffff',
            linecolor: '#fff',
            gridcolor: '#777',
            ticks: "",
            showticklabels: false,
        },
        yaxis: {
            name: "Depth",
            autorange: 'reversed',
            ticks: "",
            showticklabels: false,
            color: '#fff',
            showgrid: false,
            tickcolor: '#ffffff',
            fixedrange: true,
            exponentformat: "none",
            hoverformat: '.2f'
        },
        showlegend: false,
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)'
    }

    Plotly.newPlot('strainPlots', strainDataPlot, strainLayout, {
        displaylogo: false,
        modeBarButtonsToRemove: ['toImage', 'sendDataToCloud', 'hoverCompareCartesian', 'select2d', 'lasso2d', 'toggleSpikelines']
    });


    document.getElementById('strainPlots').on('plotly_relayout',
        function(eventdata) {

            if (eventdata['dragmode'] != null) {
                return;
            }

            minX = Date.parse(filteredStrainTime[Math.round(eventdata['xaxis.range[0]'])]);
            maxX = Date.parse(filteredStrainTime[Math.round(eventdata['xaxis.range[1]'])]);

            if (PUMPLOGS_PRESENT) {

                //pumpLogsDrawnTime

                if (minX < Date.parse(pumpLogsDrawnTime[0]) || minX == null || isNaN(minX)) {
                    minXSet = 0;
                } else {

                    for (var i = 0; i < pumpLogsDrawnTime.length; i++) {
                        if (Date.parse(pumpLogsDrawnTime[i]) < minX) {
                            minXSet = i;
                        }
                    }
                }

                if (maxX > Date.parse(pumpLogsDrawnTime[pumpLogsDrawnTime.length - 1]) || maxX == null || isNaN(minX)) {
                    maxXSet = pumpLogsDrawnTime.length - 1;
                } else {
                    for (var i = 0; i < pumpLogsDrawnTime.length; i++) {
                        if (Date.parse(pumpLogsDrawnTime[i]) > maxX) {
                            maxXSet = i;
                            break;
                        }
                    }
                }

                Plotly.update('pumplogs', {}, {
                    'xaxis.range': [minXSet, maxXSet]
                }, [0]);


            }

            timelapse = [];
            for (var i = 0; i < events.length; i++) {
                if (events[i].time < minX || events[i].time > maxX) {
                    timelapse.push(0, 0, 0);
                } else {
                    timelapse.push(1, 1, 1);
                }
            }
            eventGeomDrawn.setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timelapse), 3));
            eventGeomPicking.setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timelapse), 3));

            addUpdateDepthErrorBars();
            addUpdateNorthingErrorBars();
            addUpdateEastErrorBars();
            addUpdateAziErrorBars();

            if (gyration.length > 0) {
                iTimelapse = [];
                for (var i = 0; i < gyration.length; i++) {
                    if (gyration[i].time < minX || gyration[i].time > maxX) {
                        iTimelapse.push(0, 0, 0);
                    } else {
                        iTimelapse.push(1, 1, 1);
                    }
                }
                gyrationGeomDrawn.setAttribute('iTimelapse', new THREE.InstancedBufferAttribute(new Float32Array(iTimelapse), 3))
            };


        });





}

function updateStrainPlots() {}

function resetEventTimelapse() {

    if (events.length == 0) {
        return;
    }

    // console.log("Reset timelapse called");
    ET_TIMELAPSE = false;

    var timeLapse = [];
    for (var i = 0; i < events.length; i++) {
        timeLapse.push(1, 1, 1);
    }
    eventCounterRender = eventCounterRender + Math.floor(events.length / 1000);
    eventGeomDrawn.setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timeLapse), 3));
    eventGeomPicking.setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timeLapse), 3));

    addUpdateDepthErrorBars();
    addUpdateNorthingErrorBars();
    addUpdateEastErrorBars();
    addUpdateAziErrorBars();
    if (gyration.length > 0) {

        var timeLapse = [];
        var iFilter = [];
        for (var i = 0; i < gyration.length; i++) {

            timeLapse.push(1, 1, 1);

            var result = false;

            for (var c = 0; c < Object.keys(eventControllers).length; c++) {
                try {
                    result = result || eventControllers[Object.keys(eventControllers)[c]][gyration[i].well][gyration[i].well + " " + gyration[i].stage];
                } catch {}
            }

            if (result) {
                iFilter.push(1, 1, 1);
            } else {
                iFilter.push(0, 0, 0);
            }

        }

        gyrationGeomDrawn.setAttribute('iTimelapse', new THREE.InstancedBufferAttribute(new Float32Array(timeLapse), 3));
        gyrationGeomDrawn.setAttribute('iFilter', new THREE.InstancedBufferAttribute(new Float32Array(iFilter), 3));


    }


    if (pumpLogs.length > 0) {

        Plotly.relayout('pumplogs', {
            shapes: [{
                type: 'line',
                // x-reference is assigned to the x-values
                xref: 'x',
                // y-reference is assigned to the plot paper [0,1]
                yref: 'y',
                x0: pumpLogs[0].time,
                x1: pumpLogs[0].time,
                y0: 0,
                y1: 1.1 * pumpLogs.map(e => e.pressure).max(),
                fillcolor: '#ffffff',
                line: {
                    width: 2,
                    color: '#ffffff',
                }
            }, ]
        });

    }


    if (STRAIN_PRESENT && strainData.data.length > 0) {

        var filteredStrainData = [];
        var filteredStrainTime = [];

        var eventDataSets = Object.keys(eventControllers).filter(e => e != "0" && e != "All Events");

        for (var i = 0; i < strainData.data.length; i++) {
            try {
                for (j = 0; j < eventDataSets.length; j++) {
                    if (eventControllers[eventDataSets[j]][strainData.data[i].well][strainData.data[i].well + " " + strainData.data[i].stage]) {
                        filteredStrainData.push(strainData.data[i].data);
                        filteredStrainTime.push(strainData.data[i].time);
                        break;
                    }
                }
            } catch (e) {
                console.log("Error in filtering strain data - trace - ");
                console.log(e);
            }
        }

        var zValue_trans = [];
        if (filteredStrainData.length > 0) {
            zValue_trans = filteredStrainData[0].map((col, i) => filteredStrainData.map(row => row[i]));
        }

        Plotly.restyle('strainPlots', {
            x: [filteredStrainTime],
            y: [strainData.y],
            z: [zValue_trans]
        }, [0]);

        Plotly.update('strainPlots', {}, {
            'xaxis.range': [0, filteredStrainTime.length]
        }, [0]);

    }

}

function resetSTTimelapse() {

    console.log("Reset ST timelapse called");
    ST_TIMELAPSE = false;

    var timeLapse = [];
    for (var i = 0; i < sourceTensor.length; i++) {
        timeLapse.push(1, 1, 1);
    }
    stCounterRender = stCounterRender + Math.floor(sourceTensor.length / 1000);
    sourceTensorGeomDrawn[0].setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timeLapse), 3));
    sourceTensorGeomDrawn[1].setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timeLapse), 3));
    sourceTensorGeomDrawn[2].setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timeLapse), 3));
    sourceTensorGeomDrawn[3].setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timeLapse), 3));
    sourceTensorGeomPicking.setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(timeLapse), 3));

    if (pumpLogs.length > 0 && PUMPLOGS_PRESENT) {

        Plotly.relayout('pumplogs', {
            shapes: [{
                type: 'line',
                // x-reference is assigned to the x-values
                xref: 'x',
                // y-reference is assigned to the plot paper [0,1]
                yref: 'y',
                x0: pumpLogs[0].time,
                x1: pumpLogs[0].time,
                y0: 0,
                y1: 1.1 * pumpLogs.map(e => e.pressure).max(),
                fillcolor: '#ffffff',
                line: {
                    width: 2,
                    color: '#ffffff',
                }
            }, ]
        });

    }

}

function mousewheel(event) {

    controls.noZoom = true;
    event.preventDefault();
    var factor = 50;
    var mX = (event.clientX / jQuery(container).width()) * 2 - 1;
    var mY = -(event.clientY / jQuery(container).height()) * 2 + 1;
    var vector = new THREE.Vector3(mX, mY, 0.5);
    //console.log(vector);
    vector.unproject(camera);
    vector.sub(camera.position);
    if (event.deltaY < 0) {
        camera.position.addVectors(camera.position,
            vector.setLength(factor));
        controls.target.addVectors(controls.target,
            vector.setLength(factor));
        camera.updateProjectionMatrix();
    } else {
        camera.position.subVectors(camera.position,
            vector.setLength(factor));
        controls.target.subVectors(controls.target,
            vector.setLength(factor));
        camera.updateProjectionMatrix();
    }

}

function updateScenes(rows = SCENE_ROWS, cols = SCENE_COLS) {

    if (rows * cols == 1) {
        compassRenderer.setSize(COMPASS_WIDTH, COMPASS_HEIGHT);
    } else {
        compassRenderer.setSize(parentContainer.width(), parentContainer.height());
    }

    for (n = 1; n <= 4; n++) {
        var divElement = document.getElementById("container" + (n).toString());
        divElement.style.left = '0px';
        divElement.style.top = '0px';
        divElement.style.width = '0px';
        divElement.style.height = '0px';


        divElement = document.getElementById("subcontainer" + (n).toString());
        divElement.style.position = 'absolute';
        divElement.style.left = '0px';
        divElement.style.top = '0px';
        divElement.style.width = '0px';
        divElement.style.height = '0px';

    }

    let index = 0;

    // Rows is height, cols is width;
    for (let i = 0; i < rows; i++) {
        for (let j = 0; j < cols; j++) {

            sceneView[index].left = j / cols;
            sceneView[index].bottom = 1 - ((i + 1) / rows);
            sceneView[index].width = 1 / cols;
            sceneView[index].height = 1 / rows;

            divElement = document.getElementById("container" + (index + 1).toString());
            divElement.style.left = sceneView[index].left * sceneView[index].maxWidth + 'px';
            divElement.style.top = (i / rows) * sceneView[index].maxHeight + 'px';
            divElement.style.width = sceneView[index].width * sceneView[index].maxWidth + 'px';
            divElement.style.height = sceneView[index].height * sceneView[index].maxHeight + 'px';

            divElement = document.getElementById("subcontainer" + (index + 1).toString());
            divElement.style.left = sceneView[index].left * sceneView[index].maxWidth + 'px';
            divElement.style.top = (i / rows) * sceneView[index].maxHeight + 'px';
            divElement.style.width = sceneView[index].width * sceneView[index].maxWidth + 'px';
            divElement.style.height = sceneView[index].height * sceneView[index].maxHeight + 'px';



            var width = sceneView[index].maxWidth * sceneView[index].width;
            var height = sceneView[index].maxHeight * sceneView[index].height

            if (isNaN(Number(sceneView[index].aspect)) || Number(sceneView[index].aspect) == 0) {
                var aspect = width / height;
            } else {
                var new_height = width / sceneView[index].aspect;
                if (new_height > height) {
                    width = height * sceneView[index].aspect
                } else {
                    height = new_height;
                }
            }

            if (sceneView[index].camera_type == 1) {
                sceneView[index].pCamera.aspect = width / height;
                sceneView[index].pCamera.updateProjectionMatrix();
            } else {

                var v3_object = new THREE.Vector3();
                var v3_camera = sceneView[index].oCamera.position;

                var line_of_sight = new THREE.Vector3();
                sceneView[index].oCamera.getWorldDirection(line_of_sight);

                var v3_distance = v3_object.clone().sub(v3_camera);
                var depth = v3_distance.dot(line_of_sight);

                var aspect = width / height;

                var height_ortho = depth;
                var width_ortho = height_ortho * aspect;

                sceneView[index].oCamera.left = -width_ortho / 2;
                sceneView[index].oCamera.right = width_ortho / 2;
                sceneView[index].oCamera.top = (height_ortho) / 2;
                sceneView[index].oCamera.bottom = -(height_ortho) / 2;
                sceneView[index].oCamera.near = 0.0010;
                sceneView[index].oCamera.far = 100000;
                sceneView[index].oCamera.updateProjectionMatrix();

            }


            // Add DAT GUI folders;
            var folderName = "Scene " + (index + 1).toString();

            if (!gui.__folders['Views'].__folders[folderName]) {

                var sceneFolder = gui.__folders['Views'].addFolder(folderName);

                sceneFolder.add(sceneView[index], 'camera_type_name', ['Orthographic', 'Perspective'])
                    .name("Camera Type")
                    .onChange(function(val) {


                        var sceneIndex = sceneView.indexOf(this.object);

                        var width = sceneView[sceneIndex].maxWidth * sceneView[sceneIndex].width;
                        var height = sceneView[sceneIndex].maxHeight * sceneView[sceneIndex].height

                        if (val == 'Perspective') {
                            sceneView[sceneIndex].camera_type = 1;
                            sceneView[sceneIndex].pControls.reset();
                            sceneView[sceneIndex].pCamera.position.set(sceneView[sceneIndex].oCamera.position.x, sceneView[sceneIndex].oCamera.position.y, sceneView[sceneIndex].oCamera.position.z);
                            sceneView[sceneIndex].pCamera.rotation.set(sceneView[sceneIndex].oCamera.rotation.x, sceneView[sceneIndex].oCamera.rotation.y, sceneView[sceneIndex].oCamera.rotation.z);
                            sceneView[sceneIndex].pCamera.zoom = sceneView[sceneIndex].oCamera.zoom;
                            sceneView[sceneIndex].pCamera.aspect = width / height;
                            sceneView[sceneIndex].pCamera.updateProjectionMatrix();
                            sceneView[sceneIndex].pControls.update();
                            sceneView[sceneIndex].pControls.target.set(sceneView[sceneIndex].oControls.target.x, sceneView[sceneIndex].oControls.target.y, sceneView[sceneIndex].oControls.target.z);
                            sceneView[sceneIndex].pControls.update();
                        } else {

                            sceneView[sceneIndex].camera_type = 0;
                            var v3_object = new THREE.Vector3();
                            var v3_camera = sceneView[sceneIndex].pCamera.position;
                            var line_of_sight = new THREE.Vector3();
                            sceneView[sceneIndex].pCamera.getWorldDirection(line_of_sight);
                            var v3_distance = v3_object.clone().sub(v3_camera);
                            var depth = v3_distance.dot(line_of_sight);
                            var aspect = width / height;
                            var height_ortho = depth; // * 2 * Math.atan( fov_y*(Math.PI/180) / 2 )
                            var width_ortho = height_ortho * aspect;
                            sceneView[sceneIndex].oControls.reset();
                            sceneView[sceneIndex].oCamera.left = -width_ortho / 2;
                            sceneView[sceneIndex].oCamera.right = width_ortho / 2;
                            sceneView[sceneIndex].oCamera.top = (height_ortho) / 2;
                            sceneView[sceneIndex].oCamera.bottom = -(height_ortho) / 2;
                            sceneView[sceneIndex].oCamera.updateProjectionMatrix();
                            sceneView[sceneIndex].oCamera.position.set(sceneView[sceneIndex].pCamera.position.x, sceneView[sceneIndex].pCamera.position.y, sceneView[sceneIndex].pCamera.position.z);
                            sceneView[sceneIndex].oCamera.rotation.set(sceneView[sceneIndex].pCamera.rotation.x, sceneView[sceneIndex].pCamera.rotation.y, sceneView[sceneIndex].pCamera.rotation.z);
                            sceneView[sceneIndex].oCamera.zoom = sceneView[sceneIndex].pCamera.zoom;
                            sceneView[sceneIndex].oCamera.updateProjectionMatrix();
                            sceneView[sceneIndex].oControls.target.set(sceneView[sceneIndex].pControls.target.x, sceneView[sceneIndex].pControls.target.y, sceneView[sceneIndex].pControls.target.z);
                            sceneView[sceneIndex].oControls.update();

                        }


                        updateScenes();
                    }).listen();

                if (!MODELLING_MODE) {

                    sceneFolder.add(sceneView[index], 'rotation').name('Rotation')
                        .onChange(function(value) {
                            var sceneIndex = sceneView.indexOf(this.object);
                            sceneView[sceneIndex].oControls.enableRotate = value;
                            sceneView[sceneIndex].pControls.enableRotate = value;
                        });


                    sceneFolder.add(sceneView[index], 'rotateSpeed').name('Rotation Speed')
                        .onChange(function(value) {
                            var sceneIndex = sceneView.indexOf(this.object);
                            sceneView[sceneIndex].oControls.rotateSpeed = value;
                            sceneView[sceneIndex].pControls.rotateSpeed = value;
                        });

                }

                sceneFolder.add(sceneView[index], 'top_view').name('Top View')
                    .onChange(function(value) {

                        var sceneIndex = sceneView.indexOf(this.object);

                        if (sceneView[sceneIndex].camera_type == 0) {

                            sceneView[sceneIndex].oControls.reset();
                            // sceneView[sceneIndex].oCamera.position.y = y_max * 0.99; //y_min + 0.75*(y_max - y_min);
                            // sceneView[sceneIndex].oCamera.position.x = 0; //y_max * 0.99; //y_min + 0.75*(y_max - y_min);
                            // sceneView[sceneIndex].oCamera.position.z = 0; //y_max * 0.99; //y_min + 0.75*(y_max - y_min);

                            sceneView[sceneIndex]['oCamera'].position.set(0.0 * (bk_x_max - bk_x_min), 0.5 * (bk_y_max - bk_y_min), 0.0 * (bk_z_max - bk_z_min));

                            if (topView % 4 == 0) {
                                sceneView[sceneIndex].oControls.target.set(0.001, 0, 0);
                            } else if (topView % 4 == 1) {
                                sceneView[sceneIndex].oControls.target.set(0, 0, -0.001);
                            } else if (topView % 4 == 2) {
                                sceneView[sceneIndex].oControls.target.set(-0.001, 0, 0);
                            } else if (topView % 4 == 3) {
                                sceneView[sceneIndex].oControls.target.set(0, 0, 0.001);
                            }

                            topView = topView + 1;
                            sceneView[sceneIndex].oCamera.updateProjectionMatrix();
                            sceneView[sceneIndex].oControls.update();
                            console.log("Updated Orthographic Controls.");

                        } else {

                            sceneView[sceneIndex].pControls.reset();
                            // sceneView[sceneIndex].pCamera.position.x = 0; //y_max * 0.99; //y_min + 0.75*(y_max - y_min);
                            // sceneView[sceneIndex].pCamera.position.z = 0; //y_max * 0.99; //y_min + 0.75*(y_max - y_min);
                            // sceneView[sceneIndex].pCamera.position.y = y_max * 0.99; //y_min + 0.75*(y_max - y_min);

                            sceneView[sceneIndex]['pCamera'].position.set(0.0 * (bk_x_max - bk_x_min), 0.5 * (bk_y_max - bk_y_min), 0.0 * (bk_z_max - bk_z_min));

                            if (topView % 4 == 0) {
                                sceneView[sceneIndex].pControls.target.set(0.001, 0, 0);
                            } else if (topView % 4 == 1) {
                                sceneView[sceneIndex].pControls.target.set(0, 0, -0.001);
                            } else if (topView % 4 == 2) {
                                sceneView[sceneIndex].pControls.target.set(-0.001, 0, 0);
                            } else if (topView % 4 == 3) {
                                sceneView[sceneIndex].pControls.target.set(0, 0, 0.001);
                            }

                            topView = topView + 1;
                            sceneView[sceneIndex].pCamera.updateProjectionMatrix();
                            sceneView[sceneIndex].pControls.update();

                            console.log("Updated Perspective Controls.");
                        }

                    });

                sceneFolder.add(sceneView[index], 'side_view').name('Side View')
                    .onChange(function(value) {

                        var sceneIndex = sceneView.indexOf(this.object);

                        if (sceneView[sceneIndex].camera_type == 0) {

                            sceneView[sceneIndex].oControls.reset();

                            if (false) {

                                if (sideView % 4 == 0) {
                                    sceneView[sceneIndex].oCamera.position.z = z_max - 1;
                                } else if (sideView % 4 == 1) {
                                    sceneView[sceneIndex].oCamera.position.z = z_min + 1;
                                } else if (sideView % 4 == 2) {
                                    sceneView[sceneIndex].oCamera.position.x = x_max - 1;
                                } else if (sideView % 4 == 3) {
                                    sceneView[sceneIndex].oCamera.position.x = x_min + 1;
                                }

                            }

                            if (sideView % 4 == 0) {
                                sceneView[sceneIndex].oCamera.position.z = Math.min(0.75 * z_max, 0.75 * x_max) - 1;
                            } else if (sideView % 4 == 1) {
                                sceneView[sceneIndex].oCamera.position.z = Math.max(0.75 * z_min, 0.75 * x_min); //0.75 * z_min + 1;
                            } else if (sideView % 4 == 2) {
                                sceneView[sceneIndex].oCamera.position.x = Math.min(0.75 * z_max, 0.75 * x_max) - 1;
                            } else if (sideView % 4 == 3) {
                                sceneView[sceneIndex].oCamera.position.x = Math.max(0.75 * z_min, 0.75 * x_min);
                            }

                            sceneView[sceneIndex].oCamera.updateProjectionMatrix();

                            sceneView[sceneIndex].oControls.update();

                            sideView = sideView + 1;

                        } else {

                            sceneView[sceneIndex].pControls.reset();

                            if (sideView % 4 == 0) {
                                sceneView[sceneIndex].pCamera.position.z = z_max - 1;
                            } else if (sideView % 4 == 1) {
                                sceneView[sceneIndex].pCamera.position.z = z_min + 1;
                            } else if (sideView % 4 == 2) {
                                sceneView[sceneIndex].pCamera.position.x = x_max - 1;
                            } else if (sideView % 4 == 3) {
                                sceneView[sceneIndex].pCamera.position.x = x_min + 1;
                            }

                            sceneView[sceneIndex].pCamera.updateProjectionMatrix();

                            sceneView[sceneIndex].pControls.update();

                            sideView = sideView + 1;

                        }

                    });

                take_snapshot_button[index] = sceneFolder.add(sceneView[index], 'take_snapshot').name('Take Snapshot')
                    .onChange(function(filename = Date.now().toString()) {

                        var sceneIndex = sceneView.indexOf(this.object);

                        var factor = SNAPSHOT_FACTOR;
                        var factor_compass_grid = COMPASS_FACTOR;
                        var widthImg = factor * sceneView[sceneIndex].maxWidth * sceneView[sceneIndex].width;
                        var heightImg = factor * sceneView[sceneIndex].maxHeight * sceneView[sceneIndex].height
                        var leftImg = factor * sceneView[sceneIndex].maxWidth * sceneView[sceneIndex].left;
                        var bottomImg = factor * sceneView[sceneIndex].maxHeight * sceneView[sceneIndex].bottom;
                        if (SCENE_ROWS > 1) {
                            bottomImg = heightImg - bottomImg;
                        }

                        if (!isNaN(sceneView[sceneIndex].aspect) && Number(sceneView[sceneIndex].aspect) != 0) {

                            var newHeightImg = widthImg / sceneView[sceneIndex].aspect;
                            if (newHeightImg > heightImg) {
                                var newWidthImg = heightImg * sceneView[sceneIndex].aspect
                                leftImg = leftImg + Math.abs(0.5 * (newWidthImg - widthImg));
                                widthImg = newWidthImg;
                            } else {
                                bottomImg = bottomImg + Math.abs(0.5 * (newHeightImg - heightImg));
                                heightImg = newHeightImg;
                            }
                        }

                        renderer.setSize(factor * renderer.getSize().x, factor * renderer.getSize().y, false);
                        compassRenderer.setSize(factor_compass_grid * compassRenderer.getSize().x, factor_compass_grid * compassRenderer.getSize().y, false);
                        render(factor, factor_compass_grid);
                        var masterScreenshot = renderer.domElement.toDataURL();
                        var compassScreenshot = compassRenderer.domElement.toDataURL();


                        renderer.setSize(renderer.getSize().x / factor, renderer.getSize().y / factor, false);
                        compassRenderer.setSize(compassRenderer.getSize().x / factor_compass_grid, compassRenderer.getSize().y / factor_compass_grid, false);

                        render();

                        arrowHelper.setLength(45, 10, 10);
                        arrowHelper2.setLength(45, 10, 10);
                        arrowHelper3.setLength(45, 10, 10);


                        var img1 = document.createElement('img');
                        var img2 = document.createElement('img');
                        var img3 = document.createElement('img');
                        var canvas = document.createElement('canvas');

                        img1.src = masterScreenshot;
                        img1.onload = function() {
                            img2.src = compassScreenshot;
                            // canvas.width = img1.width;
                            // canvas.height = img1.height;
                            canvas.width = widthImg;
                            canvas.height = heightImg;
                        };

                        img2.onload = function() {
                            if (SCENE_COLS > 1 || SCENE_ROWS > 1)
                                img3.src = img2.src;
                            else {
                                let imageData = removeImageBlanks(img2); //Will return cropped image data
                                img3.src = imageData;
                            }
                        }

                        img3.onload = function() {

                            var fontSize = 12 * factor_compass_grid; //Math.round(Math.min(img1.height, img1.width)/50);

                            var context = canvas.getContext('2d');
                            context.drawImage(img1, leftImg, bottomImg, widthImg, heightImg, 0, 0, widthImg, heightImg);
                            if (SCENE_COLS > 1 || SCENE_ROWS > 1) {
                                context.drawImage(img3, leftImg, bottomImg, widthImg, heightImg, 0, 0, widthImg, heightImg);
                            } else {
                                // context.drawImage(img3, 0.025*Math.min(img1.height, img1.width), img1.height - img3.height - 0.025*Math.min(img1.height, img1.width));
                                context.drawImage(img3, 0.025 * Math.min(heightImg, widthImg), heightImg - img3.height - 0.025 * Math.min(heightImg, widthImg));
                            }
                            context.font = fontSize.toString() + 'pt Nunito';
                            context.fillStyle = 'white';
                            context.fillText("Grid size " + gridSize + " ft", 0.025 * Math.min(heightImg, widthImg), 0.025 * Math.min(heightImg, widthImg) + fontSize);

                            var heightPerElement = 20 * factor_compass_grid;
                            for (let l = 0; l < LEGEND_TEXT.length; l++) {
                                var grd = context.createLinearGradient(0, 0, 0, 10);
                                grd.addColorStop(0, LEGEND_COLOR[l]);
                                grd.addColorStop(1, LEGEND_COLOR[l]);
                                context.fillStyle = grd;
                                if (false) {

                                    y0 = l * heightPerElement + 0.025 * Math.min(img1.height, img1.width);
                                    y1 = (l + 1) * heightPerElement + 0.025 * Math.min(img1.height, img1.width);
                                    context.fillRect(img1.width - 0.025 * Math.min(img1.height, img1.width) - 20 * factor, Math.ceil(y0) + 1, 20 * factor, 20 * factor);


                                    context.font = Math.round(0.6 * (y1 - y0)).toString() + 'pt Nunito';
                                    context.fillStyle = 'white';
                                    context.textAlign = "end";
                                    context.fillText(LEGEND_TEXT[l],
                                        img1.width - 0.025 * Math.min(img1.height, img1.width) - 30 * factor,
                                        y1 - Math.round(0.2 * (y1 - y0))
                                    );


                                }

                                var y0 = l * heightPerElement + 0.025 * Math.min(heightImg, widthImg);
                                var y1 = (l + 1) * heightPerElement + 0.025 * Math.min(heightImg, widthImg);
                                context.fillRect(widthImg - 0.025 * Math.min(heightImg, widthImg) - 20 * factor_compass_grid, Math.ceil(y0) + 1, 20 * factor_compass_grid, 20 * factor_compass_grid);
                                context.font = Math.round(0.6 * (y1 - y0)).toString() + 'pt Nunito';
                                context.fillStyle = 'white';
                                context.textAlign = "end";
                                context.fillText(LEGEND_TEXT[l],
                                    widthImg - 0.025 * Math.min(heightImg, widthImg) - 30 * factor_compass_grid,
                                    y1 - Math.round(0.2 * (y1 - y0))
                                );


                            }

                            var link = document.createElement('a');
                            link.download = 'BHS_Plot_' + filename + '.png';
                            link.href = canvas.toDataURL()
                            link.click();


                        };



                    });


                if (!MODELLING_MODE) {

                    sceneFolder.add(sceneView[index], 'aspect').name("Aspect Ratio (W/H)")
                        .onChange(function(val) {
                            if (Number(val) > 7 || Number(val) <= 0.3) {
                                var sceneIndex = sceneView.indexOf(this.object);
                                var width = sceneView[sceneIndex].maxWidth * sceneView[sceneIndex].width;
                                var height = sceneView[sceneIndex].maxHeight * sceneView[sceneIndex].height;
                                this.setValue(width / height);
                                sceneView[sceneIndex]['aspect'] = width / height;
                            }
                            window.dispatchEvent(new Event('resize'));
                        }).listen();

                    sceneFolder.add(sceneView[index], 'save_view').name('Save View')
                        .onChange(function(val) {

                            var doc = prompt("View Name?", "Custom View");

                            if (doc == null) return;

                            var sceneIndex = sceneView.indexOf(this.object);
                            var custom_views = localStorage.getItem("views-" + plot_id);
                            custom_views = JSON.parse(custom_views);

                            if (custom_views == null) {
                                custom_views = {};
                            }

                            custom_views[doc] = {
                                camera_type: sceneView[sceneIndex].camera_type,
                                position: sceneView[sceneIndex].camera_type == 0 ? sceneView[sceneIndex].oCamera.position : sceneView[sceneIndex].pCamera.position,
                                zoom: sceneView[sceneIndex].camera_type == 0 ? sceneView[sceneIndex].oCamera.zoom : sceneView[sceneIndex].pCamera.zoom,
                                rotation: sceneView[sceneIndex].camera_type == 0 ? sceneView[sceneIndex].oCamera.rotation : sceneView[sceneIndex].pCamera.rotation,
                                target: sceneView[sceneIndex].camera_type == 0 ? sceneView[sceneIndex].oControls.target : sceneView[sceneIndex].pControls.target,
                                aspect: sceneView[sceneIndex].aspect,
                                gridSize: gridSize
                            }

                            localStorage.setItem("views-" + plot_id, JSON.stringify(custom_views));

                            alert('Saved Camera Position');

                            updateScenes();

                        });

                }

            }


            sceneFolder = gui.__folders['Views'].__folders[folderName];

            var custom_views = localStorage.getItem("views-" + plot_id);
            custom_views = JSON.parse(custom_views);

            // Remove existing folders
            var views = [];
            if (custom_views) {
                views = Object.keys(custom_views);
            }

            for (var m = 0; m < views.length; m++) {

                if (sceneFolder.__folders[views[m]] != null) continue;

                var custom_view_folder = sceneFolder.addFolder(views[m]);

                custom_views[views[m]]['go_to_view'] = function() {

                    var custom_views_temp = localStorage.getItem("views-" + plot_id);
                    custom_views_temp = JSON.parse(custom_views_temp);
                    var view_details = custom_views_temp[this.folder];
                    var type = view_details.camera_type.toString();

                    sceneView[this.sceneIndex].camera_type = Number(type);

                    if (type == '1') {
                        sceneView[this.sceneIndex].camera_type_name = "Perspective";
                    } else if (type == '0') {
                        sceneView[this.sceneIndex].camera_type_name = "Orthographic";
                    }

                    var camPosition = view_details.position; // JSON.parse(getCookie("Save3CameraPosition_V_1_1" + @plot.id %>'));
                    var camRotation = view_details.rotation; // JSON.parse(getCookie("Save3CameraRotation_V_1_1" + @plot.id %>'));
                    var camZoom = view_details.zoom; // (getCookie("Save3CameraZoom_V_1_1" + @plot.id %>'));
                    var controlCenter = view_details.target; // JSON.parse(getCookie("Save3ControlCenter_V_1_1" + @plot.id %>'));

                    if (type == '1') {
                        sceneView[this.sceneIndex].pControls.reset();
                        sceneView[this.sceneIndex].pCamera.position.set(camPosition.x, camPosition.y, camPosition.z);
                        sceneView[this.sceneIndex].pCamera.rotation.set(camRotation.x, camRotation.y, camRotation.z);
                        sceneView[this.sceneIndex].pCamera.zoom = camZoom;
                        sceneView[this.sceneIndex].pCamera.updateProjectionMatrix();
                        sceneView[this.sceneIndex].pControls.update();
                        sceneView[this.sceneIndex].pControls.target.set(controlCenter.x, controlCenter.y, controlCenter.z);
                        sceneView[this.sceneIndex].pControls.update();
                        sceneView[this.sceneIndex].camera_type = 1;
                    } else if (type == '0') {

                        sceneView[this.sceneIndex].oControls.reset();
                        // sceneView[this.sceneIndex].oCamera.left = - parentContainer.width()  / 2;
                        // sceneView[this.sceneIndex].oCamera.right = parentContainer.width() / 2;
                        // sceneView[this.sceneIndex].oCamera.top = ( window.innerHeight - 100 ) / 2;
                        // sceneView[this.sceneIndex].oCamera.bottom = - ( window.innerHeight - 100 ) / 2;
                        sceneView[this.sceneIndex].oCamera.updateProjectionMatrix();
                        sceneView[this.sceneIndex].oCamera.position.set(camPosition.x, camPosition.y, camPosition.z);
                        sceneView[this.sceneIndex].oCamera.rotation.set(camRotation.x, camRotation.y, camRotation.z);
                        sceneView[this.sceneIndex].oCamera.zoom = camZoom;
                        sceneView[this.sceneIndex].oCamera.updateProjectionMatrix();
                        sceneView[this.sceneIndex].oControls.update();
                        sceneView[this.sceneIndex].oControls.target.set(controlCenter.x, controlCenter.y, controlCenter.z);
                        sceneView[this.sceneIndex].oControls.update();
                        sceneView[this.sceneIndex].camera_type = 0;
                    }

                    var gridSize = Number(view_details.gridSize);
                    gridSizeDropdown.setValue(Number(view_details.gridSize))

                    if (view_details.hasOwnProperty("aspect")) {
                        sceneView[this.sceneIndex].aspect = (view_details.aspect)
                    } else {
                        sceneView[this.sceneIndex].aspect = Number(view_details.width) / Number(view_details.height);
                    }
                    window.dispatchEvent(new Event('resize'));

                }

                custom_views[views[m]]['update_view'] = function() {

                    var confirmation = confirm("Are you sure you want to update view?");
                    if (!confirmation) return;

                    var custom_views_temp = localStorage.getItem("views-" + plot_id);
                    custom_views_temp = JSON.parse(custom_views_temp);

                    custom_views_temp[this.folder] = {
                        camera_type: sceneView[this.sceneIndex].camera_type,
                        position: sceneView[this.sceneIndex].camera_type == 0 ? sceneView[this.sceneIndex].oCamera.position : sceneView[this.sceneIndex].pCamera.position,
                        zoom: sceneView[this.sceneIndex].camera_type == 0 ? sceneView[this.sceneIndex].oCamera.zoom : sceneView[this.sceneIndex].pCamera.zoom,
                        rotation: sceneView[this.sceneIndex].camera_type == 0 ? sceneView[this.sceneIndex].oCamera.rotation : sceneView[this.sceneIndex].pCamera.rotation,
                        target: sceneView[this.sceneIndex].camera_type == 0 ? sceneView[this.sceneIndex].oControls.target : sceneView[this.sceneIndex].pControls.target,
                        // width: RENDERER_WIDTH,
                        // height: RENDERER_HEIGHT,
                        aspect: sceneView[this.sceneIndex].aspect,
                        gridSize: gridSize,
                    }

                    localStorage.setItem("views-" + plot_id, JSON.stringify(custom_views_temp));

                    alert('Updated Camera Position');


                }

                custom_views[views[m]]['delete_view'] = function() {

                    var confirmation = confirm("Are you sure you want to delete this view?");
                    if (!confirmation) return;

                    var custom_views_temp = localStorage.getItem("views-" + plot_id);
                    custom_views_temp = JSON.parse(custom_views_temp);
                    delete custom_views_temp[this.folder];
                    localStorage.setItem("views-" + plot_id, JSON.stringify(custom_views_temp));

                    for (let x = 1; x <= 4; x++) {
                        try {

                            gui.__folders['Views'].__folders['Scene ' + x.toString()].removeFolder(this.folder);
                        } catch (e) {
                            console.log("Error deleting view!", e);
                        }
                    }

                    alert('Deleted Camera Position');
                    window.dispatchEvent(new Event('resize'));

                }

                custom_view_folder.add(custom_views[views[m]], 'go_to_view').name('Go to view');
                custom_view_folder.add(custom_views[views[m]], 'update_view').name('Update view');
                custom_view_folder.add(custom_views[views[m]], 'delete_view').name('Delete view');
                custom_views[views[m]].folder = views[m];
                custom_views[views[m]].sceneIndex = index;

            }


            // var leftPos = sceneView[index].maxWidth * sceneView[index].left;
            // var bottomPos = sceneView[index].maxHeight * sceneView[index].bottom;
            // var widthScene = sceneView[index].maxWidth * sceneView[index].width;
            // var heightScene = sceneView[index].maxHeight * sceneView[index].height

            var leftPos = 0; //sceneView[index].left * sceneView[index].maxWidth;
            var bottomPos = 0; //(i / rows) * sceneView[index].maxHeight;
            var widthScene = sceneView[index].maxWidth * sceneView[index].width;
            var heightScene = sceneView[index].maxHeight * sceneView[index].height

            if (!isNaN(sceneView[index].aspect) && Number(sceneView[index].aspect) != 0) {

                var newHeightScene = widthScene / Number(sceneView[index].aspect);
                if (newHeightScene > heightScene) {
                    var newWidthScene = heightScene * sceneView[index].aspect
                    leftPos = leftPos + Math.abs(0.5 * (newWidthScene - widthScene));
                    widthScene = newWidthScene;
                } else {
                    bottomPos = bottomPos + Math.abs(0.5 * (newHeightScene - heightScene));
                    heightScene = newHeightScene;
                }
            }
            index = index + 1;

            divElement = document.getElementById("subcontainer" + (index).toString());
            divElement.style.position = 'absolute';
            divElement.style.left = leftPos + 'px';
            divElement.style.top = bottomPos + 'px';

            // divElement.style.left = '0px';
            // divElement.style.top = '0px';

            divElement.style.width = (widthScene - 2) + 'px';
            divElement.style.height = heightScene + 'px';


        }
    }

    index = index + 1;
    while (index < 5) {
        folderName = "Scene " + index.toString();
        gui.__folders['Views'].removeFolder(folderName);
        index++;
    }

}

function onWindowResize(IP_RENDERER_WIDTH = parentContainer.width(), IP_RENDERER_HEIGHT = parentContainer.height()) {





    // console.log("Resize fired!", "width", parentContainer.width(), "height", parentContainer.height());
    // IP_RENDERER_WIDTH = Math.min( RENDERER_WIDTH, parentContainer.width() );
    // IP_RENDERER_HEIGHT = Math.min( RENDERER_HEIGHT, window.innerHeight - 100 );

    renderer.setSize(parentContainer.width(), parentContainer.height(), false);

    for (var l = 0; l < sceneView.length; l++) {
        sceneView[l].maxWidth = parentContainer.width();
        sceneView[l].maxHeight = parentContainer.height();
    }
    updateScenes();

    pickingTexture = new THREE.WebGLRenderTarget(parentContainer.width(), parentContainer.height());

    stats.domElement.style.top = '10px';
    stats.domElement.style.right = '10px';
    stats.domElement.style.left = (window.innerWidth - 100).toString() + 'px';
    // stats.domElement.style.left = (parentContainer.width() - 100).toString() + 'px';

    if (PUMPLOGS_PRESENT) {
        document.getElementById('compass').style.bottom = ((PUMPLOG_SUBPLOTS + 1) * 160).toString();
        document.getElementById('pumplogs').style.display = 'block';
    } else {
        document.getElementById('compass').style.bottom = '40px';
        document.getElementById('pumplogs').style.display = 'none';
    }

    if (PUMPLOGS_PRESENT) {
        Plotly.relayout('pumplogs', {
            width: parentContainer.width() - 22.5, //, or any new width
        });
    }

    if (STRAIN_PRESENT) {
        document.getElementById('strainPlots').style.display = 'block';
        Plotly.relayout('strainPlots', {
            width: parentContainer.width() - 22.5, //, or any new width
        });
    } else {
        document.getElementById('strainPlots').style.display = 'none';
    }


    let width = sceneView[0].width * sceneView[0].maxWidth;
    let height = sceneView[0].height * sceneView[0].maxHeight;
    if (height > width) {
        height = sceneView[0].width * sceneView[0].maxWidth;
        width = sceneView[0].height * sceneView[0].maxHeight;
    }

    if (sceneView[0].aspect > 0) {

        if (sceneView[0].aspect <= (sceneView[0].maxWidth / sceneView[0].maxHeight)) {
            width = sceneView[0].maxHeight * sceneView[0].aspect;
            height = sceneView[0].maxHeight;
        } else {
            width = sceneView[0].maxWidth
            height = sceneView[0].maxWidth / sceneView[0].aspect;
        }
    }

    if (!MODELLING_MODE) {

        for (var n = 0; n < tmWellMesh.length; n++) {
            tmWellMesh[n].material.resolution.set(width, height); // important, for now
        }

        for (var n = 0; n < mtWellMesh.length; n++) {
            mtWellMesh[n].material.resolution.set(width, height); // important, for now
        }

        for (var n = 0; n < addWellMesh.length; n++) {
            addWellMesh[n].material.resolution.set(width, height); // important, for now
        }

    }


}

function getFormationColor(stageNo) {

    if (stageNo % 5 == 0)
        return 0x800000;

    else if (stageNo % 5 == 1)
        return 0x00008b;

    else if (stageNo % 5 == 2)
        return 0x006400;

    else if (stageNo % 5 == 3)
        return 0xe75480;

    else
        return 0x9b870c;

}

function getColor(stageNo) {

    // if (stageNo % 18 == 0)
    //     return 'rgba(230, 25, 75 ,1.0)';

    // else if (stageNo % 18 == 1)
    //     return 'rgba(60, 180, 75, 1.0)';

    // else if (stageNo % 18 == 2)
    //     return 'rgba(255, 225, 25, 1.0)';

    // else if (stageNo % 18 == 3)
    //     return 'rgba(0, 130, 200, 1.0)';

    // else if (stageNo % 18 == 4)
    //     return 'rgba(245, 130, 48, 1.0)';

    // else if (stageNo % 18 == 5)
    //     return 'rgba(145, 30, 180, 1.0)';

    // else if (stageNo % 18 == 6)
    //     return 'rgba(70, 240, 240, 1.0 )';

    // else if (stageNo % 18 == 7)
    //     return 'rgba(240, 50, 230, 1.0 )';

    // else if (stageNo % 18 == 8)
    //     return 'rgba(210, 245, 60, 1.0  )';

    // else if (stageNo % 18 == 9)
    //     return 'rgba(250, 190, 190, 1.0  )';

    // else if (stageNo % 18 == 10)
    //     return 'rgba(0, 128, 128, 1.0 )';

    // else if (stageNo % 18 == 11)
    //     return 'rgba(230, 190, 255, 1.0  )';

    // else if (stageNo % 18 == 12)
    //     return 'rgba(170, 110, 40, 1.0  )';

    // else if (stageNo % 18 == 13)
    //     return 'rgba(255, 250, 200, 1.0 )';

    // else if (stageNo % 18 == 14)
    //     return 'rgba(128, 0, 0, 1.0  )';

    // else if (stageNo % 18 == 15)
    //     return 'rgba(170, 255, 195, 1.0 )';

    // else if (stageNo % 18 == 16)
    //     return 'rgba(128, 128, 0, 1.0 )';

    // else if (stageNo % 18 == 17)
    //     return 'rgba(255, 215, 180, 1.0 )';

    // else
    //     return 'rgba(255,255,255,1.0)';

    var color_options = [
        'rgb(255,255,108)', // Stage 50, 100
        'rgb(253,104,109)', // Stage 01, 51
        'rgb(115,255,108)', // Stage 02, 52
        'rgb(222,172,223)',
        'rgb(114,255,255)',
        'rgb(255,192,0)',
        'rgb(255,127,80)',
        'rgb(26,206,121)',
        'rgb(252,96,255)',
        'rgb(255,215,0)',
        'rgb(0,176,240)',
        'rgb(0,250,154)',
        'rgb(251,106,50)',
        'rgb(179,179,179)',
        'rgb(204,102,255)',
        'rgb(26,206,121)',
        'rgb(224,162,35)',
        'rgb(18,140,255)',
        'rgb(146,230,39)',
        'rgb(252,101,179)',
        'rgb(235,108,32)',
        'rgb(226,217,40)',
        'rgb(234,0,112)',
        'rgb(25,190,188)',
        'rgb(33,254,255)',
        'rgb(28,219,1)',
        'rgb(254,254,11)',
        'rgb(255,99,71)',
        'rgb(21,197,255)',
        'rgb(204,102,255)',
        'rgb(127,255,212)',
        'rgb(95,158,160)',
        'rgb(255,160,122)',
        'rgb(155,194,230)',
        'rgb(255,165,0)',
        'rgb(251,2,255)',
        'rgb(255,228,181)',
        'rgb(72,209,204)',
        'rgb(174,0,254)',
        'rgb(255,140,0)',
        'rgb(35,255,5)',
        'rgb(23,176,254)',
        'rgb(24,183,3)',
        'rgb(232,139,165)',
        'rgb(288,255,10)',
        'rgb(255,131,13)',
        'rgb(252,0,130)',
        'rgb(105,204,238)',
        'rgb(254,165,182)',
        'rgb(139,199,38)', // Stage 49, 99
    ];

    return color_options[stageNo % color_options.length];

}

function textFix(string) {
    return string.replace('-', ' ').replace('_', ' ');
}

function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function camelCase(str) {
    return str
        .replace(/\s(.)/g, function(a) {
            return a.toUpperCase();
        })
        .replace(/\s/g, '')
        .replace(/^(.)/, function(b) {
            return b.toLowerCase();
        });
}

function removeImageBlanks(imageObject) {

    var imgWidth = imageObject.width;
    var imgHeight = imageObject.height;
    var canvas = document.createElement('canvas');
    canvas.setAttribute("width", imgWidth);
    canvas.setAttribute("height", imgHeight);
    var context = canvas.getContext('2d');
    context.drawImage(imageObject, 0, 0);

    var imageData = context.getImageData(0, 0, imgWidth, imgHeight),
        data = imageData.data,
        getRBG = function(x, y) {
            var offset = imgWidth * y + x;
            return {
                red: data[offset * 4],
                green: data[offset * 4 + 1],
                blue: data[offset * 4 + 2],
                opacity: data[offset * 4 + 3]
            };
        },
        isWhite = function(rgb) {
            // many images contain noise, as the white is not a pure #fff white
            return rgb.opacity < 0.01;
        },
        scanY = function(fromTop) {
            var offset = fromTop ? 1 : -1;

            // loop through each row
            for (var y = fromTop ? 0 : imgHeight - 1; fromTop ? (y < imgHeight) : (y > -1); y += offset) {

                // loop through each column
                for (var x = 0; x < imgWidth; x++) {
                    var rgb = getRBG(x, y);
                    if (!isWhite(rgb)) {
                        if (fromTop) {
                            return y;
                        } else {
                            return Math.min(y + 1, imgHeight);
                        }
                    }
                }
            }
            return null; // all image is white
        },
        scanX = function(fromLeft) {
            var offset = fromLeft ? 1 : -1;

            // loop through each column
            for (var x = fromLeft ? 0 : imgWidth - 1; fromLeft ? (x < imgWidth) : (x > -1); x += offset) {

                // loop through each row
                for (var y = 0; y < imgHeight; y++) {
                    var rgb = getRBG(x, y);
                    if (!isWhite(rgb)) {
                        if (fromLeft) {
                            return x;
                        } else {
                            return Math.min(x + 1, imgWidth);
                        }
                    }
                }
            }
            return null; // all image is white
        };

    var cropTop = scanY(true),
        cropBottom = scanY(false),
        cropLeft = scanX(true),
        cropRight = scanX(false),
        cropWidth = cropRight - cropLeft,
        cropHeight = cropBottom - cropTop;

    canvas.setAttribute("width", cropWidth);
    canvas.setAttribute("height", cropHeight);
    // finally crop the guy
    canvas.getContext("2d").drawImage(imageObject,
        cropLeft, cropTop, cropWidth, cropHeight,
        0, 0, cropWidth, cropHeight);

    return canvas.toDataURL();
}

function modeCount(array) {

    if (array.length == 0) return null;

    var modeMap = {},
        maxEl = array[0],
        maxCount = 1;

    for (var i = 0; i < array.length; i++) {
        var el = array[i];

        if (modeMap[el] == null) modeMap[el] = 1;
        else modeMap[el]++;

        if (modeMap[el] > maxCount) {
            maxEl = el;
            maxCount = modeMap[el];
        } else if (modeMap[el] == maxCount) {
            maxEl += "&" + el;
            maxCount = modeMap[el];
        }
    }
    return maxCount;
}

// function addUpdateAziErrorBars(add = true) {
//     if (add) {
//         if (!eventErrorOptions['azimuthError']) {
//             return;
//         }

//         const linePositionsEast = new Float32Array(3 * events.length * 18);
//         for (let i = 0; i < events.length; i++) {
//             // Check if event meets conditions
//             if (!isEventValid(i)) {
//                 continue;
//             }

//             // Create curve points for azimuth error line
//             const curvePoints = [new THREE.Vector3((DOWNSAMPLE * events[i].azimuthErrorNorth1 - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
//                     (DOWNSAMPLE * events[i].azimuthErrorTVDSS1 - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
//                     (DOWNSAMPLE * events[i].azimuthErrorEast1 - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
//                 ),
//                 new THREE.Vector3(
//                     (DOWNSAMPLE * events[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
//                     (DOWNSAMPLE * events[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
//                     (DOWNSAMPLE * events[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
//                 ),
//                 new THREE.Vector3(
//                     (DOWNSAMPLE * events[i].azimuthErrorNorth2 - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
//                     (DOWNSAMPLE * events[i].azimuthErrorTVDSS2 - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
//                     (DOWNSAMPLE * events[i].azimuthErrorEast2 - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
//                 )
//             ];

//             // Create curve and get points for line positions
//             const curve = new THREE.CatmullRomCurve3(curvePoints);
//             const points = curve.getPoints(9);
//             let counter = 0;
//             for (let n = 0; n < 10; n++) {
//                 linePositionsEast[54 * i + counter] = points[n].x;
//                 counter++;
//                 linePositionsEast[54 * i + counter] = points[n].y;
//                 counter++;
//                 linePositionsEast[54 * i + counter] = points[n].z;
//                 counter++;
//                 if (n !== 0 && n !== 9) {
//                     linePositionsEast[54 * i + counter] = points[n].x;
//                     counter++;
//                     linePositionsEast[54 * i + counter] = points[n].y;
//                     counter++;
//                     linePositionsEast[54 * i + counter] = points[n].z;
//                     counter++;
//                 }
//             }
//         }
//         azimuthErrorLine.geometry.setAttribute('position', new THREE.BufferAttribute(linePositionsEast, 3));
//     } else {
//         azimuthErrorLine.geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(0), 3));
//     }

//     azimuthErrorLine.geometry.attributes.position.needsUpdate = true;


// }

function addUpdateAziErrorBars(add = true) {


    if (add) {

        if (!eventErrorOptions['azimuthError']) return;

        var linePositionsEast = new Float32Array(3 * events.length * 20);
        for (let i = 0; i < events.length; i++) {

            var condition = 1;
            condition *= eventGeomDrawn.attributes.iScale.array[3 * i];
            condition *= eventGeomDrawn.attributes.iVisible.array[3 * i];
            condition *= eventGeomDrawn.attributes.iMisfitFilter.array[3 * i];
            condition *= eventGeomDrawn.attributes.iMagnitudeFilter.array[3 * i];
            condition *= eventGeomDrawn.attributes.iErrorFilter.array[3 * i];
            condition *= eventGeomDrawn.attributes.iErrorFilter.array[3 * i + 1];
            condition *= eventGeomDrawn.attributes.iErrorFilter.array[3 * i + 2];
            condition *= eventGeomDrawn.attributes.iSize.array[3 * i];
            condition *= eventGeomDrawn.attributes.iSizeVariation.array[3 * i];
            condition *= eventGeomDrawn.attributes.timelapse.array[3 * i];
            condition *= eventGeomDrawn.attributes.iWellPickedFilter.array[3 * i];

            if (condition == 0) continue;
            if (isNaN(events[i].azimuthErrorNorth1)) continue;

            let curvePoints = [];

            curvePoints.push(
                new THREE.Vector3(
                    (DOWNSAMPLE * events[i].azimuthErrorNorth1 - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    (DOWNSAMPLE * events[i].azimuthErrorTVDSS1 - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    (DOWNSAMPLE * events[i].azimuthErrorEast1 - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                )
            )

            curvePoints.push(
                new THREE.Vector3(
                    (DOWNSAMPLE * events[i].x - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    (DOWNSAMPLE * events[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    (DOWNSAMPLE * events[i].z - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                )
            )

            curvePoints.push(
                new THREE.Vector3(
                    (DOWNSAMPLE * events[i].azimuthErrorNorth2 - bk_x_min - 0.5 * (bk_x_max - bk_x_min)),
                    (DOWNSAMPLE * events[i].azimuthErrorTVDSS2 - bk_y_min - 0.5 * (bk_y_max - bk_y_min)),
                    (DOWNSAMPLE * events[i].azimuthErrorEast2 - bk_z_min - 0.5 * (bk_z_max - bk_z_min))
                )
            )

            let curve = new THREE.CatmullRomCurve3(curvePoints);
            let points = curve.getPoints(10);


            let counter = 0;
            for (let n = 0; n <= 10; n++) {
                linePositionsEast[60 * i + counter] = points[n].x;
                counter = counter + 1;
                linePositionsEast[60 * i + counter] = points[n].y;
                counter = counter + 1;
                linePositionsEast[60 * i + counter] = points[n].z;
                counter = counter + 1;
                if (n != 0 && n != 10) {
                    linePositionsEast[60 * i + counter] = points[n].x;
                    counter = counter + 1;
                    linePositionsEast[60 * i + counter] = points[n].y;
                    counter = counter + 1;
                    linePositionsEast[60 * i + counter] = points[n].z;
                    counter = counter + 1;
                }
            }
        }

        azimuthErrorLine.geometry.setAttribute('position', new THREE.BufferAttribute(linePositionsEast, 3));
    } else {
        azimuthErrorLine.geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(0), 3));
    }
    azimuthErrorLine.geometry.attributes.position.needsUpdate = true;
}

function addUpdateEastErrorBars(add = true) {

    if (add) {

        if (!eventErrorOptions['eastError']) return;

        var linePositionsEast = new Float32Array(2 * events.length * 3);
        for (let i = 0; i < events.length; i++) {

            var condition = 1;
            condition *= eventGeomDrawn.attributes.iScale.array[3 * i];
            condition *= eventGeomDrawn.attributes.iVisible.array[3 * i];
            condition *= eventGeomDrawn.attributes.iMisfitFilter.array[3 * i];
            condition *= eventGeomDrawn.attributes.iMagnitudeFilter.array[3 * i];
            condition *= eventGeomDrawn.attributes.iErrorFilter.array[3 * i];
            condition *= eventGeomDrawn.attributes.iErrorFilter.array[3 * i + 1];
            condition *= eventGeomDrawn.attributes.iErrorFilter.array[3 * i + 2];
            condition *= eventGeomDrawn.attributes.iSize.array[3 * i];
            condition *= eventGeomDrawn.attributes.iSizeVariation.array[3 * i];
            condition *= eventGeomDrawn.attributes.timelapse.array[3 * i];
            condition *= eventGeomDrawn.attributes.iWellPickedFilter.array[3 * i];

            if (condition == 0) continue;

            linePositionsEast[6 * i] = (EXAGGERATION_X * DOWNSAMPLE * Number(events[i].x) - bk_x_min - 0.5 * (bk_x_max - bk_x_min));
            linePositionsEast[6 * i + 1] = (EXAGGERATION_Y * DOWNSAMPLE * events[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min));
            linePositionsEast[6 * i + 2] = (EXAGGERATION_Z * DOWNSAMPLE * (Number(events[i].z) - Number(events[i].eastError)) - bk_z_min - 0.5 * (bk_z_max - bk_z_min));
            linePositionsEast[6 * i + 3] = (EXAGGERATION_X * DOWNSAMPLE * Number(events[i].x) - bk_x_min - 0.5 * (bk_x_max - bk_x_min));
            linePositionsEast[6 * i + 4] = (EXAGGERATION_Y * DOWNSAMPLE * (events[i].y) - bk_y_min - 0.5 * (bk_y_max - bk_y_min));
            linePositionsEast[6 * i + 5] = (EXAGGERATION_Z * DOWNSAMPLE * (Number(events[i].z) + Number(events[i].eastError)) - bk_z_min - 0.5 * (bk_z_max - bk_z_min));
        }
        eastErrorLine.geometry.setAttribute('position', new THREE.BufferAttribute(linePositionsEast, 3));
    } else {
        eastErrorLine.geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(0), 3));
    }

    eastErrorLine.geometry.attributes.position.needsUpdate = true;
}

function addUpdateNorthingErrorBars(add = true) {

    if (add) {

        if (!eventErrorOptions['northError']) return;

        var linePositionsNorth = new Float32Array(2 * events.length * 3);
        for (let i = 0; i < events.length; i++) {

            var condition = 1;
            condition *= eventGeomDrawn.attributes.iScale.array[3 * i];
            condition *= eventGeomDrawn.attributes.iVisible.array[3 * i];
            condition *= eventGeomDrawn.attributes.iMisfitFilter.array[3 * i];
            condition *= eventGeomDrawn.attributes.iMagnitudeFilter.array[3 * i];

            condition *= eventGeomDrawn.attributes.iErrorFilter.array[3 * i];
            condition *= eventGeomDrawn.attributes.iErrorFilter.array[3 * i + 1];
            condition *= eventGeomDrawn.attributes.iErrorFilter.array[3 * i + 2];

            condition *= eventGeomDrawn.attributes.iSize.array[3 * i];
            condition *= eventGeomDrawn.attributes.iSizeVariation.array[3 * i];
            condition *= eventGeomDrawn.attributes.timelapse.array[3 * i];
            condition *= eventGeomDrawn.attributes.iWellPickedFilter.array[3 * i];

            if (condition == 0) continue;


            linePositionsNorth[6 * i] = (EXAGGERATION_X * DOWNSAMPLE * (Number(events[i].x) - Number(events[i].northError)) - bk_x_min - 0.5 * (bk_x_max - bk_x_min));
            linePositionsNorth[6 * i + 1] = (EXAGGERATION_Y * DOWNSAMPLE * events[i].y - bk_y_min - 0.5 * (bk_y_max - bk_y_min));
            linePositionsNorth[6 * i + 2] = (EXAGGERATION_Z * DOWNSAMPLE * (Number(events[i].z)) - bk_z_min - 0.5 * (bk_z_max - bk_z_min));
            linePositionsNorth[6 * i + 3] = (EXAGGERATION_X * DOWNSAMPLE * (Number(events[i].x) + Number(events[i].northError)) - bk_x_min - 0.5 * (bk_x_max - bk_x_min));
            linePositionsNorth[6 * i + 4] = (EXAGGERATION_Y * DOWNSAMPLE * (events[i].y) - bk_y_min - 0.5 * (bk_y_max - bk_y_min));
            linePositionsNorth[6 * i + 5] = (EXAGGERATION_Z * DOWNSAMPLE * (Number(events[i].z)) - bk_z_min - 0.5 * (bk_z_max - bk_z_min));
        }
        northErrorLine.geometry.setAttribute('position', new THREE.BufferAttribute(linePositionsNorth, 3));
    } else {
        northErrorLine.geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(0), 3));
    }
    northErrorLine.geometry.attributes.position.needsUpdate = true;
}

function addUpdateDepthErrorBars(add = true) {


    if (add) {

        if (!eventErrorOptions['depthError']) return;

        var linePositionsDepth = new Float32Array(2 * events.length * 3);
        for (let i = 0; i < events.length; i++) {

            var condition = 1;
            condition *= eventGeomDrawn.attributes.iScale.array[3 * i];
            condition *= eventGeomDrawn.attributes.iVisible.array[3 * i];
            condition *= eventGeomDrawn.attributes.iMisfitFilter.array[3 * i];
            condition *= eventGeomDrawn.attributes.iMagnitudeFilter.array[3 * i];

            condition *= eventGeomDrawn.attributes.iErrorFilter.array[3 * i];
            condition *= eventGeomDrawn.attributes.iErrorFilter.array[3 * i + 1];
            condition *= eventGeomDrawn.attributes.iErrorFilter.array[3 * i + 2];

            condition *= eventGeomDrawn.attributes.iSize.array[3 * i];
            condition *= eventGeomDrawn.attributes.iSizeVariation.array[3 * i];
            condition *= eventGeomDrawn.attributes.timelapse.array[3 * i];
            condition *= eventGeomDrawn.attributes.iWellPickedFilter.array[3 * i];
            if (condition == 0) continue;

            linePositionsDepth[6 * i] = (EXAGGERATION_X * DOWNSAMPLE * (Number(events[i].x)) - bk_x_min - 0.5 * (bk_x_max - bk_x_min));
            linePositionsDepth[6 * i + 1] = (EXAGGERATION_Y * DOWNSAMPLE * (Number(events[i].y) - Number(events[i].depthError)) - bk_y_min - 0.5 * (bk_y_max - bk_y_min));
            linePositionsDepth[6 * i + 2] = (EXAGGERATION_Z * DOWNSAMPLE * (Number(events[i].z)) - bk_z_min - 0.5 * (bk_z_max - bk_z_min));
            linePositionsDepth[6 * i + 3] = (EXAGGERATION_X * DOWNSAMPLE * (Number(events[i].x)) - bk_x_min - 0.5 * (bk_x_max - bk_x_min));
            linePositionsDepth[6 * i + 4] = (EXAGGERATION_Y * DOWNSAMPLE * (Number(events[i].y) + Number(events[i].depthError)) - bk_y_min - 0.5 * (bk_y_max - bk_y_min));
            linePositionsDepth[6 * i + 5] = (EXAGGERATION_Z * DOWNSAMPLE * (Number(events[i].z)) - bk_z_min - 0.5 * (bk_z_max - bk_z_min));
        }
        depthErrorLine.geometry.setAttribute('position', new THREE.BufferAttribute(linePositionsDepth, 3));
    } else {
        depthErrorLine.geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(0), 3));
    }
    depthErrorLine.geometry.attributes.position.needsUpdate = true;
}

function mode(arr) {
    return arr.sort((a, b) =>
        arr.filter(v => v === a).length -
        arr.filter(v => v === b).length
    ).pop();
}

function getRelativeDepth(original_depth, original_x, new_x, original_y, new_y, azimuth, inclination) {

    if (azimuth == 0 && inclination == 0) {
        return original_depth;
    }

    var plane_4 = Math.sin(inclination * Math.PI / 180) * Math.cos(azimuth * Math.PI / 180);
    var plane_5 = Math.sin(inclination * Math.PI / 180) * Math.sin(azimuth * Math.PI / 180);


    var nrmZ = 1 - (Math.pow(plane_4, 2) + Math.pow(plane_5, 2));

    // console.log(plane_4, plane_5, original_depth, original_x, new_x, original_y, new_y, azimuth, inclination);
    // console.log("NrmZ", nrmZ);

    if (nrmZ <= 0) {
        return -99999999;
    } else {
        nrmZ = Math.pow(nrmZ, 0.5);
        var difference = (plane_4 * (original_x - new_x) + plane_5 * (original_y - new_y)) / nrmZ;
        // console.log(difference);
        // console.log("difference", difference);
        return original_depth - difference;
    }
}



// exports.seismicViewer = seismicViewer;
// Object.defineProperty(exports, '__esModule', { value: true });
// }));


// // Helpers

Array.prototype.max = function() {
    return Math.max.apply(null, this);
};

Array.prototype.min = function() {
    return Math.min.apply(null, this);
}

Array.prototype.average = function() {
    return this.reduce(function(a, b) { return a + b; }) / this.length;
};

Array.prototype.mean = function() {
    return this.reduce(function(a, b) { return a + b; }) / this.length;
};

Array.prototype.contains = function(v) {
    for (var i = 0; i < this.length; i++) {
        if (this[i] === v) return true;
    }
    return false;
};

Array.prototype.unique = function() {
    var arr = [];
    for (var i = 0; i < this.length; i++) {
        if (!arr.contains(this[i])) {
            arr.push(this[i]);
        }
    }
    return arr;
}

Array.prototype.asc = function() {
    return this.sort((a, b) => a - b);
}

Array.prototype.quantile = function(q) {
    const sorted = this.asc();
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
};


String.prototype.format = function() {
    var str = this;
    for (var i = 0; i < arguments.length; i++) {
        str = str.replace('{' + i + '}', arguments[i]);
    }
    return str;
};

dat.GUI.prototype.removeFolder = function(name) {
    // console.log("this", this);
    var folder = this.__folders[name];
    if (!folder) {
        return;
    }
    folder.close();
    this.__ul.removeChild(folder.domElement.parentNode);
    delete __folders[name];
    onResize();
}

dat.GUI.prototype.checkFolder = function(name) {
    var folder = __folders[name];
    if (!folder)
        return false;
    else
        return true;
}

THREE.Vector3.prototype.equals = function(v, epsilon = Number.EPSILON) {
    return ((Math.abs(v.x - this.x) < epsilon) && (Math.abs(v.y - this.y) < epsilon) && (Math.abs(v.z - this.z) < epsilon));
}



function findLinearityAzimuthOfPoints(values_x, values_y) {

    var azimuth = 0,
        linearity = 0;

    var mean_x = values_x.mean();
    var mean_y = values_y.mean();

    var input_svd = [];
    for (var i = 0; i < values_x.length; i++) {
        input_svd[i] = [];
        input_svd[i][0] = values_x[i] - mean_x;
        input_svd[i][1] = values_y[i] - mean_y;
    }

    var A = eig.Matrix.fromArray(input_svd);
    var svd = eig.Decompositions.svd(A, true);

    // sv needed
    // var eigenValues = [];
    // for (var i = 0; i < values_x.length; i ++){
    //     eigenValues.push( svd.sv.get(i, 0) );
    // }

    // var eigenVectors = [];
    // for (var i = 0; i < values_x.length; i ++){
    //     eigenVectors.push([]);
    //     eigenVectors[i].push( svd.V.get(i, 0), svd.V.get(i, 1) );
    // }


    var eigVec1 = [svd.V.get(0, 0), svd.V.get(1, 0)];
    var eigVal1 = svd.sv.get(0, 0)

    var eigVec2 = [svd.V.get(0, 1), svd.V.get(1, 1)];
    var eigVal2 = svd.sv.get(1, 0)


    azimuth = Math.atan2(eigVec1[1], eigVec1[0]) * 180 / Math.PI;
    linearity = 1 - eigVal2 / eigVal1;


    if (azimuth > 90) {
        azimuth = azimuth - 180;
    }


    if (azimuth < -90) {
        azimuth = azimuth + 180;
    }

    azimuth = 90 - azimuth;

    return {
        linearity: linearity,
        azimuth: azimuth,
    }


}

function findLineByLeastSquares(values_x, values_y) {
    var x_sum = 0;
    var y_sum = 0;
    var xy_sum = 0;
    var xx_sum = 0;
    var count = 0;

    /*
     * The above is just for quick access, makes the program faster
     */
    var x = 0;
    var y = 0;
    var values_length = values_x.length;

    if (values_length != values_y.length) {
        throw new Error('The parameters values_x and values_y need to have same size!');
    }

    /*
     * Above and below cover edge cases
     */
    if (values_length === 0) {
        return [
            [],
            []
        ];
    }

    /*
     * Calculate the sum for each of the parts necessary.
     */
    for (let i = 0; i < values_length; i++) {
        x = values_x[i];
        y = values_y[i];
        x_sum += x;
        y_sum += y;
        xx_sum += x * x;
        xy_sum += x * y;
        count++;
    }

    /*
     * Calculate m and b for the line equation:
     * y = x * m + b
     */
    var m = (count * xy_sum - x_sum * y_sum) / (count * xx_sum - x_sum * x_sum);
    var b = (y_sum / count) - (m * x_sum) / count;

    /*
     * We then return the x and y data points according to our fit
     */
    var result_values_x = [];
    var result_values_y = [];

    for (let i = 0; i < values_length; i++) {
        x = values_x[i];
        y = x * m + b;
        result_values_x.push(x);
        result_values_y.push(y);
    }

    // return [result_values_x, result_values_y];
    return Math.atan(m) * 180 / Math.PI;

}

function setCurrentStages(stages) {
    if (perfGeomDrawn != null) {
        perfGeomDrawn.setAttribute('iPulsate', new THREE.InstancedBufferAttribute(new Float32Array(Array(perf.length * 3).fill(1)), 3));
    }
    currentStages = stages;
    currentStagesPerfIndex = perf.map((e, i) => stages.indexOf(e['name']) > -1 ? i : undefined).filter(x => x > -1);
}

async function getImageData(factor = 4) {

    var sceneIndex = 0;

    var factor_compass_grid = factor;
    var widthImg = factor * sceneView[sceneIndex].maxWidth; // * sceneView[sceneIndex].width;
    var heightImg = factor * sceneView[sceneIndex].maxHeight; // * sceneView[sceneIndex].height
    var leftImg = 0; //factor * sceneView[sceneIndex].maxWidth * sceneView[sceneIndex].left;
    var bottomImg = 0; //factor * sceneView[sceneIndex].maxHeight * sceneView[sceneIndex].bottom;
    // if (SCENE_ROWS > 1) {
    // bottomImg = heightImg - bottomImg;
    // }

    // if (!isNaN(sceneView[sceneIndex].aspect) && Number(sceneView[sceneIndex].aspect) != 0) {

    //     var newHeightImg = widthImg / sceneView[sceneIndex].aspect;
    //     if (newHeightImg > heightImg) {
    //         var newWidthImg = heightImg * sceneView[sceneIndex].aspect
    //         leftImg = leftImg + Math.abs(0.5 * (newWidthImg - widthImg));
    //         widthImg = newWidthImg;
    //     } else {
    //         bottomImg = bottomImg + Math.abs(0.5 * (newHeightImg - heightImg));
    //         heightImg = newHeightImg;
    //     }
    // }

    renderer.setSize(factor * renderer.getSize().x, factor * renderer.getSize().y, false);
    compassRenderer.setSize(factor_compass_grid * compassRenderer.getSize().x, factor_compass_grid * compassRenderer.getSize().y, false);
    render(factor, factor_compass_grid);
    var masterScreenshot = renderer.domElement.toDataURL();
    var compassScreenshot = compassRenderer.domElement.toDataURL();


    renderer.setSize(renderer.getSize().x / factor, renderer.getSize().y / factor, false);
    compassRenderer.setSize(compassRenderer.getSize().x / factor_compass_grid, compassRenderer.getSize().y / factor_compass_grid, false);

    render();

    arrowHelper.setLength(45, 10, 10);
    arrowHelper2.setLength(45, 10, 10);
    arrowHelper3.setLength(45, 10, 10);


    var img1 = document.createElement('img');
    var img2 = document.createElement('img');
    var img3 = document.createElement('img');
    var canvas = document.createElement('canvas');

    img1.src = masterScreenshot;
    await img1.decode();

    img2.src = compassScreenshot;
    canvas.width = widthImg;
    canvas.height = heightImg;

    await img2.decode();

    let imageData = removeImageBlanks(img2); //Will return cropped image data
    img3.src = imageData;

    await img3.decode();

    var fontSize = 12 * factor_compass_grid; ////Math.round(Math.min(img1.height, img1.width)/50);
    var context = canvas.getContext('2d');
    context.drawImage(img1, leftImg, bottomImg, widthImg, heightImg, 0, 0, widthImg, heightImg);
    if (SCENE_COLS > 1 || SCENE_ROWS > 1) {
        // context.drawImage(img3, leftImg, bottomImg, widthImg, heightImg, 0, 0, widthImg, heightImg);
        context.drawImage(img2, 0, heightImg - img2.height - 0.025 * Math.min(heightImg, widthImg));
    } else {
        // context.drawImage(img3, 0.025*Math.min(img1.height, img1.width), img1.height - img3.height - 0.025*Math.min(img1.height, img1.width));
        context.drawImage(img3, 0.025 * Math.min(heightImg, widthImg), heightImg - img3.height - 0.025 * Math.min(heightImg, widthImg));
    }
    context.font = fontSize.toString() + 'pt Nunito';
    context.fillStyle = 'white';
    context.fillText("Grid size " + gridSize + " ft", 0.025 * Math.min(heightImg, widthImg), 0.025 * Math.min(heightImg, widthImg) + fontSize);

    if (document.getElementById("azimuthValue").innerHTML != "") {
        context.textAlign = 'right';
        context.fillText(document.getElementById("azimuthValue").innerHTML, widthImg - 0.025 * Math.min(heightImg, widthImg), 0.025 * Math.min(heightImg, widthImg) + fontSize);
        context.textAlign = 'left';
    }



    var heightPerElement = 20 * factor_compass_grid;
    for (let l = 0; l < LEGEND_TEXT.length; l++) {
        var grd = context.createLinearGradient(0, 0, 0, 10);
        grd.addColorStop(0, LEGEND_COLOR[l]);
        grd.addColorStop(1, LEGEND_COLOR[l]);
        context.fillStyle = grd;
        var y0 = l * heightPerElement + 0.025 * Math.min(heightImg, widthImg);
        var y1 = (l + 1) * heightPerElement + 0.025 * Math.min(heightImg, widthImg);
        context.fillRect(widthImg - 0.025 * Math.min(heightImg, widthImg) - 20 * factor_compass_grid, Math.ceil(y0) + 1, 20 * factor_compass_grid, 20 * factor_compass_grid);
        context.font = Math.round(0.6 * (y1 - y0)).toString() + 'pt Nunito';
        context.fillStyle = 'white';
        context.textAlign = "end";
        context.fillText(LEGEND_TEXT[l],
            widthImg - 0.025 * Math.min(heightImg, widthImg) - 30 * factor_compass_grid,
            y1 - Math.round(0.2 * (y1 - y0))
        );
    }

    if (SCENE_COLS > 1 || SCENE_ROWS > 1) {

        context.strokeStyle = 'rgb(128, 128, 128)';
        context.lineWidth = 3;

        context.beginPath();
        context.moveTo(0, heightImg / 2);
        context.lineTo(widthImg, heightImg / 2);
        context.stroke();

    }

    return canvas.toDataURL();

}

function setTimelapseEventsMax(dateStr) {

    if (events.length == 0) {
        return;
    }

    var iTimelapse = [];
    var dateStrTime = new Date(dateStr).getTime();

    for (let n = 0; n < events.length; n++) {

        var eventTime = new Date(events[n].time).getTime();

        if (eventTime <= dateStrTime) {
            iTimelapse.push(1, 1, 1);
        } else {
            iTimelapse.push(0, 0, 0);
        }
    }

    eventGeomDrawn.setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(iTimelapse), 3));
    eventGeomPicking.setAttribute('timelapse', new THREE.InstancedBufferAttribute(new Float32Array(iTimelapse), 3));

}


export { seismicViewer, addAnnotation, removeAnnotation, setCurrentStages, getImageData, setTimelapseEventsMax, resetEventTimelapse }